import React, { useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'

import Button from '~/components/Button'
import api from '~/services/api'
import { Container, ThumbsContainer } from './styles'
import Thumb from '~/components/Thumb'
import PhotoProfile from '~/assets/images/foto_perfil.svg'
import { toast } from 'react-toastify'
import pdfIcon from '~/assets/icons/pdfIcon.png'
import Skeleton from 'react-loading-skeleton'

export default function Previews(
  { callback,
    assetType,
    btnText='Buscar',
    update = false,
    url= '',
    userId='',
    name='',
    error='',
    loading,
    skeletonDivClass
  }) {

  const [files, setFiles] = useState([])
  const [showPdfIcon, setShowPdfIcon] = useState(false)


  const getMessage = () => {
    switch (assetType){
      case 'address':
        if (!error)
          return 'Erro: atualize o endereço no perfil do paciente'
        return 'Ocorreu um erro!'
      default:
        return 'Ocorreu um erro!'
    }
  }

  const sendFile = async file => {
    try {
      var formData = new FormData()
      formData.append('file', file)
      formData.append('assetType', assetType)
      const res = await api.post('/files', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      if (update && url !=='') {
        let fileData
        if (assetType !=='anvisa'){
          fileData = {
            file_id: res.data.id,
            user_id: userId,
            type: res.data.type,
            name
          }
        }
        else {
          fileData = {
            anvisa_id: res.data.id,
            user_id: userId,
            type: res.data.type,
            name
          }
        }
        await api.put(url, fileData)
          .then(()=>toast.success('Atualizado com sucesso'))
          .catch(()=>toast.error(getMessage()))
      }
      return res
    } catch (e) {
      toast.error(e)
    }
  }
  const { getRootProps, getInputProps } = useDropzone({
    noKeyboard: true,
    multiple: false,
    onDrop: async acceptedFiles => {
      const res = await sendFile(acceptedFiles[0])
      if (res.data) {
        setFiles(
          acceptedFiles.map(file =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            }),
          ),
        )
        !update && callback(res.data.id)
        if (res.data.type === 'identification' || res.data.type === 'address') {
          setShowPdfIcon(true)
        }
      }
    },
  })

  let thumbs = (
    <Thumb>
      <div className="inner">
        <img src={PhotoProfile} alt="" />
      </div>
    </Thumb>
  )

  if (Array.isArray(files) && files.length) {
    thumbs = files.map(file => (
      <Thumb key={file.name}>
        <div className="inner">
          <img src={showPdfIcon ? pdfIcon : file.preview} alt="" />
        </div>
      </Thumb>
    ))
  }

  useEffect(() => {
    files.forEach(file => URL.revokeObjectURL(file.preview))
  }, [files])

  return (
    <>
      {
        loading ?
          (
            <div className={skeletonDivClass}>
              <div className='row'>
                <div className='col-lg-1 col-2'>
                  <Skeleton width={90} height={90} circle={true} />
                </div>
                <div className='col-lg-10 col-10'>
                  <Skeleton className='mt-3' width='150px' height={44} />
                </div>
              </div>
            </div>
          ):
          (
            <Container>
              <ThumbsContainer>{thumbs}</ThumbsContainer>
              <div {...getRootProps({ className: 'dropzone' })}>
                <input {...getInputProps()} />
                <Button
                  type="button"
                  className="blue sm m-3 "
                  onClick={e => e.preventDefault()}
                >
                  {btnText}
                </Button>
              </div>
            </Container>
          )
      }
    </>

  )
}
