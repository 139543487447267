import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import moment from 'moment'
import { useParams } from 'react-router-dom'

import { Creators as ErrorActions } from '~/store/ducks/error'

import Yup from '~/helpers/yup'
import api from '~/services/api'
import UploadFile from '~/components/UploadAvatar'
import StatesBrasil from '~/helpers/StatesBrasil.json'
import Skeleton from 'react-loading-skeleton'
import { ButtonPrimary, CustomInput, CustomSelect } from '~/components'
import { toast } from 'react-toastify'
import { getAddressByCep } from '~/services/viaCEP'

let parseFormats = ['DD/MM/YYYY']

const EditProfileSchema = Yup.object().shape({
  user: Yup.object().shape({
    email: Yup.string()
      .email('Digite um email válido')
      .required('Digite um email'),
  }),
  profile: Yup.object().shape({
    full_name: Yup.string().required('Digite seu nome.'),
    birthdate: Yup.date()
      .format(parseFormats)
      .max(new Date(), 'Você não pode ter nascido no futuro!')
      .typeError('Insira uma data Válida'),
    gender: Yup.string().required('Selecione o gênero'),
    phone_number: Yup.string().removeSpecials(),
    mobile_number: Yup.string().removeSpecials(),
    rg: Yup.string(),
    cpf: Yup.string().removeSpecials().required('Digite o CPF'),
  }),
  address: Yup.object().shape({
    cep: Yup.string().required('Digite o CEP do seu endereço'),
    state: Yup.string().required('Digite o estado '),
    city: Yup.string().required('Digite a cidade '),
    street: Yup.string(),
    number: Yup.string(),
    complement: Yup.string(),
    district: Yup.string(),
  }),
})

export default function Edit({ history }) {
  const { register, handleSubmit, errors, setValue, watch } = useForm({
    validationSchema: EditProfileSchema,
  })
  // const error = useSelector(state => state.error)
  const [physician, setPhysician] = useState({})
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const cep = watch('address.cep')
  const { id } = useParams()
  const [avatarId, setAvatarId] = useState()

  const getPhysician = async physicianId => {
    setLoading(true)
    const res = await api.get(`/admin/physician/${physicianId}`)
    if (res.data.roles[0].slug !== 'physician') {
      toast.warn('Selecione um médico!')
      history.push('/admin/medicos')
    }
    setPhysician(res.data)
    setLoading(false)
  }

  useEffect(() => {
    getPhysician(id)
  }, [id])


  useEffect(() => {
    if (cep && cep.length === 8) {
      setLoading(true)
      getAddressByCep(cep).then((res) => {
        setValue('address.state', res.data.uf)
        setValue('address.city', res.data.localidade)
        setValue('address.street', res.data.logradouro)
        setValue('address.district', res.data.bairro)
      }).finally(() => setLoading(false))
    }
  }, [cep])

  useEffect(() => {
    setValue('profile', physician.profile)
    if (physician.profile?.birthdate)
      setValue(
        'profile.birthdate',
        moment(physician.profile?.birthdate).format('DD/MM/YYYY'),
      )
    if (physician.addresses?.length) setValue('address', physician.addresses[0])
    setValue('user.email', physician.email)
  }, [physician.profile])

  const onSubmit = async data => {
    setLoading(true)
    try {
      if (avatarId) data.profile.avatar_id = avatarId
      await api.put(`/admin/physician/${physician.physician.id}`, data)
      setLoading(false)
      const to = `/admin/medico/editar-medico/${physician.physician.id}`
      history.push(to)
    } catch (e) {
      dispatch(ErrorActions.setError(e.response))
      setLoading(false)
    }
  }

  return (
    <>
      <div className={` container-fluid admin_physician_edit`}>
        <div className='row'>
          <div className='col-lg-12'>
            <h2 className='admin_physician_edit_title'>Termine seu cadastro</h2>
          </div>
        </div>
        <div className='row'>
          <div className='col-lg-12'>
            <h2 className='admin_physician_edit_info_title'>Informações Pessoais</h2>
          </div>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='row'>
                <div className='col-lg-12'>
                  <p>Adicione uma foto de perfil</p>
                  <div className={`${!loading && 'd-none'}`}>
                    <div className='row mt-3'>
                      <div className='col-lg-1 col-3'>
                        <Skeleton width='90px' height='90px' className='rounded rounded-circle' />
                      </div>
                      <div className='col-lg-2 col-6'>
                        <Skeleton className='ml-3 mt-3' height='40px' />
                      </div>
                    </div>
                  </div>
                  <div className={`${loading && 'd-none'}`}>
                    <UploadFile callback={setAvatarId} />
                  </div>
                </div>
              </div>
              <div className='row mt-4'>
                <div className='col-lg-6'>
                  <div className={`${!loading && 'd-none'}`}>
                    <Skeleton width='30%' />
                    <Skeleton className='mt-1' height='40px' />
                  </div>

                  <div className={`${loading && 'd-none'}`}>
                    <CustomInput
                      label='Nome Completo'
                      inputName='profile.full_name'
                      inputPlaceholder='Nome'
                      error={errors.profile?.full_name}
                      register={register()}
                    />
                  </div>
                </div>
                <div className='col-lg-6'>
                  <div className={`${!loading && 'd-none'}`}>
                    <Skeleton width='30%' />
                    <Skeleton className='mt-1' height='40px' />
                  </div>

                  <div className={`${loading && 'd-none'}`}>
                    <CustomInput
                      label='Data de nascimento'
                      inputName='profile.birthdate'
                      inputPlaceholder='__/__/____'
                      error={errors.profile?.birthdate}
                      register={register()}
                      mask='99/99/9999'
                    />
                  </div>
                </div>
              </div>

              <div className='row'>
                <div className='col-lg-4'>
                  <div className={` mt-4 ${!loading && 'd-none'}`}>
                    <Skeleton width='30%' />
                    <Skeleton className='mt-1' height='40px' />
                  </div>

                  <div className={`${loading && 'd-none'}`}>
                    <CustomSelect
                      label='Gênero'
                      inputName='profile.gender'
                      error={errors.profile?.gender}
                      register={register}
                    >
                      <option value='m'>Masculino</option>
                      <option value='f'>Feminino</option>
                      <option value='-'>Prefiro não informar</option>
                    </CustomSelect>
                  </div>
                </div>

                <div className='col-lg-4'>
                  <div className={` mt-4 ${!loading && 'd-none'}`}>
                    <Skeleton width='30%' />
                    <Skeleton className='mt-1' height='40px' />
                  </div>

                  <div className={`${loading && 'd-none'}`}>
                    <CustomInput
                      label='RG'
                      inputName='profile.rg'
                      inputPlaceholder='9999999'
                      error={errors.profile?.rg}
                      register={register}
                    />
                  </div>
                </div>

                <div className='col-lg-4'>
                  <div className={` mt-4 ${!loading && 'd-none'}`}>
                    <Skeleton width='30%' />
                    <Skeleton className='mt-1' height='40px' />
                  </div>

                  <div className={`${loading && 'd-none'}`}>
                    <CustomInput
                      label='CPF'
                      inputName='profile.cpf'
                      inputPlaceholder='123.456.789-10'
                      error={errors.profile?.cpf}
                      mask='999.999.999-99'
                      register={register}
                    />
                  </div>
                </div>
              </div>


            </div>
          </div>

          <div className='row'>
            <div className='col-lg-6'>
              <div className={` mt-4 mb-4 ${!loading && 'd-none'}`}>
                <Skeleton width='30%' />
                <Skeleton className='mt-1' height='40px' />
              </div>

              <div className={`${loading && 'd-none'}`}>
                <CustomInput
                  label='Telefone pessoal'
                  inputName='profile.mobile_number'
                  inputPlaceholder='(11) 99999-9999'
                  error={errors.profile?.mobile_number}
                  mask='(99) 99999-9999'
                  register={register}
                />
              </div>

            </div>
          </div>

          <div className='row'>
            <div className='col-lg-12'>
              <h2 className='admin_physician_edit_info_title'>Informações para atendimento</h2>
            </div>
          </div>

          <div className='row'>
            <div className='col-lg-12'>
              <div className='row'>
                <div className='col-lg-6'>
                  <div className={` mt-4 ${!loading && 'd-none'}`}>
                    <Skeleton width='30%' />
                    <Skeleton className='mt-1' height='40px' />
                  </div>

                  <div className={`${loading && 'd-none'}`}>
                    <CustomInput
                      label='Telefone para agendamento de consultas'
                      inputName='profile.phone_number'
                      inputPlaceholder='(11) 99999-9999'
                      error={errors.profile?.phone_number}
                      mask='(99) 99999-9999'
                      register={register}

                    />
                  </div>
                </div>

                <div className='col-lg-6'>
                  <div className={` mt-4 ${!loading && 'd-none'}`}>
                    <Skeleton width='30%' />
                    <Skeleton className='mt-1' height='40px' />
                  </div>

                  <div className={`${loading && 'd-none'}`}>
                    <CustomInput
                      label='CEP'
                      inputName='address.cep'
                      inputPlaceholder='00000-000'
                      error={errors.adresss?.cep}
                      register={register}
                    />
                  </div>

                </div>

                <div className='col-lg-6'>
                  <div className={` mt-4 ${!loading && 'd-none'}`}>
                    <Skeleton width='30%' />
                    <Skeleton className='mt-1' height='40px' />
                  </div>

                  <div className={`${loading && 'd-none'}`}>
                    <CustomSelect
                      label='UF'
                      inputName='address.state'
                      error={errors.address?.state}
                      register={register}
                    >
                      {StatesBrasil.map(state => (
                        <option value={state.value}>{state.label}</option>
                      ))}
                    </CustomSelect>
                  </div>
                </div>

                <div className='col-lg-6'>
                  <div className={` mt-4 ${!loading && 'd-none'}`}>
                    <Skeleton width='30%' />
                    <Skeleton className='mt-1' height='40px' />
                  </div>

                  <div className={`${loading && 'd-none'}`}>
                    <CustomInput
                      label='Cidade'
                      inputName='address.city'
                      error={errors.address?.city}
                      register={register}

                    />
                  </div>
                </div>

                <div className='col-lg-6'>
                  <div className={` mt-4 ${!loading && 'd-none'}`}>
                    <Skeleton width='30%' />
                    <Skeleton className='mt-1' height='40px' />
                  </div>

                  <div className={`${loading && 'd-none'}`}>
                    <CustomInput
                      label='Bairro'
                      inputName='address.district'
                      error={errors.address?.district}
                      register={register}
                    />
                  </div>
                </div>


                <div className='col-lg-8'>
                  <div className={` mt-4 ${!loading && 'd-none'}`}>
                    <Skeleton width='30%' />
                    <Skeleton className='mt-1' height='40px' />
                  </div>

                  <div className={`${loading && 'd-none'}`}>
                    <CustomInput
                      label='Logradouro'
                      inputName='address.street'
                      error={errors.address?.street}
                      register={register}
                    />
                  </div>

                </div>

                <div className='col-lg-4'>
                  <div className={` mt-4 ${!loading && 'd-none'}`}>
                    <Skeleton width='30%' />
                    <Skeleton className='mt-1' height='40px' />
                  </div>

                  <div className={`${loading && 'd-none'}`}>
                    <CustomInput
                      label='Número'
                      inputName='address.number'
                      error={errors.address?.number}
                      register={register}
                    />
                  </div>

                </div>
              </div>

              <div className='row'>
                <div className='col-lg-6'>
                  <div className={` mt-4 ${!loading && 'd-none'}`}>
                    <Skeleton width='30%' />
                    <Skeleton className='mt-1' height='40px' />
                  </div>

                  <div className={`${loading && 'd-none'}`}>
                    <CustomInput
                      label='Complemento'
                      inputName='address.complement'
                      error={errors.address?.complement}
                      register={register}
                    />
                  </div>
                </div>
              </div>

              <div className='row'>

                <div className='col-lg-6'>
                  <div className={` mt-4 ${!loading && 'd-none'}`}>
                    <Skeleton width='30%' />
                    <Skeleton className='mt-1' height='40px' />
                  </div>

                  <div className={`${loading && 'd-none'}`}>
                    <CustomInput
                      label='Email'
                      inputName='user.email'
                      error={errors.user?.email}
                      register={register}
                    />
                  </div>

                </div>

                <div className='col-lg-12'>
                  <ButtonPrimary
                    btnType='submit'
                    text={loading ? 'Carregando...' : 'Continuar'}
                    disabled={loading}
                  />
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  )
}
