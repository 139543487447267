import React from 'react'

const ButtonSecondary = ({
 text = '',
 btnClass = '',
 width = '',
 callBack,
 disabled = false,
 controls,
 target,
 toggle,
 dismiss,
 icon,
 btnMt='mt-3'
}) => {
  return (
    <button
      className={`btn button__secondary font-weight-bold ${btnMt} ${btnClass} ${width}`}
      type='button'
      onClick={callBack}
      disabled={disabled}
      data-toggle={toggle}
      data-target={target}
      aria-controls={controls}
      data-dismiss={dismiss}
    >
      {icon}
      {text}
    </button>
  )
}

export { ButtonSecondary }
