import React, { useState, useCallback, useRef, useEffect } from 'react'
import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import api from '~/services/api'

const pixelRatio = window.devicePixelRatio || 1

function getResizedCanvas(canvas, newWidth, newHeight) {
  const tmpCanvas = document.createElement('canvas')
  tmpCanvas.width = newWidth
  tmpCanvas.height = newHeight

  const ctx = tmpCanvas.getContext('2d')
  ctx.drawImage(
    canvas,
    0,
    0,
    canvas.width,
    canvas.height,
    0,
    0,
    newWidth,
    newHeight,
  )

  return tmpCanvas
}

const uploadFile = async (file, setFiles, preview, route) => {
  const res = await api.post(route, file, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
  if (res.data) {
    setFiles([
      {
        id: res.data.id,
        name: 'Avatar',
        preview,
      },
    ])
  }
}

function generateDownload(
  previewCanvas,
  crop,
  setFiles,
  route,
  isPrivate,
  assetType,
) {
  if (!crop || !previewCanvas) {
    return
  }

  const canvas = getResizedCanvas(previewCanvas, crop.width, crop.height)
  const preview = canvas.toDataURL()
  canvas.toBlob(blob => {
    let formData = new FormData()
    formData.append('assetType', assetType)
    formData.append('isPrivate', isPrivate)
    formData.append('file', blob, 'avatar.jpg')

    uploadFile(formData, setFiles, preview, route).then()
  })
}

const CropModal = ({
  files,
  setShowCrop,
  setFiles,
  height = 234,
  aspect = 1,
  route = '/avatar',
  width = 234,
  isPrivate = true,
  assetType,
}) => {
  const imgRef = useRef(null)
  const previewCanvasRef = useRef(null)
  const [crop, setCrop] = useState({
    height,
    width,
    aspect,
  })
  const [completedCrop, setCompletedCrop] = useState(null)

  const onLoad = useCallback(img => {
    imgRef.current = img
  }, [])

  useEffect(() => {
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return
    }

    const image = imgRef.current
    const canvas = previewCanvasRef.current
    const cropArea = completedCrop

    const scaleX = image.naturalWidth / image.width
    const scaleY = image.naturalHeight / image.height
    const ctx = canvas.getContext('2d')

    canvas.width = cropArea.width * pixelRatio
    canvas.height = cropArea.height * pixelRatio

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0)
    ctx.imageSmoothingQuality = 'high'

    ctx.drawImage(
      image,
      cropArea.x * scaleX,
      cropArea.y * scaleY,
      cropArea.width * scaleX,
      cropArea.height * scaleY,
      0,
      0,
      cropArea.width,
      cropArea.height,
    )
  }, [completedCrop])

  return (
    <div
      className="modal fade show d-block"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog h-100" role="document">
        <div className="modal-content" style={{ height: '95%' }}>
          <div className="modal-body d-flex" style={{ height: '80%' }}>
            <ReactCrop
              src={files[0].preview}
              onImageLoaded={onLoad}
              crop={crop}
              onChange={c => setCrop(c)}
              onComplete={c => setCompletedCrop(c)}
              className="crop"
            />
            <div className="d-none">
              <canvas
                ref={previewCanvasRef}
                style={{
                  width: Math.round(completedCrop?.width ?? 0),
                  height: Math.round(completedCrop?.height ?? 0),
                }}
              />
            </div>
          </div>
          <div className="modal-footer d-flex justify-content-center">
            <button
              className="btn btn-success"
              type="button"
              disabled={!completedCrop?.width || !completedCrop?.height}
              onClick={() => {
                generateDownload(
                  previewCanvasRef.current,
                  completedCrop,
                  setFiles,
                  route,
                  isPrivate,
                  assetType,
                )
                setShowCrop(false)
              }}
            >
              Salvar
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export { CropModal }
