import React, { useState, useEffect } from 'react'
import Skeleton from 'react-loading-skeleton'

import api from '~/services/api'
import ParserPubMed from '~/helpers/ParserPubMed'
import Article from '~/components/Article'

export default function Articles() {
  const [loading, setLoading] = useState(true)
  const [articlesId, setArticlesId] = useState()
  const [articles, setArticles] = useState([])

  const getArticlesId = async () => {
    setLoading(true)
    const res = await api.get('/articles')
    setArticlesId(res.data)
  }

  useEffect(() => {
    getArticlesId()
  }, [])

  const getArticles = async () => {
    setLoading(true)
    if (articlesId) {
      const parsed = await ParserPubMed(articlesId)
      setArticles(parsed)
    }
    setLoading(false)
  }

  useEffect(() => {
    getArticles()
  }, [articlesId])

  return (
    <div className='container-fluid physician__articles'>
      {loading && (
        <>
        <div className='row'>
          <div className='col-lg-12'>
            <Skeleton width='80%'/>
          </div>
        </div>

          <div className='row mt-2'>
            <div className='col-lg-3'>
              <Skeleton/>
            </div>
            <div className='col-lg-3 offset-lg-6'>
              <Skeleton/>
            </div>
          </div>

          <div className='row mt-4'>
            <div className='col-lg-12'>
              <Skeleton count={5} className='mt-2'/>
            </div>
          </div>

          <div className='row mt-5'>
            <div className='col-lg-12'>
              <Skeleton width='80%'/>
            </div>
          </div>

          <div className='row mt-2'>
            <div className='col-lg-3'>
              <Skeleton/>
            </div>
            <div className='col-lg-3 offset-lg-6'>
              <Skeleton/>
            </div>
          </div>

          <div className='row mt-4'>
            <div className='col-lg-12'>
              <Skeleton count={5} className='mt-2'/>
            </div>
          </div>

        </>
      )}
      {articles.length > 0 &&
        !loading &&
        articles.map(article => <Article article={article} key={article.id} />)}
    </div>
  )
}
