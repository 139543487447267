import React, { useEffect, useState } from 'react'

import PhysicianList from './PhysiciansList'
import api from '~/services/api'
import { useForm } from 'react-hook-form'
import Specialties from '~/helpers/Specialties.json'
import { useSelector } from 'react-redux'
import TagManager from 'react-gtm-module'
import { ButtonPrimary, CustomInput, CustomSelect } from '~/components'
import { Skeleton } from '@material-ui/lab'
import { getStatesOrCities } from '~/services/servicoDados'

export default function Physicians() {
  const [count, setCount] = useState({ data: [] })
  const [countTitle, setCountTitle] = useState()
  const [physicians, setPhysicians] = useState({ data: [] })
  const [status, setStatus] = useState('')
  const [title, setTitle] = useState('Médicos')
  const [loading, setLoading] = useState(false)
  const [loadingPhysicians, setLoadingPhysicians] = useState(false)
  const [statesBrasil, setStatesBrasil] = useState([])
  const [citiesBrasil, setCitiesBrasil] = useState([])
  const [form, setForm] = useState({
    state: '',
    city: '',
  })
  const { register, watch } = useForm()
  const search = watch('search')
  const specialty = watch('specialty')
  const contacted = watch('contacted')
  const prescribes = watch('prescribes')
  const onlineConsultation = watch('onlineConsultation')
  const { session } = useSelector(state => state.auth)

  const getCount = () => {
    setLoading(true)
    api.get('/physicians/count/all')
      .then(r => setCount(r.data))
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    setForm(prevState => {
      return {
        ...prevState,
        city: '',
      }
    })
    getStatesOrCities(form.state)
      .then((r) =>
        form.state ? setCitiesBrasil(r.data) : setStatesBrasil(r.data),
      )
  }, [form.state])

  useEffect(() => {
    getCount()

    const tagManagerArgs = {
      gtmId: 'GTM-59529VR',
      dataLayer: {
        userId: session.profile.id,
        role: session.roles[0],
        name: session.profile.full_name,
        page: '/admin/medicos',
      },
    }
    TagManager.initialize(tagManagerArgs)
  }, [])

  const setTotalPhysicians = () => {
    const { approved, disapproved, pending } = count
    return parseInt(approved) + parseInt(disapproved) + parseInt(pending)
  }

  const getPhysicians = (
    page,
    searchQuery,
    physicianSpecialty,
    physicianStatus,
    physicianPrescribes,
    physicianContacted,
    physicianOnlineConsultation,
    state,
    city,
  ) => {
    setLoading(true)
    setLoadingPhysicians(true)
    let query = ``
    if (physicianStatus) query += `status=${physicianStatus}&`
    if (page) query += `page=${page}&`
    if (searchQuery) query += `search=${encodeURIComponent(searchQuery)}&`
    if (physicianSpecialty) query += `specialty=${physicianSpecialty}&`
    if (physicianPrescribes) query += `prescribes=${(physicianPrescribes === 'sim')}&`
    if (physicianContacted) query += `contacted=${(physicianContacted === 'sim')}&`
    if (physicianOnlineConsultation) query += `onlineConsultation=${(physicianOnlineConsultation === 'sim')}&`
    if (state) {
      const index = statesBrasil.findIndex(value => value.id === parseInt(state))
      if (index > -1) query += `state=${encodeURIComponent(statesBrasil[index].sigla)}&`
    }
    if (city) {
      const index = citiesBrasil.findIndex(value => value.id === city.toString())
      if (index > -1) query += `city=${encodeURIComponent(citiesBrasil[index].nome)}&`
      console.log(citiesBrasil, index, city)
    }

    api.get(`/physicians?${query}`)
      .then(res => setPhysicians(res.data))
      .finally(() => {
        setLoading(false)
        setLoadingPhysicians(false)
      })
  }


  const handlePageChange = page => {
    window.scrollTo(0, 0)
    getPhysicians(page, search, specialty, status, prescribes, contacted, onlineConsultation)
  }


  switch (title) {
    case 'approved':
      setTitle('Médicos ativos: ')
      setCountTitle(count.approved)
      break
    case 'pending':
      setTitle('Médicos em avaliação: ')
      setCountTitle(count.pending)
      break
    case 'disapproved':
      setTitle('Médicos inativos: ')
      setCountTitle(count.disapproved)
      break
    case '':
      setTitle('Todos os médicos: ')
      setCountTitle(setTotalPhysicians())

  }

  const setElementValue = e => {
    const { value, name } = e.currentTarget

    setForm(prevState => {
      return {
        ...prevState,
        [name]: value,
      }
    })
  }


  return (
    <div className='container-fluid admin_physician_list'>
      <div className='row'>
        <div className='col-lg-12'>
          <h2 className='admin_physician_list_title'>{title}{countTitle}</h2>
        </div>
      </div>
      {loading ? (
        <div className='row'>
          <div className='col-lg-6 col-12'>
            <Skeleton height='40px' />
          </div>
        </div>
      ) : (
        <div className='row'>
          <div className='col-lg-12'>
        <span className='badge badge_approved mb-1'
              onClick={() => {
                setStatus('approved')
                getPhysicians(1, search, specialty, 'approved', prescribes, contacted, onlineConsultation, form.state, form.city)
                setTitle('approved')
              }}>
          Ativos: {count.approved}
        </span>
            <span
              className='badge badge_pending'
              onClick={() => {
                setStatus('pending')
                getPhysicians(1, search, specialty, 'pending', prescribes, contacted, onlineConsultation, form.state, form.city)
                setTitle('pending')
              }}>
          Em avaliação: {count.pending}
        </span>
            <span
              className='badge badge_disapproved'
              onClick={() => {
                setStatus('disapproved')
                getPhysicians(1, search, specialty, 'disapproved', prescribes, contacted, onlineConsultation, form.state, form.city)
                setTitle('disapproved')
              }}
            >
          Inativos: {count.disapproved}
        </span>
            <span
              className='badge badge_all'
              onClick={() => {
                setStatus('')
                getPhysicians(1, search, specialty, status, prescribes, contacted, onlineConsultation)
                setTitle('')
              }}
            >
          Total: {setTotalPhysicians()}
        </span>
          </div>
        </div>)}
      <div className='row mt-3'>
        <div className='col-lg-12'>
          <CustomInput
            inputName='search'
            inputPlaceholder='Nome ou Email ou CPF'
            inputClass='w-100'
            icon='far fa-search'
            register={register}
          />
        </div>
      </div>
      <div className='row mt-3'>
        <div className='col-lg-3'>
          <CustomSelect
            register={register}
            inputName='specialty'
            label='Especialidade'
          >
            <option value=''>Todas</option>
            {Specialties.map(item => (
              <option value={item}>{item}</option>
            ))}
          </CustomSelect>
        </div>
        <div className='col-lg-3'>
          <CustomSelect
            register={register}
            inputName='prescribes'
            label='Prescreve'
          >
            <option value=''>Todos</option>
            <option value='sim'>Sim</option>
            <option value='nao'>Não</option>
          </CustomSelect>
        </div>
        <div className='col-lg-3'>
          <CustomSelect
            register={register}
            inputName='contacted'
            label='Contatado'
          >
            <option value=''>Todos</option>
            <option value='sim'>Sim</option>
            <option value='nao'>Não</option>
          </CustomSelect>
        </div>

        <div className='col-lg-3'>
          <CustomSelect
            register={register}
            inputName='onlineConsultation'
            label='Telemed'
          >
            <option value=''>Todos</option>
            <option value='sim'>Sim</option>
            <option value='nao'>Não</option>
          </CustomSelect>
        </div>
      </div>
      <div className='row mt-3'>
        <div className='col-sm-12 col-lg-5'>
          <CustomSelect className='form-control'
                        label='Selecione um estado'
                        id='state'
                        inputName='state'
                        callBack={setElementValue}
          >
            <option value=''>Selecione</option>
            {statesBrasil.map(state => (
              <option value={state.id}>{state.nome}</option>
            ))}
          </CustomSelect>
        </div>
        <div className='col-sm-12 col-lg-5'>
          <CustomSelect className='form-control'
                        label='Selecione uma cidade'
                        id='city'
                        inputName='city'
                        callBack={setElementValue}
                        disable={!form.state}
          >
            <option value=''>Selecione</option>
            {citiesBrasil.map(city => (
              <option value={city.id}>{city.nome}</option>
            ))}
          </CustomSelect>
        </div>
        <div className='col-lg-2 d-flex justify-content-end h-100'>
          <ButtonPrimary
            callBack={() => {
              getPhysicians(1,
                search,
                specialty,
                status,
                prescribes,
                contacted,
                onlineConsultation,
                form.state,
                form.city)
              setTitle('Médicos')
              setCountTitle('')
            }
            }
            loading={loading}
            disabled={loading}
            text='Buscar'
            btnClass='btn_search_button mt-4 w-100'
            btnMt=''
          />
        </div>
      </div>


      {
        loadingPhysicians || loading ? (
          <div className='row admin_physician_list_skeleton'>
            <div className='col-lg-1 admin_physician_list_skeleton_avatar'>
              <Skeleton width='90px' height='140px' className='rounded rounded-circle mt-1' />
            </div>
            <div className='col-lg-10  admin_physician_list_skeleton_padding'>
              <Skeleton className='admin_physician_list_skeleton_width' height='25px' width='70%' />
              <Skeleton className='admin_physician_list_skeleton_width mt-1' height='25px' width='65%' />
              <Skeleton className='admin_physician_list_skeleton_width mt-1' height='25px' width='60%' />
              <Skeleton className='admin_physician_list_skeleton_width mt-1' height='25px' width='50%' />
              <Skeleton className='admin_physician_list_skeleton_width mt-1' height='25px' width='60%' />
              <Skeleton className='admin_physician_list_skeleton_width mt-1' height='25px' width='55%' />
              <Skeleton className='admin_physician_list_skeleton_width mt-1' height='25px' width='60%' />
              <Skeleton className='admin_physician_list_skeleton_width mt-1' height='25px' width='60%' />
            </div>
          </div>) : (
          <PhysicianList loading={loadingPhysicians && 'd-none'} list={physicians}
                         handlePageChange={handlePageChange} />
        )
      }

    </div>
  )
}
