import React, { useEffect, useState } from 'react'
import { Col, Modal, Row } from 'react-bootstrap'
import { useForm } from 'react-hook-form'

import Button from '~/components/Button'
import { Input } from '~/components/Form'
import api from '~/services/api'
import { getAddressByCep } from '~/services/viaCEP'


export default function AddAddress({ show, onHide, addressId }) {
  const { register, handleSubmit, errors, setValue, watch } = useForm()
  const [loading, setLoading] = useState(false)

  const [form, setForm] = useState({
    address: {
      cep: '',
      state: '',
      city: '',
      street: '',
      district: '',
      number: '',
      complement: ''
    },
  })

  const submitAddress = async data => {
    setLoading(true)
    data.country = 'Brasil'
    if (addressId) await api.put(`/addresses/${addressId}`, data.address)
    else await api.post('/addresses', data.address)
    setLoading(false)
    onHide()
  }

  const getAddress = async () => {
    if (addressId) {
      const res = await api.get(`/addresses/${addressId}`)
      setValue('address', res.data)
    }
  }
  useEffect(() => {
    if (show) {
      getAddress()
    }
  }, [show])

  const changeInput = (e) => {
    const { value, name } = e.currentTarget

    let auxName = name.replace('address.', '')

    if (auxName === 'cep' && value) {
      let cleanCep = value.replace('-', '')
      cleanCep = cleanCep.replaceAll('_', '')
      if (cleanCep.length === 8) {
        getAddressByCep(cleanCep).then(r => {
          setForm(prevState => {
            return {
              ...prevState,
              address: {
                state: r.data.uf,
                city: r.data.localidade,
                street: r.data.logradouro,
                district: r.data.bairro,
              },
            }
          })
        })
      }
    }

    setForm(prevState => {
      return {
        ...prevState,
        address: {
          [auxName]: value,
        },
      }
    })

  }

  return (
    <Modal
      show={show}
      onHide={onHide}
      size='md'
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id='contained-modal-title-vcenter'>
          Adicionar Endereço
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit(submitAddress)}>
          <Row>
            <Col lg={6}>
              <Input
                onChange={(e) => changeInput(e)}
                type='text'
                onkeypress='return onlynumber();'
                label='CEP'
                name='address.cep'
                placeholder='00000-000'
                mask='99999-999'
                value={form.address.cep}
                size='md'
                fluid
                className='my-2'
                error={errors.addresss?.cep}
                ref={register}
              />
            </Col>

            <Col lg={6}>
              <Input
                onChange={(e) => changeInput(e)}
                type='text'
                label='UF'
                name='address.state'
                value={form.address.state}
                placeholder=''
                size='md'
                fluid
                className='my-2'
                error={errors.state}
                ref={register}
              />
            </Col>

            <Col lg={6}>
              <Input
                onChange={(e) => changeInput(e)}
                type='text'
                label='Cidade'
                name='address.city'
                value={form.address.city}
                placeholder=''
                size='md'
                fluid
                className='my-2'
                error={errors.city}
                ref={register}
              />
            </Col>

            <Col lg={6}>
              <Input
                onChange={(e) => changeInput(e)}
                type='text'
                label='Bairro'
                name='address.district'
                value={form.address.district}
                placeholder=''
                size='md'
                fluid
                className='my-2'
                error={errors.district}
                ref={register}
              />
            </Col>

            <Col lg={8}>
              <Input
                onChange={(e) => changeInput(e)}
                type='text'
                label='Logradouro'
                name='address.street'
                value={form.address.street}
                placeholder=''
                size='md'
                fluid
                className='my-2'
                error={errors.street}
                ref={register}
              />
            </Col>

            <Col lg={4}>
              <Input
                onChange={(e) => changeInput(e)}
                type='text'
                label='Número'
                name='address.number'
                value={form.address.number}
                placeholder=''
                size='md'
                fluid
                className='my-2'
                error={errors.number}
                ref={register}
              />
            </Col>

            <Col lg={6}>
              <Input
                onChange={(e) => changeInput(e)}
                type='text'
                label='Complemento'
                name='address.complement'
                value={form.address.complement}
                placeholder=''
                size='md'
                fluid
                className='my-2'
                error={errors.complement}
                ref={register}
              />
            </Col>
            <Col xs={12} className='d-flex justify-content-end'>
              <Button className='primary' type='submit' disabled={loading}>
                Salvar
              </Button>
            </Col>
          </Row>
        </form>
      </Modal.Body>
    </Modal>
  )
}
