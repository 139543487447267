import React, { useEffect, useState } from 'react'
import { CustomCheckbox, CustomInput, CustomTextArea } from '~/components'
import { createCampaign, updateCampaigns } from '~/services/requests'
import { toast } from 'react-toastify'
import * as $ from 'jquery'


const ModalNewCampaign = (
  {
    updateList,
    defaultValues = {
      name: '',
      description: '',
      active: false,
    },
    isPut = false,
    slug = ''
  }) => {
  const [form, setForm] = useState(defaultValues)

  useEffect(()=> {
    if (isPut)
      setForm(defaultValues)
  }, [defaultValues])

  const changeOptionsForm = (e) => {
    const { value, checked, type, name } = e.currentTarget
    setForm(prevState => {
      return {
        ...prevState,
        [name]: type === 'checkbox' ? checked : value,
      }
    })
  }

  const sendData = () => {
    if (isPut) {
      updateCampaigns(form, slug).then((r) => {
        toast.success('Atualizada com sucesso')
        updateList(r.data)
        setForm(defaultValues)
        $('#campaignModal').modal('hide')
      })
        .catch(() => toast.error('Ocorreu um erro ao atualizar campanha'))
    } else {
      createCampaign(form)
        .then((r) => {
          toast.success('Uma nova campanha foi criada')
          updateList(r.data)
          setForm(defaultValues)
          $('#campaignModal').modal('hide')
        })
        .catch(() => toast.error('Ocorreu um erro ao criar campanha'))
    }
  }


  return (
    <div className='modal fade'
         id='campaignModal'
         tabIndex='-1'
         role='dialog'
         aria-labelledby='campaignModalLabel'
         aria-hidden='true'
    >
      <div className='modal-dialog' role='document'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h5 className='modal-title'
                id='campaignModalLabel'
            >
              { isPut ?
                'Atualizar Campanha'
                :
                'Cadastrar Campanha'
              }
            </h5>
            <button type='button'
                    className='close'
                    data-dismiss='modal'
                    aria-label='Close'
                    onClick={() => setForm(defaultValues)}
            >
              <span aria-hidden='true'>&times;</span>
            </button>
          </div>
          <div className='modal-body'>
            <div className='row'>
              <div className='col-8'>
                <CustomInput label='Nome'
                             inputName='name'
                             defaultValue={form.name}
                             callBack={changeOptionsForm}
                />
              </div>
              <div className='col-4'>
                <CustomCheckbox label='Ativo'
                                inputName='active'
                                inputChecked={form.active}
                                callBack={changeOptionsForm}
                                inputClass='my-4'
                />
              </div>
            </div>
            <div className='row'>
              <div className='col-12'>
                <CustomTextArea label='Descrição'
                                inputName='description'
                                defaultValue={form.description}
                                callBack={changeOptionsForm}
                />
              </div>
            </div>

          </div>
          <div className='modal-footer'>
            <button type='button'
                    className='btn btn-secondary'
                    data-dismiss='modal'
                    onClick={() => setForm(defaultValues)}
            >
              Cancelar
            </button>
            <button type='button'
                    className='btn btn-primary'
                    onClick={() => sendData()}
            >
              {isPut? 'Atualizar': 'Cadastrar'}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export { ModalNewCampaign }
