import React, { useEffect, useState } from 'react'
import api from '~/services/api'
import { toast } from 'react-toastify'
import * as $ from 'jquery'


const ModalConfimation = ({clicked=false, orderId}) => {
  const [time, setTime] = useState(5)

  useEffect(()=> {
    if (time >0 && clicked) {
      setTimeout(()=> setTime(time-1), 1000)
    }
  }, [time, clicked])

  const deleteOrder = async ()=> {
    await api.delete(`order/delete/${orderId}`)
      .then(()=> {
        toast.success('Pedido Deletado com sucesso')
        $('#confirmModal').modal('hide')
      })
      .catch(()=>toast.error('Erro ao deletar pedido'))
  }

  return (
    <div className="modal fade" id="confirmModal" tabIndex="-1" role="dialog" aria-labelledby="confirm" aria-hidden="true">
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="modalLabel">Você realmente deseja deletar este pedido?</h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <span className="text-danger">Atenção! Este procedimento não pode ser desfeito</span>
          </div>
          <div className="modal-footer">
            <button type="button"
                    className="btn btn-secondary"
                    data-dismiss="modal"
            >
              Cancelar
            </button>
            <button type="button"
                    className="btn btn-primary"
                    disabled={time !==0}
                    onClick={()=>deleteOrder()}
            >
              Deletar {time ===0 ? '': `(${time})`}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export {ModalConfimation}
