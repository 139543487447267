let errors = [];

function ValidationContract(){
  errors = [];
}

ValidationContract.prototype.isRequired = (value, message,field) => {
  if(!String(value) || String(value).length <= 0)
    errors.push([field, message]);
}

ValidationContract.prototype.hasMinLen = (value, min, message,field) => {
  if(!value || value.length < min)
    errors.push([field, message]);}

ValidationContract.prototype.hasMaxLen = (value, max, message,field) => {
  if(!value || value.length > max)
    errors.push([field, message]);}

ValidationContract.prototype.hasLen = (value, len, message,field) => {
  if(!value || value.toString().length !== len)
    errors.push([field, message]);}

ValidationContract.prototype.isInteger = (value, message,field) => {
  if(!String(value) || !Number.isInteger(value))
    errors.push([field, message]);}

ValidationContract.prototype.isEmail = (value, message,field) => {
  const reg = new RegExp(/^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/);
  if(!reg.test(value))
    errors.push([field, message]);}

ValidationContract.prototype.errors = () => errors;

ValidationContract.prototype.clear = () => errors = [];

ValidationContract.prototype.isValid = () => errors.length === 0;

export default ValidationContract;
