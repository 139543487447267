import React from 'react'
import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import './index.css'
import App from './App'

import 'jquery/dist/jquery.min'
import 'bootstrap/dist/js/bootstrap.min'
import './assets/custom-css/main.scss'


const environment = process.env.REACT_APP_ENVIRONMENT

if (environment !== 'development') {
  Sentry.init({
    dsn: 'https://5b5c9895177d4dfb8a16b8eae632d45c@o1054363.ingest.sentry.io/6039745',
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.05,
  })
}

ReactDOM.render(<App />, document.getElementById('root'))
