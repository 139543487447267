import React from 'react'
import NumberFormat from 'react-number-format'
import PhysicianImg from '~/assets/images/physician-default.png'
import { Currency } from '~/components/Currency'
import { Skeleton } from '@material-ui/lab'
import { formatDate, formatText } from '~/shared/formatAndMasks'

const PhysicianCard = ({ data, phone, email, loading, ...rest }) => {
  const profile = data.user?.profile
  const prefix = profile?.gender === 'm' ? 'Dr.' : 'Dra.'

  const getClass = status => {
    switch (status) {
      case 'approved':
        return 'fa fa-check-circle admin_physician_card_icon_vector mt-1 mr-1 text-white'
      case 'disapproved':
        return 'fa fa-times-circle admin_physician_card_icon_vector mt-1 mr-1 text-white'
      case 'pending':
        return 'fa fa-question-circle admin_physician_card_icon_vector mt-1 mr-1 text-white'
      default:
        return ''
    }
  }

  const getContainerClass = status => {
    switch (status) {
      case 'approved':
        return 'admin_physician_card_container_approve physician_card-success'
      case 'disapproved':
        return 'admin_physician_card_container_disapprove physician_card-disapprove'
      case 'pending':
        return 'admin_physician_card_container_pending physician_card-pending'
      default:
        return ''
    }
  }
  return (
    <>
          <div
            className={` admin_physician_card mt-4 ${getContainerClass(data.status)}`}
          >
            <div className="row">
                <div className="col-lg-1  admin_physician_card_avatar pt-3">
                  {profile?.avatar?.id ? (
                    <img
                      className="mx-auto admin_physician_card_avatar_img rounded-circle rounded"
                      src={profile?.avatar.uuidUrl}
                      alt="Avatar"
                    />
                  ) : (
                    <img
                      className="mx-auto admin_physician_card_avatar_img rounded-circle rounded"
                      src={PhysicianImg}
                      alt="Avatar"
                    />
                  )}
                </div>

              <div className='col-lg-10'>
                <div className='row'>
                  <div className="col-lg-12">
                    <div className="admin_physician_card_body">
                      <div className='row'>
                        <div className='col-lg-12 d-flex'>
                          <div className='admin_physician_card_body_header d-flex flex-wrap align-items-center'>
                            <h3 className="admin_physician_card_body_title text-capitalize">
                              {prefix} {profile?.full_name}{' '}
                            </h3>
                            {data.online_consultation && (
                              <h4>
                            <span
                              className="telemed_badge  font-weight-normal"
                              style={{ 'background-color': '#72c7b6' }}
                            >
                              <i className="fas fa-video" />
                              Faz Telemedicina
                            </span>
                              </h4>
                            )}
                            <h4>
                              <span
                                className=" badge-secondary font-weight-normal"
                                style={{ 'background-color': '#72c7b6' }}
                              >
                                {formatText(data.specialty, 30)}
                              </span>
                            </h4>
                            <h4>
                          <span
                            className=" badge-secondary font-weight-normal"
                            style={{ 'background-color': '#72c7b6' }}
                          >
                            {data.prescribes ? 'É prescritor' : 'Não é prescritor'}
                          </span>
                            </h4>

                          </div>
                        </div>
                      </div>

                      <div className="d-flex ">
                        <div>
                          <p className="card-text">
                            Telefone:{' '}
                            {profile?.phone_number && (
                              <>
                                <NumberFormat
                                  value={profile?.phone_number}
                                  displayType={'text'}
                                  format="(##) #####-####"
                                />
                              </>
                            )}
                          </p>
                          <p className="card-text">
                            Celular: {' '}
                            {profile?.mobile_number && (
                              <NumberFormat
                                value={profile?.mobile_number}
                                displayType={'text'}
                                format="(##) #####-####"
                                className="mt-2"
                              />
                            )}
                          </p>
                          <p className="card-text">
                            Email: {' '}
                            {data.user?.email && (
                              <span>{data.user.email}</span>
                            )}
                          </p>
                          <p className="card-text">
                            Endereço: {' '}
                            {data.user?.addresses.length > 0 && (
                              <span>
                              {data.user.addresses[0].city}
                            </span>
                            )}
                          </p>
                          <p className="card-text">
                            <div>
                          <span>
                            Cadastro: {formatDate(data.created_at)}
                          </span>

                            </div>
                          </p>
                          <p className='card-text'>
                            {formatDate(data.evaluation_date) && (
                              <span>
                                Liberação: {formatDate(data.evaluation_date)}
                              </span>
                            )}
                          </p>
                          <p className="card-text">
                            Valor da consulta: {' '}
                            {data.price && (
                              <span className='default-green'> <Currency value={data.price} currency={"R$"} /> </span>
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-1 d-flex justify-content-end">
                <i className={getClass(data.status)} />
              </div>
            </div>
          </div>
    </>
  )
}

export { PhysicianCard }
