import React, { useState } from 'react'
import { Row, Col, Modal, Alert } from 'react-bootstrap'
import api from '~/services/api'
import Button from '~/components/Button/index'
import { Input } from '~/components/Form/index'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
export default function NewModalForgotPassword({ show, handleClose }) {
  const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState('')
  const [errorEmail, setErrorEmail] = useState(false)

  const { handleSubmit } = useForm()

  const onSubmit = async event => {
    event.preventDefault()
    setLoading(true)
    await api.post('/forgotpassword', {email:email}).then(res=>{
      setLoading(false);
      setErrorEmail(false)
      toast.success('Email com as informações para a alteração de senha enviado!')
      handleClose(false)
    }).catch(err => {
      setLoading(false);
      setErrorEmail(true)
      toast.error('Email inválido! Por favor verifique o campo e tente novamente.')
    });
  }


  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Recuperar a senha</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Alert variant="warning">
          Preencha seu email de cadastro abaixo e enviaremos um email com
          intruções para você alterar sua senha
        </Alert>
        <form id="formForgotPassword" onSubmit={event => handleSubmit(onSubmit(event))}>
          <Row>
            <Col>
              <Input
                type="email"
                label="Email para recuperação"
                name="email"
                size="md"
                fluid
                onChange={(event)=>setEmail(event.target.value)}
                className="my-3"
                required={true}
                error={errorEmail}
              />
            </Col>
          </Row>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          form="formForgotPassword"
          type="submit"
          className="primary"
          disabled={loading}
        >
          {loading ? 'Carregando ...': 'Enviar'}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
