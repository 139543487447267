import React from 'react'

import { Redirect } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { Creators as AuthActions } from '~/store/ducks/auth'
import TagManager from 'react-gtm-module'

export default function Logout() {
  const dispatch = useDispatch()

  localStorage.clear()

  let tagManagerArgs = {
    gtmId: 'GTM-59529VR',
    dataLayer: {
      event: 'logout',
      userId: '',
      role: '',
      name: '',
    },
  }
  TagManager.dataLayer(tagManagerArgs)

  dispatch(AuthActions.logout())
  return <Redirect to="/" />
}
