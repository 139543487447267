export const TOKEN_KEY = '@DrCannabis:JWT_TOKEN'
export const isAuthenticated = () => localStorage.getItem(TOKEN_KEY) !== null
export const getToken = () => localStorage.getItem(TOKEN_KEY)
export const setToken = token => {
  localStorage.setItem(TOKEN_KEY, token)
}
export const removeToken = () => {
  localStorage.removeItem(TOKEN_KEY)
  localStorage.removeItem('telemedicine')
}
