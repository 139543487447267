import React, { useEffect, useState } from 'react'
import successImg from '../../assets/images/telemed/Success Icon.png'
import logo from '../../assets/images/telemed/logo/logo.png'
import reciboFooter from '../../assets/images/telemed/reciboFooter.png'
import { Currency } from '../../components/Currency'
import moment from 'moment'
import 'moment/locale/pt-br'
import { cpfMask, phoneMask } from '~/shared/formatAndMasks'


const SchedulingReceipt = ({ display, event, isPhysician = false }) => {
  moment.locale('pt-br')

  const [info, setInfo] = useState({
    dayString: '',
    day: '',
    month: '',
    hourStart: '',
    hourEnd: '',
    personalName: '',
    price: '',
  })

  useEffect(() => {
    setInfo(prevState => {
      return {
        ...prevState,
        dayString: moment(event?.HourStart).format('dddd'),
        day: moment(event?.HourStart).format('DD'),
        month: moment(event?.HourStart).format('MMMM'),
        hourStart: moment(event?.HourStart).format('HH:mm'),
        hourEnd: moment(event?.HourEnd).format('HH:mm'),
        personalName: isPhysician ? event?.room?.attendee?.personal_profile.name : event?.room?.attendant.personal_profile.name,
        price: event?.room?.appointment_price,
        cpf: event?.room?.cpf,
        phone: event?.room?.attendee?.personal_profile?.mobile,
      }
    })
  }, [event])


  return (
    <div className={display}>
      <div className='row scheduling-receipt d-flex justify-content-center'>
        <div className='d-flex flex-column align-items-center justify-content-center'>
          <img
            className='img-fluid scheduling-receipt__success-img'
            src={successImg}
            alt=''
          />
          <div className='footer2 d-flex align-items-center flex-column'>
            <div className='scheduling-receipt__bg-image text-center'>
              <img className='img-fluid' src={logo} alt='' />
              <h1>Recibo de agendamento</h1>
              <div className='scheduling-receipt__content'>
                <ul>
                  <li className='mt-2'>
                    Dia:{' '}
                    <span>{info.dayString}, {info.day} de {info.month}</span>
                  </li>
                  <li>
                    Hora:{' '}
                    <span>{info.hourStart}</span>
                  </li>
                  <li>
                    {!isPhysician ? 'Médico:' : 'Paciente'} <span>{info.personalName}</span>
                  </li>
                  {isPhysician && (
                    <>
                      <li>
                        CPF:{' '}
                        <span>{cpfMask(info.cpf)}</span>
                      </li>
                      <li>
                        Celular:{' '}
                        <a
                          href={`https://api.whatsapp.com/send?phone=+55${info?.phone}&text=Olá, somos da DrCannabis e temos noticias sobre sua consulta`}
                          target='_blank'
                        >
                          <span>
                            +55 {phoneMask(info?.phone)} <i className='fab fa-whatsapp text-success' />
                          </span>
                        </a>

                      </li>
                    </>
                  )}
                  <li>
                    Valor pago: <span>
                    <Currency value={info.price} currency='R$' />
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            <img className='teste' src={reciboFooter} alt='' />
          </div>
        </div>
      </div>
    </div>
  )
}

export { SchedulingReceipt }
