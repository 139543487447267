import styled from 'styled-components'

export const Container = styled.div`
  a {
    text-decoration: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 16px;
    width:  60px;
    height: 60px;
    border-radius: 50px;
    background: #115850;
    color: #fff;
    position: fixed;
    bottom: 24px;
    right: 24px;
    z-index: 900;
  }
  svg {
    width: 66px;
    height: 66px;
    margin-left: 0;
    margin-right: 16px;
  }
`