import React from 'react'

export const formatValue = (value, currency) => {
  switch (currency) {
    case "R$":
      return parseFloat(value).toLocaleString("pt-br", {
        style: "currency",
        currency: "BRL",
      });
    case "US$":
      return parseFloat(value).toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      });
    case "€":
      return parseFloat(value).toLocaleString("en-GB", {
        style: "currency",
        currency: "EUR",
      });
    default:
      return "";
  }
};

const Currency = ({value, currency}) => {
  return formatValue(value, currency)
}

export { Currency }
