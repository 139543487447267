import React, { useEffect, useState } from 'react'
import {
  adminCheckPhysicianTelemed,
  adminEnrollPhysicianTelemed,
  adminSetBankAccount,
  bankAccount,
  enrollPhysicianTelemed,
} from '~/services/requests'
import { ButtonPrimary, CustomInput, CustomSelect } from '~/components'
import { removeCaracter, showArrayMessages, validateCpfAndCnpj } from '~/shared/utils'
import { toast } from 'react-toastify'
import BankList from '~/helpers/BankList.json'
import Yup from '~/helpers/yup'
import { Link, useHistory, useParams } from 'react-router-dom'

const FormSchema = Yup.object().shape({
  bank_account_holder: Yup.string().required(''),
  bank_number: Yup.number().min(1).max(757),
  bank_agency: Yup.number().min(1).max(999999),
  bank_account: Yup.number().min(3).max(9999999999),
  bank_account_digit: Yup.number().min(0).max(9),
  bank_name: '',
  cpf: Yup.string().required(),
})


const PhysicianOnboarding = ({ setTelemedicine }) => {
  const { id } = useParams()
  const initialValues = {
    bank_account_holder: '',
    bank_number: '',
    bank_agency: '',
    bank_account: '',
    bank_account_digit: '',
    cpf: '',
    type: 'CPF',
  }
  const [form, setForm] = useState(initialValues)
  const [bankName, setBankName] = useState('')
  const [errors, setErrors] = useState(initialValues)
  const [showForm, setShowForm] = useState(false)
  const [validated, setValidated] = useState(false)
  const [mask, setMask] = useState('999.999.999-99')

  const history = useHistory()

  useEffect(() => {
    const index = BankList.findIndex(val => val.code === parseInt(form.bank_number))
    if (index !== -1) {
      FormSchema.isValid(form).then(r => setValidated(r))
    }
  }, [form])

  useEffect(() => {
    if (!id) toast.warn('Parâmetro inválido! \n entre em contato com o suporte')
    adminCheckPhysicianTelemed(id)
      .then(() => setShowForm(true))
      .catch(e => showArrayMessages(e.response.data))
  }, [id])

  const changeErrors = (key, value) => {
    setErrors(prevState => {
      return {
        ...prevState,
        [key]: value,
      }
    })
  }

  const validate = (e) => {
    const { value, name } = e.currentTarget

    switch (name) {
      case 'cpf':
        const isCpf = form.type === 'CPF'
        if (validateCpfAndCnpj(value, isCpf)) {
          changeErrors('cpf', '')
        } else {
          changeErrors('cpf', 'CPF ou CNPJ inválido')
        }
        break
      case 'bank_agency':
        if (!value || value < 1 || value > 9999) {
          changeErrors('bank_agency', `Agência ${value} inválida`)
        } else {
          changeErrors('bank_agency', '')
        }
        break
      case 'bank_number':
        const index = BankList.findIndex(val => val.code === parseInt(value))
        if (!value || value < 1 || value > 757 || index === -1) {
          changeErrors('bank_number', 'Banco inválido')
        } else {
          changeErrors('bank_number', '')
        }
        break
      case 'bank_account_holder':
        if (!value) {
          changeErrors('bank_account_holder', 'Nome do proprietário inválido')
        } else {
          changeErrors('bank_account_holder', '')
        }
        break
      case'bank_account':
        if (!value || value < 100 || value > 9999999999) {
          changeErrors('bank_account', 'Numero da conta inválida')
        } else {
          changeErrors('bank_account', '')
        }
        break
      case 'bank_account_digit':
        if (!value || value < 0 || value > 9) {
          changeErrors('bank_account_digit', 'Dígito inválido')
        } else {
          changeErrors('bank_account_digit', '')
        }
        break
    }


  }


  const submitBankAccount = () => {
    const {
      bank_account_holder,
      bank_number,
      bank_agency,
      bank_account,
      bank_account_digit,
      cpf,
      type,
    } = form
    let cpfVal = removeCaracter(cpf)

    const data = {
      bank_account_holder,
      bank_number,
      bank_agency,
      bank_account,
      bank_account_digit,
      [type === 'CPF' ? 'cpf' : 'cnpj']: cpfVal,
    }

    if (id)
      adminSetBankAccount(data, id).then(() => {
        toast.success('Sucesso ao vincular conta')
        adminEnrollPhysicianTelemed(id)
          .then(() => history.push(`/admin/medicos/agenda/${id}`))
          .catch(e => showArrayMessages(e.response.data))
      }).catch(e => showArrayMessages(e.response.data))

    else
      bankAccount(data).then(() => {
        toast.success('Sucesso ao vincular conta')
        enrollPhysicianTelemed()
          .then(() => setTelemedicine(true))
          .catch(e => showArrayMessages(e.response.data))
      })
  }

  const clear = () => {
    return setForm(initialValues)
  }

  useEffect(() => {
    setMask(form.type === 'CPF' ? '999.999.999-99' : '99.999.999/9999-99')
  }, [form.type])

  const changeForm = (e) => {
    validate(e)
    const { value, name } = e.currentTarget
    setForm(prevState => {
      return {
        ...prevState,
        [name]: value,
      }
    })
  }


  useEffect(() => {
    const index = BankList.findIndex(val => val.code === parseInt(form.bank_number))
    if (index !== -1) {
      setBankName(BankList[index].name)
    } else {
      setBankName('')
      setValidated(false)
    }
  }, [form.bank_number])


  return (
    showForm ?
      (
        <div className='container-fluid mt-5'>
          <div className='row mb-4'>
            <div className='col-12'>
              <h1 className='font-size-30 mb-4'>Bem vindo à Telemedicina Dr Cannabis.</h1>
              <p>Para adiciona-lo à nossa plataforma de telemedicina, precisamos de alguns dados
                complementares que nos
                permitirão prestar um serviço mais completo.</p>
              <p className='mt-3'>Como a plataforma faz automaticamente a cobrança da consulta, precisamos que preencha
                o formulário a
                seguir com o seus dados bancários, de forma que possamos transferir para você os valores recebidos.</p>
            </div>
          </div>
          <div className='row mt-4'>
            <div className='col-6'>
              <CustomInput
                useRegister={false}
                label='Nome do Titular da Conta'
                inputName='bank_account_holder'
                skeletonDivClass='mb-4'
                defaultValue={form.bank_account_holder}
                callBack={changeForm}
                errorMessage={errors.bank_account_holder && errors.bank_account_holder}
                maxLength='30'
              />
            </div>
            <div className='col-2'>
              <CustomSelect label='Tipo de conta'
                            inputName='type'
                            callBack={changeForm}
              >
                <option selected='selected'>
                  CPF
                </option>
                <option value='CNPJ'>
                  CNPJ
                </option>
              </CustomSelect>
            </div>
            <div className='col-4'>
              <CustomInput
                useRegister={false}
                label={`${form.type} do titular da conta`}
                inputName='cpf'
                skeletonDivClass='mb-4'
                mask={mask}
                defaultValue={form.cpf}
                callBack={changeForm}
                errorMessage={errors.cpf && errors.cpf}
              />
            </div>
            <div className='col-2'>
              <CustomInput
                useRegister={false}
                label='Código do banco'
                inputName='bank_number'
                skeletonDivClass='mb-4'
                inputType='number'
                defaultValue={form.bank_number}
                callBack={changeForm}
                errorMessage={errors.bank_number && errors.bank_number}
              />
            </div>
            <div className='col-6'>
              <CustomInput
                useRegister={false}
                label='Nome do banco'
                skeletonDivClass='mb-4'
                inputType='text'
                defaultValue={bankName}
                callBack={changeForm}
                disabled={true}
              />
            </div>
            <div className='col-4'>
              <CustomInput
                useRegister={false}
                label='Numero da agência (sem dígito)'
                inputName='bank_agency'
                skeletonDivClass='mb-4'
                inputType='number'
                defaultValue={form.bank_agency}
                callBack={changeForm}
                errorMessage={errors.bank_agency && errors.bank_agency}
              />
            </div>
            <div className='col-8'>
              <CustomInput
                useRegister={false}
                label='Numero da Conta'
                inputName='bank_account'
                skeletonDivClass='mb-4'
                inputType='number'
                defaultValue={form.bank_account}
                callBack={changeForm}
                errorMessage={errors.bank_account && errors.bank_account}
              />
            </div>
            <div className='col-4'>
              <CustomInput
                useRegister={false}
                label='Dígito da conta'
                inputName='bank_account_digit'
                skeletonDivClass='mb-4'
                inputType='number'
                defaultValue={form.bank_account_digit}
                callBack={changeForm}
                errorMessage={errors.bank_account_digit && errors.bank_account_digit}
              />
            </div>
          </div>
          <div className='row mb-5'>
            <div className='col-12 d-flex justify-content-end'>
              <ButtonPrimary text='Limpar'
                             btnClass='bg-danger border-0 mr-2'
                             callBack={clear}
              />
              <ButtonPrimary text='Cadastrar conta'
                             callBack={submitBankAccount}
                             disabled={!validated}
              />
            </div>
          </div>
        </div>
      )
      :
      (
        <div className='container-fluid mt-5'>
          <div className='row d-flex'>
            <div className='col-12'>
              <h1 className='font-size-30'>Perfil incompleto</h1>
              <Link className='w-100 text-center' to='/medico/perfil/editar'>
                <ButtonPrimary text='Editar perfil ' />
              </Link>
            </div>
          </div>
        </div>
      )
  )
}

export { PhysicianOnboarding }
