import styled from 'styled-components'

import { Variables as V } from '~/styles/global'

export const Container = styled.div`
  &.md {
    width: 16.3rem;
    height: 3.2rem;
    @media only screen and (max-width: 767px) {
      width: 100%;
    }
  }

  &.lg {
    width: 20rem;
    height: 3.7rem;
    @media only screen and (max-width: 575px) {
      width: 14rem;
    }
  }

  &.xl {
    width: 32rem;
    height: 3.2rem;
    @media only screen and (max-width: 575px) {
      width: 14.3rem;
    }
  }

  &.fluid {
    width: 100% !important;
  }

  .text,
  .dropdown {
    width: 100%;
    height: 100%;
    border: 1px solid ${V.colorGreyDark};
    border-radius: 5px;
    background-color: ${V.colorWhite};
  }

  .text {
    &::placeholder {
      font-family: Roboto;
      font-size: 1.1rem;
      color: ${V.colorGreyDark};
    }
    &.light {
      &::placeholder {
        font-family: Roboto;
        font-size: 0.9rem;
        color: ${V.colorGreyLight};
      }
    }
  }

  .dropdown {
    appearance: none;
    -webkit-appearance: none;
  }
  position: relative;

  &:after {
    content: '›';
    font-size: 30px;
    position: absolute;
    top: 11px;
    right: 17px;
    pointer-events: none;
    transform: rotate(90deg);
  }

  .error {
    border-color: #dc3545;
  }

  span {
    padding: 0.3rem;
    color: #dc3545;
    font-size: 0.8rem;
  }
`
