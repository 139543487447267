import React, { useEffect, useState } from 'react'
import * as $ from 'jquery'
import { toast } from 'react-toastify'
import moment from 'moment'
import { useHistory } from 'react-router-dom'
import { ButtonPrimary, ButtonSecondary, CustomInput, CustomSelect } from '~/components'
import { removeCaracter, searchPatientAndSetValues, validateCpfAndCnpj, validateEmail } from '~/shared/utils'
import { confirmConsultation, splitTimeCourse } from '~/shared/telemedOptions'
import {
  adminCreateAppointment,
  adminPatientPreRegisterAppointment,
  physicianCreateAppointment,
  physicianPatientPreRegisterAppointment,
} from '~/services/requests'
import { ConfirmConflict } from '~/pages/telemed/ConfirmConflict'


const ModalNewConsultation = (
  {
    appointmentLength,
    maxTime,
    minTime,
    event,
    calendarRef,
    resourceId,
    isAdmin = true,
  }) => {
  const initialValues = {
    id: undefined,
    horary: undefined,
    cpf: undefined,
    name: undefined,
    mobilePhone: undefined,
    email: undefined,
    date: undefined,
  }
  const [form, setForm] = useState(initialValues)
  const [error, setError] = useState(initialValues)
  const [enable, setEnable] = useState(false)
  const [horaryOptions, setHoraryOptions] = useState([])

  const history = useHistory()

  const errorMessage = (name, value) => {
    setError(prevState => {
      return {
        ...prevState,
        [name]: value,
      }
    })
  }

  const formValidate = () => {
    const { cpf, date, email, horary, mobilePhone, name } = form

    if (!validateCpfAndCnpj(cpf) && cpf !== undefined) {
      errorMessage('cpf', 'CPF inválido')
      setEnable(false)
    } else {
      setEnable(true)
      errorMessage('cpf', '')
    }


    if (!validateEmail(email) && email !== undefined) {
      setEnable(false)
      errorMessage('email', 'E-mail inválido')
    } else {
      setEnable(true)
      errorMessage('email', '')
    }


    if (!horary && horary !== undefined) {
      setEnable(false)
      errorMessage('horary', 'Horário inválido')
    } else {
      setEnable(true)
      errorMessage('horary', '')
    }


    if (!mobilePhone && mobilePhone !== undefined) {
      setEnable(false)
      errorMessage('mobilePhone', 'Número de celular inválido')
    } else {
      setEnable(true)
      errorMessage('mobilePhone', '')
    }

    if ((!name || name.length <= 2) && name !== undefined) {
      setEnable(false)
      errorMessage('name', 'Nome inválido')
    } else {
      setEnable(true)
      errorMessage('name', '')
    }

    if (!moment(date).isValid() && date !== undefined) {
      setEnable(false)
      errorMessage('date', 'Data inválida')
    } else {
      setEnable(true)
      errorMessage('date', '')
    }

  }

  useEffect(() => {
    formValidate()
  }, [form])

  const changeForm = (e) => {
    const { value, name, checked, type } = e.currentTarget

    setForm(prevState => {
      return {
        ...prevState,
        [name]: type === 'checkbox' ? checked : value,
      }
    })
  }

  useEffect(() => {
    searchPatientAndSetValues(form.cpf, setForm, isAdmin)
  }, [form.cpf])


  useEffect(() => {
    setHoraryOptions(splitTimeCourse(minTime, maxTime, appointmentLength))
  }, [appointmentLength, maxTime, maxTime])


  const requestSuccess = (r) => {
    let day = moment(r.data?.start).format('YYYY-MM-DD')
    const routePath = isAdmin ? `/admin/medicos/consultas/${resourceId}/${day}` : ''
    toast.success('Agendado com sucesso')
    $('#newConsultation').modal('hide')
    // $('#conflict').modal('hide')
    history.push(routePath)
  }

  const requestError = (confirmScreen) => {
    // $('#conflict').modal('hide')
    toast.error('Erro ao agendar')
    confirmScreen && $('#newConsultation').modal('show')
  }

  const request = (confirmScreen = true) => {
    let requestType
    if (form.id) {
      requestType = isAdmin ? adminCreateAppointment : physicianCreateAppointment
      requestType(form.date, form.horary, form.id, resourceId)
        .then((r) => {
          requestSuccess(r)
        })
        .catch(() => {
          requestError(confirmScreen)
        })
    } else {
      let phone = removeCaracter(form.mobilePhone)
      phone = phone.replace(' ', '')

      const data = {
        profile: {
          full_name: form.name,
          cpf: form.cpf,
          mobile_number: phone,
        },
        user: {
          email: form.email,
        },
      }

      if (isAdmin) {
        adminPatientPreRegisterAppointment(form.date, resourceId, form.horary, data)
          .then((r) => {
            requestSuccess(r)
          })
          .catch(() => {
            requestError(confirmScreen)
          })
      } else {
        physicianPatientPreRegisterAppointment(form.date, form.horary, data)
          .then((r) => {
            requestSuccess(r)
          })
          .catch(() => {
            requestError(confirmScreen)
          })
      }
    }
  }


  const cancelOption = () => {
    setForm(initialValues)
    setError(initialValues)
    $('#newConsultation').modal('hide')
  }

  return (
    <>
      <div className='modal fade'
           id='newConsultation'
           tabIndex='-1'
           role='dialog'
           aria-labelledby='newConsultation'
           aria-hidden='true'
      >
        <div className='modal-dialog modal-lg' role='document'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title' id='newConsultationTitle'>Agendar consulta</h5>
              <button type='button' className='close' data-dismiss='modal' aria-label='Close'>
                <span aria-hidden='true'>&times;</span>
              </button>
            </div>
            <div className='modal-body'>
              <div className='row'>
                <div className='col-12'>
                  <CustomInput label='Data' inputName='date'
                               inputType='date'
                               callBack={changeForm}
                               defaultValue={form.date}
                               error={error.date ? { message: error.date } : false}

                  />
                </div>
              </div>
              <div className='row'>
                <div className='col-4'>
                  <CustomSelect label='Horários'
                                inputName='horary'
                                selectValue={form.horary}
                                callBack={changeForm}
                                error={error.horary ? { message: error.horary } : false}
                  >
                    <option value=''>Todos os horários</option>
                    {horaryOptions.map(hourly => (
                      <option key={hourly.start}
                              value={hourly.start}
                      >
                        {hourly.start} até {hourly.end}
                      </option>
                    ))}
                  </CustomSelect>
                </div>
                <div className='col-8'>
                  <CustomInput label='CPF'
                               mask='999.999.999-99'
                               inputName='cpf'
                               defaultValue={form.cpf}
                               callBack={changeForm}
                               error={error.cpf ? { message: error.cpf } : false}
                  />
                </div>
              </div>
              <div className='row'>
                <div className='col-12'>
                  <CustomInput label='Nome'
                               inputName='name'
                               defaultValue={form.name}
                               callBack={changeForm}
                               error={error.name ? { message: error.name } : false}
                  />
                </div>
                <div className='col-6'>
                  <CustomInput label='Telefone'
                               mask='(99) 99999-9999'
                               inputName='mobilePhone'
                               defaultValue={form.mobilePhone}
                               callBack={changeForm}
                               error={error.mobilePhone ? { message: error.mobilePhone } : false}
                  />
                </div>
                <div className='col-6'>
                  <CustomInput label='email'
                               inputType='email'
                               inputName='email'
                               defaultValue={form.email}
                               callBack={changeForm}
                               error={error.email ? { message: error.email } : false}
                  />
                </div>
              </div>
            </div>
            <div className='modal-footer'>
              <ButtonPrimary text='Agendar'
                             callBack={() => confirmConsultation(calendarRef, form, request, event, isAdmin)}
                             disabled={!enable}
              />
              <ButtonSecondary text='Cancelar' callBack={() => cancelOption()} />
            </div>
          </div>
        </div>
      </div>
      <ConfirmConflict callback={request} />
    </>
  )
}

export { ModalNewConsultation }
