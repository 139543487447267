import React from 'react'
import Button from '~/components/Button'

const ModalConfirmVote = ({ sendEmail, cleanForm }) => {

  return (
    <div className='modal fade'
         id='modalConfirmVote'
         tabIndex='-1'
         role='dialog'
         aria-labelledby='modalConfirmVoteLabel'
         aria-hidden='true'
    >
      <div className='modal-dialog modal-dialog-centered' role='document'>
        <div className='modal-content modal-confirm-vote_modal pb-5'>
          <div className='modal-header border-bottom-0'>
            <Button type='button'
                    className='close'
                    data-dismiss='modal'
                    aria-label='Close'
                    onClick={() => cleanForm()}
            >
              <i className='fas fa-times fa-2x' />
            </Button>
          </div>
          <div className='modal-body text-center'>
            <div className='modal-confirm-vote_icon text-center mb-3'>
              <i className='fas fa-check-circle fa-4x mx-auto' />
            </div>
            <br />
            <h6 className='modal-confirm-vote_titulo'>SEU VOTO PRECISAR SER VALIDADO</h6> <br />
            <p className='modal-confirm-vote_text'>Para validá-lo, clique no link que recebeu por e-mail.<br />
              Caso não tenha recebido, <a className='link modal-confirm-vote_link' onClick={() => sendEmail()}>clique
                aqui.</a>
            </p>
          </div>
          <div className='modal-footer text-center border-top-0'>
            <Button type='button'
                    className='modal-confirm-vote_button btn-lg rounded mx-auto lg'
                    data-dismiss='modal'
                    aria-label='Close'
                    onClick={() => cleanForm()}
            >
              FECHAR
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export { ModalConfirmVote }
