import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

import api from '~/services/api'
import { Container } from './styles'
import Prescription from './Prescription'
import ModalFinishRegistration from '~/components/ModalFinishRegistration/index'

export default function Prescriptions(props) {
  const [prescriptions, setPrescription] = useState([])
  const [showModal, setShowModal] = useState(false)
  const auth = useSelector(state => state.auth)

  const getPrescription = async () => {
    try {
      const res = await api.get('/prescriptions')
      setPrescription(res.data)
      setShowModal(false)
    } catch (e) {
      if (e.response.status == 406) {
        setShowModal(true)
      } else {
        throw e
      }
    }
  }

  useEffect(() => {
    auth.session.profile.full_name &&
    (!auth.session.profile.cpf || !auth.session.profile.birthdate)
      ? setShowModal(true)
      : setShowModal(false)
    getPrescription()
  }, [])

  return (
    <>
      <Container>
        {prescriptions.map(
          prescription =>
            prescription.id && (
              <Prescription
                data={prescription}
                className="mb-3"
                key={prescription.id}
              />
            ),
        )}
        <Prescription className="mb-3" userMail={auth.session.email} />
      </Container>

      <ModalFinishRegistration
        show={showModal}
        handleClose={() => {}}
        backdrop="static"
      />
    </>
  )
}
