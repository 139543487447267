import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'

import { Creators as ErrorActions } from '~/store/ducks/error'
import { Creators as AuthActions } from '~/store/ducks/auth'

import Yup from '~/helpers/yup'
import api from '~/services/api'
import { CustomInput, CustomSelect } from '~/components'
import Skeleton from 'react-loading-skeleton'
import UploadAvatar from '~/components/UploadAvatar'
import Button from '~/components/Button'
import StatesBrasil from '~/helpers/StatesBrasil.json'
import { getAddressByCep } from '~/services/viaCEP'

let parseFormats = ['DD/MM/YYYY']

const EditProfileSchema = Yup.object().shape({
  user: Yup.object().shape({
    email: Yup.string()
      .email('Digite um email válido')
      .required('Digite um email'),
  }),
  profile: Yup.object().shape({
    full_name: Yup.string().required('Digite seu nome.'),
    birthdate: Yup.date()
      .format(parseFormats)
      .max(new Date(), 'Você não pode ter nascido no futuro!')
      .typeError('Insira uma data Válida'),
    gender: Yup.string().required('Selecione seu gênero'),
    phone_number: Yup.string().removeSpecials(),
    mobile_number: Yup.string().removeSpecials(),
    rg: Yup.string().required('Digite seu RG'),
    cpf: Yup.string().required('Digite seu CPF'),
  }),
  address: Yup.object().shape({
    cep: Yup.string().required('Digite o CEP do seu endereço'),
    state: Yup.string().required('Digite o estado '),
    city: Yup.string().required('Digite a cidade '),
    street: Yup.string().required('Qual a rua?'),
    number: Yup.string(),
    complement: Yup.string(),
    district: Yup.string().required('Digite o bairro '),
  }),
})

export default function Edit({ history }) {
  const { register, handleSubmit, errors, setValue, watch } = useForm({
    validationSchema: EditProfileSchema,
  })
  // const error = useSelector(state => state.error)
  const { session } = useSelector(state => state.auth)
  const dispatch = useDispatch()
  const [avatarId, setAvatarId] = useState()
  const [loading, setLoading] = useState(false)
  const cep = watch('address.cep')

  useEffect(() => {
    if (cep && cep.length === 8) {
      setLoading(true)
      getAddressByCep(cep).then((res)=> {
        setValue('address.state', res.data.uf)
        setValue('address.city', res.data.localidade)
        setValue('address.street', res.data.logradouro)
        setValue('address.district', res.data.bairro)
      }).finally(()=> setLoading(false))
    }
  }, [cep])

  useEffect(() => {
    setValue('profile', session.profile)
    if (session.profile?.birthdate)
      setValue(
        'profile.birthdate',
        moment(session.profile?.birthdate).format('DD/MM/YYYY'),
      )
    setValue('address', session.addresses[0])
    setValue('user.email', session.email)
  }, [session.profile])

  const onSubmit = async data => {
    setLoading(true)
    try {
      data.profile.birthdate = moment(data.profile.birthdate).format('YYYY/MM/DD')
      data.profile.cpf = data.profile.cpf.replaceAll('.', '')
      data.profile.cpf = data.profile.cpf.replaceAll('-', '')
      if (avatarId) {
        data.profile.avatar_id = avatarId
      }
      await api.put(`/users/${session.id}`, data)
      dispatch(AuthActions.getSessionRequest())
      setLoading(false)
      const to = '/medico/perfil/medico/editar'
      history.push(to)
    } catch (e) {
      dispatch(ErrorActions.setError(e.response))
      setLoading(false)
    }
  }

  return (
    <>
      {
        !session.roles[0] === '' || !session.roles[0] ? (

          <div className='p-5 patient__profile__edit__skeleton'>
            <div className='row'>
              <div className='col-lg-12'>
                <Skeleton width='50%' />
              </div>
            </div>

            <div className='row mt-4'>
              <div className='col-lg-12'>
                <Skeleton width='50%' />
              </div>
            </div>

            <div className='row mt-5'>
              <div className='col-lg-2 col-6 pr-0 patient__profile__edit__skeleton__mobile__botton'>
                <Skeleton className='rounded-circle ' width='90px' height='90px' />
              </div>
              <div className='col-lg-10 col-6 pl-0'>
                <Skeleton className='mt-4 patient__profile__edit__skeleton__mobile__button' width='15%' height='40px' />
              </div>
            </div>


            <div className='row mt-4'>
              <div className='col-lg-6 patient__profile__edit__skeleton__mobile__botton'>
                <Skeleton width='100%' />
              </div>
              <div className='col-lg-6'>
                <Skeleton width='70%' />
              </div>
            </div>

            <div className='row mt-4 patient__profile__edit__skeleton__mobile__botton'>
              <div className='col-lg-12'>
                <Skeleton width='49%' />
              </div>
            </div>

            <div className='row mt-4 patient__profile__edit__skeleton__mobile__botton'>
              <div className='col-lg-3 patient__profile__edit__skeleton__mobile__botton'>
                <Skeleton width='100%' />
              </div>

              <div className='col-lg-3 patient__profile__edit__skeleton__mobile__botton'>
                <Skeleton width='100%' />
              </div>

              <div className='col-lg-3 patient__profile__edit__skeleton__mobile__botton'>
                <Skeleton width='100%' />
              </div>

              <div className='col-lg-3 patient__profile__edit__skeleton__mobile__botton'>
                <Skeleton width='100%' />
              </div>
            </div>

            <div className='row mt-4 patient__profile__edit__skeleton__mobile__botton'>
              <div className='col-lg-4 patient__profile__edit__skeleton__mobile__botton'>
                <Skeleton width='100%' />
              </div>
              <div className='col-lg-4 patient__profile__edit__skeleton__mobile__botton'>
                <Skeleton width='100%' />
              </div>
              <div className='col-lg-4 patient__profile__edit__skeleton__mobile__botton'>
                <Skeleton width='100%' />
              </div>
            </div>

            <div className='row mt-4 patient__profile__edit__skeleton__mobile__botton'>
              <div className='col-lg-4 patient__profile__edit__skeleton__mobile__botton'>
                <Skeleton width='100%' />
              </div>
              <div className='col-lg-4 patient__profile__edit__skeleton__mobile__botton'>
                <Skeleton width='100%' />
              </div>
              <div className='col-lg-4 patient__profile__edit__skeleton__mobile__botton'>
                <Skeleton width='100%' />
              </div>
            </div>

            <div className='row mt-4 patient__profile__edit__skeleton__mobile__botton'>
              <div className='col-lg-6 patient__profile__edit__skeleton__mobile__botton'>
                <Skeleton width='100%' />
              </div>
            </div>


          </div>
        ) : (
          <div className='container-fluid physician__profile__edit'>
            <div className='row'>
              <div className='col-lg-12'>
                <h2 className='physician__profile__edit__title'>Termine seu cadastro</h2>
              </div>
            </div>
            <div className='row'>
              <div className='col-lg-12'>
                <h2 className='physician__profile__edit__info__title'>Informações Pessoais</h2>
              </div>
            </div>

            <form onSubmit={handleSubmit(onSubmit)}>
              <div className='row'>
                <div className='col-lg-12'>
                  <p>Adicione uma foto de perfil</p>
                  <UploadAvatar callback={setAvatarId} />
                </div>
              </div>
              <div className='row mt-4'>
                <div className='col-lg-6'>
                  <CustomInput
                    label='Nome Completo'
                    inputName='profile.full_name'
                    inputPlaceholder='Nome'
                    error={errors.profile?.full_name}
                    register={register}
                  />
                </div>
                <div className='col-lg-6'>
                  <CustomInput
                    label='Data de nascimento'
                    inputName='profile.birthdate'
                    inputPlaceholder='__/__/____'
                    error={errors.profile?.birthdate}
                    register={register}
                    mask='99/99/9999'
                  />
                </div>
                <div className='col-lg-4'>
                  <CustomSelect
                    label='Gênero'
                    inputName='profile.gender'
                    error={errors.profile?.gender}
                    register={register}
                  >
                    <option value='m'>Masculino</option>
                    <option value='f'>Feminino</option>
                    <option value='-'>Pregisteriro não informar</option>
                  </CustomSelect>
                </div>

                <div className='col-lg-4'>
                  <CustomInput
                    label='RG'
                    inputName='profile.rg'
                    inputPlaceholder='9999999'
                    error={errors.profile?.rg}
                    register={register}
                  />
                </div>

                <div className='col-lg-4'>
                  <CustomInput
                    label='CPF'
                    inputName='profile.cpf'
                    inputPlaceholder='123.456.789-10'
                    error={errors.profile?.cpf}
                    mask='999.999.999-99'
                    register={register}
                  />
                </div>

                <div className='col-lg-6'>
                  <CustomInput
                    label='Telefone pessoal'
                    inputName='profile.mobile_number'
                    inputPlaceholder='(11) 99999-9999'
                    error={errors.profile?.mobile_number}
                    mask='(99) 99999-9999'
                    register={register}
                  />
                </div>
              </div>

              <div className='row'>
                <div className='col-lg-12'>
                  <h2 className='physician__profile__edit__info__title'>Informações para atendimento</h2>
                </div>
              </div>
              <div className='row'>
                <div className='col-lg-6'>
                  <CustomInput
                    label='Telefone para agendamento de consultas'
                    inputName='profile.phone_number'
                    inputPlaceholder='(11) 99999-9999'
                    error={errors.profile?.phone_number}
                    mask='(99) 99999-9999'
                    register={register}
                  />
                </div>

                <div className='col-lg-6'>
                  <CustomInput
                    label='CEP'
                    inputName='address.cep'
                    inputPlaceholder='00000-000'
                    error={errors.adresss?.cep}
                    register={register}
                  />
                </div>

                <div className='col-lg-6'>
                  <CustomSelect
                    label='UF'
                    inputName='address.state'
                    error={errors.address?.state}
                    register={register}
                  >
                    {StatesBrasil.map(state => (
                      <option value={state.value}>{state.label}</option>
                    ))}
                  </CustomSelect>
                </div>

                <div className='col-lg-6'>
                  <CustomInput
                    label='Cidade'
                    inputName='address.city'
                    error={errors.address?.city}
                    register={register}
                  />
                </div>

                <div className='col-lg-6'>
                  <CustomInput
                    label='Bairro'
                    inputName='address.district'
                    error={errors.address?.district}
                    register={register}
                  />
                </div>

                <div className='col-lg-8'>
                  <CustomInput
                    label='Logradouro'
                    inputName='address.street'
                    error={errors.address?.street}
                    register={register}
                  />
                </div>

                <div className='col-lg-4'>
                  <CustomInput
                    label='Número'
                    inputName='address.number'
                    error={errors.address?.number}
                    register={register}
                  />
                </div>

                <div className='col-lg-6'>
                  <CustomInput
                    label='Complemento'
                    inputName='address.complement'
                    error={errors.address?.complement}
                    register={register}
                  />
                </div>
              </div>

              <div className='row'>
                <div className='col-lg-6'>
                  <CustomInput
                    label='Email'
                    inputName='user.email'
                    error={errors.user?.email}
                    register={register}

                  />
                </div>
              </div>
              <div className='row'>
                <div className='col-lg-6'>
                  <Button
                    type='submit'
                    disabled={loading}
                    className='primary md my-3'
                  >
                    {loading ? 'Carregando...' : 'Continuar'}
                  </Button>
                </div>
              </div>
            </form>
          </div>)
      }
    </>
  )
}
