import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import moment from 'moment'
import Skeleton from 'react-loading-skeleton'
import { ButtonSecondary } from '~/components/Buttons/SecondaryButton'
import { NewPassword } from '~/pages/NewPassword'
import { PrivateProfileCard } from '~/components/ProfileCard/PrivateProfileCard'

export default function Profile() {
  const { session } = useSelector(state => state.auth)

  moment.locale('pt')

  return (
    <>
      {
        !session.roles[0] === '' || !session.roles[0] ? (
          <div className='contanier-fluid p-5 patient__profile__show__skeleton'>
            <div className='row mt-5'>
              <div className='col-lg-2 col-12  patient__profile__show__skeleton__center'>
                <Skeleton className='rounded-circle ' width='90px' height='90px' />
              </div>
              <div className='col-lg-6 col-12 patient__profile__show__skeleton__center'>
                <Skeleton className='mt-4' width='90%' />
                <Skeleton className='mt-2 d-inline-block' width='30%' />
              </div>
              <div className='col-lg-4 col-12 patient__profile__show__skeleton__center'>
                <Skeleton className='mt-4' width='50%' />
                <Skeleton className='mt-2 d-block patient__profile__show__skeleton__center__btn' width='50%' />
              </div>
            </div>

            <div className='row mt-5'>
              <div className='col-lg-12'>
                <Skeleton width='60%' />
              </div>
            </div>

            <div className='row mt-5'>
              <div className='col-lg-6 patient__profile__show__skeleton__mb'>
                <Skeleton width='70%' />
                <Skeleton className='mt-2' width='50%' />
              </div>
              <div className='col-lg-6'>
                <Skeleton width='70%' />
                <Skeleton className='mt-2' width='50%' />
              </div>
            </div>

            <div className='row mt-5'>
              <div className='col-lg-6 patient__profile__show__skeleton__mb'>
                <Skeleton width='70%' />
                <Skeleton className='mt-2' width='50%' />
              </div>
              <div className='col-lg-6'>
                <Skeleton width='70%' />
                <Skeleton className='mt-2' width='50%' />
              </div>
            </div>

            <div className='row mt-5'>
              <div className='col-lg-6 patient__profile__show__skeleton__mb'>
                <Skeleton width='70%' />
                <Skeleton className='mt-2' width='50%' />
              </div>
              <div className='col-lg-6'>
                <Skeleton width='70%' />
                <Skeleton className='mt-2' width='50%' />
              </div>
            </div>

            <div className='row mt-5'>
              <div className='col-lg-6 patient__profile__show__skeleton__mb'>
                <Skeleton width='70%' />
                <Skeleton className='mt-2' width='50%' />
              </div>
              <div className='col-lg-6'>
                <Skeleton width='70%' />
                <Skeleton className='mt-2' width='50%' />
              </div>
            </div>


          </div>
        ) : (
          <div className='container-fluid physician__profile__show'>
            <div className='row'>
              <div className='col-lg-8'>
                <PrivateProfileCard physician={session.physician} data={session} />
              </div>
              <div className='col-lg-4 physician__profile__show__buttons'>

                <Link className='w-100 text-center' to='/medico/perfil/editar'>
                  <ButtonSecondary
                    text='Editar'
                    btnClass='physician__profile__show__btn'
                  />
                </Link>

                <ButtonSecondary
                  text='Alterar Senha'
                  toggle='modal'
                  target='#newPasswordModal'
                  btnClass='physician__profile__show__btn'
                />

              </div>
            </div>
            <div className='row'>
              <div className='col-lg-12 my-5'>
                <h2 className='physician__profile__show__title'>Informações pessoais</h2>
              </div>
            </div>
            <div className='row'>
              <div className='col-lg-6'>
                <div className='mb-4'>
                  <h4>Nome completo</h4>
                  <p className='data'>{session.profile?.full_name}</p>
                </div>
              </div>
              {session.profile?.birthdate && (
                <div className='col-lg-6'>
                  <div className='mb-4'>
                    <h4>Data de Nascimento</h4>
                    <p className='data'>
                      {moment(session.profile?.birthdate).format('DD/MM/YYYY')}
                    </p>
                  </div>
                </div>
              )}
              {session.profile?.gender && (
                <div className='col-lg-12'>
                  <div className='mb-4'>
                    <h4>Gênero</h4>
                    <p className='data'>
                      {session.profile?.gender === 'm' ? 'Masculino' : session.profile?.gender === 'f' ? 'Feminino' : session.profile?.gender === '-' ? 'Não informado' : ''}
                    </p>
                  </div>
                </div>
              )}
              {session.addresses.length > 0 && (
                <div className='col-lg-12 my-3'>
                  <h4>Endereço de Atendimento</h4>
                </div>
              )}
              {session.addresses?.map(address => (
                <>
                  <div className='col-lg-6'>
                    <div className='mb-4'>
                      <h4>UF</h4>
                      <p className='data'>{address.state}</p>
                    </div>
                  </div>
                  <div className='col-lg-6'>
                    <div className='mb-4'>
                      <h4>Cidade</h4>
                      <p className='data'>{address.city}</p>
                    </div>
                  </div>
                  <div className='col-lg-6'>
                    <div className='mb-4'>
                      <h4>CEP</h4>
                      <p className='data'>{address.cep}</p>
                    </div>
                  </div>
                  <div className='col-lg-6'>
                    <div className='mb-4'>
                      <h4>Endereço</h4>
                      <p className='data'>{address.street}</p>
                    </div>
                  </div>
                  <div className='col-lg-6'>
                    <div className='mb-4'>
                      <h4>Número</h4>
                      <p className='data'>{address.number}</p>
                    </div>
                  </div>
                  <div className='col-lg-6'>
                    <div className='mb-4'>
                      <h4>Complemento </h4>
                      <p className='data'>{address.complement}</p>
                    </div>
                  </div>
                </>
              ))}
              {session.profile?.phone_number && (
                <div className='col-lg-6'>
                  <div className='mb-4'>
                    <h4>Tel residencial</h4>
                    <p className='data'>{session.profile?.phone_number}</p>
                  </div>
                </div>
              )}
              {session.profile?.mobile_number && (
                <div className='col-lg-6'>
                  <div className='mb-4'>
                    <h4>Tel celular</h4>
                    <p className='data'>{session.profile?.mobile_number}</p>
                  </div>
                </div>
              )}
              {session.profile?.rg && (
                <div className='col-lg-6'>
                  <div className='mb-4'>
                    <h4>RG</h4>
                    <p className='data'>{session.profile?.rg}</p>
                  </div>
                </div>
              )}
              {session.profile?.cpf && (
                <div className='col-lg-6'>
                  <div className='mb-4'>
                    <h4>CPF</h4>
                    <p className='data'>{session.profile?.cpf}</p>
                  </div>
                </div>
              )}
              <div className='col-lg-12'>
                <div className='mb-4'>
                  <h4>Email </h4>
                  <p className='data'>{session.email}</p>
                </div>
              </div>
            </div>
            <NewPassword />
          </div>)}
    </>
  )


}
