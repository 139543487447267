import React, { useState } from 'react'
import { CreditCard } from '~/components/CreditCard/CreditCard'
import { patientFinishAppointment, patientFinishPayment } from '~/services/requests'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { useHistory } from 'react-router-dom'
import { showArrayMessages } from '~/shared/utils'

const ModalCreditCard = ({ userData, resourceId, showModal = false, callback }) => {
  const { session } = useSelector(state => state.auth)
  const [loading, setLoading] = useState(false)

  const history = useHistory()

  const submit = (e, cardNumber, name, cvc, expiry) => {
    e.preventDefault()
    setLoading(true)
    const date = moment(userData.defaultDate).format('YYYY-MM-DD')
    const hourly = moment(userData.defaultDate).format('HH:mm')

    const {
      state,
      city,
      complement,
      street,
      number,
      cep,
      district,
    } = session.addresses[0]

    const data = {
      card_number: cardNumber,
      cvv: cvc,
      expiration_date: expiry,
      holder_name: name,
      billing: {
        name,
        address: {
          state,
          city,
          neighborhood: district ?? '',
          street,
          street_number: number,
          zipcode: cep,
        },
      },
    }

    patientFinishAppointment(date, hourly, resourceId)
      .then((r) => {
          let day = moment(r.data.start).format('YYYY-MM-DD')
          patientFinishPayment(date, hourly, resourceId, data)
            .then(() => {
              toast.success('Pagamento enviado')
              callback(false)
            })
            .catch(e => e.response.status === 400 ?
              toast.warn('Pagamento não autorizado')
              :
              showArrayMessages(e.response.data)).finally(() => history.push(`/paciente/telemed/${resourceId}/${day}`))
        },
      )
      .catch((e) => showArrayMessages(e.response.data))
      .finally(() => setLoading(false))
  }

  return (
    <>
      <div className={`modal show ${showModal ? 'd-block' : ''}`} tabIndex='-1' role='dialog' id='payment'>
        <div className='modal-calculator--input-group modal-dialog-centered' role='document'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title'>Pagamento</h5>
              <button type='button' className='close' aria-label='Close' onClick={() => callback(false)}>
                <span aria-hidden='true'>&times;</span>
              </button>
            </div>
            <div className='modal-body'>
              <CreditCard action={submit}
                          callBack={() => {
                            toast.warn('Ação cancelada')
                            callback(false)
                          }}
                          loading={loading}
              />
            </div>
          </div>
        </div>
      </div>
      {showModal && <div
        className='modal-backdrop fade show'
        aria-hidden
      />}
    </>
  )
}

export { ModalCreditCard }
