import React from 'react'

import PatientImg from '~/assets/images/physician-default.png'
import { RenderImg } from '~/components/RenderImg/RenderImg'

export default function Patient({ data, ...rest }) {
  const profile = data.profile

  return (
    <div className='container-fluid physician__patients__patient__card'>
      <div className="physician__patients__patient__card__avatar">
        {profile && profile.avatar ? (
          <RenderImg
            photo_id={profile.avatar.id}
            photo_uuid={profile.avatar.uuidUrl}
            imgSecond={PatientImg}
            isPrivate={profile.avatar.private}
            alt="Avatar"
          />
        ) : (
          <img
            src={profile?.avatar ? profile?.avatar.url : PatientImg}
            alt="avatar"
          />
        )}
      </div>
      <div className="physician__patients__patient__card__description">
          <h5 className="">{profile?.full_name}</h5>
      </div>
    </div>
  )
}
