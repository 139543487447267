import React, { useEffect, useState } from 'react'
import { getAppointmentList } from '~/services/requests'
import { DatePicker } from '~/components/DatePicker/DatePicker'
import moment from 'moment'
import { toast } from 'react-toastify'
import Skeleton from 'react-loading-skeleton'

const AppointmentList = () => {
  const [date, setDate] = useState(moment().format('YYYY-MM-DD'))
  const [appointments, setAppointments] = useState([])
  const [loading, setLoading] = useState(true)


  useEffect(() => {
    setAppointments([])
    setLoading(true)
    getAppointmentList(date)
      .then(r => setAppointments(r.data))
      .catch(() => toast.error('Erro ao buscar consultas'))
      .finally(() => setLoading(false))
  }, [date])


  return (
    <div>
      <div className='row'>
        <div className='col-12'>
          <DatePicker callBack={setDate}
                      returnFormat='YYYY-MM-DD'
                      showMessage={true}
          />
        </div>
      </div>
      <div className='row'>
        {Array.isArray(appointments) && appointments.map(value => (
          <div className='col-sm-12 col-lg-4'>
            <div className='admin_order_card'>
              <div className='card-body'>
                <p>Paciente:<br /> {value.patient}</p>
                <p className='card-text mt-2'>Médico:<br /> {value.physician}</p>
                <p className='mt-2'>Hora: <br />{moment(value.start).format('HH:mm')}</p>
                <div className='mt-4 d-flex justify-content-center'>
                  <div
                    className={`${value?.has_prescriptions ? 'drc-background' : 'drc-background-inative'} mx-3 rounded-circle appointment-list-icon`}
                    data-toggle='tooltip'
                    data-placement='top'
                    title={`${value?.has_prescriptions ? 'Prescrição anexada' : 'Não possui prescrição'}`}
                  >
                    <i className='fas fa-file-medical' />
                  </div>
                  <div
                    className={`${value?.has_order ? 'drc-background' : 'drc-background-inative'} mx-3 rounded-circle appointment-list-icon`}
                    data-toggle='tooltip'
                    data-placement='top'
                    title={`${value?.has_order ? 'Pedido criado' : 'Não possui pedido'}`}
                  >
                    <i className='fas fa-gift' />
                  </div>
                  <div
                    className={`${value?.is_order_paid ? 'drc-background' : 'drc-background-inative'} mx-3 rounded-circle appointment-list-icon`}
                    data-toggle='tooltip'
                    data-placement='top'
                    title={`${value?.is_order_paid ? 'Pagamento efetuado' : 'Pagamento não efetuado'}`}
                  >
                    <i className='fas fa-dollar-sign' />
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}

        {loading && (
          <>
            <div className='col-sm-12 col-lg-4 p-2'>
              <Skeleton className='mt-2' width='100%' />
              <Skeleton className='mt-2' width='100%' />
              <Skeleton className='mt-2' width='100%' />
              <Skeleton className='mt-5' width='100%' height='42%' />
            </div>
            <div className='col-sm-12 col-lg-4 p-2'>
              <Skeleton className='mt-2' width='100%' />
              <Skeleton className='mt-2' width='100%' />
              <Skeleton className='mt-2' width='100%' />
              <Skeleton className='mt-5' width='100%' height='42%' />
            </div>
            <div className='col-sm-12 col-lg-4 p-2'>
              <Skeleton className='mt-2' width='100%' />
              <Skeleton className='mt-2' width='100%' />
              <Skeleton className='mt-2' width='100%' />
              <Skeleton className='mt-5' width='100%' height='42%' />
            </div>
          </>
        )}

        {!loading && appointments.length === 0 && (
          <div className='col-12 text-center'>
            <h3 className='text--grey'>Sem consultas neste período</h3>
          </div>
        )}
      </div>
    </div>
  )
}

export { AppointmentList }
