import styled from 'styled-components'

import { Variables as V } from '~/styles/global'

export const Container = styled.label`
         display: block;
         position: relative;
         padding-left: 35px;
         margin-bottom: 12px;
         cursor: pointer;
         font-size: 22px;

         input {
           position: absolute;
           opacity: 0;
           cursor: pointer;
           height: 0;
           width: 0;

           &:checked ~ .checkmark {
             background-color: ${V.colorPrimary};
           }
           &:checked ~ .checkmark:after {
             display: block;
           }
         }

         .checkmark {
           position: absolute;
           top: 0;
           left: 0;
           height: 25px;
           width: 25px;
           background-color: #eee;
           box-sizing: border-box;
           border-radius: 4px;
           border: 1px solid #aaa;
           &:after {
             font-family: 'Font Awesome 5 Pro';
             content: '';
             position: absolute;
             display: none;
             top: 0px;
             left: 1px;
             width: 0px;
             height: 0x;
             border-radius: 0%;
             color: white;
           }

           &.error {
             border-color: #dc3545 !important;
           }
         }

         &:hover input ~ .checkmark {
           background-color: #ccc;
         }

         .label {
           font-size: 16px;
           line-height: 23px;
         }
       `
