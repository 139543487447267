import React from 'react'
import { RenderImg } from '~/components/RenderImg/RenderImg'
import ProfileIcon from '~/assets/icons/profile.svg'
import { SocialLinks } from '~/components/SocialLinks/SocialLinks'

const PrivateProfileCard = ({ data, physician }) => {

  return (
    <div className='container profile__card'>
      <div className=' profile__card__img-box'>
        {data &&
        data.profile &&
        (data.profile.avatar ? (
          <RenderImg
            photo_id={data?.profile?.avatar.id}
            photo_uuid={data?.profile?.avatar.uuidUrl}
            isPrivate={data?.profile?.avatar.private}
            imgSecond={ProfileIcon}
            classNameImg='align-self-center rounded-circle'
            alt='Usuário'
          />
        ) : (
          <img
            className='align-self-center rounded-circle profile__card_default_avatar'
            src={ProfileIcon}
            alt='Usuário'
          />
        ))}
      </div>
      <div className='profile__card__description'>
        <div className='mx-3'>
          <h3 className='profile__card__description__name'>
            {physician?.crm && data.profile.gender === 'm' ? 'Dr.' : 'Dra.'}
            {data?.profile?.full_name}
          </h3>
          <h5 className='mt-2 profile__card__description__crm'>
            {physician?.crm && `CRM: ${physician?.crm}`}{' '}
            {physician?.uf && physician?.uf !== 'XX' && (
              <span>{physician?.uf}</span>
            )}
            {!physician &&
            data?.addresses?.length > 0 &&
            data?.addresses[0]?.state}
          </h5>
          {physician?.online_consultation && (
            <h5 className='profile__card__description__telemed'>
              {data.profile.gender === 'f' ? 'Esta médica ' : 'Este médico '} faz consultas online
            </h5>
          )}
          <h5 className='profile__card__description__link mr-2 mt-2'>
            <a
              href={`/perfil/${physician?.slug}`}>{`${window.location.origin.toString()}/perfil/${physician?.slug}`}</a>
          </h5>
          <SocialLinks physician={physician} iconWidthClass='fa-2x' />
        </div>
      </div>
    </div>
  )
}

export { PrivateProfileCard }
