import * as actionTypes from "./actionTypes";

const setListPrescriptions = (payload, form) => {
  return {
    type: actionTypes.SET_LIST_PRESCRIPTIONS,
    payload,
    form
  }
}
const setListPrescriptionsRequest = () => {
  return {
    type: actionTypes.SET_LIST_PRESCRIPTIONS_REQUEST,
  }
}

const setBrandToPrescription = (payload) => {
  return {
    type: actionTypes.SET_BRAND_TO_PRESCRIPTION,
    payload
  }
}

export { setListPrescriptions, setListPrescriptionsRequest, setBrandToPrescription }
