import React, { useState } from 'react'

import { SectionNewsletter } from './styles'

import Button from '~/components/Button'
import api from '~/services/api'

export default function Newsletter() {
  const [emailState, setEmailState] = useState('')
  return (
    <>
      <SectionNewsletter className="d-md-none pt-4 pb-5 pl-2 pr-2">
        <h3 className="title text--grey-dark text--sm text-center text-md-center mb-5">
          A informação é o melhor remédio: Receba nossa newsletter
        </h3>
        <form action="">
          <div className="d-flex justify-content-center pl-2 pr-2">
            <div className="box lg mr-3">
              <label htmlFor={'email'} className={'d-none'}>
                email
              </label>
              <input
                className="text pl-3 pr-3"
                type="text"
                name="email"
                placeholder="E-mail"
                value={emailState}
                onChange={e => {
                  setEmailState(e.currentTarget.value)
                }}
              />
            </div>
            <Button
              className="primary md text--sm"
              onClick={() =>
                api.post('/users', {
                  isResponsible: false,
                  passwordConfirm:
                    Math.random()
                      .toString(36)
                      .substring(2, 15) +
                    Math.random()
                      .toString(36)
                      .substring(2, 15),
                  profile: {
                    full_name: 'newsletterAdmin',
                  },
                  user: {
                    password:
                      Math.random()
                        .toString(36)
                        .substring(2, 15) +
                      Math.random()
                        .toString(36)
                        .substring(2, 15),
                    email: emailState,
                  },
                  role: 'patient',
                })
              }
            >
              Saiba mais
            </Button>
          </div>
        </form>
      </SectionNewsletter>

      <SectionNewsletter className="d-none d-md-block pt-5 pb-5">
        <h3 className="text--grey-dark text--sm text-left text-md-center mb-5">
          A informação é o melhor remédio: Receba nossa newsletter
        </h3>
        <form action="">
          <div className="d-flex justify-content-center">
            <div className="box lg mr-3">
              <label htmlFor={'email'} className={'accessibility'}>
                email
              </label>
              <input
                className="text pl-3 pr-3"
                type="text"
                name="email"
                id="email"
                placeholder="E-mail"
                value={emailState}
                onChange={e => {
                  setEmailState(e.currentTarget.value)
                }}
              />
            </div>
            <Button
              className="blue md text--sr"
              onClick={() =>
                api.post('/users', {
                  isResponsible: false,
                  passwordConfirm:
                    Math.random()
                      .toString(36)
                      .substring(2, 15) +
                    Math.random()
                      .toString(36)
                      .substring(2, 15),
                  profile: {
                    full_name: 'newsletterAdmin',
                  },
                  user: {
                    password:
                      Math.random()
                        .toString(36)
                        .substring(2, 15) +
                      Math.random()
                        .toString(36)
                        .substring(2, 15),
                    email: emailState,
                  },
                  role: 'patient',
                })
              }
            >
              Receber
            </Button>
          </div>
        </form>
      </SectionNewsletter>
    </>
  )
}
