import React from 'react'
import { Alert } from 'react-bootstrap'

import api from '~/services/api'
import { Container } from './styles'
import Button from '~/components/Button'
import { ButtonPrimary, ButtonSecondary } from '~/components'


export default function GetPrescription({ next, data }) {
  const goSendPrescription = async () => {
    const prescription = {
      status: 'sendPrescription',
    }
    await api.put(`/prescriptions/${data.id}`, prescription)
    next('sendPrescription')
  }

  const goAskMedicine = async () => {
    const prescription = {
      status: 'askMedicine',
    }
    await api.put(`/prescriptions/${data.id}`, prescription)
    next('askMedicine')
  }

  return (
    <div className='container-fluid physician__prescriptions__prescription__getPrescription'>
      <div className="alert-success p-3 mb-2">
          Agora só falta você imprimir a prescrição, assinar e nos enviar de
          volta.
      </div>
      <h4 className="mb-3 text-center">
        Veja aqui a prescrição de {data.patient?.profile?.full_name}
      </h4>
      <div className="d-flex justify-content-center mt-5 mb-3">
        <a href={`/template/prescricao/${data.id}`} target="_blank" rel="noopener">
          <ButtonSecondary
            text='Visualizar/Imprimir'
            btnClass='btn__secondary__prescription'
          />
        </a>
      </div>
      <div className="d-flex flex-column align-items-center mt-3 mb-5">
        <div>
          <ButtonPrimary
            text='Assinatura Digital'
            disabled={true}
            btnClass='btn__primary__prescription disabled'
            />

        </div>
        <small className="text--rm mt-2">Em breve</small>
      </div>
      <div className="d-flex justify-content-between">
        <ButtonPrimary
          text='Voltar'
          callBack={goAskMedicine}
          btnClass='btn__primary__prescription'
        />

        <ButtonPrimary
          text='Subir Prescrição'
          callBack={goSendPrescription}
          btnClass='btn__primary__prescription'
        />

      </div>
    </div>
  )
}
