import axios from 'axios'


const servicoDados = axios.create({
  baseURL: 'https://servicodados.ibge.gov.br/api/v1/localidades/',
})


const getStatesOrCities = (state = '') => {
  let url = `estados?orderBy=nome`
  if (state) url = `estados/${state}/municipios`
  return servicoDados.get(url)
}

export { getStatesOrCities }
