import React, { useEffect, useState } from 'react'
import { ButtonPrimary, ButtonSecondary, CustomInput } from '~/components'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { patientFinishAppointment } from '~/services/requests'
import ModalPayment from '~/components/ModalPayment'
import { toast } from 'react-toastify'


const ModalConfirmConsultation = ({ modalId = 'modal-consultation', title, event, resourceId }) => {
  const { session } = useSelector(state => state.auth)
  const roles = localStorage.getItem('roles')

  const initialValues = {
    defaultDate: '',
    date: '',
    name: '',
    type: '',
    email: '',
    message: '',
    schedule: '',
    physician: '',
  }
  const [form, setForm] = useState(initialValues)
  const [modalPayment, setModalPayment] = useState(false)
  const [idModalPayment, setIdModalPayment] = useState()
  const [room, setRoom] = useState()
  const [date, setDate] = useState('')
  const [hourly, setHourly] = useState('')
  const [dados, setDados] = useState([])
  const [expirationTime, setExpirationTime] = useState({})

  useEffect(() => {
    if (!resourceId) toast.warn('Erro ao selecionar médico! \n por favor contate o suporte')
  }, [resourceId])

  useEffect(() => {
    if (event) {
      setDados(event)
      setDate(moment(event.HourStart).format('YYYY-MM-DD'))
      setHourly(moment(event.HourStart).format('HH:mm'))
      let patientEmail = session.email
      let patientName = session.profile.full_name
      setForm(prevState => {
        return {
          ...prevState,
          date: event.selectedDate,
          email: patientEmail,
          name: patientName,
          schedule: moment(event?.HourStart).format('HH:mm'),
          type: 'Teleatendimento',
          defaultDate: event?.HourStart,
          physician: event?.attendant,
        }
      })
    }
  }, [event])

  const handleClose = (value) => {
    setModalPayment(value)
  }

  const handleModal = (resourceId) => {
    patientFinishAppointment(date, hourly, resourceId)
      .then((r) => {
        setRoom(r.data.room)
        if (r.data.expiration_time) {
          setExpirationTime({ expiration_time: r.data.expiration_time })
        }
        setIdModalPayment(resourceId)
      }).finally(() => setModalPayment(true))
  }

  return (
    <>
      <div
        className='modal fade modal-consultation'
        id={modalId}
        tabIndex='-1'
        aria-labelledby='modal'
        aria-hidden='true'
      >
        <div className='modal-dialog modal-lg modal-dialog-centered'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h1>{title} </h1>
              <button
                type='button'
                className='close'
                data-dismiss='modal'
                aria-label='Close'
              >
                <span aria-hidden='true'>&times;</span>
              </button>
            </div>
            <div className='modal-body'>
              <div className='row'>
                <div className='col-12 p-3'>
                  <CustomInput
                    label='Nome do Médico'
                    inputName='physician'
                    defaultValue={form.physician}
                    disabled={true}
                    inputClass='form-control'
                  />
                </div>
              </div>
              <div className='row'>
                <div className='col-4'>
                  <div className='form-group'>
                    <CustomInput
                      inputClass='w-100'
                      label='Data'
                      inputName='date'
                      inputType='text'
                      defaultValue={form.date}
                      disabled={true}
                    />
                  </div>
                </div>
                <div className='col-4'>
                  <div className='form-group'>
                    <CustomInput
                      label='qual o horário?'
                      inputName='schedule'
                      inputClass='form-control'
                      inputType='text'
                      defaultValue={form.schedule}
                      disabled={true}
                    />

                  </div>
                </div>
                <div className='col-4'>
                  <div className='form-group'>
                    <CustomInput
                      label='Tipo de consulta'
                      inputName='type'
                      inputClass='form-control'
                      inputType='text'
                      defaultValue={form.type}
                      disabled={true}
                    />
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col-12'>
                  <CustomInput
                    label={roles === 'responsible'? 'Nome do responsável': 'Nome do paciente'}
                    inputName='name'
                    defaultValue={form.name}
                    disabled={true}
                    inputClass='form-control'
                  />
                </div>
                <div className='col-12'>
                  <CustomInput
                    label={roles === 'responsible'? 'E-mail do responsável': 'E-mail do paciente'}
                    inputName='email'
                    defaultValue={form.email}
                    disabled={true}
                    inputClass='form-control'
                  />
                </div>
              </div>
            </div>
            <div className='modal-footer'>
              <ButtonSecondary
                text='cancelar'
                btnClass='mx-4 modal-consultation__btn'
                dismiss='modal'
              />

              <ButtonPrimary
                text='Agendar Consulta e Pagar'
                btnClass='modal-consultation__btn'
                toggle='modal'
                dismiss='modal'
                callBack={() => handleModal(resourceId)}
              />

            </div>
          </div>
        </div>
      </div>
      <ModalPayment
        data={dados}
        callback={setModalPayment}
        type={'consultation'}
        id={idModalPayment}
        show={modalPayment}
        handleClose={handleClose}
        room={room}
        expirationTime={expirationTime}
      />
    </>
  )
}

export { ModalConfirmConsultation }
