import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'

import api from '~/services/api'
import defaultBrandCover from '~/assets/images/defaultBrandCover.jpg'
import ProductImage from '~/assets/images/product-sample.jpg'
import OpengraphReactComponent from 'opengraph-react'
import { CustomCheckbox } from '~/components'
import { formatValue } from '~/shared/formatAndMasks'
import Pagination from 'react-js-pagination'

export default function Brand({ history }) {
  const { id } = useParams()
  const [medicines, setMedicines] = useState([])
  const [medicinesInfo, setMedicinesInfo] = useState()
  const [routeState] = useState('/produtos/')
  const [listLinks, setListLinks] = useState([])
  const [listLinksArticles, setListArticles] = useState([])
  const [brand, setBrand] = useState({})
  const role = localStorage.getItem('roles')
  const [showMedicine, setShowMedicine] = useState(role === 'administrator')


  useEffect(() => {
    setShowMedicine(role === 'administrator')
  }, [role])

  const fetchMedicines = async (page=1) => {
    const res = await api.get(`/medicines?brand=${id}&page=${page}`)
    setMedicines(res.data.data)
    setMedicinesInfo(res.data)
  }

  const fetchBrand = async () => {
    const res = await api.get(`/brands/${id}`)
    setListArticles(res.data.articles)
    setListLinks(res.data.links)
    if (res.data) setBrand(res.data)
    else history.push('/')
  }

  const redirect = () => {
    if (role !== 'administrator' && role !== 'physician') {
      if (id !== 'proprium-my-cannabis-code' && id !== 'pacificool-cbd') {
        history.push('/')
      }
    }
  }

  useEffect(() => {
    fetchMedicines()
    fetchBrand()
    redirect()
  }, [])


  const showMedicines = (e) => {
    const { checked } = e.currentTarget
    setShowMedicine(checked)
  }

  return (
    <div>
      <div className='row mx-0'>
        <div className='col-12 px-0'>
          <img className='brand__cover'
               src={
                 brand.cover
                   ? brand.cover.uuidUrl
                   : defaultBrandCover}
               alt=''
          />
          <img className='brand__cover--icon'
               src={brand.logo?.uuidUrl}
               alt=''
          />
        </div>
      </div>
      <div className='row'>
        <div className='col-12'>
          <div className='d-flex justify-content-center my-3'>
            <h3 className='font-primary font-weight-bold brand--text'>
              Produtos
            </h3>
          </div>
        </div>
      </div>
      {role === 'administrator' && (
        <div className='row'>
          <div className='col-12 ml-4'>
            <CustomCheckbox
              label={showMedicine ? 'Desmarque para ocultar produtos inativos' : 'Exibir produtos inativos'}
              inputName='isActive'
              callBack={showMedicines}
              inputValue={showMedicine}
              inputChecked={showMedicine}
            />
          </div>
        </div>
      )}
      <div className='row px-4 mb-3'>
        {medicines.map(product => (product.active || showMedicine) && (
          <div className='col-sm-12 col-lg-4 my-2'>
            <div className='card  brand__medicine shadow border-0'
                 onClick={() => history.push(`${routeState}${product.slug}`)}
            >
              <div className='card-header bg-white border-0'>
                <img className='img-fluid'
                     src={product.photo ? product.photo.uuidUrl : ProductImage}
                     alt=''
                />
              </div>
              <div className='card-body border-0'>
                <h4
                  className='text--rm text--black-dark mt-2 mt-md-3 mb-2 text-truncate'
                >
                  {product.name}
                </h4>
                <div className='d-flex justify-content-between'>
                  <div className='mt-2'>
                    <p className='mb-3'>
                      <Link to={`/marca/${product.brand.slug}`}>
                        {product.brand.name}
                      </Link>
                    </p>
                    <div className='d-flex'>
                      <p className='text--rr text--black-dark mb-5'>
                        {formatValue(product.price, product.currency)}
                      </p>
                      {product.price_previous &&
                      (
                        (product.price * 100) / product.price_previous -
                        100
                      ).toFixed(2) < 0 && (
                        <p className='pl-3 text--black-dark text--rm value text-danger'>
                          <strike>
                            {formatValue(
                              product.price_previous,
                              product.currency,
                            )}
                          </strike>{' '}
                          <span className='value value secondary ml-3 text-success'>
                                  {(
                                    (product.price * 100) /
                                    product.price_previous -
                                    100
                                  ).toFixed(2)}
                            %
                                </span>
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <span className='text--black-dark text--rm text-truncate d-block'>
                      {product.presentation}
                </span>
              </div>
            </div>
          </div>
        ))}
      </div>

      {listLinks !== null && listLinks.length > 0 && listLinks[0] !== '' && (
        <>
          <div className='row'>
            <div className='col-12'>
              <div className='d-flex justify-content-center my-3'>
                <h3 className='font-primary font-weight-bold brand--text'>
                  Videos
                </h3>
              </div>
            </div>
          </div>
          <div className='row px-4'>
            {listLinks.map(value => (
              <div className='col-lg-4 col-sm-12 mb-3'>
                <div className='embed-responsive embed-responsive-16by9'>
                  <iframe
                    className='embed-responsive-item'
                    src={`https://www.youtube.com/embed/${value.replace('https://youtu.be/', '')}`}
                    allowFullScreen
                  />
                </div>
              </div>
            ))}
          </div>
        </>
      )}
      {
        listLinksArticles !== null &&
        listLinksArticles.length > 0 &&
        listLinksArticles[0] !== '' && (
          <>
            <div className='row '>
              <div className='col-12'>
                <div className='d-flex justify-content-center my-3'>
                  <h3 className='font-primary font-weight-bold brand--text'>
                    Artigos
                  </h3>
                </div>
              </div>
            </div>
            <div className='row px-4'>
              {
                Array.isArray(listLinksArticles) &&
                listLinksArticles.map(links => (
                  <div className='col-sm-12 col-lg-4 my-3'>
                    <OpengraphReactComponent
                      site={links}
                      appId={'dee10d5e-b083-4dda-8d08-403ef268a087\t'}
                      size={'large'}
                    />
                  </div>
                ))}
            </div>
          </>
        )}

      <div className='row my-3'>
        <div className="d-flex col-lg-12 justify-content-center mt-3">
          <Pagination
            activePage={medicinesInfo?.page}
            itemsCountPerPage={medicinesInfo?.perPage}
            totalItemsCount={medicinesInfo?.total}
            pageRangeDisplayed={5}
            onChange={fetchMedicines}
            itemClass="page-item"
            linkClass="page-link"
          />
        </div>
      </div>
    </div>
  )
}
