import React from 'react'
import { Redirect, Switch } from 'react-router-dom'

import Route from './Route'
import Home from '~/pages/Admin/Home'
import { HelmetPrivate } from '~/components/HelmetPrivate/HelmetPrivate'
import Profile from '~/pages/Patient/Profile/Show'
import Orders from '~/pages/Admin/Orders'
import Order from '~/pages/Admin/Order'
import Prescriptions from '~/pages/Admin/Prescriptions'
import NewPrescription from '~/pages/Physician/Prescriptions'
import Prescription from '~/pages/Admin/Prescription'
import Patient from '~/pages/Admin/Patient'
import PatientEdit from '~/pages/Admin/Patient/Edit'

export default function Routes() {
  if (localStorage.getItem('roles') !== 'representative') {
    return <Redirect to='/' />
  }

  return (
    <>
      <HelmetPrivate routeStr='Representative' />
      <Switch>
        <Route path='/representantes' exact component={Home} isPrivate redirectTo='/' />
        <Route
          path='/representantes/perfil'
          exact
          component={Profile}
          isPrivate
          redirectTo='/login'
        />
        <Route
          path='/representantes/prescricao'
          exact
          component={Prescriptions}
          isPrivate
          redirectTo='/login'
        />
        <Route
          path='/representantes/paciente/:id'
          exact
          component={Patient}
          isPrivate
          redirectTo='/login'
        />
        <Route
          path='/representantes/pacientes/editar/:id'
          exact
          component={PatientEdit}
          isPrivate
          redirectTo='/login'
        />
        <Route
          path='/representantes/nova/prescricao'
          component={NewPrescription}
          exact
          isPrivate
          redirectTo='/'
        />
        <Route
          path='/representantes/prescricoes/:id'
          component={Prescription}
          exact
          isPrivate
          redirectTo='/'
        />
        <Route
          path='/representantes/pedidos'
          exact
          component={Orders}
          isPrivate
          redirectTo='/login'
        />
        <Route
          path='/representantes/pedidos/:id'
          component={Order}
          exact
          isPrivate
          redirectTo='/'
        />
      </Switch>
    </>
  )
}
