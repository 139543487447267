import React, { useEffect, useState } from 'react'
import moment from 'moment'
import 'moment/locale/pt-br'
import { patientListConsultation } from '~/services/requests'
import { ModalConsultationDetails } from '~/pages/telemed/ModalConsultationDetails'
import ModalPayment from '~/components/ModalPayment'
import Skeleton from 'react-loading-skeleton'
import { AppointmentCard } from '~/components/AppointmentCard/AppointmentCard'
import { showAppointmentDetail, treatmentListConsultation } from '~/shared/telemedOptions'
import { DatePicker } from '~/components/DatePicker/DatePicker'

const PatientListConsultation = () => {
  const [date, setDate] = useState(moment().format('YYYY-MM-DD'))
  const [loading, setLoading] = useState(true)
  const [proposal, setProposal] = useState()
  const [confirmed, setConfirmed] = useState()
  const [event, setSelectedEvent] = useState({})
  const [showPayment, setShowPayment] = useState(false)

  moment.locale('pt-br')

  useEffect(() => {
    setLoading(true)
    patientListConsultation(moment().format(date)).then(r => {
      setProposal(r.data.PROPOSAL)
      setConfirmed(r.data.CONFIRMED)
    }).finally(() => setLoading(false))
  }, [date])

  const handleClose = (value) => {
    setShowPayment(value)
  }

  return (
    <>
      <div className='container-fluid mb-5 view-presential px-4'>
        <div className='row'>
          <div className='col-lg-12 d-flex align-items-center justify-content-between'>
            <h1 className='page-title'>Minhas consultas</h1>
            <DatePicker callBack={setDate}
                        returnFormat='YYYY-MM-DD'
                        showMessage={false}
            />
          </div>
        </div>

        {loading && (
          <div className='row'>
            <div className='col-6'>
              <Skeleton className='mt-2' count={5} />
            </div>
            <div className='col-6'>
              <Skeleton className='mt-2' count={5} />
            </div>
          </div>
        )}

        {Array.isArray(proposal) && !loading && (
          <div className='row my-2'>
            <div className='col-12'>
              <h4>Pendentes de pagamento</h4>
            </div>
            {proposal.map(appointment => (
              <AppointmentCard appointment={appointment}
                               callback={() => showAppointmentDetail(appointment, setSelectedEvent, setShowPayment)}
                               treatment={treatmentListConsultation}
              />
            ))}
          </div>
        )}

        {Array.isArray(confirmed) && !loading && (
          <div className='row mt-2'>
            <div className='col-12'>
              <h4>Confirmadas</h4>
            </div>
            {confirmed.map(appointment => (
              <AppointmentCard appointment={appointment}
                               callback={() => showAppointmentDetail(appointment, setSelectedEvent, setShowPayment)}
                               treatment={treatmentListConsultation}
              />
            ))}
          </div>
        )}

        {!Array.isArray(proposal) && !Array.isArray(confirmed) && !loading && (
          <div className="row mt-2">
            <div className="col-12 d-flex justify-content-center">
              <h5>Você não possuí nenhum agendamento</h5>
            </div>
          </div>
        )}
      </div>
      <ModalConsultationDetails event={event} />
      <ModalPayment
        data={event}
        id={event.physicianId}
        show={showPayment}
        handleClose={handleClose}
        type='consultation'
      />
    </>
  )
}


export { PatientListConsultation }
