import React from 'react'
import { SchedulingReceipt } from '~/pages/telemed/SchedulingReceipt'


const ModalConsultationDetails = ({ event, isPhysician = false }) => {

  return (
    <div className='modal fade' id='modal-consultation-details' tabIndex='-1' role='dialog'
         aria-labelledby='exampleModalLabel'
         aria-hidden='true'>
      <div className='modal-dialog' role='document'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h5 className='modal-title' id='exampleModalLabel'>Consulta - {event?.selectedDate}</h5>
            <button type='button' className='close' data-dismiss='modal' aria-label='Close'>
              <span aria-hidden='true'>&times;</span>
            </button>
          </div>
          <div className='modal-body'>
            <SchedulingReceipt display='d-block' event={event} isPhysician={isPhysician} />
          </div>
        </div>
      </div>
    </div>
  )
}


export { ModalConsultationDetails }
