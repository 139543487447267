import React from 'react'

import { SectionAdmin } from './styles'
import { Container, Row, Col } from 'react-bootstrap'

import SideMenu from '~/components/SideMenu'
import AdminRoutes from '~/routes/Admin'
import ScrollToTop from '~/components/ScrollToTop'

export default function Admin() {
  return (
    <SectionAdmin>
      <ScrollToTop />
      <Container fluid className="p-0">
        <Row className="m-0">
          <Col md={3} className="d-none p-0 d-md-block">
            <SideMenu />
          </Col>
          <Col xs={12} md={9} className="mb-5 mb-md-0">
            <AdminRoutes />
          </Col>
        </Row>
      </Container>
    </SectionAdmin>
  )
}
