import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { Creators as AuthActions } from '~/store/ducks/auth'
import StatesBrasil from '~/helpers/StatesBrasil.json'
import Diseases from '~/helpers/Diseases.json'
import Specialties from '~/helpers/Specialties.json'
import ValidationContract from '~/shared/validationContract'
import {
  ButtonPrimary,
  CustomCheckbox,
  CustomInput,
  CustomMultSelect,
  CustomSelect,
  CustomTextArea,
} from '~/components'
import { getPhysicianForAdmin, updatePhysicianForAdmin } from '~/services/requests'
import { toast } from 'react-toastify'
import Skeleton from 'react-loading-skeleton'
import { Creators as ErrorActions } from '~/store/ducks/error'
import { ReactTinyLink } from 'react-tiny-link'

export default function Edit({ history }) {
  const { session } = useSelector(state => state.auth)
  const [diseases, setDefaultDiseases] = useState([])
  const [listLinks, setListLinks] = useState([])
  const [link, setLink] = useState([])
  const [listArticles, setListArticles] = useState([])
  const [articles, setArticles] = useState([])
  const [errors, setErrors] = useState({})
  const dispatch = useDispatch()

  const [loading, setLoading] = useState(false)
  const { id } = useParams()

  const [serviceCharge, setServiceCharge] = useState('')

  const [form, setForm] = useState({
    crm: '',
    uf: '',
    specialty: '',
    about: '',
    price: '',
    prescribes: false,
    online_consultation: false,
    diseases: '',
    secretary_name: '',
    secretary_email: '',
    secretary_option: '',
  })


  const changeForm = (e) => {
    const { name, value, checked, type } = e.currentTarget

    if (name === 'service_charge') {
      return setServiceCharge(value)
    }

    setForm((prevState => {
      return {
        ...prevState,
        [name]: type === 'checkbox' ? checked : value,
      }
    }))
  }

  const validationForm = () => {
    const validForm = form
    const contract = new ValidationContract()
    contract.isRequired(validForm.crm, 'Digite seu CRM', 'crm')
    contract.isRequired(validForm.uf, 'Selecione o UF do seu CRM', 'uf')
    contract.isRequired(validForm.specialty, 'Selecione sua especialidade', 'specialty')
    contract.isRequired(validForm.diseases, 'Selecione pelo menos uma patologia', 'diseases')
    const messages = Object.fromEntries(contract.errors())
    setErrors(messages)
    return contract
  }

  useEffect(() => {

    setLoading(true)
    getPhysicianForAdmin(id)
      .then(r => {
        setForm(r.data.physician)
        setServiceCharge(r.data?.service_charge)
        setDefaultDiseases(r.data.physician.diseases)
        if (Array.isArray(r.data.physician?.articles)) setListArticles(r.data.physician?.articles)
        if (Array.isArray(r.data.physician?.links)) setListLinks(r.data.physician?.links)
      })
      .catch(e => toast.error(e))
      .finally(() => setLoading(false))

  }, [id])


  const submitForm = async () => {
    let validation = validationForm()
    if (validation.isValid()) {
      setLoading(true)
      let data = form
      data.articles = listArticles
      data.links = listLinks
      if (serviceCharge) data.platform_fee_value = parseFloat(serviceCharge)
      let slug = ''
      updatePhysicianForAdmin({ physician: data }, id)
        .then(r => {
          slug = r.data.physician?.slug
          dispatch(AuthActions.getSessionRequest())
          const to = `/admin/medicos/${slug}`
          history.push(to)
        })
        .catch(r => dispatch(ErrorActions.setError(r.response.data.errors)))
        .finally(() => {
          setLoading(false)
        })
    }

  }

  const addingLink = (listKey, setListKey, origin, linkValue) => {
    if (linkValue) {
      let list = Array.isArray(listKey) ? listKey : []
      list.push(linkValue)
      setListKey(list)
      origin('')
    }
  }

  const changeLink = (e, set) => {
    set(e.currentTarget.value)
  }

  const removeLink = (index, set, list = []) => {
    let newList = []
    list.map((result, removeIndex) => {
      if (removeIndex !== index) {
        newList.push(result)
      }
    })
    return set(newList)
  }

  const mails = ['andersonalves4608@gmail.com', 'viviane@drcannabis.com.br']

  return (
    <>
      <div className={`container-fluid admin_physician_edit_physician`}>
        <div className='row'>
          <div className='col-lg-12'>
            <h2 className='admin_physician_edit_physician_title'>Termine seu cadastro</h2>
          </div>
        </div>
        <div className='row'>
          <div className='col-lg-12'>
            <h2 className='admin_physician_edit_physician_info_title'>Informações médicas</h2>
          </div>
        </div>
        <div className='row'>
          <div className='col-lg-12'>
            <form>
              <div className='row mt-4'>
                <div className='col-lg-8'>
                  {
                    loading ?
                      (
                        <label className='w-100'>CRM
                          <Skeleton className='mt-1' height='44px' />
                        </label>
                      )
                      :
                      (
                        <CustomInput
                          label='CRM'
                          inputName='crm'
                          inputPlaceholder='000000'
                          useRegister={false}
                          errorMessage={errors.crm}
                          callBack={(e) => changeForm(e)}
                          defaultValue={form?.crm}
                        />
                      )
                  }

                </div>
                <div className='col-lg-4'>
                  {
                    loading ?
                      (
                        <label className='w-100'>UF
                          <Skeleton className='mt-1' height='44px' />
                        </label>
                      )
                      :
                      (
                        <CustomSelect
                          label='UF'
                          inputName='uf'
                          errorMessage={errors.uf}
                          useRegister={false}
                          callBack={(e) => changeForm(e)}
                          selectValue={form?.uf}
                        >
                          <option value=''>Selecione Estado</option>
                          {StatesBrasil.map(state => (
                            <option value={state.value}>{state.value}</option>
                          ))}
                        </CustomSelect>
                      )
                  }

                </div>
                <div className='col-lg-6'>
                  {
                    loading ?
                      (
                        <label className='w-100 mt-3'>Especialidade
                          <Skeleton className='mt-1' height='44px' />
                        </label>
                      )
                      :
                      (
                        <CustomSelect
                          label='Especialidade'
                          inputName='specialty'
                          useRegister={false}
                          errorMessage={errors.specialty}
                          callBack={(e) => changeForm(e)}
                          selectValue={form?.specialty}
                        >
                          <option value=''>Selecione Especialidade</option>
                          {Specialties.map(specialty => (
                            <option value={specialty}>{specialty}</option>
                          ))}
                        </CustomSelect>
                      )
                  }

                </div>

                <div className='col-lg-12'>
                  {
                    loading ?
                      (
                        <label className='w-100 mt-3'>Lista de patologias atendidas
                          <Skeleton className='mt-1' height='44px' />
                        </label>)
                      :
                      (
                        <CustomMultSelect
                          label='Lista de patologias atendidas'
                          listOptions={Diseases}
                          inputName='diseases'
                          inputPlaceholder=''
                          value={diseases}
                          useRegister={false}
                          errorMessage={errors.diseases}
                          select={(value) =>
                            setForm((prev) => {
                              return { ...prev, diseases: value }
                            })
                          }
                          remove={(value) =>
                            setForm((prev) => {
                              return { ...prev, diseases: value }
                            })
                          }

                        />
                      )

                  }

                </div>
                <div className='col-lg-12'>
                  {
                    loading ?
                      (
                        <label className='w-100 mt-3'>Sobre
                          <Skeleton className='mt-1' height='140px' />
                        </label>
                      )
                      :
                      (
                        <CustomTextArea
                          label='Sobre'
                          inputName='about'
                          inputPlaceholder='Conte um pouco sobre sua vida profissional e a área que atua. Esta informação ficará visível para pacientes quando o seu perfil for aprovado.'
                          callBack={(e) => changeForm(e)}
                          defaultValue={form?.about}
                        />
                      )

                  }
                </div>
                {mails.includes(session?.email) && form.online_consultation && localStorage.getItem('roles') === 'administrator' && (
                  <div className='col-lg-12'>
                    {
                      loading ?
                        (
                          <label className='w-100 mt-3'>Taxa de consulta (Drc)
                            <Skeleton className='mt-1' height='44px' />
                          </label>
                        )
                        :
                        (
                          <CustomInput
                            label='Taxa de consulta (Drc)'
                            inputName='service_charge'
                            callBack={(e) => changeForm(e)}
                            defaultValue={serviceCharge}
                          />
                        )}
                  </div>
                )}

                <div className='col-lg-4'>
                  {
                    loading ?
                      (
                        <label className='w-100 mt-3'>Preço da consulta
                          <Skeleton className='mt-1' height='44px' />
                        </label>
                      )
                      :
                      (
                        <CustomInput
                          label='Preço da consulta'
                          inputName='price'
                          callBack={(e) => changeForm(e)}
                          defaultValue={form?.price}
                        />
                      )}

                </div>

                <div className='col-3  d-flex align-items-center'>
                  {
                    loading ?
                      (<label className='w-100 mt-3'>
                          <Skeleton className='mt-1' width='30%' height='44px' />
                        </label>
                      )
                      :
                      (
                        <CustomCheckbox
                          inputName='prescribes'
                          label='Prescreve CBD.'
                          callBack={(e) => changeForm(e)}
                          inputChecked={form?.prescribes}
                        />
                      )
                  }


                </div>

                <div className='col-3 d-flex align-items-center'>
                  {
                    loading ?
                      (
                        <label className='w-100 mt-4'>
                          <Skeleton className='mt-1' width='30%' height='44px' />
                        </label>
                      )
                      :
                      (
                        <CustomCheckbox
                          inputName='online_consultation'
                          label='Faz consultas online'
                          inputClass='mt-3'
                          callBack={(e) => changeForm(e)}
                          inputChecked={form.online_consultation}
                          inputValue={form.online_consultation}
                        />)
                  }
                </div>

                <div className='col-lg-4'>
                  <CustomInput
                    label='Nome do(a) Secretário(a)'
                    inputName='secretary_name'
                    defaultValue={form.secretary_name}
                    callBack={(e) => changeForm(e)}
                  />
                </div>

                <div className='col-lg-4'>
                  <CustomInput
                    label='Email do(a) Secretário(a)'
                    inputName='secretary_email'
                    defaultValue={form.secretary_email}
                    callBack={(e) => changeForm(e)}
                  />
                </div>

                <div className='col-lg-4'>
                  <CustomSelect
                    label='Opções de email'
                    inputName='secretary_option'
                    defaultValue={form.secretary_option}
                    callBack={(e) => changeForm(e)}
                  >
                    <option value='physician'>Enviar apenas para mim</option>
                    <option value='secretary'>
                      Enviar apenas para secretária
                    </option>
                    <option value='both'>Enviar email para ambos</option>
                  </CustomSelect>
                </div>

                <div className='col-12 my-3'>
                  <div className='card'>
                    <div className='card-header d-flex justify-content-center'>
                      <h6>Videos</h6>
                    </div>
                    <div className='card-body'>
                      <div className='row'>
                        {Array.isArray(listLinks) &&
                        listLinks.map(
                          (data, index) =>
                            data && (
                              <div className='col-4 mt-2'>
                                <ReactTinyLink
                                  cardSize='large'
                                  showGraphic={true}
                                  maxLine={3}
                                  minLine={1}
                                  url={data}
                                  proxyUrl='https://corsanywhere.herokuapp.com'
                                />
                                <ButtonPrimary
                                  text='Deletar'
                                  btnClass='btn_delete'
                                  callBack={() =>
                                    removeLink(index, setListLinks, listLinks)
                                  }
                                />
                              </div>
                            ),
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className='col-12'>
                  <label htmlFor='links'>Link do video</label>
                  <div className='row'>
                    <div className='col-lg-10'>
                      <CustomInput
                        id='links'
                        inputPlaceholder='youtube.com'
                        defaultValue={link}
                        loading={loading}
                        skeletonDivClass='mb-4'
                        callBack={e => changeLink(e, setLink)}
                      />
                    </div>
                    <div className='col-lg-2'>
                      <ButtonPrimary
                        text='adicionar'
                        width='w-100'
                        btnMt='mt-2'
                        btnType='button'
                        callBack={() =>
                          addingLink(listLinks, setListLinks, setLink, link)
                        }
                        disabled={!link.length > 0}
                      />
                    </div>


                  </div>
                </div>

                <div className='col-12 my-3'>
                  <div className='card'>
                    <div className='card-header d-flex justify-content-center'>
                      <h6>Artigos</h6>
                    </div>
                    <div className='card-body'>
                      <div className='row'>
                        {Array.isArray(listArticles) &&
                        listArticles.map(
                          (data, index) =>
                            data && (
                              <div className='col-4 mt-2'>
                                <ReactTinyLink
                                  cardSize='large'
                                  showGraphic={true}
                                  maxLine={3}
                                  minLine={1}
                                  url={data}
                                  proxyUrl='https://corsanywhere.herokuapp.com'
                                />
                                <ButtonPrimary
                                  text='Deletar'
                                  btnClass='btn_delete'
                                  callBack={() =>
                                    removeLink(index, setListArticles, listArticles)
                                  }
                                />
                              </div>
                            ),
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className='col-12'>
                  <label htmlFor='links'>Link do artigo</label>
                  <div className='row'>
                    <div className='col-lg-10'>
                      <CustomInput
                        id='articles'
                        inputPlaceholder='articles.com'
                        defaultValue={articles}
                        loading={loading}
                        skeletonDivClass='mb-4'
                        callBack={e => changeLink(e, setArticles)}
                      />
                    </div>

                    <div className='col-lg-2'>
                      <ButtonPrimary
                        text='Adicionar'
                        width='w-100'
                        btnMt='mt-2'
                        btnType='button'
                        callBack={() =>
                          addingLink(
                            listArticles,
                            setListArticles,
                            setArticles,
                            articles,
                          )
                        }
                        disabled={!articles.length > 0}
                      />

                    </div>


                  </div>
                </div>

                <div className='col-lg-12 mb-4'>
                  <ButtonPrimary
                    btnType='button'
                    disabled={loading}
                    text={loading ? 'Carregando...' : 'Continuar'}
                    callBack={submitForm}
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}
