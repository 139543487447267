import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { ButtonPrimary, CustomInput } from '~/components'
import { getAllCampaigns } from '~/services/requests'
import { ModalNewCoupon } from '~/components/ModalNewCoupon/ModalNewCoupon'
import { ModalNewCampaign } from '~/components/ModalNewCampaign/ModalNewCampaign'


const Campaign = () => {
  const [search, setSearch] = useState('')
  const [loading, setLoading] = useState(true)
  const [listCampaigns, setListCampaigns] = useState([])
  const [auxListCampaigns, setAuxListCampaigns] = useState([])

  const history = useHistory()

  useEffect(() => {
    getAllCampaigns().then((r) => {
      setLoading(false)
      setListCampaigns(r.data)
      setAuxListCampaigns(r.data)
    })
  }, [])


  const changeOptionsForm = (e) => {
    setSearch(e.currentTarget.value)
  }

  useEffect(() => {
    const data = []
    listCampaigns.findIndex((list) => {
      if (list.name.toLowerCase().includes(search)) {
        data.push(list)
      }
    })
    setAuxListCampaigns(data)
  }, [search])

  const updateList = (payload) => {
    const data = listCampaigns
    data.push(payload)
    setAuxListCampaigns(data)
    setListCampaigns(data)
    window.location.reload();
  }

  return (
    <>
      <div className='admin_campaign'>
        <div className='row'>
          <div className='col-lg-12 d-flex justify-content-between align-items-center'>
            <h1 className='admin_campaign_title'>Campanhas</h1>
            <div>
              <ButtonPrimary text='Nova campanha'
                             btnClass='mr-2'
                             toggle='modal'
                             target='#campaignModal'
              />
              <ButtonPrimary text='Novo Cupon'
                             toggle='modal'
                             target='#couponModal'
              />
            </div>
          </div>
        </div>
        <div className='row mt-2'>
          <div className='col-lg-4'>
            <CustomInput
              inputName='slug'
              inputPlaceholder='Nome do Campanha'
              inputClass='w-100'
              icon='far fa-search'
              loading={loading}
              defaultValue={search}
              callBack={changeOptionsForm}
            />
          </div>
        </div>
        <div className='row mt-4'>
          {auxListCampaigns.map(value => (
            <div className='col-lg-6 col-sm-12 mb-2'
                 onClick={() => history.push(`/admin/campanha/${value.slug}`)}
                 key={value.name}
            >
              <div className='card border-0 shadow admin_campaign-card'>
                <div className='card-header bg-transparent border-0 d-flex justify-content-between'>
                  <span className='d-block text-truncate'>
                    <b>Campanha:</b> {value.slug}
                  </span>
                  {value.active ?
                    <i className="fas fa-check text-success"
                       data-toggle="tooltip"
                       data-placement="top"
                       title="Campanha ativa"
                    />
                    :
                    <i className="fas fa-ban text-danger"
                       data-toggle="tooltip"
                       data-placement="top"
                       title="Campanha inativa"
                    />
                  }
                </div>
                <div className='card-body border-0'>
                  <p className='mt-2'><b>Descrição:</b> {value.description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <ModalNewCoupon listCampaigns={listCampaigns} />
      <ModalNewCampaign updateList={updateList} />
    </>

  )
}

export { Campaign }
