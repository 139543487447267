import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { deletedCampaign, getCampaign } from '~/services/requests'
import { toast } from 'react-toastify'
import { ButtonPrimary } from '~/components'
import { ModalNewCampaign } from '~/components/ModalNewCampaign/ModalNewCampaign'
import { formatDate } from '~/shared/formatAndMasks'


const CampaignDetails = () => {
  const { slug } = useParams()
  const [campaign, setCampaign] = useState({})
  const [coupons, setCoupons] = useState({})

  const history = useHistory()

  useEffect(() => {
    getCampaign(slug)
      .then((r) => {
        setCampaign(r.data)
        setCoupons(r.data.coupons)
      })
      .catch(() => toast.error('Erro ao buscar campanha'))
  }, [])

  const deleteCampaign = () => {
    deletedCampaign(slug).then(()=>history.push('/admin/campanha'))
  }

  return (
    <>
      <div className='admin_campaign'>
        <div className='row mb-5'>
          <div className='col-lg-12 d-flex justify-content-between align-items-center'>
            <h1 className='admin_campaign_title'>{campaign.name}</h1>
            <div>
              <ButtonPrimary text='Editar'
                             btnClass='mr-2'
                             toggle='modal'
                             target='#campaignModal'
              />
              <ButtonPrimary text='Excluir'
                             btnClass='mr-2 bg-danger border-0'
                             callBack={deleteCampaign}
              />
            </div>
          </div>
        </div>
        <div className='row mb-5'>
          <div className='col-12'>
            <h4>Descrição:</h4>
            <p>{campaign.description}</p>
          </div>
        </div>
        <div className='row'>
          {Array.isArray(coupons) && coupons.map(value => (
            <div className='col-lg-6 col-sm-12 mb-2'
                 onClick={() => history.push(`/admin/campanha/cupom/${value.slug}`)}
                 key={value.name}
            >
              <div className='card border-0 shadow admin_campaign-card'>
                <div className='card-header bg-transparent border-0 d-flex justify-content-between'>
                  <span className='d-block text-truncate'>
                    <b>Cupom:</b> {value.name}
                  </span>
                  {value.active ?
                    <i className='fas fa-check text-success'
                       data-toggle='tooltip'
                       data-placement='top'
                       title='Cupom ativo'
                    />
                    :
                    <i className='fas fa-ban text-danger'
                       data-toggle='tooltip'
                       data-placement='top'
                       title='Cupom inativo'
                    />
                  }
                </div>
                <div className='card-body border-0'>
                  <p className='mt-2'><b>Descrição:</b> {value.description}</p>
                  <div className='d-flex justify-content-between mt-2'>
                    <p><b>Quantidade:</b> {value.quantity}</p>
                    <p><b>Expira em:</b> {formatDate(value.expiration)}</p>
                    <p><b>Desconto:</b> {value.discount}%</p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <ModalNewCampaign defaultValues={campaign}
                        isPut={true}
                        updateList={setCampaign}
                        slug={slug}
      />
    </>
  )
}

export { CampaignDetails }
