import React, { useEffect, useState } from 'react'
import { useParams, Link } from 'react-router-dom'
import { Row, Col } from 'react-bootstrap'
import Skeleton from 'react-loading-skeleton'
import Pagination from 'react-js-pagination'

import { Container, Product } from './styles'
import api from '~/services/api'
import Sprites from '~/assets/icons/sprites.svg'
import { ButtonPrimary } from '~/components'
import { formatValue } from '~/components/Currency'


export default function Brand() {
  const { slug } = useParams()
  const [medicines, setMedicines] = useState({ data: [] })
  const [loading, setLoading] = useState({ data: [] })
  const [brand, setBrand] = useState({})

  const getData = async (brandSlug, page = 1) => {
    setLoading(true)
    const resBrand = await api.get(`/brands/${brandSlug}`)
    setBrand(resBrand.data)
    const resMedicine = await api.get(`/medicines?brand=${brandSlug}`, {
      params: { page },
    })
    setMedicines(resMedicine.data)
    setLoading(false)
  }

  useEffect(() => {
    getData(slug)
  }, [slug])

  const handlePageChange = page => {
    getData(slug, page)
  }

  return (
    <div className='container-fluid admin_brand'>
      {loading ? (<Skeleton width='200px'/>):(<h2 className='admin_brand_title'>{brand.name}</h2>)}
      {
        loading && (
          <div className='row'>
            <div className='col-lg-4'>
              <Skeleton className='mt-2' height='400px'/>
            </div>
            <div className='col-lg-4'>
              <Skeleton className='mt-2' height='400px'/>
            </div>
            <div className='col-lg-4'>
              <Skeleton className='mt-2' height='400px'/>
            </div>
          </div>
        )
      }

      {!loading && (
        <>
          <div className="row mt-3">
            {medicines.data.map(medicine => (
              <div className='col-lg-4'>
                <Link to={`/produtos/${medicine.slug}`}>
                  <div className="admin_brand_product d-flex flex-column mb-4">
                      <img
                        src={medicine?.photo?.uuidUrl}
                        style={{ cursor: 'pointer' }}
                        alt=""
                      />
                      <div className="d-flex flex-column p-3 pr-4">
                        <h4 className="admin_brand_product_name">
                          {medicine.name}
                        </h4>
                        <p className="admin_brand_product_price">
                          {formatValue(medicine?.price,medicine?.currency)}
                        </p>
                        <Link
                          to={`/admin/produtos/editar/${medicine.slug}`}
                          className="anchor text--sm text-decoration-none d-flex align-items-center justify-content-end"
                        >
                          <ButtonPrimary
                            text='Editar'
                            width='w-100'
                          />

                        </Link>
                      </div>
                </div>
                </Link>
              </div>
            ))}
          </div>
          <div className='row'>
            <div className="d-flex col-lg-12 justify-content-center mt-3">
              <Pagination
                activePage={medicines.page}
                itemsCountPerPage={medicines.perPage}
                totalItemsCount={medicines.total}
                pageRangeDisplayed={5}
                onChange={handlePageChange}
                itemClass="page-item"
                linkClass="page-link"
              />
            </div>
          </div>
        </>
      )}
    </div>
  )
}
