import React, { useEffect, useState } from 'react'
import ProductSample from '~/assets/images/product-sample.jpg'
import { round } from '~/shared/utils'
import * as $ from 'jquery'
import { RenderImg } from '~/components/RenderImg/RenderImg'

const ModalCalculator = ({ modalId, medicine }) => {
  const [dosage, setDosage] = useState({
    dailyDose: 1,
    dailyDoseMg: 0,
    dropsPerDose: 0,
    TreatmentTime: 0,
    concentration: 0,
    volumePerDose: 0,
    bottleDuration: 0,
    requiredBottles: 0,
    dosePerApplication: 0,
  })

  $(document).ready(function() {
    $('.modal').on('hidden.bs.modal', function() {
      setDosage(prevState => {
        return {
          ...prevState,
          dailyDose: 1,
          dailyDoseMg: 0,
          dropsPerDose: 0,
          TreatmentTime: 0,
          volumePerDose: 0,
          bottleDuration: 0,
          requiredBottles: 0,
          dosePerApplication: 0,
        }
      })
    })
  })

  const changeInput = (e, key) => {
    const value = e.currentTarget.value
    setDosage(prevState => {
      return {
        ...prevState,
        [key]: value,
      }
    })
  }

  useEffect(() => {
    setDosage(prevState => {
      return {
        ...prevState,
        dosePerApplication: dosage.dailyDoseMg / dosage.dailyDose,
      }
    })
  }, [dosage.dailyDoseMg, dosage.dailyDose])

  useEffect(() => {
    const value = round(dosage.dosePerApplication / dosage.concentration, 2)
    setDosage(prevState => {
      return {
        ...prevState,
        volumePerDose: isNaN(value) ? 0 : value,
      }
    })
  }, [dosage.dosePerApplication])

  useEffect(() => {
    const value = Math.ceil(medicine.cbdmg / medicine.content)
    setDosage(prevState => {
      return {
        ...prevState,
        concentration: isNaN(value) ? 0 : value,
      }
    })
  }, [medicine.cbdmg, medicine.content])

  useEffect(() => {
    const value = Math.ceil(dosage.volumePerDose * medicine.drops_per_ml)
    setDosage(prevState => {
      return {
        ...prevState,
        dropsPerDose: isNaN(value) ? 0 : value,
      }
    })
  }, [dosage.volumePerDose])

  useEffect(() => {
    const value = Math.ceil(
      medicine.content / (dosage.dailyDose * dosage.volumePerDose),
    )
    setDosage(prevState => {
      return {
        ...prevState,
        bottleDuration: isNaN(value) || value === Infinity ? 0 : value,
      }
    })
  }, [dosage.concentration, dosage.dailyDose, dosage.volumePerDose])

  useEffect(() => {
    const value = Math.ceil(dosage.TreatmentTime / dosage.bottleDuration)
    setDosage(prevState => {
      return {
        ...prevState,
        requiredBottles: isNaN(value) ? 0 : value,
      }
    })
  }, [
    dosage.TreatmentTime,
    dosage.dailyDose,
    dosage.volumePerDose,
    dosage.concentration,
  ])

  return (
    <div
      className="modal fade"
      id={modalId}
      tabIndex="-1"
      role="dialog"
      aria-labelledby={`${modalId}-label`}
      aria-hidden="true"
    >
      <div className="modal-dialog modal-xl" role="document">
        <div className="modal-content">
          <div className="modal-header border-bottom-0 mt-2">
            <h1
              className=" font-weight-normal outline-primary ml-auto modal-calculator--title"
              id={`${modalId}-label`}
            >
              <i className="fas fa-calculator mr-2" />
              Calculadora de Dosagem{' '}
              <span className="font-size-20">(beta)</span>
            </h1>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body border-bottom-0">
            <div className="row">
              <div className="col-5">
                {medicine && medicine.photo ? (
                  <img
                    src={medicine.photo.uuidUrl}
                    className="img mb-3 m-sm-0 modal-calculator--img"
                    alt="Produto"
                  />
                ) : (
                  <img
                    src={ProductSample}
                    className="img mb-3 m-sm-0 modal-calculator--img"
                    alt="Produto"
                  />
                )}
              </div>
              <div className="col-7 pt-5">
                <span className="font-weight-normal font-size-24 modal-calculator--label">
                  Produto:
                </span>
                <p className="font-weight-normal modal-calculator--product font-size-24">
                  {medicine.name}
                </p>
                <div className="row mt-3">
                  <div className="col-6">
                    <span className="font-weight-normal font-size-24 modal-calculator--label">
                      Concentração:
                    </span>
                    <p className="font-weight-normal modal-calculator--product font-size-18">
                      {round(medicine.cbdmg / medicine.content, 2)} mg/ml
                    </p>
                  </div>
                  <div className="col-6">
                    <span className="font-weight-normal font-size-24 modal-calculator--label">
                      Volume:
                    </span>
                    <p className="font-weight-normal modal-calculator--product font-size-18">
                      {medicine.content} ml
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mx-4 mt-3">
              <div className="col-12">
                <span className="font-weight-normal modal-calculator--label">
                  Posologia:
                </span>
              </div>
            </div>
            <div className="row mx-4 mt-3">
              <div className="col-3">
                <div className="form-group">
                  <label
                    htmlFor="dailyDoseMg"
                    className="font-weight-normal font-size-18 modal-calculator--label w-100"
                  >
                    Dose total Diária
                  </label>
                  <div className="input-group modal-calculator--input-group unity-input">
                    <input
                      type="text"
                      className="form-control modal-calculator--input modal-calculator--unity-input"
                      id="dailyDoseMg"
                      placeholder="300 mg"
                      value={dosage.dailyDoseMg}
                      onChange={e => changeInput(e, 'dailyDoseMg')}
                    />
                    <span
                      className="modal-calculator--unity-box"
                      id="basic-addon2"
                    >
                      mg
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-3">
                <div className="form-group">
                  <label
                    htmlFor="dailyDose"
                    className="font-weight-normal font-size-18 modal-calculator--label w-100"
                  >
                    Divididos em
                  </label>
                  <div className="input-group modal-calculator--input-group unity-input">
                    <input
                      type="text"
                      className="form-control modal-calculator--input modal-calculator--unity-input"
                      id="dailyDose"
                      value={dosage.dailyDose}
                      onChange={e => changeInput(e, 'dailyDose')}
                    />
                    <span
                      className="modal-calculator--unity-box"
                      id="basic-addon2"
                    >
                      doses
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-3">
                <div className="form-group ">
                  <label
                    htmlFor="volumePerDose"
                    className="font-weight-normal font-size-18 modal-calculator--label w-100"
                  >
                    Volume por dose
                  </label>
                  <div className="input-group modal-calculator--input-group unity-input">
                    <input
                      type="text"
                      className="form-control modal-calculator--input modal-calculator--unity-input"
                      id="volumePerDose"
                      placeholder="2 ml"
                      value={dosage.volumePerDose}
                      disabled
                    />
                    <span
                      className="modal-calculator--unity-box modal-calculator--unity-box-disabled"
                      id="basic-addon2"
                    >
                      ml
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-3">
                <div className="form-group">
                  <div className="row">
                    <label
                      htmlFor="dropsPerDose"
                      className="font-weight-normal font-size-18 modal-calculator--label w-100"
                    >
                      Gotas por dose
                    </label>
                  </div>
                  <div className="row">
                    <div className="input-group modal-calculator--input-group unity-input">
                      <input
                        type="text"
                        className="form-control modal-calculator--input modal-calculator--unity-input"
                        id="dropsPerDose"
                        placeholder="40 gotas"
                        value={dosage.dropsPerDose}
                        disabled
                      />
                      <span
                        className="modal-calculator--unity-box modal-calculator--unity-box-disabled"
                        id="basic-addon2"
                      >
                        gotas
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 text-right modal-calculator--label font-size-14">
                * Calculado com base em {medicine.drops_per_ml} gotas por ml.
              </div>
            </div>
            <div className="row mx-4 mt-4">
              <div className="col-12">
                <span className="font-weight-normal modal-calculator--label">
                  Duração do tratamento:
                </span>
              </div>
            </div>
            <div className="row mx-4 mt-2 mb-3">
              <div className="col-4">
                <div className="form-group">
                  <label
                    htmlFor="TreatmentTime"
                    className="font-weight-normal font-size-18 modal-calculator--label w-100"
                  >
                    Tempo de tratamento
                  </label>
                  <div className="input-group modal-calculator--input-group unity-input">
                    <input
                      type="text"
                      className="form-control modal-calculator--input modal-calculator--unity-input"
                      id="TreatmentTime"
                      placeholder="180 dias"
                      value={dosage.TreatmentTime}
                      onChange={e => changeInput(e, 'TreatmentTime')}
                    />
                    <span
                      className="modal-calculator--unity-box"
                      id="basic-addon2"
                    >
                      dias
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-4">
                <div className="form-group">
                  <label
                    htmlFor="bottleDuration"
                    className="font-weight-normal font-size-18 modal-calculator--label w-100"
                  >
                    Duração do Frasco
                  </label>
                  <div className="input-group modal-calculator--input-group unity-input">
                    <input
                      type="text"
                      className="form-control modal-calculator--input modal-calculator--unity-input"
                      id="bottleDuration"
                      placeholder="8 dias"
                      value={dosage.bottleDuration}
                      disabled
                    />
                    <span
                      className="modal-calculator--unity-box modal-calculator--unity-box-disabled"
                      id="basic-addon2"
                    >
                      dias
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-4">
                <div className="form-group">
                  <label
                    htmlFor="requiredBottles"
                    className="font-weight-normal font-size-18 modal-calculator--label w-100"
                  >
                    Frascos Necessários
                  </label>
                  <div className="input-group modal-calculator--input-group unity-input">
                    <input
                      type="text"
                      className="form-control modal-calculator--input modal-calculator--unity-input"
                      id="requiredBottles"
                      placeholder="23 frascos"
                      value={dosage.requiredBottles}
                      disabled
                    />
                    <span
                      className="modal-calculator--unity-box modal-calculator--unity-box-disabled"
                      id="basic-addon2"
                    >
                      frascos
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export { ModalCalculator }
