import { updateObject } from '~/shared/updateObject'

const initialState = {
  listPrescriptions: [],
  finishRequest: false,
  form: {
    search: '',
    status: '',
    haveAnvisa: null,
    havePrescription: null,
    haveOrder: null,
  },
  brands: [],
}

const setListPrescriptions = (state, action) => {
  return updateObject(state, {
    listPrescriptions: action.payload,
    finishRequest: true,
    form: action.form,
  })
}

const setListPrescriptionsRequest = (state) => {
  return updateObject(state, {
    finishRequest: false,
  })
}

const setBrandsToPrescription = (state, action) => {
  return updateObject(state, {
    brands: action.payload,
  })
}

const prescriptionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_LIST_PRESCRIPTIONS':
      return setListPrescriptions(state, action)
    case 'SET_LIST_PRESCRIPTIONS_REQUEST':
      return setListPrescriptionsRequest(state)
    case 'SET_BRAND_TO_PRESCRIPTION':
      return setBrandsToPrescription(state, action)
    default:
      return state
  }
}

export { prescriptionsReducer }
