import React, { useState } from 'react'
import moment from 'moment'
import { cepMask, cpfMask, phoneMask } from '~/shared/formatAndMasks'
import { ModalEditAppointment } from '~/components/ModalEditAppointment/ModalEditAppointment'
import { ModalDeletedAppointment } from '~/components/ModalDeletedAppointment/ModalDeletedAppointment'


const AppointmentCard = (
  {
    appointment,
    callback,
    treatment,
    isPhysician = false,
    isAdmin = false,
    isEditable = false,
    calendarOptions,
    appointmentLength,
  },
) => {
  const [appointmentInfo, setAppointmentInfo] = useState({
    name: '',
    uuid: '',
  })
  const [showEdit, setShowEdit] = useState(false)
  const [showDelete, setShowDelete] = useState(false)

  const getTitle = (isPhysician, appointment) => {
    if (isPhysician || isAdmin)
      return `${moment(appointment.from ? appointment.from : appointment.start).format('HH:mm')} - ${appointment?.attendee?.personal_profile.name}`
    else
      return `Consulta - ${moment(appointment?.start).format('DD/MM/YYYY')}`
  }

  const changeAppointmentInfo = () => {
    setAppointmentInfo({
      name: appointment?.attendee?.personal_profile.name,
      uuid: appointment?.uuid,
    })
    setShowEdit(true)
  }

  const changeAppointmentInfoToDeleted = () => {
    setShowDelete(true)
    setAppointmentInfo({
      name: appointment?.attendee?.personal_profile.name,
      uuid: appointment?.uuid,
    })
  }

  return (
    <>
      <div className='col-6 my-2'>
        <div className='card shadow'>
          <div className='card-header border-0 d-flex justify-content-between'>
            <h5>{getTitle(isPhysician, appointment)}</h5>
            <div>
              {appointment.status === 'CONFIRMED' ?
                <span className='badge bg-success text-white'>
                <i className='fas fa-dollar-sign text-white'
                   data-toggle='tooltip'
                   title='Pagamento confirmado'
                /> Pago
              </span>
                :
                <i className='fas fa-exclamation-triangle mr-2 text-warning' data-toggle='tooltip'
                   title='Aguardando pagamento' />}

            </div>
          </div>
          <div key={appointment?.id}
               className='card-body '
               onClick={() => callback(appointment)}
          >
            <>
              <p className='mb-1 d-flex'>
                <div className='appointment-icon-row justify-content-center'>
                  <i className='fas fa-phone-alt' />
                </div>
                {appointment?.attendee?.personal_profile?.phone && (
                  <span className='ml-2'>
                  {phoneMask(appointment?.attendee?.personal_profile?.phone)}
                </span>
                )}
              </p>
              <p className='mb-1 mt-2 d-flex'>
                <div className='appointment-icon-row justify-content-center'>
                  <i className='fas fa-mobile-alt' />
                </div>
                {appointment?.attendee?.personal_profile?.mobile && (
                  <span className='ml-2'>
                  {phoneMask(appointment?.attendee?.personal_profile?.mobile)}
                </span>
                )}
              </p>
              <p className='mb-1 mt-2 d-flex'>
                <div className='appointment-icon-row'>
                  <i className='fas fa-id-card' />
                </div>
                <span className='ml-2'>{cpfMask(appointment?.cpf)} (cpf)</span>
              </p>
              <p className='mb-1 mt-3'>Endereço</p>
              <p className='mb-1 ml-3'>
                {appointment?.address?.street}{` `}
                Nº {appointment?.address?.number}{` `}
                {appointment?.address?.complement && `Complemento: ${appointment?.address?.complement}`}
              </p>
              <p className='mb-1 ml-3'>{appointment?.address?.district}</p>
              <p className='mb-1 ml-3'>{appointment?.address?.city} / {appointment?.address?.state}</p>
              <p className='mb-1 ml-3'>{cepMask(appointment?.address?.cep)}</p>
            </>
          </div>
          {isEditable && (
            <div className='bg-transparent border-0 card-footer d-sm-inline-flex justify-content-end'>

              <i className='far fa-edit text-success cursor-pointer mr-lg-4'
                 data-toggle='modal'
                 data-target='#editAppointment'
                 onClick={() => changeAppointmentInfo()}
              >
                {' '}Editar
              </i>
              <i className='far fa-calendar-minus cursor-pointer mr-lg-2 text-danger'
                 data-toggle='modal'
                 data-target='#deleteAppointment'
                 onClick={() => changeAppointmentInfoToDeleted()}
              >
                {' '}Apagar
              </i>
            </div>
          )}
        </div>
      </div>
      <ModalEditAppointment calendarOptions={calendarOptions}
                            appointmentLength={appointmentLength}
                            appointment={appointmentInfo}
                            show={showEdit}
                            closeModal={setShowEdit}
      />
      <ModalDeletedAppointment appointment={appointmentInfo}
                               show={showDelete}
                               closeModal={setShowDelete}
      />
    </>
  )
}


export { AppointmentCard }
