import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import moment from 'moment'
import { useForm } from 'react-hook-form'

import api from '~/services/api'
import MedicineImage from '~/assets/images/produto.png'
import Thumb from '~/components/Thumb'
import ModalEditOrder from './ModalEditOrder/index'
import { Input } from '~/components/Form'
import { transform } from '~/shared/utils'
import { Currency } from '~/components/Currency'
import { ModalConfimation } from '~/components/ModalConfirmation/ModalConfimation'
import pdfIcon from '~/assets/icons/pdfIcon.png'
import { isAuthenticated } from '~/services/auth'
import { setOrderStates } from '~/redux/actions/orders'
import { ButtonPrimary, ButtonSecondary, CustomInput, CustomSelect } from '~/components'
import { phoneMask } from '~/shared/formatAndMasks'
import { toast } from 'react-toastify'
import { Creators as ErrorActions } from '~/store/ducks/error'

export default function Order() {
  const [order, setOrder] = useState({ medicines: [] })
  const [loading, setLoading] = useState(true)
  const { id } = useParams()
  const [brands, setBrands] = useState([])
  const [tracking, setTracking] = useState('')
  const [link, setLink] = useState('')
  const [showEditOrder, setShowEditOrder] = useState(false)
  const [productDiscount, setProductDiscount] = useState(0)
  const [shippingDiscount, setShippingDiscount] = useState(0)
  const { register, watch, setValue } = useForm()
  const [showButton, setShowButton] = useState(false)
  const { orderStates } = useSelector(state => state.orderReducer)
  const [discountState, setDiscountState] = useState(0)
  const [openModalConfirmation, setOpenModalConfirmation] = useState(false)
  const [persistedDiscount, setPersistedDiscount] = useState({
    product: 0,
    shipping: 0,
  })
  const [baseAnvisa, setBaseAnvisa] = useState('')
  const [baseRg, setBaseRg] = useState('')
  const [basePrescription, setBasePrescription] = useState('')
  const [baseProofOfAddress, setBaseProofOfAddress] = useState('')
  const [showLinkAnvisa, setShowLinkAnvisa] = useState(false)
  const [showLinkRg, setShowLinkRg] = useState(false)
  const [showLinkPrescription, setShowLinkPrescription] = useState(false)
  const [updateStatus, setUpdateStatus] = useState(false)
  const { status } = watch()
  const dispatch = useDispatch()

  useEffect(() => {
    if (orderStates.length === 0) {
      if (isAuthenticated) {
        const requestOrder = async () => {
          const responseOrder = await api.get('/orders/states')
          dispatch(setOrderStates(responseOrder.data))
        }
        requestOrder()
      }
    }
  }, [order])

  const getOrder = async () => {
    setLoading(true)
    const res = await api.get(`/orders/${id}`)
    setOrder(res.data)
    setLoading(false)
    setValue('status', res.data.status)
    setLink(res.data.link)
    setTracking(res.data.tracking_code)
    setShowButton(res.data.status === 'VALIDATION_SUCCEEDED')
  }

  const submitCode = (
    orderId,
    orderTrackingCode,
    orderLink,
  ) => {
    setUpdateStatus(true)
    setLoading(true)
    api.put(`/orders/${orderId}`, {
      tracking_code: orderTrackingCode,
      status: status,
      link: orderLink,
    }).then(() => toast.success('Gravado com sucesso'))
      .catch(r => dispatch(ErrorActions.setError(r.response.data.errors)))
      .finally(() => {
        setLoading(false)
        getOrder()
      })
  }

  const changeForm = e => {
    const { name, value } = e.currentTarget
    if (name === 'tracking') {
      setTracking(value)
    }
    if (name === 'link') {
      setLink(value)
    }
  }

  useEffect(() => {
    if (!updateStatus) {
      if (
        order.prescription?.patient?.documents &&
        order.prescription?.patient?.documents[0]?.file?.id
      ) {
        const url = `/files/${order.prescription.patient.documents[0].file.id}`
        transform(url).then(r => setBaseRg(r))
      }
      if (order.address) {
        const url = `/files/${order.address.file?.id}`
        transform(url).then(r => setBaseProofOfAddress(r))
      }
      if (order?.prescription?.file?.id) {
        const url = `/files/${order.prescription.file.id}`
        transform(url).then(r => setBasePrescription(r))
      }
      if (order?.prescription?.anvisa) {
        const url = `/files/${order.prescription.anvisa.id}`
        transform(url).then(r => setBaseAnvisa(r))
      }
    }
    setUpdateStatus(false)
  }, [order.prescription])

  useEffect(() => {
    if (baseAnvisa.substr(0, 5) === 'data:') {
      setShowLinkAnvisa(true)
    }
  }, [baseAnvisa])

  useEffect(() => {
    if (baseRg.substr(0, 5) === 'data:') {
      setShowLinkRg(true)
    }
  }, [baseRg])

  useEffect(() => {
    let result
    if (showButton) {
      result = 1
      if (productDiscount > 0 || shippingDiscount > 0) {
        if (
          productDiscount === persistedDiscount.product &&
          shippingDiscount === persistedDiscount.shipping
        ) {
          result = 2 // salvo sem mudar de valor
        } else {
          result = 1 // Valor alterado
        }
      } else {
        result = 1 // sem mudar de valor
      }
    } else {
      result = 0
    }
    setDiscountState(result)
  }, [productDiscount, shippingDiscount, persistedDiscount])

  useEffect(() => {
    getOrder()
  }, [id])

  useEffect(() => {
    setBrands(
      order.medicines
        .map(medicine => medicine.brand)
        .filter(
          (brand, index, self) =>
            index === self.findIndex(b => b.id === brand.id),
        ),
    )
  }, [order])

  const putOrders = () => {
    if (!loading) {
      const data = {
        status,
      }
      api.put(`/orders/${id}`, data)
        .then(() => toast.success('Atualizado com sucesso'))
        .catch(() => toast.error('Erro ao atualizar'))
      setShowButton(data.status === 'VALIDATION_SUCCEEDED')
    }
  }


  const addDiscount = () => {
    if (!loading) {
      const data = {
        status,
      }
      api.put(`/orders/${id}`, data)
      setDiscountState(0)
    }
  }

  const handleCloseEditOrder = async () => {
    await getOrder()
    setShowEditOrder(false)
  }

  let total = []


  async function streamToString(stream) {
    // lets have a ReadableStream as a stream variable
    const chunks = []

    for await (const chunk of stream) {
      chunks.push(Buffer.from(chunk))
    }
    console.log(Buffer.concat(chunks).toString('utf-8'))
    return Buffer.concat(chunks).toString('utf-8')
  }

  const downloadPDF = (id) => {
    let url = `admin/prescription/${id}/generate_pdf`

    api.get(`${url}`, { responseType: 'blob' })
      .then(r => {
        let file = new Blob([r.data], { type: 'application/pdf' })
        let fileURL = URL.createObjectURL(file)
        const link = document.createElement('a')
        link.href = fileURL
        link.download = `peticionamento_pedido_${id}.pdf`
        link.click()

        URL.revokeObjectURL(fileURL)

      })
      .catch((e) => console.log(e))

  }

  const isRepresentative = localStorage.getItem('roles') === 'representative'

  return (
    <div className='container-fluid admin_order'>
      {!loading && (
        <>

          <div className='row'>
            <div className='col-lg-4 pl-0 mt-1'>
              <h2>Pedido #{order.id}</h2>
            </div>
            <div className='col-lg-8 mt-1'>
              <div className='row d-flex justify-content-end'>
                <div className={`col-lg-4 mt-1 ${isRepresentative && 'd-none'}`}>
                  <ButtonPrimary
                    text='Gerar Peticionamento'
                    btnType='button'
                    callBack={() => downloadPDF(order.id)}
                    btnMt='mt-0'
                    width='w-100'
                  />
                </div>
                <div className='col-lg-4 mt-1'>
                  <a href={process.env.REACT_APP_API + `/orders/${order.id}/quotation`}
                     target='_blank'
                     rel='noopener noreferrer'
                  >
                    <ButtonPrimary
                      text='Gerar cotação'
                      btnMt='mt-0'
                      width='w-100'

                    />
                  </a>
                </div>
                <div className={`col-lg-4 mt-1 ${isRepresentative && 'd-none'}`}>
                  <a href={process.env.REACT_APP_API + `/orders/${order.id}/invoice`}
                     target='_blank'
                     rel='noopener noreferrer'
                  >
                    <ButtonPrimary
                      text='Gerar Invoice'
                      btnMt='mt-0'
                      width='w-100'
                    />
                  </a>
                </div>
              </div>

            </div>
          </div>
          <div className='row'>
            <div className='col-lg-4 pl-0 '>
              <div className='admin_order_card'>
                <b>{isRepresentative ? 'Representante' : 'Médico'}</b>
                {order.prescription &&
                order.prescription.physician !== null ? (
                  <>
                    <p>
                      {isRepresentative ?
                        (
                          <span>
                            {order.prescription.physician
                              ? order.prescription.physician.profile.full_name
                              : order.prescription.prePhysician?.full_name}
                          </span>
                        )
                        :
                        (
                          <a
                            href={`/admin/medicos/${order.prescription.physician?.physician?.slug}`}
                          >
                            {order.prescription.physician
                              ? order.prescription.physician.profile.full_name
                              : order.prescription.prePhysician?.full_name}
                          </a>
                        )
                      }
                    </p>

                    {!isRepresentative && <b>Telefone</b> }

                    <p>
                      {phoneMask(
                        order.prescription.physician.profile.phone_number,
                      )}
                    </p>
                    <b>Email</b>{' '}
                    <p>
                      <a
                        href={`mailto:${order.prescription.physician.email}`}
                      >
                        {order.prescription.physician.email}
                      </a>
                    </p>

                  </>
                ) : (
                  <p>
                    --------------------------------------------------------
                  </p>
                )}
              </div>
            </div>

            <div className='col-lg-4 pl-0'>
              <div className='admin_order_card'>
                <b>Paciente</b>
                <p>
                  {order.prescription && (
                    <a
                      href={isRepresentative ? `/representantes/paciente/${order.prescription.patient.id}`: `/admin/pacientes/${order.prescription.patient.id}`}
                    >
                      {order.prescription.patient.profile.full_name}
                    </a>
                  )}
                </p>
                <b>Telefone</b>
                <p>
                  {order.prescription &&
                  phoneMask(
                    order.prescription.patient.profile.phone_number,
                  )}
                </p>
                <b>Email</b>
                <p>
                  <a href={`mailto:${order.prescription?.patient?.email}`}>
                    {order.prescription?.patient?.email}
                  </a>
                </p>
              </div>
            </div>

            <div className='col-lg-4 pl-0'>
              <div className='admin_order_card'>
                <b>Data do pedido</b>
                <p>
                  {moment(order.created_at).format('DD/MM/YYYY')}
                </p>
                <b>Endereço da entrega</b>
                <p>
                  {order.address?.street}, {order.address?.number}
                </p>
                <p>{order.address?.complement}</p>
                <p>{order.address?.district}</p>
                <p>
                  {order.address?.state} - {order.address?.city}
                </p>
                <p>{order.address?.cep}</p>
              </div>
            </div>

          </div>
          <div className='row'>

            {order.prescription.patient?.documents && order.prescription.patient?.documents[0]?.file?.id && (
              <div className={showLinkRg ? 'col-lg-2 admin_order_docs' : 'd-none'}>
                <a
                  href={baseRg}
                  target='_blank'
                  rel='noopener'
                  download={`${order.prescription.patient?.profile?.full_name}-documento-rg`}
                >
                  <div className='admin_order_docs_file'>
                    <img src={pdfIcon} alt='' />
                    <p>RG</p>
                  </div>
                </a>
              </div>
            )}

            {order.prescription.patient?.addresses && order.prescription.patient?.addresses[0]?.file?.id && (
              <div className={showLinkRg ? 'col-lg-2 admin_order_docs' : 'd-none'}>
                <a
                  href={baseProofOfAddress}
                  target='_blank'
                  rel='noopener'
                  download={`${order.prescription.patient?.profile?.full_name}-comprovante-residencia`}
                >
                  <div className='admin_order_docs_file'>
                    <img src={pdfIcon} alt='' />
                    <p>Comp Residência</p>
                  </div>
                </a>
              </div>
            )}

            {order.prescription.file && (
              <div className={showLinkPrescription ? 'col-lg-2 admin_order_docs' : 'd-none'}>
                <a
                  href={basePrescription}
                  target='_blank'
                  rel='noopener'
                  download={`${order.prescription.patient?.profile?.full_name}-${order.prescription.id}`}
                >
                  <div className='admin_order_docs_file'>
                    <img src={pdfIcon} alt='' />
                    <p>Prescrição</p>
                  </div>
                </a>
              </div>
            )}


            {order.prescription.anvisa && (
              <div className={showLinkAnvisa ? 'col-lg-2 admin_order_docs' : 'd-none'}>
                <a
                  href={baseAnvisa}
                  target='_blank'
                  rel='noopener'
                  download={`${order.prescription.patient?.profile?.full_name}-${order.prescription.id}`}
                >
                  <div className='admin_order_docs_file'>
                    <img src={pdfIcon} alt='' />
                    <p>ANVISA</p>
                  </div>
                </a>
              </div>
            )}

          </div>
          <div className='row'>
            <div className='col-lg-10 mt-4'>
              <CustomSelect
                register={register}
                inputName='status'
                label='Status do pedido'
                selectValue={status}
              >
                {orderStates.map(state => (
                  <option value={state.key}>{state.name}</option>
                ))}
              </CustomSelect>
            </div>
          </div>
          {(order.status === 'IN_TRANSIT' || status === 'IN_TRANSIT') && (
            <div className='row'>
              <div className='col-lg-3'>
                <CustomInput
                  label='Link de rastreio'
                  inputName='link'
                  inputPlaceholder='Link do rastreio'
                  defaultValue={link}
                  callBack={changeForm}
                />
              </div>

              <div className='col-lg-3'>
                <CustomInput
                  label='Código de rastreio'
                  inputName='tracking'
                  inputPlaceholder='Código de rastreio'
                  defaultValue={tracking}
                  callBack={changeForm}
                />
              </div>

              <div className='col-lg-3'>
                <ButtonPrimary
                  text='Salvar código'
                  btnType='button'
                  callBack={() =>
                    submitCode(
                      order.id,
                      tracking,
                      link,
                    )
                  }
                  btnMt='mt-4'
                />
              </div>

            </div>
          )}

          <div className='row'>
            <div className='admin_order_products w-100 mt-4'>
              <div className='col-lg-12 mb-5'>
                <h4>Produtos</h4>
              </div>
              {order.medicines.map(medicine => (
                <div className='border-bottom mb-5 pb-5 row' key={medicine.id}>
                  <div className='col-lg-3 admin_order_products_img'>
                    <Thumb className='lg'>
                      <div className='inner'>
                        <Link className='btn-link' to={`/produtos/${medicine.slug}`}>
                          {order.medicines[0].photo ? (
                            <img
                              src={order.medicines[0].photo?.uuidUrl}
                              alt={order.medicines[0].photo?.name}
                            />
                          ) : (
                            <img src={MedicineImage} alt='' />
                          )}
                        </Link>
                      </div>
                    </Thumb>
                  </div>
                  <div className='col-lg-9 admin_order_products_desc'>
                    <div className='d-flex'>
                      <div>
                        <h4 className='mb-2'>
                          <Link className='admin_order_products_name'
                                to={`/produtos/${medicine.slug}`}> {medicine?.name}</Link>
                        </h4>
                        <p>QTD: {' '}
                          {medicine?.pivot.quantity} unidade(s)</p>
                      </div>
                    </div>
                    <div>
                      <p>Valor vendido: {' '}
                        <Currency
                          value={medicine.pivot.price}
                          currency={medicine.currency}
                        />
                      </p>

                    </div>
                    <div className='d-flex justify-content-between'>
                      <div className='d-flex flex-column justify-content-end'>
                        <p>Vendido por: {' '}
                          {medicine?.brand.name}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
              {brands.map((brand, brandIdx) => {
                return (
                  <div key={brand.id}>
                    <h5 className='mt-3 mb-2'>{brand.name}</h5>
                    <table className='w-100'>
                      <thead>
                      <tr>
                        <th>
                          <b>Produto</b>
                        </th>
                        <th className='text-center'>
                          <b>Quantidade</b>
                        </th>
                        <th className='text-right'>
                          <b>Valor</b>
                        </th>
                      </tr>
                      </thead>
                      <tbody>
                      {order.medicines.map((medicine, medIdx) => {
                        if (medicine.brand.id === brand.id) {
                          total[brandIdx] =
                            parseInt(total[brandIdx]) +
                            parseInt(medicine.shipping) *
                            parseInt(medicine.pivot.quantity)
                          total[brandIdx] = total[brandIdx].toFixed(2)

                          return (
                            <>
                              <tr key={medicine.id}>
                                <td>{medicine.comercial}</td>
                                <td className='text-center'>
                                  {medicine.pivot.quantity}
                                </td>
                                <td className='text-right py-2'>
                                  <Currency
                                    value={medicine.pivot.price}
                                    currency={medicine.currency}
                                  />
                                </td>
                              </tr>
                            </>
                          )
                        }
                      })}
                      <tr>
                        <td colSpan='2' />
                        <td className='d-flex justify-content-between py-2'>
                          <b className='mr-3'>SubTotal:</b>{' '}
                          <Currency
                            value={order.subtotal}
                            currency={
                              order.medicines[0].shipping_currency
                                ? order.medicines[0].shipping_currency
                                : order.medicines[0].brand.shipping_currency
                            }
                          />
                        </td>
                      </tr>
                      {discountState === 0 && order.discount && order.discount.product > 0 && (
                        <tr>
                          <td colSpan='2' />
                          <td className='d-flex justify-content-between py-2'>
                            <b className='mr-3'>Desconto produto:</b>{' '}
                            <Currency
                              value={-order.discount?.product}
                              currency={
                                order.medicines[0].shipping_currency
                                  ? order.medicines[0].shipping_currency
                                  : order.medicines[0].brand.shipping_currency
                              }
                            />
                          </td>
                        </tr>
                      )}
                      {showButton && discountState > 0 && (
                        <tr>
                          <td className='text-right' colSpan='3'>
                            <Input
                              label='Desconto sobre o Produto'
                              value={order.discount ? order.discount.product : productDiscount}
                              innerLabel={true}
                              size='sm'
                              type='text'
                              name='productDiscount'
                              className='my-2 ml-3 align-items-right'
                              classContainer='d-flex justify-content-end'
                              classLabel='mt-2 mr-3 font-weight-bold'
                              placeholder='0,00'
                              classInput='discount-input'
                              onChange={e =>
                                setProductDiscount(e.currentTarget.value)
                              }
                            />
                          </td>
                        </tr>
                      )}
                      <tr>
                        <td colSpan='2' />
                        <td className='d-flex justify-content-between py-2'>
                          <b className='mr-3'>Frete:</b>{' '}
                          <Currency
                            value={order.shipping}
                            currency={
                              order.medicines[0].shipping_currency
                                ? order.medicines[0].shipping_currency
                                : order.medicines[0].brand.shipping_currency
                            }
                          />
                        </td>
                      </tr>
                      {discountState === 0 && order.discount && order.discount.shipping > 0 && (
                        <tr>
                          <td colSpan='2' />
                          <td className='d-flex justify-content-between py-2'>
                            <b className='mr-3'>Desconto frete:</b>{' '}
                            <Currency
                              value={-order.discount?.shipping}
                              currency={
                                order.medicines[0].shipping_currency
                                  ? order.medicines[0].shipping_currency
                                  : order.medicines[0].brand.shipping_currency
                              }
                            />
                          </td>
                        </tr>
                      )}

                      {showButton && discountState > 0 && (
                        <tr>
                          <td className='text-right' colSpan='3'>
                            <Input
                              value={order.discount ? order.discount.shipping : shippingDiscount}
                              label='Desconto sobre o frete'
                              innerLabel={true}
                              size='sm'
                              type='text'
                              name='shippingDiscount'
                              className='my-2 ml-3 align-items-right'
                              classContainer='d-flex justify-content-end'
                              classLabel='mt-2 mr-3 font-weight-bold'
                              placeholder='0,00'
                              classInput='discount-input'
                              onChange={(e) =>
                                setShippingDiscount(e.currentTarget.value)
                              }
                            />
                          </td>
                        </tr>
                      )}
                      <tr>
                        <td colSpan='2' />
                        <td className='d-flex justify-content-between py-2'>
                          <b className='mr-3'>Total:</b>{' '}
                          <b>
                            <Currency
                              value={order.total}
                              currency={order.medicines[0].currency}
                            />
                          </b>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                )
              })}
            </div>

            <div className='col-lg-12 d-flex justify-content-end mt-3'>
              {showButton && discountState === 0 && (
                <ButtonPrimary
                  btnClass='mr-5 px-5'
                  disabled={loading}
                  btnType='submit'
                  callBack={async () => {
                    setDiscountState(2)
                  }}
                  text='Adicionar Desconto'
                />

              )}

              {showButton && discountState > 0 && (
                <ButtonPrimary
                  btnClass='mr-5 px-5'
                  disabled={loading}
                  btnType='submit'
                  callBack={async () => {
                    setShowButton(false)
                    const data = { product: 0, shipping: 0 }
                    setPersistedDiscount(data)
                    await api.delete(`/orders/discount/${id}`)
                      .then(()=>window.location.reload())
                    setDiscountState(0)
                    setShowButton(true)
                  }}
                  text='Remover Desconto'
                />
              )}

              {showButton && discountState === 1 && (
                <ButtonPrimary
                  btnClass='mr-5 px-5'
                  disabled={loading}
                  btnType='submit'
                  callBack={async () => {
                    const data = { product: productDiscount, shipping: shippingDiscount }
                    setPersistedDiscount(data)
                    await api.post(`/orders/discount/${id}`, data)
                      .then(()=>window.location.reload())
                  }}
                  text='Gravar Desconto'
                />
              )}
              <ButtonPrimary
                btnClass='btn-delete mr-5 d-none'
                toggle='modal'
                target='#confirmModal'
                callBack={() => setOpenModalConfirmation(true)}
                text='Apagar pedido'
              />

              <ButtonPrimary
                btnClass='mr-5'
                disabled={loading}
                btnType='submit'
                callBack={() => putOrders()}
                text='Salvar'
              />
              <ButtonSecondary
                btnClass='primary sm'
                callBack={() => setShowEditOrder(true)}
                text='Editar'
              />
            </div>
          </div>
          <ModalEditOrder
            show={showEditOrder}
            handleClose={handleCloseEditOrder}
            order={order}
          />
          <ModalConfimation clicked={openModalConfirmation} />
        </>
      )}
    </div>
  )
}
