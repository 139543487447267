import React, { useState } from 'react'
import { Row, Col, Modal } from 'react-bootstrap'
import { useForm, useFormContext, FormContext } from 'react-hook-form'

import api from '~/services/api'
import Button from '~/components/Button/index'
import { Input } from '~/components/Form/index'

export default function ModalEditOrder({ show, handleClose, order }) {
  const formMethods = useForm()
  const [loading, setLoading] = useState(false)

  const saveMedicines = async data => {
    setLoading(true)
    const medicinesData = order.medicines.map((medicine, idx) => {
      return { id: medicine.id, ...data.medicines[idx] }
    })
    const orderData = {
      medicines: medicinesData,
      shipping: data.shipping,
    }
    await api.put(`/orders/${order.id}`, orderData)
    handleClose()
    setLoading(false)
  }

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>

      </Modal.Header>
      <Modal.Body>
        <form id="formOrder" onSubmit={formMethods.handleSubmit(saveMedicines)}>
          <Row className="mt-3">
            <Col lg={12}>
              <h3>Produtos:</h3>
              {order.medicines && (
                <FormContext {...formMethods}>
                  {order.medicines.map((medicine, idx) => (
                    <WindowedRow
                      key={medicine.id}
                      index={idx}
                      item={medicine}
                    />
                  ))}
                </FormContext>
              )}
              <Row className="justify-content-end">
                <Col lg={5}>
                  <Input
                    className="my-3"
                    label="Frete"
                    size="md"
                    type="text"
                    fluid
                    ref={formMethods.register({ required: true })}
                    defaultValue={order.shipping}
                    name="shipping"
                    error={formMethods.errors.shipping}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          form="formOrder"
          type="submit"
          className="primary"
          disabled={loading}
        >
          Salvar
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

const WindowedRow = React.memo(({ index, item }) => {
  const { register, errors } = useFormContext()

  return (
    <div>
      {index !== 0 && <hr className="separator" />}

      <div key={item.id}>
        <div className="d-flex align-items-center">
          <Row>
            <Col className="d-flex align-items-center">
              <div className="name">{item.name}</div>
            </Col>
            <Col md={2}>
              <div>
                <Input
                  className="my-3"
                  label="Qnt"
                  size="md"
                  fluid
                  name={`medicines[${index}].quantity`}
                  ref={register({ required: true })}
                  error={
                    errors.medicines ? errors.medicines[index]?.quantity : false
                  }
                  defaultValue={item.pivot.quantity}
                  type="number"
                />
              </div>
            </Col>
            <Col>
              <div>
                <Input
                  className="my-3"
                  label="Valor"
                  size="md"
                  fluid
                  ref={register({ required: true })}
                  defaultValue={item.pivot.price}
                  name={`medicines[${index}].price`}
                  error={
                    errors.medicines ? errors.medicines[index]?.price : false
                  }
                />
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  )
})
