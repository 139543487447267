import Immutable from 'seamless-immutable'
import { createActions, createReducer } from 'reduxsauce'

export const { Types, Creators } = createActions({
  storeSessionRequest: ['post'],
  storeSessionSuccess: ['data'],
  storeSessionError: ['error'],
  getSessionRequest: [],
  getSessionSuccess: ['data'],
  logout: [],
})

const INITIAL_STATE = Immutable({
  isLogged: false,
  token: null,
  error: false,
  loading: false,
  session: {
    profile: {},
    roles: [],
    addresses: [],
    telemedicine: false
  },
})

const storeSessionRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
})

const storeSessionSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  loading: false,
  token: action.data.token,
  isLogged: true,
  error: false,
  session: action.data.session,
})

const storeSessionError = (state = INITIAL_STATE, action) => ({
  ...state,
  loading: false,
  error: action.error,
})

const getSessionRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
})

const getSessionSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  loading: false,
  isLogged: true,
  session: action.data,
})

const logout = (state = INITIAL_STATE) => ({
  INITIAL_STATE,
})

export default createReducer(INITIAL_STATE, {
  [Types.STORE_SESSION_REQUEST]: storeSessionRequest,
  [Types.STORE_SESSION_SUCCESS]: storeSessionSuccess,
  [Types.STORE_SESSION_ERROR]: storeSessionError,
  [Types.GET_SESSION_REQUEST]: getSessionRequest,
  [Types.GET_SESSION_SUCCESS]: getSessionSuccess,
  [Types.LOGOUT]: logout,
})
