import React, { useEffect, useState } from 'react'
import { Alert } from 'react-bootstrap'
import { Link, useHistory, useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import moment from 'moment'
import api from '~/services/api'
import PrescriptionItem from '../Prescriptions/PrescriptionItem'
import { useDispatch } from 'react-redux'
import { Creators as ErrorActions } from '~/store/ducks/error'
import Skeleton from 'react-loading-skeleton'
import { ButtonPrimary, CustomCheckbox, CustomSelect, CustomTextArea } from '~/components'
import { PrivateProfileCard } from '~/components/ProfileCard/PrivateProfileCard'

export default function Physician() {
  const { register, setValue, getValues, watch } = useForm()
  const { slug } = useParams()
  const [physician, setPhysician] = useState({})
  const [loading, setLoading] = useState(false)
  const [comments, setComments] = useState({ data: [] })
  const [loadingComment, setLoadingComments] = useState(true)
  const [loadingPrescription, setLoadingPrescription] = useState(true)
  const [integrationStatus, setIntegrationStatus] = useState(false)
  const [prescriptions, setPrescriptions] = useState({ data: [] })
  const [selectStatus, setSelectStatus] = useState()
  const [id, setId] = useState('')
  const history = useHistory()
  const [showConfirmDelete, setShowConfirmDelete] = useState(false)
  const [loadingEnrolled, setLoadingEnrolled] = useState(false)
  const contacted = watch('contacted')
  const dispatch = useDispatch()


  const getData = async slug => {
    try {
      setLoading(true)
      const res = await api.get(`/physicians/${slug}`)
      setId(res.data.id)
      setPhysician(res.data)
      setValue('contacted', res.data.contacted)
      setLoading(false)
      setSelectStatus(res.data.status)
      await getComments(res.data.user.id)
      await getPrescriptions(res.data.user.id)
    } catch (e) {
      dispatch(ErrorActions.setError(e.response))
      setLoading(false)
    }
  }

  const getPhysicianIntegration = async physicianId => {
    try {
      setLoading(true)
      setLoadingEnrolled(true)
      const res = await api.get(`/physician/${physicianId}/telemedicine/enrolled`)
      setIntegrationStatus(res.data?.enrolled)
      setLoading(false)
      setLoadingEnrolled(false)
    } catch (e) {
      setIntegrationStatus(false)
      setLoadingEnrolled(false)
      setLoading(false)
    }
  }

  const getPrescriptions = async physicianId => {
    setLoadingPrescription(true)
    const res = await api.get(`/prescriptions?physician=${physicianId}`)
    setPrescriptions(res.data)
    setLoadingPrescription(false)
  }

  const getComments = async userId => {
    setLoadingComments(true)
    const res = await api.get(`/user/${userId}/comments`)
    setComments(res.data)
    setLoadingComments(false)
  }

  const sendComment = async () => {
    try {
      setLoadingComments(true)
      const { content } = getValues()
      const data = { content }
      await api.post(`/user/${physician.user.id}/comment`, data)
      await getComments(physician.user.id)
      setLoadingComments(false)
      setValue('content', '')
    } catch (e) {
      dispatch(ErrorActions.setError(e.response))
      setLoadingComments(false)
    }
  }

  const deleteComment = async commentId => {
    setLoadingComments(true)
    await api.delete(`/user/${commentId}/comment`)
    await getComments(physician.user.id)
    setLoadingComments(false)
  }

  const updateContacted = async physicianId => {
    try {
      setLoading(true)
      const data = { contacted: !contacted }
      await api.put(`/physician/${physicianId}/contacted`, data)
      setLoading(false)
    } catch (e) {
      dispatch(ErrorActions.setError(e.response))
      setLoading(false)
    }
  }

  const formatToArray = data => {
    return data.split(',')
  }

  useEffect(() => {
    getData(slug)
  }, [slug])

  useEffect(() => {
    if (id) {
      getPhysicianIntegration(id)
    }
  }, [id])

  const changeStatus = async status => {
    setLoading(true)
    await api.get(`/physician/${physician.id}/status?status=${status}`)
    await getData(slug)
    setLoading(false)
  }
  const handleStatus = (e) => {
    setSelectStatus(e)
    changeStatus(e)
  }

  const deletePhysician = async () => {
    setLoading(true)
    await api.delete(`/users/${physician.user.id}`)
    await getData(slug)
    setLoading(false)
    history.push('/admin/medicos')
  }

  return (
    <>
      <div className={`container-fluid admin_physician `}>
        <div className='row'>
          <div className='col-lg-9 my-4'>
            <div className={`${!loading && 'd-none'}`}>
              <div className='row'>
                <div className='col-lg-2 col-4'>
                  <Skeleton width='90px' height='90px' className='rounded rounded-circle' />
                </div>
                <div className='col-lg-8 col-8'>
                  <Skeleton height='40px' className='mt-3' />
                </div>
              </div>
            </div>
            <div className={`${loading && 'd-none'} `}>
              <PrivateProfileCard data={physician.user} physician={physician} />
            </div>
          </div>
          <div className='col-lg-3'>
            {
              loading ? (
                  <>
                    <div className='d-flex'>
                      <Skeleton className='mt-4' width='49%' height='40px' />
                      <Skeleton width='49%' height='40px' />
                    </div>
                    <Skeleton className='mt-3 mb-2' />
                  </>
                )
                :
                (
                  <>
                    <Link to={`/admin/medico/editar/${id}`}>
                      <ButtonPrimary
                        text='Editar Pessoal'
                        btnClass='my-2 w-100' />
                    </Link>
                    <Link to={`/admin/medico/editar-medico/${physician.id}`}>
                      <ButtonPrimary
                        text='Editar Profissional'
                        btnClass='primary my-2 w-100' />
                    </Link>

                    {physician.online_consultation && integrationStatus ? (
                        loadingEnrolled ? (
                            <>
                              <Skeleton className='my-2' width='100%' height='40px' />
                              <Skeleton className='my-2' width='100%' height='40px' />
                            </>
                          ) :
                          <>
                            <Link to={`/admin/medicos/agenda/${id}`}>
                              <ButtonPrimary text='Visualizar agenda' btnClass='primary my-2 w-100' />
                            </Link>
                            <Link to={`/admin/medicos/consultas/${id}`}>
                              <ButtonPrimary text='Visualizar consultas' btnClass='primary my-2 w-100' />
                            </Link>
                          </>
                      )
                      :
                      (
                        loadingComment ?
                          <>
                            <Skeleton className='my-2' width='100%' height='40px' />
                            <Skeleton className='my-2' width='100%' height='40px' />
                          </>
                          :
                          <Link to={`/admin/medicos/onboarding/${physician.id}`}>
                            <ButtonPrimary text='Ativar Telemedicina' btnClass='primary my-2 w-100' />
                          </Link>
                      )
                    }

                    <CustomCheckbox
                      label='Contato realizado'
                      callBack={() => updateContacted(physician.id)}
                      inputName='contacted'
                      inputClass='mt-3'
                      register={register()}
                    />
                  </>
                )
            }
          </div>
        </div>
        <div className='row'>
          <div className='col-lg-12 mb-4'>
            <h4>Sobre</h4>
            <div className={`${!loading && 'd-none'}`}>
              <Skeleton height='40px' />
            </div>
            <div className={`${loading && 'd-none'}`}>
              <p>{physician.about}</p>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-lg-4 mb-4'>
            <h4>Dr.Cannabis</h4>
            <div className={`${!loading && 'd-none'}`}>
              <Skeleton />
            </div>
            <div className={`${loading && 'd-none'}`}>
              <p>
                Status:{' '}
                <b>
                  {physician.status === 'approved'
                    ? 'Aprovado'
                    : physician.status === 'pending'
                      ? 'Pendente'
                      : 'Inativo'}
                </b>
              </p>
            </div>

            <div className={`${!loading && 'd-none'}`}>
              <Skeleton className='mt-2' />
            </div>

            <div className={`${loading && 'd-none'}`}>
              <p>
                Já prescreveu: <b>{physician.prescribes ? 'Sim' : 'Não'}</b>
              </p>
            </div>

            <div className={`${!loading && 'd-none'}`}>
              <Skeleton className='mt-2' />
            </div>

            <div className={`${loading && 'd-none'}`}>
              <p>
                Cadastrado em:{' '}
                <b>{moment(physician.created_at).format('DD/MM/YYYY')}</b>
              </p>
            </div>

            <div className={`${!loading && 'd-none'}`}>
              <Skeleton className='mt-2' />
            </div>

            <div className={`${loading && 'd-none'}`}>
              <p>
                Aprovado em em:{' '}
                <b>{moment(physician.evaluation_date).format('DD/MM/YYYY')}</b>
              </p>
            </div>
          </div>
          <div className='col-lg-3'>

            <div className={`${!loading && 'd-none'}`}>
              <Skeleton className='mt-2' />
              <Skeleton className='mt-2 mb-2' height='40px' />
            </div>

            <div className={`${loading && 'd-none'}`}>
              <div className='d-flex flex-column'>
                <CustomSelect
                  label='Alterar Status'
                  callBack={(e) => {
                    handleStatus(e.target.value)
                  }}
                  focus={false}
                  inputName='selectStatus'
                >
                  <option value='PENDING' selected={selectStatus === 'pending'}>Pendente</option>
                  <option value='APPROVED' selected={selectStatus === 'approved'}>Aprovado</option>
                  <option value='DISAPPROVED' selected={selectStatus === 'disapproved'}>Inativo</option>
                </CustomSelect>
              </div>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-lg-12'>
            <div className='row'>
              <div className='col-lg-12 mb-4'>
                <h4>Especialidade</h4>
                <div className={`${!loading && 'd-none'}`}>
                  <Skeleton width='50%' />
                </div>
                <div className={`${loading && 'd-none'}`}>
                  <span className='admin_physician_badges'>{physician.specialty}</span>
                </div>
              </div>
            </div>
            {physician.diseases?.length > 0 && (
              <div className='row'>
                <div className='col-lg-12 mb-4'>
                  <h4>Enfermidades</h4>
                  <div className={`${!loading && 'd-none'}`}>
                    <Skeleton width='50%' />
                  </div>
                  <div className={`${loading && 'd-none'}`}>
                    {Array.isArray(physician.diseases)
                      ? physician.diseases?.map(disease => (
                        <span className='admin_physician_badges'>
                            {disease}
                          </span>
                      ))
                      : formatToArray(physician.diseases).map(disease => (
                        <span className='admin_physician_badges'>
                            {disease}
                          </span>
                      ))}
                  </div>
                </div>
              </div>
            )}
            <div className='row'>
              <div className='col-lg-12 mb-4'>
                <h4>Contato</h4>
                <div className={`${!loading && 'd-none'}`}>
                  <Skeleton className='mt-2' width='50%' />
                </div>
                <div className={`${loading && 'd-none'}`}>
                  <p>{physician.user?.email}</p>
                </div>

                <div className={`${!loading && 'd-none'}`}>
                  <Skeleton className='mt-2' width='50%' />
                </div>
                <div className={`${loading && 'd-none'}`}>
                  {physician.user?.profile?.mobile_number && (
                    <p>{physician.user?.profile?.mobile_number}</p>
                  )}
                </div>

                <div className={`${!loading && 'd-none'}`}>
                  <Skeleton className='mt-2' width='50%' />
                </div>
                <div className={`${loading && 'd-none'}`}>
                  {physician.user?.profile?.phone_number && (
                    <p>{physician.user?.profile?.phone_number}</p>
                  )}
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-lg-12 mb-4'>
                <h4>Endereço</h4>
                <div className={`${!loading && 'd-none'}`}>
                  <Skeleton width='50%' />
                </div>

                <div className={`${!loading && 'd-none'}`}>
                  <Skeleton className='mt-2' width='50%' />
                </div>

                <div className={`${!loading && 'd-none'}`}>
                  <Skeleton className='mt-2' width='50%' />
                </div>
                {physician.user?.addresses.map(address => (
                  <>
                    <div className={`${loading && 'd-none'}`}>
                      <p>
                        {address?.street && `${address?.street}, `}
                        {address?.number && `${address?.number} - `}
                        {address?.complement}
                      </p>
                    </div>

                    <div className={`${loading && 'd-none'}`}>
                      <p>{address?.cep}</p>
                    </div>

                    <div className={`${loading && 'd-none'}`}>
                      <p>
                        {address?.city} - {address?.state}
                      </p>
                    </div>

                  </>
                ))}
                {physician.user?.addresses.length === 0 &&
                'Endereço não cadastrado'}
              </div>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-lg-12 0'>
            <h4 className='my-3'>Comentários</h4>

            <div className={`${!loading && 'd-none'}`}>
              <Skeleton className='mt-2' height='150px' />
            </div>
          </div>
          <div className='col-lg-12 mt-4'>
            <div className={`${!loading && 'd-none'}`}>
              <Skeleton className='mt-2' height='150px' />
            </div>
            <div className={`${loading && 'd-none'}`}>
              <CustomTextArea
                label='Novo comentário'
                inputName='content'
                inputPlaceholder='Seu comentário'
                inputClass='mt-3'
                register={register}
              />
              <div className='d-flex justify-content-end'>
                <ButtonPrimary
                  text='Enviar comentário'
                  btnClass='mb-3'
                  btnMt='mt-0'
                  callBack={sendComment} />
              </div>
            </div>
          </div>
          {comments.data.map(
            comment =>
              comment.owner &&
              comment.owner.profile && (
                <div className='col-lg-12'>
                  <div className='admin_physician_comment'>
                    <div className='d-flex align-items-center justify-content-between'>
                      <b>{comment.owner?.profile?.full_name}</b>
                      <ButtonPrimary
                        text='Deletar'
                        btnClass='btn_delete'
                        btnMt='mt-0'
                        callBack={() => deleteComment(comment.id)}
                      />
                    </div>
                    <p className='my-2'>{comment.content}</p>
                    <p className='text-right'>
                      {moment(comment.created_at).format('DD/MM/YYYY HH:mm')}
                    </p>
                  </div>
                </div>
              ),
          )}
        </div>
        <div className='row'>
          {!loadingPrescription &&
          prescriptions.data?.map(prescription => (
            <div className='col-lg-12 mt-3' key={prescription.id}>
              <Link to={`/admin/prescricoes/${prescription.id}`}>
                <PrescriptionItem prescription={prescription} />
              </Link>
            </div>
          ))}
        </div>
        <div className='row'>
          <div className='col-lg-12 '>
            <ButtonPrimary
              text='Deletar Médico'
              callBack={() => setShowConfirmDelete(true)}
              btnClass='btn_delete'
            />
            <Alert className='mt-2' show={showConfirmDelete} variant='danger'>
              <div className='d-flex justify-content-between align-items-center'>
                <h4 className='pb-0'>Deseja mesmo deletar esse Médico?</h4>
                <ButtonPrimary
                  text='DELETAR'
                  callBack={deletePhysician}
                  btnClass='btn_delete'
                  btnMt=''
                />
              </div>
            </Alert>
          </div>
        </div>
      </div>
    </>
  )
}
