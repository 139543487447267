import moment from 'moment'
import * as $ from 'jquery'
import {
  adminUpdateBaseCalendar,
  adminUpdateWeekCalendar,
  physicianUpdateBaseCalendar,
  physicianUpdateWeekCalendar,
} from '~/services/requests'
import { toast } from 'react-toastify'

const getEvents = (calendarApi) => {
  const list = []

  calendarApi.getEvents().map((value) => {
    list.push({
      to: moment(value.end).format('YYYY-MM-DD HH:mm'),
      from: moment(value.start).format('YYYY-MM-DD HH:mm'),
    })
  })

  return list
}


const clickCalendar = (eventClick, e, isPhysician, isAdmin, openModalId, setState, openModal) => {


  if (eventClick) {
    if (moment(e.event._def.extendedProps.HourStart).format('YYYY-MM-DD HH:mm') > moment().add(1, 'days').format('YYYY-MM-DD HH:mm') || isPhysician || isAdmin) {
      if (e.event._def.extendedProps.mine === undefined || isPhysician || isAdmin) {
        switch (e.event._def.extendedProps.status) {
          case 'CONFIRMED':
            setState(e.event._def.extendedProps)
            $(`#${openModalId}`).modal('show')
            break
          case 'PROPOSAL':
            if (isAdmin) {
              setState(e.event._def.extendedProps)
              $(`#${openModalId}`).modal('show')
            }
            break
          default:
            setState(e.event._def.extendedProps)
            $(`#${openModalId}`).modal('show')
            break
        }
      }
    }
    if (e.event._def.extendedProps.mine) {
      if (e.event._def.extendedProps.status === 'PROPOSAL') {
        setState(e.event._def.extendedProps)
        openModal(true)
      }
      if (e.event._def.extendedProps.status === 'CONFIRMED') {
        setState(e.event._def.extendedProps)
        $('#modal-consultation-details').modal('show')
      }
    }
  }
}

const treatmentAppointments = ({ appointments }) => {
  const schedule = []
  appointments.map((value) => {
    schedule.push({
      title: value.attendee?.personal_profile?.name,
      start: value.from,
      end: value.to,
      HourStart: value.from,
      HourEnd: value.to,
      monthString: moment(value?.from).format('MMMM'),
      dayNumber: moment(value?.from).format('DD'),
      patientName: value.attendee?.personal_profile?.name,
      birth_date: value.attendee?.personal_profile?.birth_date,
      phone: value.attendee?.personal_profile?.phone,
      mobile: value.attendee?.personal_profile?.mobile,
      email: value.attendee?.user?.email,
      date: moment(value.from).format('DD/MM/YYYY'),
      day: moment(value.from).format('dddd'),
      status: value.status,
      className: value.status === 'PROPOSAL' && 'view-telemed waiting-payment',
      expiration_time: value.expiration_time
    })
  })

  return schedule
}

const treatmentOfDates = (
  {
    blueprint,
    appointments,
    attendant = '',
  },
  isPhysicianView = false,
  configuration = false,
  isAdmin,
) => {
  const schedule = []
  if (Array.isArray(blueprint)) {
    blueprint.map((value) => {
      if (Array.isArray(value)) {
        value.map((date) => {
          if (!isPhysicianView && !isAdmin) {
            date.available.map((horary) => {
              schedule.push({
                start: horary[0],
                end: horary[1],
                HourStart: horary[0],
                HourEnd: horary[1],
                available: date.available ? date.available : [],
                selectedDate: moment(horary[0]).format('DD/MM/YYYY'),
                className: (isPhysicianView && !configuration) ? '' : moment(horary[0]) < moment().add(1, 'days') ? 'view-telemed shift-blocked' : 'view-telemed awaiting-appointment',
                attendant,
              })
            })
          } else {
            schedule.push({
              start: date.from,
              end: date.to,
              HourStart: date.from,
              HourEnd: date.to,
              selectedDate: moment(date.from).format('DD/MM/YYYY'),
              className: (isPhysicianView && !configuration) ? '' : moment(date.from) < moment().add(1, 'days') ? 'view-telemed shift-blocked' : 'view-telemed awaiting-appointment',
            })
          }
        })
      }
    })
  }

  if (Array.isArray(appointments)) {
    appointments.map(horary => {
      schedule.push({
        start: horary?.from,
        end: horary?.to,
        HourStart: horary?.from,
        HourEnd: horary?.to,
        selectedDate: moment(horary?.from).format('DD/MM/YYYY'),
        className: horary?.mine ?
          horary?.status === 'PROPOSAL' ?
            'view-telemed waiting-payment'
            :
            'view-telemed payment-ok'
          :
          'view-telemed shift-blocked',
        room: horary?.room,
        mine: horary?.mine,
        status: horary?.status,
        expiration_time: horary?.expiration_time
      })
    })
  }
  return schedule
}


const updateCalendar = (calendarRef, updateWeek, updateBase, setUpdateWeek, setUpdateBase, isAdmin = false, physicianID = '') => {

  let calendarApi = calendarRef.current.getApi()

  const requestWeek = isAdmin ? adminUpdateWeekCalendar : physicianUpdateWeekCalendar
  const requestBase = isAdmin ? adminUpdateBaseCalendar : physicianUpdateBaseCalendar


  const { end } = calendarApi.currentDataManager.data.dateProfile.currentRange
  const formatEnd = moment(end).add(-1, 'days').format('YYYY-MM-DD')

  const list = getEvents(calendarApi)

  if (updateWeek) {
    requestWeek(list, formatEnd, physicianID)
      .then(() => toast.success('Atualizado com sucesso'))
      .catch(() => toast.error('Erro ao atualizar'))
      .finally(() => setUpdateWeek(false))
  }

  if (updateBase) {
    requestBase(list, formatEnd, physicianID)
      .then(() => toast.success('Atualizado com sucesso'))
      .catch(() => toast.error('Erro ao atualizar'))
      .finally(() => setUpdateBase(false))
  }

  calendarApi.removeAllEvents()
  calendarApi.refetchEvents()
}

const splitTimeCourse = (min, max, appointmentLength) => {
  let allTimes = []
  const start = parseInt(min)
  const end = parseInt(max)
  const duration = end - start


  if (duration === 0) {
    const minMinute = parseInt(min.substr(3))
    const maxMinute = parseInt(max.substr(3))
    return [{
      start: moment().hours(start).minute(minMinute).format('HH:mm'),
      end: moment().hours(end).minute(maxMinute).format('HH:mm')
    }]
  }

  const splitHours = 60 / parseInt(appointmentLength)

  let hourStart = start

  for (let hour = 0; hour < duration; hour++) {
    let splitMinute = 0
    for (let minute = 0; minute < splitHours; minute++) {
      allTimes.push({
        start: moment().hours(hourStart).minute(splitMinute).format('HH:mm'),
        end: moment().hours(hourStart).minute(splitMinute + appointmentLength - 1).format('HH:mm'),
      })
      splitMinute += appointmentLength
    }
    hourStart += 1
  }
  return allTimes
}


const confirmConsultation = (calendarRef, form, callback, event, isAdmin=true) => {
  // if (isAdmin) {
  //   let calendarApi = calendarRef.current.getApi()
  //
  //   let hour = form.horary.substr(0, 2)
  //   let minute = form.horary.substr(3, 5)
  //
  //   const selectedHour = moment(event.date).hours(parseInt(hour)).minute(parseInt(minute)).format('YYYY-MM-DD HH:mm')
  //
  //   const listEvents = getEvents(calendarApi).filter(value => value.from === selectedHour)
  //   if (listEvents && listEvents[0]?.to) {
  //     toast.warn('Já existe uma consulta neste horário')
  //     // $('#conflict').modal('show')
  //     // $('#newConsultation').modal('hide')
  //   } else {
  //     callback(false)
  //   }
  // }
  // else callback(false)

  callback(false)
}

const showAppointmentDetail = (appointment, setEvent, showModal, isPhysician=false) => {
  const treatmentEvent = {
    selectedDate: moment(appointment.start).format('DD/MM/YYYY HH:mm'),
    HourStart: isPhysician? appointment.from: appointment.start,
    physicianId: appointment.physician_id,
    room: {
      attendant: appointment.attendant,
      attendee: appointment.attendee,
      cpf: appointment.cpf,
      appointment_price: appointment?.room?.appointment_price,
    },
  }
  setEvent(treatmentEvent)
  appointment.status === 'CONFIRMED' ?
    $('#modal-consultation-details').modal('show')
    :
    showModal(true)
}

const treatmentListConsultation = (date) => {
  return `${moment(date?.start).format('dddd')},
    ${moment(date?.start).format('DD')} de
    ${moment(date?.start).format('MMMM')}
    ${moment(date?.start).format('HH:mm')}`
}

export {
  treatmentAppointments,
  clickCalendar,
  treatmentOfDates,
  getEvents,
  updateCalendar,
  splitTimeCourse,
  confirmConsultation,
  showAppointmentDetail,
  treatmentListConsultation
}
