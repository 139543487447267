const phoneMask = phoneNumber => {
  if (phoneNumber === null || phoneNumber === undefined) {
    return ''
  }
  let mask = phoneNumber.replace(/\D/g, '')
  mask = mask.replace(/^(\d{2})(\d)/g, '($1) $2')
  return mask.replace(/(\d)(\d{4})$/, '$1-$2')
}
const cpfMask = (cpf) => {
  if (!cpf) return ''
  cpf = cpf.replace(/[^\d]/g, '')
  return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
}
const cepMask = (cep) => {
  if (!cep) return ''
  cep = cep.replace(/[^\d]/g, '')
  return cep.replace(/(\d{5})(\d{3})/, '$1-$2')
}
const formatDate = date => {
  if (date) {
    const year = date.slice(0, 4)
    const month = date.slice(5, 7)
    const day = date.slice(8, 10)
    return `${day}/${month}/${year}`
  }
}
const formatText = (text, len) => {
  if (text) {
    return text.substr(0, len)
  }
}
const formatValue = (value, currency) => {
  switch (currency) {
    case 'R$':
      return parseFloat(value).toLocaleString('pt-br', {
        style: 'currency',
        currency: 'BRL',
      })
    case 'US$':
      return parseFloat(value).toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      })
    case '€':
      return parseFloat(value).toLocaleString('en-GB', {
        style: 'currency',
        currency: 'EUR',
      })
    default:
      return ''
  }
}
export {
  formatValue,
  formatText,
  formatDate,
  cepMask,
  cpfMask,
  phoneMask,
}
