import React, { useState, useEffect } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import moment from 'moment'

import { Section } from './styles'
import LogoImg from '~/assets/images/logo_horizontal_p.png'
import api from '~/services/api'
import Button from '~/components/Button/index'
import { transform } from '~/shared/utils'


export default function TemplateAnvisa({ history }) {
  const { session } = useSelector(state => state.auth)
  const [prescription, setPrescription] = useState({ medicines: [] })
  const [loading, setLoading] = useState(false)
  const [basePrescription, setBasePrescription] = useState('')
  const { id } = useParams()

  const getData = async id => {
    setLoading(true)
    const res = await api.get(`/prescriptions/${id}`)
    if (res.data) setPrescription(res.data)
    else history.push('/')
    setLoading(false)
  }

  useEffect(() => {
    getData(id)
  }, [])

  useEffect(()=> {
    if (prescription) {
      if (prescription.file) {
        if (prescription.file.id) {
          let url = `/files/${prescription.file.id}`
          if (url) {
            transform(url).then(r => setBasePrescription(r))
          }
        }
      }
    }
  }, [prescription])

  return (
    <>
      <Section>
        {!loading && (
          <div id="print">
            <Container id="anexo">
              <div className="d-flex justify-content-center">
                <img className="auto" src={LogoImg} width="25%" height="auto" />
              </div>
              <Row className="">
                <Col lg={12} className=" mt-3">
                  <h3 className="ident text-center mb-sm-5">
                    FORMULÁRIO PARA IMPORTAÇÃO E USO DE PRODUTO
                    <br />À BASE DE CANABIDIOL
                  </h3>
                </Col>
              </Row>

              <Row className="">
                <Col lg={12} className="">
                  <p>
                    A Dr. Cannabis fez este gabarito para te ajudar a dar
                    entrada na solicitação de autorização da Anvisa. Você deverá
                    acessar os links a seguir, fazer o seu cadastro no Portal do
                    Cidadão - preenchido por você ou alguém de sua confiança - e
                    solicitar seu ofício.
                  </p>
                  <br />
                  <p>Acesse o Portal do Cidadão e faça seu cadastro:</p>
                  <p>
                    <a href="https://sso.acesso.gov.br/login?client_id=acesso.gov.br">
                      https://sso.acesso.gov.br/login?client_id=acesso.gov.br
                    </a>
                  </p>
                  <p>Solicite sua autorização:</p>
                  <p>
                    <a href="https://www.gov.br/pt-br/servicos/solicitar-autorizacao-para-importacao-excepcional-de-produtos-a-base-de-canabidiol">
                      {' '}
                      https://www.gov.br/pt-br/servicos/solicitar-autorizacao-para-importacao-excepcional-de-produtos-a-base-de-canabidiol
                    </a>
                  </p>
                  <br />
                  <p>
                    Após este preenchimento, você deverá acessar o link para
                    solicitar sua autorização você fará o preenchimento das
                    informações contidas neste documento, que seguem as normas e
                    exigências da Anvisa.{' '}
                  </p>
                  <br />
                  <p>
                    Observe que você terá um número de protocolo e você deverá
                    acompanhar o seu processo neste mesmo link. Este é o local
                    em que você poderá acessar o seu ofício de autorização. Com
                    esse ofício entre em contato para receber a sua cotação,
                    adquirir seu produto e iniciar seu tratamento.
                  </p>
                  <br />
                  <p>
                    Aqui (
                    <a href="http://portal.anvisa.gov.br/importacao-de-canabidiol/prazo-de-analise">
                      http://portal.anvisa.gov.br/importacao-de-canabidiol/prazo-de-analise
                    </a>
                    ) você poderá saber qual o prazo da Anvisa para a análise
                    dos seus documentos.
                  </p>
                  <br />
                  <p>
                    Caso restem dúvidas, estamos à disposição em nossos canais
                    de Apoio ao Paciente: pacientes@drcannabis.com.br ou
                    whatsapp (11) 99999-5734.
                  </p>
                  <br />
                  <p>
                    <b>Conte sempre com a Dr. Cannabis.</b>
                  </p>
                </Col>
              </Row>

              <Row className=" mt-3">
                <Col lg={12} className="">
                  <Row className="" v-if="false">
                    <Col lg={12} className="">
                      <h4>TIPO DE SOLICITAÇÃO</h4>
                    </Col>
                    <Col lg={6} className="">
                      <div className="form-group">
                        <label htmlFor="tiposolicitacao">Tipo de solicitação</label>
                        <div
                          className="form-control user-info"
                          id="tiposolicitacao"
                        >
                          Inicial
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row className="">
                    <Col lg={12} className="">
                      <hr />
                    </Col>
                    <Col lg={12} className=" mt-3">
                      <h4>DADOS DO SOLICITANTE</h4>
                    </Col>
                    <Col lg={12} className="">
                      <div className="form-check my-3">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          disabled
                          id="defaultCheck1"
                          checked={session.roles.includes('patient')}
                        />
                        <label className="form-check-label" for="defaultCheck1">
                          O solicitante é o próprio paciente
                        </label>
                      </div>
                    </Col>
                    <Col lg={6} className="">
                      <div className="form-group">
                        <label htmlFor="nomecomp">
                          Nome Completo do Solicitante
                        </label>
                        <div className="form-control user-info" id="nomecomp">
                          {prescription.patient?.profile?.full_name}
                        </div>
                      </div>
                    </Col>
                    <Col lg={4} className="">
                      <div className="form-group">
                        <label htmlFor="datanasc">Data de Nascimento</label>
                        <div className="form-control user-info" id="datanasc">
                          {moment(prescription.patient?.profile?.birthdate).format(
                            'DD/MM/YYYY',
                          )}
                        </div>
                      </div>
                    </Col>
                    <Col lg={6} className="">
                      <div className="form-group">
                        <label htmlFor="numdocumento">
                          Nº do Documento de Identificação
                        </label>
                        <div
                          className="form-control user-info"
                          id="numdomcumento"
                        >
                          {prescription.patient?.profile?.rg}
                        </div>
                      </div>
                    </Col>
                    <Col lg={12} className="">
                      <div className="form-group">
                        <label htmlFor="endereco">Endereço</label>
                        <div className="form-control user-info" id="endereco">
                          {prescription.patient?.addresses[0]?.street},{' '}
                          {prescription.patient?.addresses[0]?.number}
                        </div>
                      </div>
                    </Col>
                    <Col lg={6} className="">
                      <div className="form-group">
                        <label htmlFor="estado">Estado</label>
                        <div className="form-control user-info" id="estado">
                          {prescription.patient?.addresses[0]?.state}
                        </div>
                      </div>
                    </Col>
                    <Col lg={6} className="">
                      <div className="form-group">
                        <label htmlFor="cidade">Cidade</label>
                        <div className="form-control user-info" id="cidade">
                          {prescription.patient?.addresses[0]?.city}
                        </div>
                      </div>
                    </Col>
                    <Col lg={3} className="">
                      <div className="form-group">
                        <label htmlFor="cep">CEP</label>
                        <div className="form-control user-info" id="cep">
                          {prescription.patient?.addresses[0]?.cep}
                        </div>
                      </div>
                    </Col>
                    <Col lg={3} className="">
                      <div className="form-group">
                        <label htmlFor="celular">Celular</label>
                        <div className="form-control user-info" id="celular">
                          {prescription.patient?.profile?.mobile_number}
                        </div>
                      </div>
                    </Col>
                    <Col lg={3} className="">
                      <div className="form-group">
                        <label htmlFor="telefonefixo">Telefone Fixo</label>
                        <div
                          className="form-control user-info"
                          id="telefonefixo"
                        >
                          {prescription.patient?.profile?.phone_number}
                        </div>
                      </div>
                    </Col>
                    <Col lg={12} className="">
                      <div className="form-group">
                        <label htmlFor="email">Email para contato</label>
                        <div className="form-control user-info" id="email">
                          {prescription.patient?.email}
                        </div>
                      </div>
                    </Col>
                  </Row>
                  {session.roles.includes('responsible') &&
                    prescription.patient.ward.length > 0 && (
                      <Row>
                        <Col lg={12} className="">
                          <hr />
                        </Col>
                        <Col lg={12} className=" mt-3">
                          <h4>DADOS DO PACIENTE</h4>
                        </Col>
                        <Col lg={6} className="">
                          <div className="form-group">
                            <label htmlFor="nomecomp">
                              Nome Completo do Paciente
                            </label>
                            <div
                              className="form-control user-info"
                              id="nomecomp"
                            >
                              {prescription.patient?.ward[0].full_name}
                            </div>
                          </div>
                        </Col>
                        <Col lg={4} className="">
                          <div className="form-group">
                            <label htmlFor="datanasc">Data de Nascimento</label>
                            <div
                              className="form-control user-info"
                              id="datanasc"
                            >
                              {moment(
                                prescription.patient?.ward[0].birthdate,
                              ).format('DD/MM/YYYY')}
                            </div>
                          </div>
                        </Col>
                        <Col lg={6} className="">
                          <div className="form-group">
                            <label htmlFor="numdocumento">
                              Nº do Documento de Identificação
                            </label>
                            <div
                              className="form-control user-info"
                              id="numdomcumento"
                            >
                              {prescription.patient?.ward[0].rg}
                            </div>
                          </div>
                        </Col>
                        <Col lg={12} className="">
                          <div className="form-group">
                            <label htmlFor="endereco">Endereço</label>
                            <div
                              className="form-control user-info"
                              id="endereco"
                            >
                              {prescription.patient?.addresses[0]?.street},{' '}
                              {prescription.patient?.addresses[0]?.number}
                            </div>
                          </div>
                        </Col>
                        <Col lg={6} className="">
                          <div className="form-group">
                            <label htmlFor="estado">Estado</label>
                            <div className="form-control user-info" id="estado">
                              {prescription.patient?.addresses[0]?.state}
                            </div>
                          </div>
                        </Col>
                        <Col lg={6} className="">
                          <div className="form-group">
                            <label htmlFor="cidade">Cidade</label>
                            <div className="form-control user-info" id="cidade">
                              {prescription.patient?.addresses[0]?.city}
                            </div>
                          </div>
                        </Col>
                        <Col lg={3} className="">
                          <div className="form-group">
                            <label htmlFor="cep">CEP</label>
                            <div className="form-control user-info" id="cep">
                              {prescription.patient?.addresses[0]?.cep}
                            </div>
                          </div>
                        </Col>
                        <Col lg={3} className="">
                          <div className="form-group">
                            <label htmlFor="celular">Celular</label>
                            <div
                              className="form-control user-info"
                              id="celular"
                            >
                              {prescription.patient?.ward[0].mobile_number}
                            </div>
                          </div>
                        </Col>
                        <Col lg={3} className="">
                          <div className="form-group">
                            <label htmlFor="telefonefixo">Telefone Fixo</label>
                            <div
                              className="form-control user-info"
                              id="telefonefixo"
                            >
                              {prescription.patient?.ward[0].phone_number}
                            </div>
                          </div>
                        </Col>
                      </Row>
                    )}

                  <Row className="">
                    <Col lg={12} className="">
                      <hr />
                    </Col>
                    <Col lg={12} className=" mt-3">
                      <h4>DADOS DO PRODUTO</h4>
                    </Col>
                  </Row>
                  {prescription.medicines.map(medicine => (
                    <div>
                      <Row className="mb-4">
                        <Col lg={7} className="">
                          <div className="form-group">
                            <label htmlFor="produtonome">Nome Comercial </label>
                            <div
                              className="form-control user-info"
                              id="produtonome"
                            >
                              {medicine.comercial}
                            </div>
                          </div>
                        </Col>
                        <Col lg={7} className="">
                          <div className="form-group">
                            <label htmlFor="produtocomposicao">Composição </label>
                            <div
                              className="form-control user-info"
                              id="produtocomposicao"
                            >
                              {medicine.brand.composition}
                            </div>
                          </div>
                        </Col>
                        <Col lg={12} className="">
                          <div className="form-group">
                            <label htmlFor="produtofabricante">
                              Empresa Fabricante{' '}
                            </label>
                            <div
                              className="form-control user-info"
                              id="produtofabricante"
                            >
                              {medicine.brand.manufacturer}
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  ))}

                  <Row className="">
                    <Col lg={12} className="">
                      <hr />
                    </Col>
                    <Col lg={12} className=" mt-3">
                      <h4>DADOS DO PRESCRITOR</h4>
                    </Col>
                    <Col lg={6} className="">
                      <div className="form-group">
                        <label htmlFor="mediconome">
                          Nome do Profissional Legalmente Habilitado{' '}
                        </label>
                        <div className="form-control user-info" id="mediconome">
                          {prescription.physician?.profile.full_name}
                        </div>
                      </div>
                    </Col>
                    <Col lg={3} className="">
                      <div className="form-group">
                        <label htmlFor="mediconumero">Nº no Conselho/UF </label>
                        <div
                          className="form-control user-info"
                          id="mediconumero"
                        >
                          {prescription.physician?.physician.crm}
                          {prescription.physician?.physician.uf === 'XX'
                            ? ''
                            : `/${prescription.physician?.physician.uf}`}
                        </div>
                      </div>
                    </Col>
                    <Col lg={6} className="">
                      <div className="form-group">
                        <label htmlFor="mediconome">Especialidade </label>
                        <div className="form-control user-info" id="mediconome">
                          {prescription.physician?.physician.specialty}
                        </div>
                      </div>
                    </Col>
                    <Col lg={6} className="">
                      <div className="form-group">
                        <label htmlFor="medicoestado">Estado</label>
                        <div
                          className="form-control user-info"
                          id="medicoestado"
                        >
                          {prescription.physician?.addresses[0]?.state}
                        </div>
                      </div>
                    </Col>
                    <Col lg={6} className="">
                      <div className="form-group">
                        <label htmlFor="medicocidade">Cidade</label>
                        <div
                          className="form-control user-info"
                          id="medicocidade"
                        >
                          {prescription.physician?.addresses[0]?.city}
                        </div>
                      </div>
                    </Col>
                    <Col lg={3} className="">
                      <div className="form-group">
                        <label htmlFor="medicocelular">Celular</label>
                        <div
                          className="form-control user-info"
                          id="medicocelular"
                        >
                          {prescription.physician?.profile.mobile_number}
                        </div>
                      </div>
                    </Col>
                    <Col lg={3} className="">
                      <div className="form-group">
                        <label htmlFor="medicotelefonefixo">Telefone Fixo</label>
                        <div
                          className="form-control user-info"
                          id="medicotelefonefixo"
                        >
                          {prescription.physician?.profile.phone_number}
                        </div>
                      </div>
                    </Col>
                    <Col lg={12} className="">
                      <div className="form-group">
                        <label htmlFor="medicoemail">Email para contato</label>
                        <div
                          className="form-control user-info"
                          id="medicoemail"
                        >
                          {prescription.physician?.email}
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row className="">
                    <Col lg={12} className="">
                      <hr />
                    </Col>
                    <Col lg={12} className=" mt-3">
                         <h4>DOCUMENTOS OBRIGATÓRIOS</h4>
                    </Col>
                    <Col lg={12} className="">
                      <a href={basePrescription}
                         target="_blank" rel="noopener"
                         download={`prescrição-${prescription.file?.name}`}
                      >
                        <Button className="primary my-3">
                          Baixe sua prescrição!
                        </Button>
                      </a>
                      <div className="form-group">
                        <label htmlFor="email">Receita Médica</label>
                        <div className="form-control user-info" id="email">
                          [anexe a sua prescrição digitalizada, pode ser uma
                          foto legível do documento]
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <div className="formulario-2">
                <p className="ident"></p>
              </div>
            </Container>
          </div>
        )}
      </Section>
    </>
  )
}
