import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import NumberFormat from 'react-number-format'
import { Currency } from '../../../components/Currency'
import Skeleton from 'react-loading-skeleton'
import api from '~/services/api'
import { PhysicianProfileHeader } from '~/components'
import urlImg from '../../../assets/images/drc_stationary-1440x360.jpg'
import { history } from '~/store'
import { isAuthenticated } from '~/services/auth'


export default function Physician() {
  const { id } = useParams()
  const [physician, setPhysician] = useState({})
  const [loading, setLoading] = useState(false)

  const getData = async physicianId => {
    setLoading(true)
    const res = await api.get(`/physicians/${physicianId}`)
    setPhysician(res.data)
    setLoading(false)
  }

  const formatToArray = data => {
    return data.split(',')
  }

  const toTelemed = () => {
    let url = `/paciente/telemed/${physician.id}`
    localStorage.setItem('physicianPublic', url)
    history.push(url)
  }

  useEffect(() => {
    getData(id)
  }, [id])

  const favoritePhysician = async () => {
    if (physician.user?.favoritesPatients.length) {
      await api.delete(`/favorites/${physician.user?.id}`)
    } else {
      await api.post(`/favorites/${physician.user?.id}`)
    }
    await getData(id)
  }

  let address = physician.user?.addresses[0]
  const favorite = !!physician.user?.favoritesPatients.length

  return (
    <>
      {
        loading ? (

            <div className="container-fluid patient__physician__description__skeleton">
              <div className="row mt-5 patient__physician__description__skeleton__card">
                <div className="col-lg-1 pr-0">
                  <Skeleton className="rounded-circle" width="90px" height="90px"/>
                </div>
                <div className="col-lg-11 pl-5">
                  <Skeleton className="" width="60%"/>
                  <Skeleton className="mt-2 mx-4" width="30%"/>
                  <div className="row">
                    <div className="col-lg-6">
                      <Skeleton className="mt-2" width="40%"/>
                      <Skeleton className="mt-2" width="80%"/>
                    </div>

                  </div>
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-lg-12">
                  <Skeleton width="10%"/>
                  <Skeleton className="mt-2" width="100%" height="80px"/>
                  <Skeleton className="mt-2" width="20%"/>
                </div>
              </div>

              <div className="row mt-4">
                <div className="col-lg-12">
                  <Skeleton className="d-block" width="15%"/>
                  <Skeleton className="mt-2 patient__physician__description__skeleton__card__full" width="30%"/>
                </div>

              </div>

              <div className="row mt-4">
                <div className="col-lg-12">
                  <Skeleton className="d-block" width="15%"/>
                  <Skeleton className="mt-2 patient__physician__description__skeleton__card__full" width="30%"/>
                </div>

              </div>

              <div className="row mt-4">
                <div className="col-lg-12">
                  <Skeleton className="d-block" width="15%"/>
                  <Skeleton className="mt-2 d-block patient__physician__description__skeleton__card__full" width="30%"/>
                  <Skeleton className="mt-2 d-block patient__physician__description__skeleton__card__full" width="20%"/>
                  <Skeleton className="mt-2 d-block patient__physician__description__skeleton__card__full" width="25%"/>
                </div>

              </div>
            </div>

          ) :
          (
            <>
              <PhysicianProfileHeader
                backgroundImage={urlImg}
                physician={physician}
                callBack={favoritePhysician}
                favorite={favorite}
                id={id}
              />
              <div className="container-fluid  patient__physician__description">
                <div className="row">
                  <div className="my-4">
                    <h4 className="patient__physician__description__title about">Sobre</h4>
                    <p className="physician-card__card-span">{physician.about}</p>
                    {physician.price && (
                      <div className="mt-3">
                        <p className="py-2 patient__physician__description__title"> Preço da consulta</p>
                        <span className="physician-card__card-span"><Currency value={physician.price}
                                                                              currency="R$"/> </span>
                      </div>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div>
                    <div className="mb-2">
                      <h4 className="patient__physician__description__title">Especialidade</h4>
                      <span className="patient__physician__description__badge__buttons">{physician.specialty}</span>
                    </div>

                    {physician.diseases?.length > 0 && (
                      <div>
                        <div className="mb-4">
                          <h4 className="patient__physician__description__title">Enfermidades</h4>
                          {Array.isArray(physician.diseases)
                            ? physician.diseases?.map(disease => (
                              <span className="patient__physician__description__badge__buttons ">
                              {disease}
                            </span>
                            ))
                            : formatToArray(physician.diseases).map(disease => (
                              <span className="patient__physician__description__badge__buttons ">
                              {disease}
                            </span>
                            ))}
                        </div>
                      </div>
                    )}
                    <div className="mb-4">
                      {/*<h4 className="patient__physician__description__title">Contato</h4>*/}
                      {/*{*/}
                      {/*  isAuthenticated() && (*/}
                      {/*    <div>*/}
                      {/*      {physician.user?.profile?.phone_number && (*/}
                      {/*        <p className="patient__physician__description__text">*/}
                      {/*          <NumberFormat*/}
                      {/*            value={physician.user?.profile?.phone_number}*/}
                      {/*            displayType={'text'}*/}
                      {/*            format="(##) #####-####"*/}
                      {/*          />*/}
                      {/*        </p>*/}
                      {/*      )}*/}
                      {/*      <p className="patient__physician__description__text mt-2">*/}
                      {/*        {physician.user?.email}*/}
                      {/*      </p>*/}
                      {/*    </div>*/}
                      {/*  )*/}
                      {/*}*/}
                      {physician.online_consultation ? (
                        <button className="btn physician_profile_header_telemed_button"
                                onClick={() => toTelemed()}
                        >
                          Marcar consulta online
                        </button>
                      ) : (
                        <>
                          <h4 className={'mt-4'}>Quer marcar uma consulta com esse médico?</h4>
                          <h6 className={'mt-3'}>Fale com nossa equipe através do Chat!</h6>
                        </>
                      )
                      }
                    </div>
                    {/*<div className="mb-4">*/}
                    {/*  <h4 className="patient__physician__description__title">Endereço</h4>*/}
                    {/*  {address ? (*/}
                    {/*    <>*/}
                    {/*      {address.street && (*/}
                    {/*        <p className="patient__physician__description__text">*/}
                    {/*          {address?.street}, {address?.number} -{' '}*/}
                    {/*          {address?.complement}*/}
                    {/*        </p>*/}
                    {/*      )}*/}
                    {/*      <p className="patient__physician__description__text">{address?.cep}</p>*/}
                    {/*      <p className="patient__physician__description__text">*/}
                    {/*        {address?.city} - {address?.state}*/}
                    {/*      </p>*/}
                    {/*    </>*/}
                    {/*  ) : (*/}
                    {/*    <p className="patient__physician__description__text">Endereço não cadastrado</p>*/}
                    {/*  )}*/}
                    {/*</div>*/}
                  </div>
                </div>
              </div>
            </>)
      }
    </>)
}
