import React from 'react'
import { Redirect, Switch } from 'react-router-dom'
import Route from './Route'

import Profile from '~/pages/Patient/Profile/Show'
import ProfileEdit from '~/pages/Patient/Profile/Edit'
import WhatNow from '~/pages/Patient/WhatNow'
import Prescriptions from '~/pages/Patient/Prescriptions'
import Orders from '~/pages/Patient/Orders'
import Order from '~/pages/Patient/Order'
import Physicians from '~/pages/Patient/Physicians'
import Physician from '~/pages/Patient/Physician'
import { PatientCalendar } from '~/pages/telemed/PatientCalendar'
import { PatientListConsultation } from '~/pages/telemed/PatientListConsultation'
import { HelmetPrivate } from '~/components/HelmetPrivate/HelmetPrivate'

export default function Routes() {
  if (
    localStorage.getItem('roles') !== 'responsible' &&
    localStorage.getItem('roles') !== 'patient'
  ) {
    return <Redirect to='/' />
  }

  return (
    <>
      <HelmetPrivate routeStr='Paciente' />
      <Switch>
        <Route
          path='/paciente'
          exact
          component={WhatNow}
          isPrivate
          redirectTo='/login'
        />
        <Route
          path='/paciente/telemed/:id'
          exact
          component={PatientCalendar}
          isPrivate
          redirectTo='/login'
        />
        <Route
          path='/paciente/lista/consultas'
          exact
          component={PatientListConsultation}
          isPrivate
          redirectTo='/login'
        />
        <Route
          path='/paciente/telemed/:id/:date'
          exact
          component={PatientCalendar}
          isPrivate
          redirectTo='/login'
        />
        <Route
          path='/paciente/perfil'
          exact
          component={Profile}
          isPrivate
          redirectTo='/login'
        />
        <Route
          path='/paciente/perfil/editar'
          exact
          component={ProfileEdit}
          isPrivate
          redirectTo='/login'
        />
        <Route
          path='/paciente/medicos'
          component={Physicians}
          isPrivate
          exact
          redirectTo='/login'
        />

        <Route
          path='/paciente/medicos/:id'
          component={Physician}
          isPrivate
          exact
          redirectTo='/login'
        />
        <Route
          path='/paciente/eagora'
          component={WhatNow}
          exact
          isPrivate
          redirectTo='/login'
        />
        <Route
          path='/paciente/prescricao'
          component={Prescriptions}
          exact
          isPrivate
          redirectTo='/login'
        />
        <Route
          path='/paciente/pedidos'
          component={Orders}
          exact
          isPrivate
          redirectTo='/login'
        />
        <Route
          path='/paciente/pedido/:id'
          component={Order}
          exact
          isPrivate
          redirectTo='/login'
        />
      </Switch>
    </>
  )
}
