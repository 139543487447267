import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory, useParams } from 'react-router-dom'

import UploadImage from '../../../../components/UploadImage'
import api from '~/services/api'
import { Creators as ErrorActions } from '~/store/ducks/error'
import { useDispatch } from 'react-redux'
import { ButtonPrimary, CustomCheckbox, CustomInput, CustomSelect, CustomTextArea } from '~/components'
import TypeList from '~/helpers/TypeList.json'

export default function NewMedicine() {
  const { register, handleSubmit, errors, setValue, watch } = useForm()
  const { id } = useParams()
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(true)
  const [brands, setBrands] = useState([])
  const [imageId, setImageId] = useState()
  const [leafletId, setLeafletId] = useState()
  const [formValues, setFormValues] = useState()
  const [bula, setBula] = useState(false)
  const history = useHistory()
  const show_calculator = watch('medicine.show_calculator')
  const is_controlled = watch('medicine.is_controlled')

  const getBrands = async () => {
    const res = await api.get('/brands')
    setBrands(res.data)

  }

  useEffect(() => {
    if (!id)
      setLoading(false)
    getBrands()
  }, [])

  const getData = async medicineId => {
    await getBrands()
    const res = await api.get(`/medicines/${medicineId}`)
    setFormValues(res.data)
    if (res.data.leaflet_id)
      setBula(true)
    setLoading(false)
  }

  useEffect(() => {
    if (id) getData(id)
  }, [id])

  useEffect(() => {
    setValue('medicine', formValues)
  })

  const setInput = (e) => {
    let { name, value, checked, type } = e.currentTarget
    name = name.replace('medicine.', '')
    setFormValues(prevState => {
      return {
        ...prevState,
        [name]: type === 'checkbox' ? checked : value,
      }
    })
  }

  const onSubmit = async data => {
    setLoading(true)
    const medicine = {
      photo_id: imageId,
      leaflet_id: leafletId,
      ...data.medicine,
      active: data.medicine.active === 'true',
    }
    if (id)
      await api
        .put(`/medicines/${id}`, medicine)
        .then(() => history.push('/admin/produtos'))
        .catch(r => dispatch(ErrorActions.setError(r.response.data.errors)))
    else
      await api
        .post('/medicines', medicine)
        .then(() => history.push('/admin/produtos'))
        .catch(r => dispatch(ErrorActions.setError(r.response.data.errors)))
    setLoading(false)
  }

  const currencyInput = (e) => {
    setInput(e)
    let { value, name } = e.currentTarget
    value = value.replace(/\D/g, '')
    value = value.replace(/(\d)(\d{2})$/, '$1.$2')
    value = value.replace(/(?=(\d{3})+(\D))\B/g, '')
    return setValue(name, value)
  }


  useEffect(() => {
    if (!id) {
      setValue('medicine.is_controlled', true)
      setValue('medicine.show_calculator', true)
    }
  }, [is_controlled, show_calculator])


  return (
    <div className='container-fluid admin_medicines_new_medicine'>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='row'>
          <div className='col-lg-12'>
            <h1 className='admin_medicines_new_medicine_title'>Produto</h1>
          </div>
        </div>
        <div className='row'>
          <div className='col-lg-12'>
            <p>Imagem do Produto</p>
            <UploadImage callback={setImageId} isPrivate={false} assetType='image' />
          </div>
        </div>
        <div className='row mt-3'>
          <div className='col-lg-3'>
            <CustomSelect
              label='Status'
              inputName='medicine.active'
              error={errors.medicine?.active}
              loading={loading}
              skeletonDivClass='mb-4'
              register={register}
              callBack={setInput}
            >
              <option value={true}>Ativo</option>
              <option value={false}>Inativo</option>
            </CustomSelect>
          </div>
          <div className='col-lg-3'>
            <CustomInput
              inputType='number'
              label='Ordem'
              inputName='medicine.priority'
              loading={loading}
              skeletonDivClass='mb-4'
              error={errors.medicine?.priority}
              register={register}
              callBack={setInput}
            />
          </div>
          <div className='col-lg-6 d-flex align-self-center pt-4'>
            <CustomCheckbox
              inputName='medicine.is_controlled'
              label='Controlado'
              loading={loading}
              skeletonDivClass='mb-4'
              register={register}
              callBack={setInput}
            />
            <CustomCheckbox
              inputName='medicine.show_calculator'
              label='Exibir calculadora'
              inputClass='ml-2'
              loading={loading}
              skeletonDivClass='mb-4'
              register={register}
              callBack={setInput}
            />
          </div>
          <div className='col-lg-6'>
            <CustomInput
              label='Nome do Produto (prescrição)'
              inputName='medicine.name'
              error={errors.medicine?.name}
              loading={loading}
              skeletonDivClass='mb-4'
              register={register}
              callBack={setInput}
            />
          </div>
          <div className='col-lg-6'>
            <CustomInput
              label='Nome Comercial (Gabarito Anvisa)'
              inputName='medicine.comercial'
              error={errors.medicine?.comercial}
              loading={loading}
              skeletonDivClass='mb-4'
              register={register}
              callBack={setInput}
            />
          </div>
          <div className='col-lg-6'>
            <CustomSelect
              label='Marca'
              inputName='medicine.brand_id'
              error={errors.medicine?.brand_id}
              loading={loading}
              skeletonDivClass='mb-4'
              register={register}
              callBack={setInput}
            >
              {brands.map(brand => (
                <option value={brand.id}>{brand.name}</option>
              ))}
            </CustomSelect>
          </div>
          <div className='col-lg-3'>
            <CustomInput
              label='CBD mg'
              inputName='medicine.cbdmg'
              error={errors.medicine?.cbdmg}
              loading={loading}
              skeletonDivClass='mb-4'
              register={register}
              callBack={setInput}
            />
          </div>
          <div className='col-lg-3'>
            <CustomInput
              label='CBD %'
              inputName='medicine.cbdp'
              error={errors.medicine?.cbdp}
              loading={loading}
              skeletonDivClass='mb-4'
              register={register}
              callBack={setInput}
            />
          </div>
          <div className='col-lg-4'>
            <CustomInput
              label='THC mg'
              inputName='medicine.thcmg'
              error={errors.medicine?.thcmg}
              loading={loading}
              skeletonDivClass='mb-4'
              register={register}
              callBack={setInput}
            />
          </div>
          <div className='col-lg-4'>
            <CustomInput
              label='THC %'
              inputName='medicine.thcp'
              error={errors.medicine?.thcp}
              loading={loading}
              skeletonDivClass='mb-4'
              register={register}
              callBack={setInput}
            />
          </div>
          <div className='col-lg-4'>
            <CustomInput
              label='Complemento (THC)'
              inputName='medicine.thc_complement'
              error={errors.medicine?.thc_complement}
              loading={loading}
              skeletonDivClass='mb-4'
              register={register}
              callBack={setInput}
            />
          </div>
          <div className='col-lg-3'>
            <CustomInput
              label='CBC mg'
              inputName='medicine.cbcmg'
              error={errors.medicine?.cbcmg}
              loading={loading}
              skeletonDivClass='mb-4'
              register={register}
              callBack={setInput}
            />
          </div>
          <div className='col-lg-3'>
            <CustomInput
              label='CBC %'
              inputName='medicine.cbcp'
              error={errors.medicine?.cbcp}
              loading={loading}
              skeletonDivClass='mb-4'
              register={register}
              callBack={setInput}
            />
          </div>
          <div className='col-lg-3'>
            <CustomInput
              label='CBN mg'
              inputName='medicine.cbnmg'
              error={errors.medicine?.cbnmg}
              loading={loading}
              skeletonDivClass='mb-4'
              register={register}
              callBack={setInput}
            />
          </div>
          <div className='col-lg-3'>
            <CustomInput
              label='CBN %'
              inputName='medicine.cbnp'
              error={errors.medicine?.cbnp}
              loading={loading}
              skeletonDivClass='mb-4'
              register={register}
              callBack={setInput}
            />
          </div>
          <div className='col-lg-3'>
            <CustomInput
              label='CBG mg'
              inputName='medicine.cbgmg'
              error={errors.medicine?.cbgmg}
              loading={loading}
              skeletonDivClass='mb-4'
              register={register}
              callBack={setInput}
            />
          </div>
          <div className='col-lg-3'>
            <CustomInput
              label='CBG %'
              inputName='medicine.cbgp'
              error={errors.medicine?.cbgp}
              loading={loading}
              skeletonDivClass='mb-4'
              register={register}
              callBack={setInput}
            />
          </div>
          <div className='col-lg-3'>
            <CustomInput
              label='THCV mg'
              inputName='medicine.thcvmg'
              error={errors.medicine?.thcvmg}
              loading={loading}
              skeletonDivClass='mb-4'
              register={register}
              callBack={setInput}
            />
          </div>
          <div className='col-lg-3'>
            <CustomInput
              label='THCV %'
              inputName='medicine.thcvp'
              error={errors.medicine?.thcvp}
              loading={loading}
              skeletonDivClass='mb-4'
              register={register}
              callBack={setInput}
            />
          </div>
          <div className='col-lg-3'>
            <CustomInput
              label='CBDA mg'
              inputName='medicine.cbdamg'
              error={errors.medicine?.cbdamg}
              loading={loading}
              skeletonDivClass='mb-4'
              register={register}
              callBack={setInput}
            />
          </div>
          <div className='col-lg-3'>
            <CustomInput
              label='CBDA %'
              inputName='medicine.cbdap'
              error={errors.medicine?.cbdap}
              loading={loading}
              skeletonDivClass='mb-4'
              register={register}
              callBack={setInput}
            />
          </div>
          <div className='col-lg-3'>
            <CustomInput
              label='CBDV mg'
              inputName='medicine.cbdvmg'
              error={errors.medicine?.cbdvmg}
              loading={loading}
              skeletonDivClass='mb-4'
              register={register}
              callBack={setInput}
            />
          </div>
          <div className='col-lg-3'>
            <CustomInput
              label='CBDV %'
              inputName='medicine.cbdvp'
              error={errors.medicine?.cbdvp}
              loading={loading}
              skeletonDivClass='mb-4'
              register={register}
              callBack={setInput}
            />
          </div>
          <div className='col-lg-3'>
            <CustomInput
              label='CBGA mg'
              inputName='medicine.cbgamg'
              error={errors.medicine?.cbgamg}
              loading={loading}
              skeletonDivClass='mb-4'
              register={register}
              callBack={setInput}
            />
          </div>
          <div className='col-lg-3'>
            <CustomInput
              label='CBGA %'
              inputName='medicine.cbgap'
              error={errors.medicine?.cbgap}
              loading={loading}
              skeletonDivClass='mb-4'
              register={register}
              callBack={setInput}
            />
          </div>
          <div className='col-lg-3'>
            <CustomInput
              label='THCVDELTA8 mg'
              inputName='medicine.thcvdelta8mg'
              error={errors.medicine?.thcvdelta8mg}
              loading={loading}
              skeletonDivClass='mb-4'
              register={register}
              callBack={setInput}
            />
          </div>
          <div className='col-lg-3'>
            <CustomInput
              label='THCVDELTA8 %'
              inputName='medicine.thcvdelta8p'
              error={errors.medicine?.thcvdelta8p}
              loading={loading}
              skeletonDivClass='mb-4'
              register={register}
              callBack={setInput}
            />
          </div>
          <div className='col-lg-3'>
            <CustomInput
              label='THCA mg'
              inputName='medicine.thcamg'
              error={errors.medicine?.thcamg}
              loading={loading}
              skeletonDivClass='mb-4'
              register={register}
              callBack={setInput}
            />
          </div>
          <div className='col-lg-3'>
            <CustomInput
              label='THCA %'
              inputName='medicine.thcap'
              error={errors.medicine?.thcap}
              loading={loading}
              skeletonDivClass='mb-4'
              register={register}
              callBack={setInput}
            />
          </div>
          <div className='col-lg-3'>
            <CustomInput
              label='DELTA8 mg'
              inputName='medicine.delta8mg'
              error={errors.medicine?.delta8mg}
              loading={loading}
              skeletonDivClass='mb-4'
              register={register}
              callBack={setInput}
            />
          </div>
          <div className='col-lg-3'>
            <CustomInput
              label='DELTA8 %'
              inputName='medicine.delta8p'
              error={errors.medicine?.delta8p}
              loading={loading}
              skeletonDivClass='mb-4'
              register={register}
              callBack={setInput}
            />
          </div>
          <div className='col-lg-3'>
            <CustomSelect
              label='Espectro'
              inputName='medicine.spectrum'
              error={errors.medicine?.spectrum}
              loading={loading}
              skeletonDivClass='mb-4'
              register={register}
              callBack={setInput}
            >
              <option value='Full Spectrum'>Full Spectrum</option>
              <option value='Isolado'>Isolado</option>
              <option value='Broad Spectrum'>Broad Spectrum</option>
            </CustomSelect>
          </div>
          <div className='col-lg-3'>
            <CustomSelect
              label='Apresentação / Tipo'
              inputName='medicine.type'
              error={errors.medicine?.type}
              loading={loading}
              skeletonDivClass='mb-4'
              register={register}
              callBack={setInput}
            >
              {TypeList?.map(value => (
                <option value={value.type}>{value.type}</option>
                )
              )}
            </CustomSelect>
          </div>
          <div className='col-lg-3'>
            <CustomInput
              label='Conteúdo (ml)'
              inputName='medicine.content'
              error={errors.medicine?.content}
              loading={loading}
              skeletonDivClass='mb-4'
              register={register}
              callBack={setInput}
            />
          </div>
          <div className='col-lg-3'>
            <CustomSelect
              label='Tipo do conteúdo'
              inputName='medicine.content_type'
              error={errors.medicine?.content_type}
              loading={loading}
              skeletonDivClass='mb-4'
              register={register}
              callBack={setInput}
            >
              <option value='ml'>ml</option>
              <option value='capsule'>Cápsula</option>
              <option value='g'>Gramas</option>
            </CustomSelect>
          </div>
          <div className='col-lg-3'>
            <CustomInput
              inputType='number'
              label='Gotas por ml'
              inputName='medicine.drops_per_ml'
              error={errors.medicine?.drops_per_ml}
              loading={loading}
              skeletonDivClass='mb-4'
              register={register}
              callBack={setInput}
            />
          </div>
          <div className='col-lg-12'>
            <CustomTextArea
              label='Composição'
              inputName='medicine.composition'
              error={errors.medicine?.composition}
              loading={loading}
              skeletonDivClass='mb-4'
              register={register}
              callBack={setInput}
            />
          </div>
          <div className='col-lg-12'>
            <CustomTextArea
              label='Uso'
              inputName='medicine.use'
              error={errors.medicine?.use}
              loading={loading}
              skeletonDivClass='mb-4'
              register={register}
              callBack={setInput}
            />
          </div>
          <div className='col-lg-12'>
            <CustomTextArea
              label='Descrição'
              inputName='medicine.description'
              error={errors.medicine?.description}
              loading={loading}
              skeletonDivClass='mb-4'
              register={register}
              callBack={setInput}
            />
          </div>
          <div className='col-lg-12'>
            <CustomInput
              label='Apresentação / Texto'
              inputName='medicine.presentation'
              error={errors.medicine?.presentation}
              loading={loading}
              skeletonDivClass='mb-4'
              register={register}
              callBack={setInput}
            />
          </div>
          <div className='col-lg-3'>
            <CustomSelect
              label='Moeda'
              inputName='medicine.currency'
              inputPlaceholder={'R$'}
              error={errors.medicine?.currency}
              loading={loading}
              skeletonDivClass='mb-4'
              register={register}
            >
              <option value={'R$'}>R$</option>
              <option value={'US$'}>US$</option>
              <option value={'€'}>€</option>

            </CustomSelect>
          </div>
          <div className='col-lg-3'>
            <CustomInput
              label='Preço'
              inputName='medicine.price'
              error={errors.medicine?.price}
              loading={loading}
              skeletonDivClass='mb-4'
              callBack={currencyInput}

              register={register}
            />
          </div>
          <div className='col-lg-3'>
            <CustomInput
              label='Preço Anterior'
              inputName='medicine.price_previous'
              error={errors.medicine?.price_previous}
              loading={loading}
              callBack={currencyInput}
              skeletonDivClass='mb-4'
              register={register}
            />
          </div>
          <div class='row w-100 m-0'>
            <div className='col-lg-3'>
              <CustomSelect
                label='Moeda (frete)'
                inputName='medicine.shipping_currency'
                error={errors.medicine?.shipping_currency}
                inputPlaceholder={'R$'}
                loading={loading}
                skeletonDivClass='mb-4'
                register={register}
              >
                <option value={'R$'}>R$</option>
                <option value={'US$'}>US$</option>
                <option value={'€'}>€</option>

              </CustomSelect>
            </div>
            <div className='col-lg-6'>
              <CustomInput
                label='Frete'
                inputName='medicine.shipping'
                error={errors.medicine?.shipping}
                loading={loading}
                skeletonDivClass='mb-4'
                callBack={currencyInput}
                register={register}
              />
            </div>
          </div>
          <div className='col-lg-12'>
            <p>Bula do produto</p>
            <UploadImage
              callback={setLeafletId}
              isPrivate={false}
              assetType='COA'
              fileIsExists={bula}
              textButton={bula ? 'Substituir Bula' : 'Buscar'}
            />
          </div>
          <div className='col-lg-12 mt-3'>
            <CustomInput
              label='Link personalizado'
              inputName='medicine.links'
              inputPlaceholder='http://www.mylink.com'
              loading={loading}
              skeletonDivClass='mb-4'
              error={errors.medicine?.link}
              register={register}
              callBack={setInput}
            />
          </div>
        </div>
        <div className='row'>
          <div className='col-lg-3'>
            <ButtonPrimary
              disabled={loading}
              btnType='submit'
              text='Salvar'
              width='w-100'
            />
          </div>
        </div>
      </form>
    </div>
  )
}
