import React, { useState, useEffect, useLayoutEffect } from 'react'
import { Modal } from 'react-bootstrap'
import Button from '~/components/Button'
import {useHistory} from 'react-router-dom'

export default function ModalLogout({show, updateStateModal}) {
  const [showModal, setShowModal] = useState(show);
  const history = useHistory();

  useEffect(() => {
    setShowModal(show)
  },[show]);

  return (
    <Modal show={showModal}
           backdrop="static"
           keyboard={false}
           centered={true}
           animation={false}>
      <Modal.Header closeButton={false}>
        <Modal.Title>Sair</Modal.Title>
      </Modal.Header>
      <Modal.Body>Você tem certeza que deseja sair?</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={()=>updateStateModal(false)}>
          Não
        </Button>
        <Button variant="primary" className="button__primary" onClick={()=>history.push('/logout')}>
          Sim
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
