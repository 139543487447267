import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

import api from '~/services/api'
import BrandImage from '~/assets/images/product-sample.jpg'
import { useForm } from 'react-hook-form'
import { ButtonPrimary, ButtonSecondary, CustomCheckbox } from '~/components'
import Skeleton from 'react-loading-skeleton'

export default function Medicines() {
  const { register, watch } = useForm({
    validationSchema: {},
  })
  const [brands, setBrands] = useState([])
  const [loading, setLoading] = useState(false)
  const showDisableBrands = watch('showDisableBrands')

  const getBrands = async () => {
    setLoading(true)
    const res = await api.get('/brands')
    setBrands(res.data)
    setLoading(false)
  }

  useEffect(() => {
    getBrands()
  }, [])

  return (
    <div className='container-fluid admin_medicines'>
      <div className='row'>
        <div className='col-lg-12'>
          <h2 className='admin_medicines_title'>Produtos</h2>
        </div>
      </div>
      <div className='row'>
        <div className='col-lg-6'>
          <CustomCheckbox
            inputClass='my-3'
            inputName="showDisableBrands"
            label="Mostrar marcas inativas"
            register={register}
          />
        </div>
        <div className='col-lg-6'>
          <div className='row'>
            <div className='col-lg-6'>
              <Link to="/admin/produtos/novo">
                <ButtonPrimary
                  text='Cadastrar Produto'
                  width='w-100'
                />
              </Link>
            </div>
            <div className='col-lg-6'>
              <Link to="/admin/produtos/marca/novo">
                <ButtonPrimary
                  text='Cadastrar Representante'
                  width='w-100'
                  btnClass='blue'
                />
              </Link>
            </div>
          </div>


        </div>
      </div>
      <div className='row'>
        {loading &&
        <>
        <div className='col-lg-6 mt-3'>
          <Skeleton height='80px'/>
        </div>

          <div className='col-lg-6 mt-3'>
            <Skeleton height='80px'/>
          </div>
        </>
        }
        {!loading &&
          brands.map(brand => (
            <>
              {showDisableBrands === false && brand.active === true &&
                <div key={brand.id} className="col-lg-6 mt-3">
                <Link to={`/admin/produtos/marca/${brand.slug}`}>
                  <div className='admin_medicines_brands'>
                    <div className='row'>
                      <div className='col-lg-3 col-4'>
                        <div className="admin_medicines_brands_avatar">
                          {brand.logo ? (
                            <img src={brand?.logo.uuidUrl} alt="" />
                          ) : (
                            <img src={BrandImage}  alt=''/>
                          )}
                        </div>
                      </div>

                      <div className='col-lg-5 col-4'>
                        <div className="admin_medicines_brands_desc">
                            <h5 className="admin_medicines_brands_desc_name">{brand?.name}</h5>
                          {brand.active ? (
                            <span className="admin_medicines_brands_desc_badge_active">Ativo</span>
                          ) : (
                            <span className="admin_medicines_brands_desc_badge_inactive">Inativo</span>
                          )}
                        </div>
                      </div>

                      <div className='col-lg-4 col-4 text-right'>

                            <Link
                              to={`/admin/produtos/marca/editar/${brand.slug}`}
                            >
                             <ButtonSecondary
                              text='Editar'
                              btnMt='m-3'
                             />
                           </Link>
                      </div>
                    </div>



                  </div>
                </Link>
              </div>
              }
              {
                showDisableBrands === true && (
                  <div  key={brand.id} className="col-lg-6 mt-3">
                    <Link to={`/admin/produtos/marca/${brand.slug}`}>
                      <div className='admin_medicines_brands'>
                        <div className='row'>
                          <div className='col-lg-3 col-4'>
                            <div className="admin_medicines_brands_avatar">
                              {brand.logo ? (
                                <img src={brand?.logo.uuidUrl} alt="" />
                              ) : (
                                <img src={BrandImage} />
                              )}
                            </div>
                          </div>

                          <div className='col-lg-5 col-4'>
                            <div className="admin_medicines_brands_desc">
                              <h5 className="admin_medicines_brands_desc_name">{brand?.name}</h5>
                              {brand.active ? (
                                <span className="admin_medicines_brands_desc_badge_active">Ativo</span>
                              ) : (
                                <span className="admin_medicines_brands_desc_badge_inactive">Inativo</span>
                              )}
                            </div>
                          </div>
                          <div className='col-lg-4 col-4 text-right'>
                            <Link
                              to={`/admin/produtos/marca/editar/${brand.slug}`}
                              className=""
                            >
                              <ButtonSecondary
                                text='Editar'
                                btnMt='m-3'
                              />
                            </Link>
                          </div>
                        </div>

                      </div>
                    </Link>
                  </div>
                )
              }
            </>
          ))}
      </div>
    </div>
  )
}
