import React from 'react'
import { PhysicianProfileHeader } from '~/components'
import urlImg from '~/assets/images/drc_stationary-1440x360.jpg'
import { Currency } from '~/components/Currency'
import { useParams } from 'react-router-dom'
import { ReactTinyLink } from 'react-tiny-link'


const Profile = ({ physician, likePhysician, patientLogged }) => {
  const { slug } = useParams()

  const formatToArray = data => {
    return data.split(',')
  }


  const favorite = !!patientLogged.user?.favoritesPatients.length


  return (
    <>
      <PhysicianProfileHeader
        backgroundImage={urlImg}
        physician={physician}
        callBack={likePhysician}
        favorite={favorite}
        customClass="link_none"
      />
      <div className="container physician_profile_public">
        <div className="row">
          <div className="my-4">
            <h4 className="physician_profile_public_title about">Sobre</h4>
            <p className="physician_profile_public_text">{physician.about}</p>
            {physician.price && (
              <p className="py-2 physician_profile_public_text">Preço da consulta: <span
                className="default-green"><Currency value={physician.price} currency="R$"/> </span></p>
            )}
          </div>
        </div>
        <div className="row">
          <div className="mb-4 col-12 p-0">
            <h4 className="physician_profile_public_title">Especialidade</h4>
            <span className="physician_profile_public_badge_buttons">{physician.specialty}</span>
          </div>
        </div>
        <div className="row">
          {physician.diseases?.length > 0 && (
            <div className="mb-4">
              <h4 className="physician_profile_public_title">Enfermidades</h4>
              {Array.isArray(physician.diseases)
                ? physician.diseases?.map(disease => (
                  <span className="physician_profile_public_badge_buttons ">
                              {disease}
                            </span>
                ))
                : formatToArray(physician.diseases).map(disease => (
                  <span className="physician_profile_public_badge_buttons ">
                              {disease}
                            </span>
                ))}
            </div>
          )}
        </div>
        <div className="row">
          {physician.articles?.length > 0 && (
            <>
              <div className="col-12">
                <div className="d-flex my-3">
                  <h5 className="font-primary font-weight-bold brand--text">
                    Artigos
                  </h5>
                </div>
              </div>
              {physician.articles.map(value => (
                <div className="col-lg-4 col-sm-12 mb-3">
                  <ReactTinyLink
                    cardSize="large"
                    showGraphic={true}
                    maxLine={3}
                    minLine={1}
                    url={value}
                    proxyUrl="https://corsanywhere.herokuapp.com"
                  />
                </div>
              ))}
            </>
          )}
        </div>

        <div className="row">
          {physician.links?.length > 0 && (
            <>
              <div className="col-12">
                <div className="d-flex my-3">
                  <h5 className="font-primary font-weight-bold brand--text">
                    Videos
                  </h5>
                </div>
              </div>
              {physician.links.map(value => (
                <div className="col-lg-4 col-sm-12 mb-3">
                  <ReactTinyLink
                    cardSize="large"
                    showGraphic={true}
                    maxLine={3}
                    minLine={1}
                    url={value}
                    proxyUrl="https://corsanywhere.herokuapp.com"
                  />
                </div>
              ))}
            </>
          )}
        </div>
      </div>
    </>
  )
}

export { Profile }
