import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import Skeleton from 'react-loading-skeleton'

import ParserPubMed from '~/helpers/ParserPubMed'
import api from '~/services/api'
import { ButtonPrimary, CustomInput } from '~/components'

function NewArticle({ show, handleClose }) {
  const { register, handleSubmit, errors } = useForm()
  const [loading, setLoading] = useState(false)

  const onSubmit = async data => {
    try {
      setLoading(true)
      api.post('/articles', data)
      handleClose()
      setLoading(false)
    } catch (e) {
      setLoading(false)
    }
  }

  return (
    <Modal className='admin__articles' show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Novo artigo</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form id='formArticle' onSubmit={handleSubmit(onSubmit)}>
          <div className='row'>
            <div className='col-lg-12'>
              <CustomInput
                inputType='text'
                label='ID PubMed'
                inputName='pubmed_id'
                error={errors.pubmed_id}
                register={register}
              />
            </div>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <ButtonPrimary
          btnForm='formArticle'
          btnType='submit'
          disabled={loading}
          text='Salvar'
          btnClass='btn__save__new__article'
        />

      </Modal.Footer>
    </Modal>
  )
}

export default function Articles() {
  const [articlesId, setArticlesId] = useState()
  const [loading, setLoading] = useState(true)
  const [articles, setArticles] = useState([])
  const [showModal, setShowModal] = useState(false)

  const getArticlesId = () => {
    setLoading(true)
    api.get('/articles')
      .then(res => setArticlesId(res.data))
      .finally(() => setLoading(false))
  }

  const getArticles = (articlesId) => {
    if (articlesId) {
      setLoading(true)
      ParserPubMed(articlesId)
        .then((parsed) => setArticles(parsed))
        .finally(() => setLoading(false))
    }
  }

  useEffect(() => {
    getArticlesId()
  }, [])

  useEffect(() => {
    getArticles(articlesId)
  }, [articlesId])

  const modalClose = async () => {
    await getArticlesId()
    setShowModal(false)
  }

  const deleteArticle = async id => {
    setLoading(true)
    await api.delete(`/articles/${id}`)
    await getArticlesId()
    setLoading(false)
  }

  return (
    <>
      <div className='admin_articles'>
        <div className='row'>
          <div className='col-lg-10'>
            <h1 className='admin_articles_title'>Artigos</h1>
          </div>
          <div className='col-lg-2 text-right'>
            <ButtonPrimary
              callBack={() => setShowModal(true)}
              text='Novo Artigo'
              btnClass='btn_new_article'
              btnMt='mt-0'
            />
          </div>
        </div>
        {loading ? (
          <div className='skeleton my-3'>
            <p>
              <Skeleton height='80px' />
            </p>
          </div>
        ) : (
          <div className='row my-3'>
            {articles.length > 0 &&
            !loading &&
            articles.map(article => (
              <div className='col-lg-12' key={article.id}>
                <div className='d-flex justify-content-between align-items-center admin_articles_item'>
                  <p dangerouslySetInnerHTML={{ __html: article.ArticleTitle }} />
                  <ButtonPrimary
                    callBack={() => deleteArticle(article.id)}
                    text='Excluir'
                    btnClass='btn_delete'
                    btnMt='mt-0'
                  />

                </div>
              </div>
            ))}
          </div>)}
      </div>

      <NewArticle show={showModal} handleClose={modalClose} />
    </>
  )
}
