import React from 'react'
import { Multiselect } from 'multiselect-react-dropdown'
import Skeleton from 'react-loading-skeleton'


const CustomMultSelect = ({label,
  inputName,
  listOptions,
  isObject = false,
  value,
  register,
  select,
  remove,
  useRegister=true,
  setValue,
  inputPlaceholder,
  errorMessage,
  loading,
  skeletonDivClass})=>{

  return (
    <>
      {
        loading ?
          (
          <div className={skeletonDivClass}>
            <Skeleton width="25%"/>
            <Skeleton className='mt-2' height={44}/>
          </div>
          ):
          (
            <div className='custom__mult__select'>
              <label className='w-100'>
                {label}
                <Multiselect
                  options={listOptions}
                  selectedValues={value}
                  isObject={isObject}
                  placeholder={inputPlaceholder}
                  onSelect={useRegister ? (value)=> setValue(inputName, value): select}
                  onRemove={useRegister ? (value)=> setValue(inputName, value): remove}
                  ref={register}
                />
                <span className="error">{errorMessage}</span>
              </label>

            </div>
          )
      }

    </>
  )
}

export {CustomMultSelect}
