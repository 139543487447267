import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import moment from 'moment'
import NumberFormat from 'react-number-format'

import ProfileCard from '~/components/ProfileCard'
import { useEffect } from 'react'
import api from '~/services/api'
import Prescription from '../Prescriptions/Prescription'
import Skeleton from 'react-loading-skeleton'

export default function Patient() {
  const { id } = useParams()
  const [patient, setPatient] = useState({})
  const [prescriptions, setPrescriptions] = useState([])
  const [loading, setLoading] = useState(false)

  const getPrescriptions = async () => {
    const res = await api.get(`/prescriptions?patient=${id}`)
    setPrescriptions(res.data)
  }

  const getPatient = async id => {
    setLoading(true)
    const res = await api.get(`/patients/${id}`)
    setPatient(res.data)
    setLoading(false)
  }

  useEffect(() => {
    getPatient(id)
    getPrescriptions()
  }, [id])

  const address = patient.addresses?.length ? patient.addresses[0] : {}
  const profile = patient.profile
  const ward = patient.wards?.length ? patient.wards[0] : {}

  return (
    <>
      {
        loading ? (<div className='physician__patient skeleton__physician__patient'>

          <div className='col-lg-12'>
            <div className='row mt-3'>
              <div className='col-lg-4'>
                <div className='row'>
                  <div className='col-lg-4 col-4'>
                    <Skeleton className='rounded-circle' width='90px' height='90px'/>
                  </div>
                  <div className='col-lg-6 col-6'>
                    <Skeleton className='skeleton__physician__patient__text__align'/>
                  </div>
                </div>
              </div>
            </div>

            <div className='row mt-4'>
              <div className='col-lg-4'>
                <Skeleton width='50%'/>
                <Skeleton className='mt-2' width='60%'/>
              </div>
            </div>

            <div className='row mt-4'>
              <div className='col-lg-4'>
                <Skeleton width='200px'/>
              </div>
            </div>

            <div className='row mt-4'>
              <div className='col-lg-12'>
                <Skeleton height='200px'/>
              </div>
            </div>
          </div>
        </div>)
          :(
    <div className='container-fluid physician__patient'>
      <div className='row'>
        <div className="col-lg-8 my-4">
          <ProfileCard data={patient} />
        </div>
      </div>
      <div className='row'>
        {profile?.birthdate && (
          <div className="col-lg-6 mb-4">
            <h5>Data de Nascimento</h5>
            <p>{moment(profile?.birthdate).format('DD/MM/YYYY')}</p>
          </div>
        )}
        {profile?.rg && (
          <div className=" col-lg-6 mb-4">
            <h5>RG</h5>
            <p>{profile?.rg}</p>
          </div>
        )}
        {profile?.cpf && (
          <div className="col-lg-6 mb-4">
            <h5>CPF</h5>
            <p>
              <NumberFormat
                value={profile?.cpf}
                displayType={'text'}
                format="###.###.###-##"
              />
            </p>
          </div>
        )}
        {profile?.mobile_number && (
          <div className="col-lg-6 mb-4">
            <h5>Contato</h5>
            <p>
              <NumberFormat
                value={profile?.mobile_number}
                displayType={'text'}
                format="(##) #####-####"
              />
            </p>
          </div>
        )}
      </div>
      {ward.profile && (
        <div className='row'>
          <div className="col-lg-12 my-3">
            <h4 className='physician__patient__info__title'>Dados do paciente</h4>
          </div>
          {ward.profile?.birthdate && (
            <div className="col-lg-6 mb-4">
              <h5>Data de Nascimento</h5>
              <p>{moment(ward.profile?.birthdate).format('DD/MM/YYYY')}</p>
            </div>
          )}
          {ward.profile?.rg && (
            <div className="col-lg-6 mb-4">
              <h5>RG</h5>
              <p>{ward.profile?.rg}</p>
            </div>
          )}
          {ward.profile?.cpf && (
            <div className="col-lg-6 mb-4">
              <h5>CPF</h5>
              <p>
                <NumberFormat
                  value={ward.profile?.cpf}
                  displayType={'text'}
                  format="###.###.###-##"
                />
              </p>
            </div>
          )}
          {ward.profile?.mobile_number && (
            <div className="col-lg-6 mb-4">
              <h5>Contato</h5>
              <p>
                <NumberFormat
                  value={ward.profile?.mobile_number}
                  displayType={'text'}
                  format="(##) #####-####"
                />
              </p>
            </div>
          )}
        </div>
      )}
      {prescriptions.length > 0 && (
        <div className='row'>
          <div className="col-lg-6 my-3">
            <h4 className="physician__patient__info__title">Prescrições</h4>
          </div>
        </div>
      )}
      {prescriptions.map(prescription => (
        <Prescription
          data={prescription}
          className="mb-3"
          key={prescription.id}
        />
      ))}
    </div> ) }
  </>
  )
}
