import React from 'react'
import Skeleton from 'react-loading-skeleton'

const CustomSelect =
  (
    {
      label,
      inputName,
      register,
      children,
      inputClass,
      error,
      callBack,
      selectValue,
      errorMessage,
      useRegister = true,
      loading = false,
      skeletonDivClass,
      disable=false,
      focus = true
    },
  ) => {
    return (
      <>
        {
          loading ?
            (<div className={skeletonDivClass}>
                <Skeleton width='25%' />
                <Skeleton className='mt-2' height={44} />
              </div>
            )
            :
            (<div className='form-group custom__select'>
              <label className='w-100' htmlFor={inputName}>
                {label}
                <select
                  className={`form-control ${inputClass} ${error && 'error'} ${errorMessage && 'error'}`}
                  name={inputName}
                  id={inputName}
                  ref={register}
                  onChange={callBack}
                  onFocus={focus && callBack}
                  value={selectValue}
                  disabled={disable}
                >
                  {children}
                </select>
              </label>
              <span>{useRegister ? (error && <span className='error'>{error.message}</span>) :
                <span className='error'>{errorMessage}</span>}</span>

            </div>)
        }
      </>
    )
  }

export { CustomSelect }
