import React from 'react'
import PhysicianImg from '~/assets/images/physician-default.png'
import { Currency } from '~/components/Currency'
import { formatText } from '~/shared/formatAndMasks'

export default function Physician({ data, phone, email, width, ...rest }) {
  const profile = data.user?.profile
  const prefix = profile?.gender === 'm' ? 'Dr.' : 'Dra.'
  const role = localStorage.getItem('roles')

  const getClass = status => {
    switch (status) {
      case 'approved':
        return 'fa fa-check-circle physician-card-icon-vector mt-1 mr-1 text-white'
      case 'disapproved':
        return 'fa fa-times-circle physician-card-icon-vector mt-1 mr-1 text-white'
      case 'pending':
        return 'fa fa-question-circle physician-card-icon-vector mt-1 mr-1 text-white'
      default:
        return ''
    }
  }

  const getContainerClass = status => {
    switch (status) {
      case 'approved':
        return 'physician-card-container-approve physician-card-success'
      case 'disapproved':
        return 'physician-card-container-disapprove physician-card-disapprove'
      case 'pending':
        return 'physician-card-container-pending physician-card-pending'
      default:
        return ''
    }
  }

  return (
    <div
      className={`card physician-card ${role === 'patient' || 'responsible ' ? '' : getContainerClass(data.status)}  `}
    >
      <div
        className={`${
          width > 575 ? 'row physician-card-avatar_div no-gutters' : ''
        }`}
      >

        <div className={`${width > 575 ? ' col-lg-1 pt-3 d-flex flex-wrap' : ''}`}>
          {profile?.avatar ? (
            <img
              className='mx-auto physician-card-avatar rounded-circle rounded'
              alt='Avatar'
              src={profile?.avatar?.uuidUrl}
            />
          ) : (
            <img
              className='mx-auto physician-card-avatar rounded-circle rounded'
              src={PhysicianImg}
              alt='Avatar'
            />
          )}
        </div>
        <div className={width > 575 && 'col-lg-10'}>
          <div className={`card-body ${width > 575 && ''}`}>
            <div className='d-flex align-items-center physician-card__card-header'>
              <h4 className='card-title text-capitalize'>
                {prefix} {profile?.full_name}{' '}
              </h4>
              {data.specialty ?
                <h4>
                  <span className='badge badge-secondary  font-weight-normal'>
                    {formatText(data.specialty, 30)}
                  </span>
                </h4>
                : ''}
              {data.online_consultation && (

                <h4>
                  <span className='telemed-badge  font-weight-normal'>
                    <i className='fas fa-video' />
                    Faz Telemedicina
                  </span>
                </h4>

              )}
            </div>

            <div className='row'>
              <div className='col-lg-6'>
                <div
                  className={`${width > 575 && 'd-flex justify-content-between'}`}
                >
                  <div>
                    <p className='card-text'>
                      {data.crm && (
                        <div>
                          CRM:{' '} <span className='physician-card__card-span'> {data?.crm} </span>
                        </div>
                      )}
                    </p>
                    <p className='card-text'>
                      {data.price && (
                        <div>
                          Valor da consulta:{' '}
                          <span className='physician-card__card-span'> <Currency value={data?.price} currency={'R$'} /> </span>
                        </div>
                      )}
                    </p>
                  </div>
                </div>

              </div>
              <div className='col-lg-6'>
                <p className='physician-card__title'>Sobre: <span
                  className='physician-card__card-span'> {data?.about?.length > 130 ? data?.about?.substring(0, 130) + '...' : data?.about} </span>
                </p>
              </div>
            </div>
          </div>
        </div>
        {width > 575 && (
          <div className='col-1 d-flex justify-content-end'>
            <i className={getClass(data.status)} />
          </div>
        )}
      </div>
    </div>
  )
}
