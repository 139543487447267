import styled from 'styled-components'

// import Pessoas from '~/assets/images/pessoas.jpg'
import Paciente1 from '~/assets/images/paciente2.jpg'
import Paciente1Mobile from '~/assets/images/paciente2.jpg'
import Paciente2 from '~/assets/images/paciente1.jpg'
import Paciente2Mobile from '~/assets/images/paciente1.jpg'
import Paciente3 from '~/assets/images/paciente3.jpg'
import Paciente3Mobile from '~/assets/images/paciente3.jpg'

import Medico1 from '~/assets/images/medico2.jpg'
import Medico1Mobile from '~/assets/images/medico2.jpg'
import Medico2 from '~/assets/images/medico1.jpg'
import Medico2Mobile from '~/assets/images/medico1.jpg'
import Medico3 from '~/assets/images/medico3.jpg'
import Medico3Mobile from '~/assets/images/medico3.jpg'

import { Variables as V } from '~/styles/global'

export const SectionBanner = styled.section`
  padding: 2.5rem 0;
  background: ${V.colorPrimary};
  color: #fff;

  h1 {
    line-height: 3.4rem;
    @media only screen and (max-width: 767px) {
      line-height: 2.4rem;
      font-size: 2rem;
    }
    @media only screen and (max-width: 575px) {
      font-size: 2.2rem;
    }
  }

  p {
    font-size: 1.3rem;
    line-height: 1.9rem;
    @media only screen and (min-width: 1440px) {
      font-size: 1.6rem;
    }
    @media only screen and (max-width: 575px) {
      font-size: 1.2rem;
    }
  }

  button {
    &.md {
      @media only screen and (max-width: 575px) {
        font-weight: bold;
        font-size: 1.1rem;
      }
    }
  }

  .price {
    height: 90px;
    width: 90px;
    bottom: 0;
  }

  .banner {
    height: 31rem;
    width: 100%;

    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .banner-mobile {
    background-size: cover;
    height: 25rem;
    width: 100%;
    @media only screen and (max-width: 628px) {
      height: 20rem;
    }

    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .angle-down {
    width: 3.8rem;
    height: 0.9rem;
    margin-top: 4.3rem;
    cursor: pointer;
    fill: $color-white;
    transition: all 0.2s;

    &:hover {
      transform: translateY(1rem);
      transition: all 0.2s;
    }

    @media only screen and (min-width: 1440px) {
      display: none;
    }
    @media only screen and (max-width: 767px) {
      margin-top: 3rem;
    }
  }
  .banner_cnabis{
    img{
      margin-top: -30px;
      margin-bottom: 15px;
    }
  }
`

export const SectionAbout = styled.section`
  background-color: #f2f2f2;
  padding: 4rem 0;
  @media only screen and (max-width: 767px) {
    padding: 2rem 0;
  }

  .title {
    @media only screen and (max-width: 575px) {
      font-size: 1.5rem;
    }
  }

  .text {
    width: 88%;
    margin: 1.7rem auto 3.3rem auto;
    font-size: 1.2rem;
    line-height: 1.55;
    @media only screen and (min-width: 1440px) {
      font-size: 1.6rem;
      width: 100%;
    }
    @media only screen and (max-width: 991px) {
      width: 100%;
    }
    @media only screen and (max-width: 575px) {
      margin: 1.7rem auto 1.6rem auto;
    }
  }

  .box {
    margin-top: 3.3rem;

    &:not(:last-of-type) {
      margin-right: 5rem;
    }
  }

  .number {
    @media only screen and (max-width: 575px) {
      font-size: 1.5rem;
    }
  }

  .decease {
    @media only screen and (max-width: 575px) {
      font-size: 1.4rem;
    }
  }

`

export const SectionGetMedicines = styled.section`
  background-color: $color-grey-lighter;
  padding: 4rem 0;
  @media only screen and (max-width: 767px) {
    padding: 2rem 0;
  }

  .card {
    border: none;
    margin-bottom: 0px;
  }

  .card-horizontal {
    display: flex;
    flex: 1 1 auto;
  }

  .card-body {
    width: 50%;
  }

  .img-square-wrapper {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }
  .img-square-wrapper img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  .title {
    @media only screen and (max-width: 575px) {
      font-size: 2.3rem;
      line-height: 1.5;
    }
  }

  .text {
    width: 70%;
    margin: 1.2rem auto 2.5rem auto;
    font-size: 1.3rem;
    line-height: 1.55;
    @media only screen and (min-width: 1440px) {
      font-size: 1.6rem;
      width: 100%;
    }
    @media only screen and (max-width: 991px) {
      width: 100%;
    }
    @media only screen and (max-width: 575px) {
    }
  }

  .box {
    width: 18.5rem;

    @media only screen and (min-width: 1440px) {
      width: 24rem;
      //height: 24rem;
      justify-content: center;
    }
    @media only screen and (max-width: 767px) {
      width: 12rem;
      //height: 12rem;
    }
    @media only screen and (max-width: 575px) {
      width: 13rem;
    }

    &-title {
      font-size: 3.3rem;
      color: #71aa74;
      @media only screen and (max-width: 767px) {
        font-size: 2.5rem;
      }
    }

    &-text {
      font-size: 1.1rem;
      line-height: 1.3;
      @media only screen and (max-width: 767px) {
        font-size: 1rem;
      }
      @media only screen and (min-width: 1440px) {
        font-size: 1.6rem;
      }
    }

    &--margin {
      margin: 0.6rem;
    }

    &-img {
      width: 18.7rem;
      height: 13rem;

      @media only screen and (max-width: 767px) {
        width: 100%;
        height: 100%;
      }
      @media only screen and (min-width: 1440px) {
        width: 100%;
        height: 100%;
      }

      &.paciente1 {
        background: url(${Paciente1}) 100% 25% no-repeat;
        background-size: cover;

        @media only screen and (max-width: 767px) {
          background: url(${Paciente1Mobile}) 100% no-repeat;
        }
      }

      &.paciente2 {
        background: url(${Paciente2}) 100% 35% no-repeat;
        background-size: cover;
        @media only screen and (max-width: 767px) {
          background: url(${Paciente2Mobile}) 100% no-repeat;
        }
      }

      &.paciente3 {
        background: url(${Paciente3}) 100% no-repeat;
        background-size: cover;
        @media only screen and (max-width: 767px) {
          background: url(${Paciente3Mobile}) 100% no-repeat;
        }
      }

      &.medico1 {
        background: url(${Medico1}) 100% 25% no-repeat;
        background-size: cover;

        @media only screen and (max-width: 767px) {
          background: url(${Medico1Mobile}) 100% no-repeat;
        }
      }

      &.medico2 {
        background: url(${Medico2}) 100% 35% no-repeat;
        background-size: cover;
        @media only screen and (max-width: 767px) {
          background: url(${Medico2Mobile}) 100% no-repeat;
        }
      }

      &.medico3 {
        background: url(${Medico3}) 100% no-repeat;
        background-size: cover;
        @media only screen and (max-width: 767px) {
          background: url(${Medico3Mobile}) 100% no-repeat;
        }
      }
    }
  }
`

export const SectionProducts = styled.section`
  .title {
    @media only screen and (max-width: 575px) {
      font-size: 2.3rem;
      color: $color-black-dark;
      line-height: 1.5;
    }
  }

  .text {
    width: 72%;
    margin: 1.2rem auto 2.5rem auto;
    font-size: 1.3rem;
    line-height: 1.55;
    @media only screen and (max-width: 991px) {
      width: 100%;
    }
  }
`

export const YoutubeDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  iframe {
    max-width: 100%;
  }
`
