import React from 'react'
import Pagination from 'react-js-pagination'
import { Link } from 'react-router-dom'

import { PhysicianCard } from '~/pages/Admin/Physicians/PhysicianCard'

export default function PhysiciansList({ list, handlePageChange, loading }) {

  return (
    <div className={`container ${loading}`}>
      {
        list.data.map(physician => (
          <Link to={`/admin/medicos/${physician.slug}`}>
            <PhysicianCard
              className="mt-3"
              phone
              email
              data={physician}
              shadow={true}
              loading={loading}
            />
          </Link>
        ))
      }
      <div className='row'>
        <div className="col-lg-12 d-flex justify-content-center mt-3">
          <Pagination
            activePage={list.page}
            itemsCountPerPage={list.perPage}
            totalItemsCount={list.total}
            pageRangeDisplayed={5}
            onChange={handlePageChange}
            itemClass="page-item"
            linkClass="page-link"
          />
        </div>
      </div>
    </div>
  )
}
