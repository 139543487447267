import { toast } from 'react-toastify'

export const paymentError = (status) => {

  if (status !== 500) {
    toast.error('Ocorreu um erro ao efetuar o pagamento! Por favor, revise os dados do cartão.')
  } else {
    toast.error('Ocorreu um erro ao efetuar o pagamento! Por favor, tente mais tarde.')
  }
}

export const paymentSuccess = (status, callback, setState) => {
  switch (status) {
    case 'PAYMENT_ACCEPTED':
    case 'paid':
      toast.success('Pagamento Realizado com sucesso!')
      if (typeof callback === 'function') callback(false)
      break

    case 'PROCESSING_PAYMENT':
    case 'processing':
    case 'authorized':
    case 'analyzing':
    case 'pending_review':
    case 'waiting_payment':
      if (typeof setState === 'function') setState(true)
      toast.info('Aguarde, seu pagamento está sendo processado!')
      if (typeof callback === 'function') callback(false)
      break
    default:
      toast.error('Pagamento rejeitado!')
      break
  }
}

export const cardData = (address, cardNumber, cardName, cardCVC, cardExpiry, installments) => {
  const {
    state,
    city,
    neighborhood,
    street,
    street_number,
    zipcode,
    complement,
  } = address


  return {
    card_number: cardNumber,
    holder_name: cardName,
    cvv: cardCVC,
    expiration_date: cardExpiry,
    installments: installments ?? 1,
    billing: {
      name: cardName,
      address: {
        state,
        city,
        neighborhood,
        street,
        street_number,
        zipcode,
        complement,
      },
    },
  }
}

export const copyToClipboard = (pixKey) => {
  navigator.clipboard.writeText(pixKey)
  toast.success('Chave PIX copiada!')
}

