import React, { useEffect, useState } from 'react'
import { Chart } from 'react-google-charts'
import { useHistory, useParams } from 'react-router-dom'
import { deletedCoupon, getCoupon } from '~/services/requests'
import { ButtonPrimary } from '~/components'
import { ModalNewCoupon } from '~/components/ModalNewCoupon/ModalNewCoupon'
import { toast } from 'react-toastify'

const CouponDetails = () => {
  const { slug } = useParams()
  const [coupon, setCoupon] = useState({})
  const [couponUsages, setCouponUsages] = useState([])
  const history = useHistory()


  useEffect(() => {
    getCoupon(slug).then(r => {
      setCoupon(r.data)
      setCouponUsages(r.data.usages)
    })
  }, [])


  const remove = () => {
    deletedCoupon(slug)
      .then(() => {
        toast.success('Deletado com sucesso')
        history.goBack()
      })
      .catch(() => toast.error('Erro ao deletar'))
  }

  return (
    <>
      <div className='admin_campaign'>
        <div className='row mb-5'>
          <div className='col-lg-12 d-flex justify-content-between align-items-center'>
            <h1 className='admin_campaign_title'>{coupon.name}</h1>
            <div>
              <ButtonPrimary text='Editar'
                             btnClass='mr-2'
                             toggle='modal'
                             target='#couponModal'
              />
              <ButtonPrimary text='Excluir'
                             btnClass='mr-2 bg-danger border-0'
                             callBack={() => remove()}
              />
            </div>
          </div>
        </div>
        <div className='row mb-3'>
          <div className='col-4'>
            <h5>Quantidade:</h5>
            <p>{coupon.quantity}</p>
          </div>
          <div className='col-4'>
            <h5>Data de expiração:</h5>
            <p>{coupon.expiration}</p>
          </div>
          <div className='col-4'>
            <h5>Desconto:</h5>
            <p>{coupon.discount}</p>
          </div>
        </div>
        <div className='row mb-5'>
          <div className='col-12'>
            <h5>Descrição:</h5>
            <p>{coupon.description}</p>
          </div>
        </div>
        <div className='row'>
          <div className='col-12'>
            <Chart
              width={'100%'}
              height={'300px'}
              chartType='PieChart'
              loader={<div>Loading Chart</div>}
              data={[
                ['Cupons', 'Usos'],
                ['Total', coupon.quantity],
                ['Usos', couponUsages.length],
              ]}
              options={{
                title: 'Usos do cupom',
              }}
              rootProps={{ 'data-testid': '1' }}
            />
          </div>
        </div>
      </div>
      <ModalNewCoupon defaultValues={coupon} isPut={true} />
    </>
  )
}

export { CouponDetails }
