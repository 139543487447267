import React, { useEffect, useState } from 'react'
import { ButtonPrimary, ButtonSecondary, CustomInput, CustomSelect } from '~/components'
import { splitTimeCourse } from '~/shared/telemedOptions'
import { adminEditAppointment } from '~/services/requests'
import { toast } from 'react-toastify'
import moment from 'moment'
import { Modal } from 'react-bootstrap'

const ModalEditAppointment = ({ calendarOptions, appointmentLength, appointment, show, closeModal }) => {
  const [horaryOptions, setHoraryOptions] = useState([])
  const [form, setForm] = useState({
    date: moment().format('YYYY-MM-DD'),
    time: '',
  })


  useEffect(() => {
    setHoraryOptions(splitTimeCourse(calendarOptions?.start, calendarOptions?.end, appointmentLength))
  }, [appointmentLength, calendarOptions])


  const updateAppointments = () => {
    if (appointment?.uuid) {
      const { date, time } = form
      adminEditAppointment(date, time, appointment?.uuid)
        .then(() => {
          document.location.reload(true)
          toast.success('Atualizado com sucesso!!!')
        })
        .catch(() => toast.error('Erro ao editar consulta'))
    }
  }


  const changeForm = (e) => {
    const { value, name } = e.currentTarget
    setForm(prevState => {
      return {
        ...prevState,
        [name]: value,
      }
    })
  }

  return (
    <Modal show={show} onHide={() => closeModal(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Editar consulta</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='row'>
          <div className='col-lg-6 col-sm-12 col-md-12'>
            <CustomInput inputType='date'
                         label='Dia'
                         inputName='date'
                         defaultValue={form.date}
                         callBack={changeForm}
            />
          </div>
          <div className='col-lg-6 col-sm-12 col-md-12'>
            <CustomSelect label='Hora' inputName='time'
                          selectValue={form.time}
                          callBack={changeForm}
            >
              <option value=''>Todos os horários</option>
              {horaryOptions.map(hourly => (
                <option key={hourly.start}
                        value={hourly.start}
                >
                  {hourly.start} até {hourly.end}
                </option>
              ))}
            </CustomSelect>
          </div>
          <div className='col-12'>
            <CustomInput label='Paciente'
                         disabled={true}
                         defaultValue={appointment?.name}
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <ButtonPrimary text='Confirmar' callBack={() => updateAppointments()} />
        <ButtonSecondary text='Cancelar' callBack={() => closeModal(false)} />
      </Modal.Footer>
    </Modal>
  )
}

export { ModalEditAppointment }
