import React from 'react'
import PropTypes from 'prop-types'
import MaskedInput from 'react-input-mask'

import { Container } from './styles'
import Sprites from '~/assets/icons/sprites.svg'

const InputTag = React.memo(props => {
  const { name, inputRef, value, maskChar, ...inputProps } = props
  return <input value={value} name={name} ref={inputRef} {...inputProps} />
})

const Input = React.forwardRef(
  (
    {
      icon,
      name,
      placeholder,
      type,
      error,
      size,
      label,
      innerLabel = false,
      fluid,
      className,
      classInput,
      classLabel='',
      mask = '',
      classContainer='',
      value,
      disabled = false,
      onChange,
      ...rest
    },
    ref,
  ) => {return (
      <>
        <div className={className}>
          {label && !innerLabel && <label htmlFor={name} className={classLabel}>{label}</label>}
          <Container
            className={`${size} ${classContainer} position-relative  ${
              fluid ? 'fluid' : ''
            }`}
          >
            {icon && (
              <svg className="icon left position-absolute">
                <use xlinkHref={`${Sprites}#${icon}`} />
              </svg>
            )}
            {label && innerLabel && <label htmlFor={name} className={classLabel}>{label}</label>}
            <MaskedInput
              name={name}
              inputRef={ref}
              mask={mask}
              value={value}
              disabled={disabled}
              alwaysShowMask
              onChange={onChange}
            >
              <InputTag
                type="tel"
                className={`text pr-3 ${classInput} ${icon ? 'pl-5' : 'pl-2'} ${
                  error ? 'error' : ''
                }`}
                placeholder={placeholder}
                id={name}
                type={type}
                {...rest}
              />
            </MaskedInput>
            {error && <span className="error">{error.message}</span>}
          </Container>
        </div>
      </>
    )
  },
)

Input.propTypes = {
  icon: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  innerLabel: PropTypes.string,
  fluid: PropTypes.bool,
  size: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  classLabel: PropTypes.string,
  type: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  error: PropTypes.object,
}

export default Input
