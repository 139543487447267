import React, { useEffect, useState } from 'react'
import moment from 'moment'
import 'moment/locale/pt-br'
import { adminPatientCalendar } from '~/services/requests'
import { ModalConsultationDetails } from '~/pages/telemed/ModalConsultationDetails'
import Skeleton from 'react-loading-skeleton'
import { AppointmentCard } from '~/components/AppointmentCard/AppointmentCard'
import { showAppointmentDetail, treatmentListConsultation } from '~/shared/telemedOptions'
import { useParams } from 'react-router-dom'

const AdminViewPatientAppointment = () => {
  const { id } = useParams()
  const [loading, setLoading] = useState(true)
  const [proposal, setProposal] = useState()
  const [confirmed, setConfirmed] = useState()
  const [event, setSelectedEvent] = useState({})
  const [showPayment, setShowPayment] = useState(false)

  moment.locale('pt-br')


  useEffect(() => {
    setLoading(true)

    const start = moment().format('YYYY-MM-DD')
    const end = '2100-12-31'

    adminPatientCalendar(start, end, id).then(r => {
      setProposal(r.data.PROPOSAL)
      setConfirmed(r.data.CONFIRMED)
    }).finally(() => setLoading(false))
  }, [])


  return (
    <>
      <div className='container-fluid mb-5 view-presential px-4'>
        <div className='row'>
          <div className='col-lg-12 d-flex align-items-center justify-content-between'>
            <h1 className='page-title py-3'>Minhas consultas</h1>
          </div>
        </div>

        {loading && (
          <div className='row'>
            <div className='col-6'>
              <Skeleton className='mt-2' count={5} />
            </div>
            <div className='col-6'>
              <Skeleton className='mt-2' count={5} />
            </div>
          </div>
        )}

        {Array.isArray(proposal) && !loading && (
          <div className='row my-2'>
            <div className='col-12'>
              <h4>Pendentes de pagamento</h4>
            </div>
            {proposal.map(appointment => (
              <AppointmentCard appointment={appointment}
                               callback={() => showAppointmentDetail(appointment, setSelectedEvent, setShowPayment)}
                               treatment={treatmentListConsultation}
                               isAdmin={true}
              />
            ))}
          </div>
        )}

        {Array.isArray(confirmed) && !loading && (
          <div className='row mt-2'>
            <div className='col-12'>
              <h4>Confirmadas</h4>
            </div>
            {confirmed.map(appointment => (
              <AppointmentCard appointment={appointment}
                               callback={() => showAppointmentDetail(appointment, setSelectedEvent, setShowPayment)}
                               treatment={treatmentListConsultation}
                               isAdmin={true}
              />
            ))}
          </div>
        )}

        {!Array.isArray(proposal) && !Array.isArray(confirmed) && !loading && (
          <h5>Você não possuí nenhum agendamento</h5>
        )}
      </div>
      <ModalConsultationDetails event={event} />
    </>
  )
}


export { AdminViewPatientAppointment }
