import React from 'react'
import InputMask from 'react-input-mask'
import Skeleton from 'react-loading-skeleton'


const CustomInput = (
  {
    inputName = '',
    label,
    error,
    inputClass = '',
    register,
    inputType = 'text',
    inputPlaceholder,
    mask,
    defaultValue,
    icon,
    callBack,
    useRegister = true,
    errorMessage,
    loading = false,
    skeletonDivClass = '',
    disabled = false,
    maxLength='',
    focusValidated = true
  }) => {

  return (
    <>
      {
        loading ?
          (
            <div className={skeletonDivClass}>
              <Skeleton width='25%' />
              <Skeleton className='mt-2' height={44} />
            </div>
          )
          :
          (<div className='form-group custom_input'>
            <label className='w-100' htmlFor=''>
              {label}
              <InputMask
                className={`form-control ${inputClass} ${icon && 'custom_input_icon_pad'} ${error && 'error'} ${errorMessage && 'error'}`}
                name={inputName}
                ref={register}
                type={inputType}
                placeholder={inputPlaceholder}
                mask={mask}
                value={defaultValue}
                onChange={callBack}
                disabled={disabled}
                maxLength={maxLength}
                onFocus={focusValidated && callBack}
              />
              <span>
                {useRegister ?
                  (error && <span className='error'>{error.message}</span>)
                  :
                  <span className='error'>{errorMessage}</span>}
              </span>
            </label>
            {icon && <i className={`custom_input_icon ${icon}`} />}
          </div>)
      }
    </>
  )
}

export { CustomInput }
