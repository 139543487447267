import styled from 'styled-components'

export const Section = styled.section`
  margin: 4rem 0;

  h1 {
    font-weight: bold;
    color: #000000;
    text-align: center;
  }
  .buttonExibirSenha {
    position: absolute;
    margin-right: 1rem;
    margin-top: 1.5rem;
  }
  .buttonExibirSenha {
    position: absolute;
    margin-right: 1rem;
    margin-top: 1.5rem;
  }

  .divBtnExibirSenha{
    display: flex;
    justify-content: flex-end;
    width: 100%;
    align-items: center;
  }

`
