import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import moment from 'moment'
import { useParams } from 'react-router-dom'

import { Creators as AuthActions } from '~/store/ducks/auth'
import { Creators as ErrorActions } from '~/store/ducks/error'

import Yup from '~/helpers/yup'
import api from '~/services/api'
import UploadFile from '~/components/UploadFile'
import StatesBrasil from '~/helpers/StatesBrasil.json'
import Diseases from '~/helpers/Diseases.json'
import Skeleton from 'react-loading-skeleton'
import { ButtonPrimary, CustomCheckbox, CustomInput, CustomMultSelect, CustomSelect } from '~/components'
import { removeCaracter } from '~/shared/utils'
import { toast } from 'react-toastify'
import { getAddressByCep } from '~/services/viaCEP'


const EditProfileSchema = Yup.object().shape({
  isResponsible: Yup.boolean(),
  address: Yup.object().shape({
    cep: Yup.string().required('Digite o CEP do o endereço'),
    state: Yup.string().required('Digite o estado'),
    city: Yup.string().required('Digite a cidade'),
    street: Yup.string().required('Digite a rua'),
    number: Yup.string().required('Digite o número'),
    complement: Yup.string(),
    district: Yup.string().required('Digite o bairro'),
  }),
})

export default function Edit({ history }) {
  const { register, handleSubmit, errors, setValue, watch } = useForm({
    validationSchema: EditProfileSchema,
  })
  const [patient, setPatient] = useState({})
  const [loading, setLoading] = useState(true)
  const dispatch = useDispatch()
  const isResponsible = watch('isResponsible')
  const diseases = watch('diseases')
  const rg = watch('profile.rg')
  const [defaultDiseases, setDefaultDiseases] = useState([])
  const { id } = useParams()
  const [form, setForm] = useState({
    cep: '',
    state: '',
    city: '',
    street: '',
    district: '',
    number: '',
    complement: '',
  })
  const [avatarId, setAvatarId] = useState()
  const isRepresentative = localStorage.getItem('roles') === 'representative'

  useEffect(() => {
    register({ name: 'name' })
    register({ name: 'diseases' })
  })

  const setPatientValue = (data) => {
    if (data) {
      setValue('isResponsible', data.isResponsible)
      if (data.isResponsible) {
        setValue('ward', data.wards[0]?.profile)
        setValue(
          'ward.birthdate',
          moment(data.wards[0]?.profile?.birthdate).format('DD/MM/YYYY'),
        )
        setValue('diseases', data.wards[0]?.profile?.diseases)
        setDefaultDiseases(data.wards[0]?.profile?.diseases)
      } else {
        setValue('diseases', data.profile?.diseases)
        setDefaultDiseases(data.profile?.diseases)
      }
      setValue('profile', data.profile)
      setValue(
        'profile.birthdate',
        moment(data.profile?.birthdate).format('DD/MM/YYYY'),
      )
      setValue('user.email', data.email)
    }
  }


  const getPatient = patientId => {
    api.get(`/patients/${patientId}`)
      .then((r) => {
        setLoading(false)
        setPatient(r.data)
        if (r.data?.addresses[0])
          setForm(r.data?.addresses[0])
        setPatientValue(r.data)
      }).catch((e) => {
      if (e.response.status === 403) {
        toast.error('Ação não permitida')
        history.push('/')
      }
    })

  }

  useEffect(() => {
    getPatient(id)
  }, [id])


  const onSubmit = async data => {
    try {
      data.role = data.isResponsible ? 'responsible' : 'patient'
      if (data.profile.cpf) {
        data.profile.cpf = removeCaracter(data.profile.cpf)
      }
      if (avatarId) data.profile.avatar_id = avatarId
      if (data.isResponsible) {
        data.ward.diseases = diseases ? diseases : defaultDiseases
      } else {
        data.profile.diseases = diseases ? diseases : defaultDiseases
      }
      data.address = form
      await api.put(`/admin/${data.role}/${patient.id}`, data)
      dispatch(AuthActions.getSessionRequest())
      const to = isRepresentative? `/representantes/paciente/${patient.id}` : `/admin/pacientes/${patient.id}`
      history.push(to)
      toast.success('Paciente editado com sucesso')
    } catch (e) {
      toast.error('Não foi possível editar o perfil do paciente')
      dispatch(ErrorActions.setError(e.response))
    }
  }

  const changeInput = (e) => {
    const { value, name } = e.currentTarget

    let auxName = name.replace('address.', '')


    if (auxName === 'cep' && value) {
      let cleanCep = value.replace('-', '')
      cleanCep = cleanCep.replaceAll('_', '')
      if (cleanCep.length === 8) {
        getAddressByCep(cleanCep).then(r => {
          setForm(prevState => {
            return {
              ...prevState,
              state: r.data?.uf,
              city: r.data?.localidade,
              street: r.data?.logradouro,
              district: r.data?.bairro,
            }
          })
        })
      }
    }

    setForm(prevState => {
      return {
        ...prevState,
        [auxName]: value,
      }
    })

  }

  return (
    <div className='container-fluid admin_patient_edit'>
      <div className='row'>
        <div className='col-lg-12'>
          <h2 className='admin_patient_edit_title'>Informações Pessoais</h2>
        </div>
      </div>
      <div className='row'>
        <div className='col-lg-12'>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className='row'>
              <div className='col-lg-12'>
                <p className=''>Adicione uma foto de perfil</p>
                <UploadFile loading={loading}
                            skeletonDivClass='my-3'
                            callback={setAvatarId}
                            assetType='avatar'
                />
              </div>
            </div>
            <div className='row'>
              <div className='col-lg-5 col-12'>
                <CustomCheckbox
                  inputClass='my-3'
                  inputName='isResponsible'
                  label='Sou o responsável legal do paciente'
                  register={register}
                  loading={loading}
                  skeletonDivClass='mb-2'
                  error={errors.isResponsible}
                />
              </div>
            </div>
            <div className='row'>
              <div className='col-lg-6'>
                <CustomInput
                  label='Nome Completo'
                  inputName='profile.full_name'
                  inputPlaceholder='Nome'
                  error={errors.profile?.full_name}
                  register={register}
                  loading={loading}
                  skeletonDivClass='mb-4'
                />

              </div>
              <div className='col-lg-6'>

                <CustomInput
                  label='Data de nascimento'
                  inputName='profile.birthdate'
                  inputPlaceholder='__/__/____'
                  error={errors.profile?.birthdate}
                  register={register}
                  mask='99/99/9999'
                  loading={loading}
                  skeletonDivClass='mb-4'
                />

              </div>
            </div>
            <div className='row'>

              <div className='col-lg-6'>
                <CustomSelect
                  label='Gênero'
                  inputName='profile.gender'
                  error={errors.profile?.gender}
                  register={register}
                  loading={loading}
                  skeletonDivClass='mb-4'
                >
                  <option value='m'>Masculino</option>
                  <option value='f'>Feminino</option>
                  <option value='-'>Prefiro não informar</option>
                </CustomSelect>

              </div>

            </div>
            <div className='row'>
              <div className='col-lg-3'>
                <CustomInput
                  label='Celular'
                  inputName='profile.mobile_number'
                  inputPlaceholder='(11) 99999-9999'
                  mask='(99) 99999-9999'
                  error={errors.profile?.mobile_number}
                  register={register}
                  loading={loading}
                  skeletonDivClass='mb-4'
                />

              </div>

              <div className='col-lg-3'>

                <CustomInput
                  label='Telefone Fixo'
                  inputName='profile.phone_number'
                  inputPlaceholder='(11) 99999-9999'
                  mask='(99) 99999-9999'
                  error={errors.profile?.phone_number}
                  register={register}
                  loading={loading}
                  skeletonDivClass='mb-4'
                />

              </div>

              <div className='col-lg-3'>
                <CustomInput
                  label='CPF'
                  inputName='profile.cpf'
                  inputPlaceholder='9999999'
                  mask='999.999.999-99'
                  error={errors.profile?.cpf}
                  register={register}
                  loading={loading}
                  skeletonDivClass='mb-4'

                />
              </div>

              <div className='col-lg-3'>
                <CustomInput
                  label='RG'
                  inputName='profile.rg'
                  inputPlaceholder='9999999'
                  error={errors.profile?.rg}
                  register={register}
                  loading={loading}
                  defaultValue={rg}
                  skeletonDivClass='mb-4'

                />
              </div>
            </div>
            <div className='row'>
              <div className='col-lg-12 my-3'>
                <CustomCheckbox
                  inputName='ward.isResponsible'
                  register={register}
                  inputClass='d-none'
                  inputChecked={isResponsible}

                />
              </div>
            </div>
            <div className='row'>
              <div className={`col-lg-6 ${isResponsible ? '' : 'd-none'}`}>
                <CustomInput
                  label='Nome Completo do Paciente'
                  inputName='ward.full_name'
                  inputPlaceholder='Nome'
                  error={errors.ward?.full_name}
                  register={register}
                  loading={loading}
                  skeletonDivClass='mb-4'
                />
              </div>

              <div className={`col-lg-6 ${isResponsible ? '' : 'd-none'}`}>
                <CustomInput
                  label='Data de nascimento do Paciente'
                  inputName='ward.birthdate'
                  inputPlaceholder='__/__/____'
                  error={errors.ward?.birthdate}
                  register={register}
                  mask='99/99/9999'
                  loading={loading}
                  skeletonDivClass='mb-4'
                />
              </div>
            </div>
            <div className='row'>
              <div className={`col-lg-6 ${isResponsible ? '' : 'd-none'}`}>
                <CustomSelect
                  label='Gênero do Paciente'
                  inputName='ward.gender'
                  error={errors.ward?.gender}
                  register={register}
                  loading={loading}
                  skeletonDivClass='mb-4'
                >
                  <option value='m'>Masculino</option>
                  <option value='f'>Feminino</option>
                  <option value='-'>Prefiro não informar</option>
                </CustomSelect>

              </div>
            </div>
            <div className='row'>
              <div className={`col-lg-6 ${isResponsible ? '' : 'd-none'}`}>

                <CustomInput
                  label='Celular do Paciente'
                  inputName='ward.mobile_number'
                  inputPlaceholder='(11) 99999-9999'
                  mask='(99) 99999-9999'
                  error={errors.ward?.mobile_number}
                  register={register}
                  loading={loading}
                  skeletonDivClass='mb-4'
                />

              </div>

              <div className={`col-lg-6 ${isResponsible ? '' : 'd-none'}`}>

                <CustomInput
                  label='Telefone Fixo do Paciente'
                  inputName='ward.phone_number'
                  inputPlaceholder='(11) 99999-9999'
                  mask='(99) 99999-9999'
                  error={errors.ward?.phone_number}
                  register={register}
                  loading={loading}
                  skeletonDivClass='mb-4'
                />

              </div>
            </div>
            <div className='row'>
              <div className={`col-lg-6 ${isResponsible ? '' : 'd-none'}`}>
                <CustomInput
                  label='RG do Paciente'
                  inputName='ward.rg'
                  inputPlaceholder='9999999'
                  error={errors.ward?.rg}
                  register={register}
                  loading={loading}
                  skeletonDivClass='mb-4'
                />

              </div>
              <div className={`col-lg-6 ${isResponsible ? '' : 'd-none'}`}>
                <CustomInput
                  label='CPF do Paciente'
                  inputName='ward.cpf'
                  inputPlaceholder='123.456.789-10'
                  mask='999.999.999-99'
                  error={errors.ward?.cpf}
                  register={register}
                  loading={loading}
                  skeletonDivClass='mb-4'
                />

              </div>
            </div>
            <div className='row'>
              <div className='col-lg-6'>
                <div className={`${!loading ? 'd-none' : 'd-block  mb-4'}`}>
                  <Skeleton width='25%' />
                  <Skeleton className='mt-2' height={44} />
                </div>
                <div className={`${loading ? 'd-none' : 'd-block'}`}>
                  <CustomMultSelect
                    label='Patologias'
                    skeletonDivClass='mb-4'
                    listOptions={Diseases}
                    inputName='diseases'
                    inputPlaceholder=''
                    value={
                      diseases
                        ? diseases
                        : Array.isArray(defaultDiseases)
                        ? defaultDiseases
                        : []
                    }
                    setValue={setValue}
                    loading={loading}
                    register={register}
                  />
                </div>

              </div>
            </div>
            <div className='row'>
              <div className='col-lg-4'>
                <CustomInput
                  label='CEP'
                  inputName='address.cep'
                  callBack={changeInput}
                  mask='99999-999'
                  defaultValue={form.cep}
                  register={register}
                  error={errors?.address?.cep}
                />
              </div>
              <div className='col-lg-4'>
                <CustomSelect
                  label='UF'
                  inputName='address.state'
                  value={form.state}
                  callBack={changeInput}
                  selectValue={form.state}
                  loading={loading}
                  skeletonDivClass='mb-4'
                  register={register}
                  error={errors?.address?.state}
                >
                  {StatesBrasil.map(state => (
                    <option value={state.value}>{state.label}</option>
                  ))}
                </CustomSelect>
              </div>
              <div className='col-lg-4'>
                <CustomInput
                  label='Cidade'
                  inputName='address.city'
                  value={form.city}
                  callBack={changeInput}
                  defaultValue={form.city}
                  loading={loading}
                  skeletonDivClass='mb-4'
                  register={register}
                  error={errors?.address?.city}
                />
              </div>
            </div>
            <div className='row'>
              <div className='col-lg-4'>
                <CustomInput
                  label='Bairro'
                  inputName='address.district'
                  value={form.district}
                  defaultValue={form.district}
                  callBack={changeInput}
                  loading={loading}
                  skeletonDivClass='mb-4'
                  register={register}
                  error={errors?.address?.district}
                />
              </div>
              <div className='col-lg-6'>
                <div className={`${loading ? 'd-none' : 'd-block'}`}>
                  <CustomInput
                    label='Logradouro'
                    inputName='address.street'
                    value={form.street}
                    callBack={changeInput}
                    defaultValue={form.street}
                    loading={loading}
                    skeletonDivClass='mb-4'
                    register={register}
                    error={errors?.address?.street}
                  />
                </div>
              </div>
              <div className='col-lg-2'>
                <CustomInput
                  label='Número'
                  inputName='address.number'
                  value={form.number}
                  callBack={changeInput}
                  defaultValue={form.number}
                  loading={loading}
                  skeletonDivClass='mb-4'
                  register={register}
                  error={errors?.address?.number}
                />
              </div>
            </div>


            <div className='row'>
              <div className='col-lg-6'>
                <CustomInput
                  label='Complemento'
                  inputName='address.complement'
                  callBack={changeInput}
                  value={form.complement}
                  defaultValue={form.complement}
                  skeletonDivClass='mb-4'
                  loading={loading}
                  register={register}
                  error={errors?.address?.complement}
                />
              </div>
            </div>
            <div className='row'>
              <div className='col-lg-6'>
                <CustomInput
                  label='Email'
                  inputName='user.email'
                  error={errors.user?.email}
                  register={register}
                  loading={loading}
                  skeletonDivClass='mb-4'
                />
              </div>
            </div>
            <div className='row'>
              <div className='col-lg-12'>
                <ButtonPrimary
                  btnType='submit'
                  disabled={loading}
                  text={loading ? 'Carregando...' : 'Continuar'}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
