import styled from 'styled-components'

import { Variables as V } from '~/styles/global'

export const Card = styled.div`
  height: auto;
  width: 100%;
  box-shadow: 0 2px 4px rgba(214, 214,214, 0.5);
  border-radius: 10px 10px 0 0;
  border-bottom: 4px solid #fff;
  @media only screen and (max-width: 768px) {
    flex-direction: column;
    margin-top: 15px;
  }
  &:hover{
    border-bottom: 4px solid #72C7B6;
  }


  img {
    width: 100%;
    height: 450px;
    box-shadow: 0 1px 3px 1px #e2e2e2;
    margin-bottom: 20px;
  }

  .name {
    font-size: 25px;
    color: #000;
    height: 50px;
  }

  .brand{
    font-size: 20px;
    display: block;
    padding-top: 15px;

  }

  .anchor {
    color: inherit;
    transition: 0.2s;

  }

  .price {
    height: 90px;
    width: 90px;
  }

  .price-tags{
    display: flex;
    flex-direction: column;
    height: 60px;
      .previous-value{
        color: #FF2D55;
        font-size: 18px;
      }
      .current-value{
        padding: 10px 0;
        font-size: 20px;
      }
    .desc{
      color: #0fd447;
      padding-bottom: 10px;
      font-size: 18px;
    }
  }

  .description{
    font-size: 20px;
    height: 100px;
    color: #414141;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
    padding-bottom: 10px;

  }
  .see-more{
    align-items: center;
      p{
        font-size: 18px;
        color: #72C7B6;
        padding-bottom: 10px;
      }
    &:hover{
      color: #414141;
    }
  }
  .icon {
    width: 0.6rem;
    height: 0.6rem;
    fill: ${V.colorPrimary};
    transition: 0.2s;
    width: 1rem;
    height: 1rem;
    margin-top: -13px;

    &:hover {
      fill: darken(${V.colorPrimary}, 15);
      transition: 0.2s;
    }
  }

  .prices-free{
    display: flex;
      .desc{
        margin-top: 11px;
        padding-left: 20px;
      }
  }
`
