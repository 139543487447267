import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory, useParams } from 'react-router-dom'

import UploadImage from '../../../../components/UploadImage'
import api from '~/services/api'
import { ReactTinyLink } from 'react-tiny-link'
import { Creators as ErrorActions } from '~/store/ducks/error'
import { useDispatch } from 'react-redux'
import { ButtonPrimary, CustomCheckbox, CustomInput, CustomSelect, CustomTextArea } from '~/components'

export default function NewBrand(props, widthCrop = 460) {
  const { register, handleSubmit, errors, setValue, watch } = useForm()
  const [loading, setLoading] = useState(false)
  const [imageId, setImageId] = useState()
  const [coverId, setCoverId] = useState()
  const [listLinks, setListLinks] = useState([])
  const [link, setLink] = useState([])
  const [listArticles, setListArticles] = useState([])
  const [articles, setArticles] = useState([])
  const [formValues, setFormValues] = useState()
  const [installment, setInstallment] = useState({
    minimum_installment_value: 0,
    minimum_installable_amount: 0,
    maximum_installments: 1,
    charge_interest: true,
    monthly_interest_rate: 0,
  })
  const { id } = useParams()
  const history = useHistory()
  const dispatch = useDispatch()

  const onSubmit = async data => {
    setLoading(true)
    const brand = {
      links: listLinks,
      articles: listArticles,
      logo_id: imageId,
      cover_id: coverId,
      ...data.brand,
      active: data.brand.active === 'true' ? true : false,
      ...installment
    }
    if (id)
      await api
        .put(`/brands/${id}`, brand)
        .then(() => history.push('/admin/produtos'))
        .catch(r => dispatch(ErrorActions.setError(r.response.data.errors)))
    else
      await api
        .post('/brands', brand)
        .then(() => history.push('/admin/produtos'))
        .catch(r => dispatch(ErrorActions.setError(r.response.data.errors)))
    setLoading(false)
  }

  const getData = async brandId => {
    setLoading(true)
    const resBrand = await api.get(`/brands/${brandId}`)
    setListLinks(resBrand.data.links ? resBrand.data.links : [])
    setListArticles(resBrand.data.articles ? resBrand.data.articles : [])
    setFormValues(resBrand.data)
    const {
      charge_interest,
      monthly_interest_rate,
      maximum_installments,
      minimum_installable_amount,
      minimum_installment_value,
    } = resBrand.data
    setInstallment(prevState => {
      return {
        ...prevState,
        charge_interest,
        monthly_interest_rate,
        maximum_installments,
        minimum_installable_amount,
        minimum_installment_value,
      }
    })
    setLoading(false)
  }

  useEffect(() => {
    if (id) getData(id)

  }, [id])

  useEffect(() => {
    setValue('brand', formValues)
  })

  const addingLink = (listKey, setListKey, origin, linkValue) => {
    if (linkValue) {
      let list = Array.isArray(listKey) ? listKey : []
      list.push(linkValue)
      setListKey(list)
      origin('')
    }
  }

  const changeLink = (e, set) => {
    set(e.currentTarget.value)
  }

  const removeLink = (index, set, list = []) => {
    let newList = []
    list.map((result, removeIndex) => {
      if (removeIndex !== index) {
        newList.push(result)
      }
    })
    return set(newList)
  }

  const currencyInput = (e) => {
    let { value, name } = e.currentTarget
    value = value.replace(/\D/g, '')
    value = value.replace(/(\d)(\d{2})$/, '$1.$2')
    value = value.replace(/(?=(\d{3})+(\D))\B/g, '')
    return setValue(name, value)
  }


  const formatInput = (value = 0, inputName) => {
    console.log(value.length)
    let numberValue = parseFloat(value)
    switch (inputName) {
      case 'minimum_installment_value':
        if (value.length <= 10) {
          return numberValue
        }
        return installment[inputName]
      case 'minimum_installable_amount':
        if (value.length <= 10) {
          return numberValue
        }
        return installment[inputName]
      case 'maximum_installments':
        if (numberValue <= 12 && numberValue >= 1) {
          return numberValue
        }
        return installment[inputName]
      case 'monthly_interest_rate':
        if (value.length <= 6) {
          return numberValue
        }
        return installment[inputName]
    }
  }

  const setInstallmentForm = (e) => {
    const { value, checked, type, name } = e.currentTarget
    setInstallment(prevState => {
      return {
        ...prevState,
        [name]: type === 'checkbox' ? checked : formatInput(value, name),
      }
    })
  }

  return (
    <div className='container-fluid admin_medicines_new_brand'>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='row'>
          <div className='col-lg-12'>
            <h1 className='admin_medicines_new_brand_title'>Marca</h1>
          </div>
        </div>
        <div className='row'>
          <div className='col-lg-6'>
            <p>Imagem da Marca</p>
            <UploadImage
              callback={setImageId}
              height={200}
              aspect={0}
              isPrivate={false}
              assetType={'brandLogo'}
            />
          </div>
          <div className='col-lg-6'>
            <p>Cover da Marca</p>
            <UploadImage
              callback={setCoverId}
              height={150}
              aspect={0}
              widthCrop={460}
              isPrivate={false}
              assetType={'brandBanner'}
            />
          </div>
        </div>
        <div className='row mt-3'>
          <div className='col-lg-3'>
            <CustomSelect
              label='Status'
              inputName='brand.active'
              error={errors.brand?.active}
              loading={loading}
              skeletonDivClass='mb-4'
              register={register}
            >
              <option value={true}>Ativo</option>
              <option value={false}>Inativo</option>
            </CustomSelect>
          </div>
          <div className='col-lg-3'>
            <CustomInput
              label='Nome da Marca'
              inputName='brand.name'
              error={errors.brand?.name}
              loading={loading}
              skeletonDivClass='mb-4'
              register={register}
            />
          </div>
          <div className='col-lg-3'>
            <CustomInput
              label='Descrição'
              inputName='brand.description'
              error={errors.brand?.description}
              loading={loading}
              skeletonDivClass='mb-4'
              register={register}
            />
          </div>
          <div className='col-lg-3'>
            <CustomInput
              label='CNPJ'
              inputName='brand.cnpj'
              inputPlaceholder='000.000.00/0000-0'
              error={errors.brand?.cnpj}
              loading={loading}
              skeletonDivClass='mb-4'
              register={register}
            />
          </div>
          <div className='col-lg-3'>
            <CustomSelect
              label='Moeda'
              inputName='brand.currency'
              inputPlaceholder={'R$'}
              error={errors.brand?.currency}
              loading={loading}
              skeletonDivClass='mb-4'
              register={register}
            >
              <option value={'R$'}>R$</option>
              <option value={'US$'}>US$</option>
              <option value={'€'}>€</option>

            </CustomSelect>
          </div>
          <div className='col-lg-3'>
            <CustomInput
              label='Frete'
              inputName='brand.shipping'
              error={errors.brand?.shipping}
              loading={loading}
              skeletonDivClass='mb-4'
              callBack={currencyInput}
              register={register}
            />
          </div>
          <div className='col-lg-3'>
            <CustomSelect
              label='Moeda (frete)'
              inputName='brand.shipping_currency'
              error={errors.brand?.shipping_currency}
              inputPlaceholder={'R$'}
              loading={loading}
              skeletonDivClass='mb-4'
              register={register}
            >
              <option value={'R$'}>R$</option>
              <option value={'US$'}>US$</option>
              <option value={'€'}>€</option>

            </CustomSelect>
          </div>
          <div className='col-lg-3'>
            <CustomInput
              label='Fabricante (Anvisa)'
              inputName='brand.manufacturer'
              error={errors.brand?.manufacturer}
              loading={loading}
              skeletonDivClass='mb-4'
              register={register}
            />
          </div>

          <div className='col-lg-12'>
            <CustomInput
              label='Nome comercial Anvisa'
              inputName='brand.anvisa_commercial_name'
              error={errors.brand?.anvisa_commercial_names}
              loading={loading}
              skeletonDivClass='mb-4'
              register={register}
            />
          </div>

          <div className='col-lg-12'>
            <CustomTextArea
              label='Instruções de pagamento'
              inputName='brand.payment_instructions'
              loading={loading}
              skeletonDivClass='mb-4'
              register={register}
            />
          </div>

          <div className='col-lg-6'>
            <CustomInput
              inputType='number'
              label='Valor mínimo de parcela'
              inputName='minimum_installment_value'
              loading={loading}
              skeletonDivClass='mb-4'
              callBack={setInstallmentForm}
              defaultValue={installment.minimum_installment_value}
              focusValidated={false}
            />
          </div>
          <div className='col-lg-6'>
            <CustomInput
              inputType='number'
              label='Valor mínimo para parcelamento'
              inputName='minimum_installable_amount'
              loading={loading}
              skeletonDivClass='mb-4'
              callBack={setInstallmentForm}
              defaultValue={installment.minimum_installable_amount}
              focusValidated={false}
            />
          </div>
          <div className='col-lg-6'>
            <CustomInput
              inputType='number'
              label='Quantidade máxima de parcelas'
              inputName='maximum_installments'
              maxLength='2'
              loading={loading}
              skeletonDivClass='mb-4'
              callBack={setInstallmentForm}
              defaultValue={installment.maximum_installments}
              focusValidated={false}
            />
          </div>
          <div className='col-lg-2'>
            <CustomCheckbox
              label='Cobrar juros?'
              inputClass='pt-4 mb-0'
              inputName='charge_interest'
              loading={loading}
              skeletonDivClass='mb-4'
              callBack={setInstallmentForm}
              defaultValue={installment.charge_interest}
              inputChecked={installment.charge_interest}
            />
          </div>
          {installment.charge_interest && (
            <div className='col-lg-4'>
              <CustomInput
                inputType='number'
                label='Taxa de juros mensal'
                inputName='monthly_interest_rate'
                loading={loading}
                skeletonDivClass='mb-4'
                callBack={setInstallmentForm}
                defaultValue={installment.monthly_interest_rate}
                focusValidated={false}
              />
            </div>
          )}


          <div className='col-12 my-3'>
            <div className='card'>
              <div className='card-header d-flex justify-content-center'>
                <h6>Videos</h6>
              </div>
              <div className='card-body'>
                <div className='row'>
                  {Array.isArray(listLinks) &&
                  listLinks.map(
                    (data, index) =>
                      data && (
                        <div className='col-4 mt-2'>
                          <ReactTinyLink
                            cardSize='large'
                            showGraphic={true}
                            maxLine={3}
                            minLine={1}
                            url={data}
                            proxyUrl='https://corsanywhere.herokuapp.com'
                          />
                          <ButtonPrimary
                            text='Deletar'
                            btnClass='btn_delete'
                            callBack={() =>
                              removeLink(index, setListLinks, listLinks)
                            }
                          />
                        </div>
                      ),
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className='col-12'>
            <label htmlFor='links'>Link do video</label>
            <div className='row'>
              <div className='col-lg-10'>
                <CustomInput
                  id='links'
                  inputPlaceholder='youtube.com'
                  defaultValue={link}
                  loading={loading}
                  skeletonDivClass='mb-4'
                  callBack={e => changeLink(e, setLink)}
                />
              </div>
              <div className='col-lg-2'>
                <ButtonPrimary
                  text='adicionar'
                  width='w-100'
                  btnMt='mt-2'
                  callBack={() =>
                    addingLink(listLinks, setListLinks, setLink, link)
                  }
                  disabled={!link.length > 0}
                />
              </div>


            </div>
          </div>

          <div className='col-12 my-3'>
            <div className='card'>
              <div className='card-header d-flex justify-content-center'>
                <h6>Artigos</h6>
              </div>
              <div className='card-body'>
                <div className='row'>
                  {Array.isArray(listArticles) &&
                  listArticles.map(
                    (data, index) =>
                      data && (
                        <div className='col-4 mt-2'>
                          <ReactTinyLink
                            cardSize='large'
                            showGraphic={true}
                            maxLine={3}
                            minLine={1}
                            url={data}
                            proxyUrl='https://corsanywhere.herokuapp.com'
                          />
                          <ButtonPrimary
                            text='Deletar'
                            btnClass='btn_delete'
                            callBack={() =>
                              removeLink(index, setListArticles, listArticles)
                            }
                          />
                        </div>
                      ),
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className='col-12'>
            <label htmlFor='links'>Link do artigo</label>
            <div className='row'>
              <div className='col-lg-10'>
                <CustomInput
                  id='articles'
                  inputPlaceholder='articles.com'
                  defaultValue={articles}
                  loading={loading}
                  skeletonDivClass='mb-4'
                  callBack={e => changeLink(e, setArticles)}
                />
              </div>

              <div className='col-lg-2'>
                <ButtonPrimary
                  text='Adicionar'
                  width='w-100'
                  btnMt='mt-2'
                  callBack={() =>
                    addingLink(
                      listArticles,
                      setListArticles,
                      setArticles,
                      articles,
                    )
                  }
                  disabled={!articles.length > 0}
                />

              </div>


            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-lg-2'>
            <ButtonPrimary
              text='Enviar'
              disabled={loading}
              btnType='submit'
              width='w-100'
            />
          </div>
        </div>
      </form>
    </div>
  )
}
