import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { CreditCard } from '~/components/CreditCard/CreditCard'
import Button from '~/components/Button'
import QRCode from 'qrcode.react'
import Logo from '~/assets/images/logo-vert-cropped.png'
import { useSelector } from 'react-redux'
import Skeleton from 'react-loading-skeleton'
import moment from 'moment'
import { getPixKey, getPixKeyConsultation, payConsultation, payOrder } from '~/services/requests'
import { cardData, copyToClipboard, paymentError, paymentSuccess } from '~/shared/paymentUtils'
import { Currency } from '~/components/Currency'
import { formatValue } from '~/shared/formatAndMasks'
import { Countdown } from '~/components/Countdown/Countdown'
import { toast } from 'react-toastify'

export default function ModalPayment({ show, handleClose, data, id, type, room, expirationTime }) {
  const [modalPayment, setModalPayment] = useState(show)
  const [pixLoading, setPixLoading] = useState(true)
  const [loading, setLoading] = useState(false)
  const [dataOrder, setDataOrder] = useState()
  const [dataConsultation, setDataConsultation] = useState()
  const [active, setActive] = useState(true)
  const [pixKey, setPixKey] = useState('')
  const [date, setDate] = useState('')
  const [hourly, setHourly] = useState('')
  const { session } = useSelector(state => state.auth)
  const QRCode = require('qrcode.react')

  useEffect(() => {
    setModalPayment(show)

    if (type === 'order' && data.id === id) {
      setDataOrder(data)
      getPixOrder()
    } else {
      setDataConsultation(data)
    }
  }, [show, data, type])

  useEffect(() => {
    const selectedDate = moment(data?.HourStart).format('YYYY-MM-DD')
    const selectedHour = moment(data?.HourStart).format('HH:mm')

    if (data && id && show && type !== 'order') {
      getPixConsultation(selectedDate, selectedHour)
    }

    setDate(selectedDate)
    setHourly(selectedHour)

  }, [data?.HourStart, show])


  const sendPay = (e, state) => {
    const { number, name, cvc, expiry, address } = state
    e.preventDefault()
    setLoading(true)

    const request = type === 'order' ? payOrder : payConsultation
    const payload = cardData(address, number, name, cvc, expiry)

    if (!id) toast.warn('Parâmetro inválido! \n entre em contato com o suporte')
    request(id, payload, date, hourly)
      .then(response => {
        setLoading(false)
        paymentSuccess(response.data?.status, handleClose, setLoading)
      }).catch((error) => {
        setLoading(false)
        paymentError(error.response.status)
      },
    )
  }

  const getPixOrder = () => {
    getPixKey(id)
      .then(res => {
        setPixKey(res.data.pix_key)
        setPixLoading(false)
      })
  }

  const getPixConsultation = (date, hourly) => {
    getPixKeyConsultation(id, date, hourly)
      .then(res => {
        setPixKey(res.data.pix_key)
        setPixLoading(false)
      })
  }

  return (
    <Modal className='overflow-auto' size='lg' show={modalPayment}>
      <Modal.Body>
        {dataOrder ?
          dataOrder.medicines.map(medicine => (
            <div className='div-modal-payment'>
              <div className='div-modal-payment__div-img'>
                <img className='div-modal-payment__div-img__img' src={medicine?.photo.url} alt='' />
              </div>
              <div className='div-modal-payment__textos'>
                <h1 className='div-modal-payment__textos__h1'>{medicine?.name}</h1>
                <p className='div-modal-payment__textos__p'><strong>SubTotal: </strong>
                  {formatValue(dataOrder.subtotal,
                    medicine.shipping_currency ? medicine.shipping_currency : medicine.brand.shipping_currency,
                  )}
                </p>
                <p className='div-modal-payment__textos__p'><strong>Frete: </strong>
                  {formatValue(dataOrder?.shipping,
                    medicine.shipping_currency ? medicine.shipping_currency : medicine.brand.shipping_currency,
                  )}</p>
                <p className='div-modal-payment__textos'><strong>Total do pedido: </strong> <span
                  className='div-modal-payment__textos__span-green'>
                  {formatValue(dataOrder.total,
                    medicine.shipping_currency ? medicine.shipping_currency : medicine.brand.shipping_currency,
                  )}</span>
                </p>
              </div>
            </div>
          ))
          :
          <div className='div-modal-payment'>
            <div className='div-modal-payment__textos w-100'>
              <h1 className='div-modal-payment__textos__h1'>Reserva de consulta</h1>
              <p className='div-modal-payment__textos__p'><strong>Data / horário: </strong>
                {(moment(dataConsultation?.HourStart).format('DD/MM/YYYY HH:mm'))}
              </p>
              <p className='div-modal-payment__textos__p'>
                <strong>
                  {'Doutor(a):'}:
                </strong>
                {dataConsultation?.attendant || dataConsultation?.room?.attendant.personal_profile.name}
              </p>
              <p className='div-modal-payment__textos__p'>
                <strong>Valor da consulta: </strong>
                <Currency value={room?.appointment_price ?? dataConsultation?.room?.appointment_price}
                          currency={'R$'} />
              </p>
              <p className='div-modal-payment__textos__p'>
                Confirme a reserva fazendo o pagamento por <strong>PIX</strong> ou <strong>Cartão de
                Crédito</strong>.<br />
              </p>
              <h6>
                Caso a reserva não seja confirmada em até <Countdown
                expirationTime={data?.expiration_time ?? expirationTime} /> a
                consulta será cancelada
                automaticamente.
              </h6>
            </div>
          </div>
        }
        <div className='div-pay-modal'>
          <div className='div-pay-modal__div-btns'>
            <div
              className={active ? 'div-pay-modal__div-btns__btn ativo pixbtn' : 'div-pay-modal__div-btns__btn pixbtn'}
              onClick={() => setActive(true)}>
              <i className='fas fa-qrcode div-pay-modal__div-btns__icon' />
              <p>PIX</p>
            </div>
            <div
              className={!active ? 'div-pay-modal__div-btns__btn ativo cardbtn' : 'div-pay-modal__div-btns__btn cardbtn'}
              onClick={() => setActive(false)}>
              <i className='far fa-credit-card div-pay-modal__div-btns__icon' />
              <p>CARTÃO DE CRÉDITO</p>
            </div>
          </div>
          {active ?
            <div className='div-pay-modal__div-card-qrcod'>
              <p className='div-pay-modal__div-card-qrcod__p-descricao'>Leia o <strong>QR CODE</strong></p>
              <div className='div-pay-modal__div-card-qrcod__div-img-qrcode'>
                {pixLoading ? (
                    <Skeleton width='250px' height='250px' />
                  ) :
                  (
                    <QRCode
                      className='div-pay-modal__div-card-qrcod__div-img-qrcode__img-qrcode'
                      value={pixKey}
                      size={250}
                      level={'H'}
                      renderAs={'svg'}
                      imageSettings={{
                        src: Logo,
                        x: null,
                        y: null,
                        height: 48,
                        width: 48,
                        excavate: true,
                      }}
                    />
                  )
                }
              </div>
              <div className='div-pay-modal__div-card-qrcod__p-descricao my-1' onClick={()=>copyToClipboard(pixKey)}>
                {pixLoading ? (
                    <p className='div-pay-modal__div-card-qrcod__p-descricao text-center my-0'>Carregando PIX, aguarde...
                    </p>
                  ) :
                  (
                    <p className='div-pay-modal__div-card-qrcod__p-descricao text-center my-0'>ou
                      <span className='div-pay-modal__div-card-qrcod__p-descricao__link'> clique aqui </span> para usar
                      o <br />
                      <strong>PIX Copia e Cola</strong>
                    </p>
                  )}
              </div>
            </div>
            :
            <div className='div-pay-modal__div-card-qrcod'>
              <div className='card-order'>
                <CreditCard
                  loading={loading}
                  action={sendPay}
                  addresses={session.addresses[0]}
                />
              </div>
              <div className='mt-3'>
                <p className='color-powered'>
                  <strong><i className='fas fa-lock' /> AMBIENTE SEGURO </strong>
                  powered by pagar.me e stone co.
                </p>
              </div>
            </div>
          }
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          type='button'
          onClick={() => handleClose(false)}
          className='primary'
        >
          FECHAR
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

