import React from 'react'
import NumberFormat from 'react-number-format'

import PatientImg from '~/assets/images/physician-default.png'
import { RenderImg } from '~/components/RenderImg/RenderImg'

export default function Patient({ data, ...rest }) {
  const profile = data.profile

  return (
    <div className='container admin_patients_profile_card' {...rest}>
      <div className='row'>
        <div className='col-lg-2 col-12 col-sm-12 col-md-12'>
          <div className="admin_patients_profile_card_avatar">
            {profile.avatar ? (
              <RenderImg
                photo_id={profile?.avatar?.id}
                photo_uuid={profile?.avatar?.uuidUrl}
                isPrivate={profile?.avatar.private}
                alt="Avatar"
              />
            ) : (
              <img src={PatientImg} alt="Avatar" />
            )}
          </div>
        </div>
        <div className='col-lg-10 col-12 col-sm-12 col-md-12'>
          <div className="admin_patients_profile_card_desc">

              <h5>
                {profile?.full_name}
              </h5>
              {data.ward?.length ? <span className=''>Responsável</span> : ''}
            <div>
              <p>{data.email}</p>
            </div>

              <p>
                <NumberFormat
                  value={profile?.phone_number}
                  displayType={'text'}
                  format="(##) #####-####"
                />{' '}
                <NumberFormat
                  value={profile?.mobile_number}
                  displayType={'text'}
                  format="(##) #####-####"
                />
              </p>

          </div>
        </div>
      </div>


    </div>
  )
}
