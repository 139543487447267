import React from 'react'
import { Redirect, Switch } from 'react-router-dom'
import Route from './Route'

import Home from '~/pages/Physician/Home'
import Profile from '~/pages/Physician/Profile/Show'
import ProfileEdit from '~/pages/Physician/Profile/Edit'
import PhysicianEdit from '~/pages/Physician/Profile/EditPhysician'
import Patients from '~/pages/Physician/Patients'
import Patient from '~/pages/Physician/Patient'
import Prescriptions from '~/pages/Physician/Prescriptions'
import Articles from '~/pages/Physician/Articles'
import { PhysicianConfigurationCalendar } from '~/pages/telemed/PhysicianConfigurationCalendar'
import { PhysicianCalendar } from '~/pages/telemed/PhysicianCalendar'
import { HelmetPrivate } from '~/components/HelmetPrivate/HelmetPrivate'

export default function Routes() {
  if (localStorage.getItem('roles') !== 'physician') {
    return <Redirect to='/' />
  }

  return (
    <>
      <HelmetPrivate routeStr='Médico' />

      <Switch>
        <Route path='/medico' exact component={Home} isPrivate redirectTo='/' />
        <Route path='/medico/telemed' exact component={PhysicianConfigurationCalendar} />
        <Route path='/medico/telemed/consultas' exact component={PhysicianCalendar} />
        <Route
          path='/medico/perfil'
          exact
          component={Profile}
          isPrivate
          redirectTo='/login'
        />
        <Route
          path='/medico/perfil/editar'
          exact
          component={ProfileEdit}
          isPrivate
          redirectTo='/login'
        />
        <Route
          path='/medico/perfil/medico/editar'
          exact
          component={PhysicianEdit}
          isPrivate
          redirectTo='/login'
        />
        <Route
          path='/medico/pacientes'
          exact
          component={Patients}
          isPrivate
          redirectTo='/login'
        />
        <Route
          path='/medico/pacientes/:id'
          exact
          component={Patient}
          isPrivate
          redirectTo='/login'
        />
        <Route
          path='/medico/prescricao'
          exact
          component={Prescriptions}
          isPrivate
          redirectTo='/login'
        />
        <Route
          path='/medico/artigos'
          exact
          component={Articles}
          isPrivate
          redirectTo='/login'
        />
      </Switch>
    </>
  )
}
