import React from 'react'

import { SectionPatient } from './styles'
import { Container, Row, Col } from 'react-bootstrap'

import SideMenu from '~/components/SideMenu'
import PatientRoutes from '~/routes/Patient'
import ScrollToTop from '~/components/ScrollToTop'


export default function Patient() {
  return (
    <SectionPatient>
      <ScrollToTop />
      <Container fluid className="p-0">
        <Row className="m-0">
          <Col md={3} className="d-none d-md-block p-0 admin_menu">
            <SideMenu />
          </Col>
          <Col xs={12} md={9} className="mb-5 mb-md-0 p-0 admin_content_full">
            <PatientRoutes />
          </Col>
        </Row>
      </Container>
    </SectionPatient>
  )
}
