import React, { useEffect, useState } from 'react'

import { Alert, Col, Container, Row } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import * as Yup from 'yup'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'

import api from '~/services/api'
import { Creators as AuthActions } from '~/store/ducks/auth'
import { Creators as ErrorActions } from '~/store/ducks/error'

import Button from '~/components/Button'
import { Section } from './styles'
import { Checkbox, Input, Radio, Select } from '~/components/Form'
import StatesBrasil from '~/helpers/StatesBrasil.json'

import Ok from '~/assets/icons/check.svg'
import Block from '~/assets/icons/quadra.png'
import ShowPassword from '~/assets/icons/olho.png'
import OcultPassword from '~/assets/icons/escondido.png'
import { showValidatePassword } from '~/shared/utils'
import TagManager from 'react-gtm-module'

const SignupSchema = Yup.object().shape({
  user: Yup.object().shape({
    email: Yup.string()
      .email('Digite um email válido')
      .required('Digite um email'),
    password: Yup.string()
      .min(4, 'A senha parece meio curta...')
      .required('Digite uma senha')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
        'Deve conter 8 caracteres, uma maiúscula, uma minúscula e um número',
      ),
  }),
  profile: Yup.object().shape({
    full_name: Yup.string().required('Digite seu nome.'),
  }),
  passwordConfirm: Yup.string()
    .test('passwords-match', 'As senhas não batem', function(value) {
      return this.parent.user.password === value
    })
    .required('Digite uma senha')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
      'Deve conter 8 caracteres, uma maiúscula, uma minúscula e um número',
    ),
  termsOfService: Yup.boolean()
    .required('The terms and conditions must be accepted.')
    .oneOf([true], 'The terms and conditions must be accepted.'),
})

export default function Signup(props) {
  const { register, handleSubmit, errors, getValues, setValue } = useForm({
    validationSchema: SignupSchema,
  })
  const error = useSelector(state => state.error)
  const dispatch = useDispatch()
  const [role, setRole] = useState('patient')
  const [loading, setLoading] = useState(false)
  const location = useLocation()
  const params = new URLSearchParams(location.search)

  const [visibilityPassword, setVisibilityPassword] = useState(false)
  const [repeatPassword, setRepeatPassword] = useState('')
  const [password, setPassword] = useState('')
  const [isResponsible, setIsResponsible] = useState(false)

  useEffect(() => {
    if (props.location?.hash) {
      setValue('user.email', props.location.hash.substr(1))
    }
  }, [props.location.hash])

  useEffect(() => {
    if (params.get('r') == 'medico') {
      setRole('physician')
    } else {
      setRole('patient')
    }
  }, [])

  const onSubmit = async data => {
    try {
      setLoading(true)
      data.role = data.user.isResponsible === 'true' ? 'responsible' : role
      const res = await api.post(`/users`, data)

      dispatch(
        AuthActions.storeSessionRequest({
          email: data.user.email,
          password: data.user.password,
        }),
      )

      const tagManagerArgs = {
        gtmId: 'GTM-59529VR',
        dataLayer: {
          event: 'sign_up',
          userId: res.data.id,
          role: data.role,
          name: res.data.profile.full_name,
          isRegisteredUser: true,
        },
      }
      TagManager.dataLayer(tagManagerArgs)
      setLoading(false)
    } catch (e) {
      dispatch(ErrorActions.setError(e.response.data.errors))
      setLoading(false)
    }
  }

  const onChangeForm = async e => {
    switch (e.target.name) {
      case 'passwordConfirm':
        return setRepeatPassword(e.target.value)
      case 'user.password':
        return setPassword(e.target.value)
      default:
        break
    }
  }

  return (
    <Section>
      <Container>
        <Row>
          <Col>
            <h1 className='text-center mb-4'>Cadastro</h1>
          </Col>
        </Row>
        <hr className='mb-5' />
        <Row className='justify-content-md-center'>
          <Col xs={12}>
            <div className='d-flex justify-content-center'>
              <Button
                onClick={() => setRole('patient')}
                className={`md-lg radius left ${
                  role === 'patient' ? 'blue' : 'outline-blue'
                }`}
              >
                Paciente
              </Button>
              <Button
                onClick={() => setRole('physician')}
                className={`md-lg radius right ${
                  role === 'physician' ? 'blue' : 'outline-blue'
                }`}
              >
                Médico
              </Button>
            </div>
          </Col>
          <Col lg={5} className='mb-5'>
            {error.body?.message && (
              <Alert className='my-3' variant='danger'>
                {error.body?.message}
              </Alert>
            )}
            <form
              className='my-3'
              onSubmit={handleSubmit(onSubmit)}
              onChange={e => onChangeForm(e)}
            >
              <Row>
                <Col>
                  <Input
                    type='text'
                    label='Nome'
                    name='profile.full_name'
                    placeholder='Nome'
                    size='md'
                    fluid
                    className='my-3'
                    error={errors.profile?.full_name}
                    ref={register}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Input
                    type='text'
                    label='Email'
                    name='user.email'
                    placeholder='Email'
                    size='md'
                    fluid
                    className='my-3'
                    error={errors.user?.email}
                    ref={register}
                  />
                </Col>
              </Row>
              {role === 'physician' && (
                <Row>
                  <Col>
                    <Input
                      type='text'
                      label='CRM'
                      name='physician.crm'
                      placeholder='000000'
                      size='md'
                      fluid
                      className='my-3'
                      error={errors.physician?.crm}
                      ref={register}
                    />
                  </Col>
                  <Col>
                    <Select
                      type='text'
                      label='UF'
                      name='physician.uf'
                      size='md'
                      icon='icon-chevron-down'
                      className='my-3'
                      error={errors.physician?.uf}
                      ref={register}
                      fluid
                    >
                      {StatesBrasil.map(state => (
                        <option value={state.value}>{state.value}</option>
                      ))}
                    </Select>
                  </Col>
                </Row>
              )}
              <Row>
                <Col>
                  <Input
                    type={visibilityPassword ? 'text' : 'password'}
                    label='Senha'
                    name='user.password'
                    placeholder='Senha'
                    size='md'
                    fluid
                    className='my-3'
                    error={errors.user?.password}
                    ref={register}
                  />
                </Col>
              </Row>
              <Row>
                <Col className='d-flex justify-content-between'>
                  <Input
                    type={visibilityPassword ? 'text' : 'password'}
                    label='Confirme a senha'
                    name='passwordConfirm'
                    placeholder='Confirme a senha'
                    size='md'
                    fluid
                    className='my-3 w-100'
                    error={errors.passwordConfirm}
                    ref={register}
                  />
                  <button
                    className='border-0 bg-transparent'
                    type='button'
                    data-toggle='tooltip'
                    title={
                      visibilityPassword ? 'Ocultar senha' : 'Mostrar senha'
                    }
                    onClick={() => setVisibilityPassword(!visibilityPassword)}
                  >
                    <img
                      src={visibilityPassword ? OcultPassword : ShowPassword}
                      alt=''
                      className='mt-4 pl-2'
                      style={{ height: '40px' }}
                    />
                  </button>
                </Col>
              </Row>
              {role === 'patient' && (
                <Row className={'my-3'}>
                  <Col xs={12}>
                    <Radio
                      name='user.isResponsible'
                      ref={register}
                      value={false}
                      onClick={() => setIsResponsible(false)}
                      checked={!isResponsible}
                      label='Busco tratamento para mim' />
                    <Radio
                      name='user.isResponsible'
                      ref={register}
                      value={true}
                      onClick={() => setIsResponsible(true)}
                      checked={isResponsible}
                      label='Sou responsável por um paciente' />
                  </Col>
                </Row>
              )}
              <Row className={'my-3'}>
                <Col xs={12}>
                  <Checkbox
                    className='my-3'
                    name='termsOfService'
                    label={
                      <Link
                        to='/termos'
                        className='text-primary'
                        target='_blank'
                        rel='noopener'
                      >
                        Aceito os termos e condições
                      </Link>
                    }
                    ref={register}
                    error={errors.termsOfService}
                  />
                </Col>
              </Row>
              <Row>
                <Col className='my-3'>
                  <div>
                    {password &&
                    showValidatePassword(password, repeatPassword, Ok, Block)}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col className='my-3'>
                  <Button
                    className='primary right md'
                    type='submit'
                    disabled={loading}
                  >
                    Enviar
                  </Button>
                </Col>
              </Row>
            </form>
          </Col>
        </Row>
      </Container>
    </Section>
  )
}
