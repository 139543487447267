import React from 'react'
import { Helmet } from 'react-helmet'


const HelmetPrivate = ({routeStr}) => {

  const title = `Dr. Cannabis - ${routeStr}`
  const description = 'Encontre tratamento com cannabis medicinal! A Dr. Cannabis é a sua aliada: encontre médicos, conteúdo e todo o acolhimento que precisa.'

  return (
    <Helmet>
      <title>{title}</title>
      <meta property='og:title' content={title} />
      <meta name='twitter:title' content={title} />
      <meta
        name='description'
        content={description}
      />
      <meta
        itemProp='description'
        content={description}
      />
      <meta
        name='og:description'
        content={description}
      />
      <meta
        name='twitter:description'
        content={description}
      />
    </Helmet>
  )
}
export { HelmetPrivate }
