import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Pagination from 'react-js-pagination'
import { useForm } from 'react-hook-form'
import Patient from './PatientCard'
import api from '~/services/api'
import { ButtonPrimary, CustomInput } from '~/components'
import Skeleton from 'react-loading-skeleton'

export default function Patients() {
  const [patients, setPatients] = useState({ data: [] })
  const [loading, setLoading] = useState(false)
  const [searchParam, setSearchParam] = useState('')
  const { register, watch } = useForm()
  const search = watch('search')

  useEffect(() => {
    setSearchParam(search)
  }, [search])

  const getPatients = async (page, searchParam = null) => {
    let params = ''
    if (page) params += `page=${page || 1}&`
    if (searchParam) params += `search=${encodeURIComponent(searchParam)}`

    setLoading(true)
    const res = await api.get(
      `/patients?${params}`,
    )
    setPatients(res.data)
    setLoading(false)
  }

  const handlePageChange = page => {
    window.scrollTo(0, 0)
    getPatients(page, searchParam)
  }
  return (
    <div className='container-fluid admin_patient'>
      <div className='row'>
        <div className='col-lg-12'>
          <h2 className='admin_patient_title'>Pacientes</h2>
        </div>
      </div>

      <div className='row'>
        <div className='col-lg-12 my-3'>
          <div className='row'>
            <div className='col-lg-4'>
              <CustomInput
                inputPlaceholder='Nome, Email ou CPF'
                inputName='search'
                register={register}
                icon='far fa-search'
              />
            </div>
            <div className='col-lg-4'>
              <ButtonPrimary
                text='Buscar'
                callBack={() => getPatients('', search)}
                btnMt=''
                btnClass='admin_patient_search_button'
                disabled={loading}
                loading={loading}
              />
            </div>
          </div>

        </div>
      </div>

      <div className='row'>
        {loading && (
          <>
            <div className='col-lg-6 admin_patient_skeleton'>
              <div className='row'>
                <div className='col-lg-2 col-12 admin_patient_skeleton_avatar'>
                  <Skeleton circle={true} width={90} height={90} />
                </div>
                <div className='col-lg-10 text-center'>
                  <Skeleton className='mt-2' width='90%' />
                  <Skeleton className='mt-2' width='90%' />
                  <Skeleton className='mt-2' width='90%' />
                </div>
              </div>
            </div>
            <div className='col-lg-6  admin_patient_skeleton'>
              <div className='row'>
                <div className='col-lg-2 col-12 admin_patient_skeleton_avatar'>
                  <Skeleton circle={true} width={90} height={90} />
                </div>
                <div className='col-lg-10 text-center'>
                  <Skeleton className='mt-2' width='90%' />
                  <Skeleton className='mt-2' width='90%' />
                  <Skeleton className='mt-2' width='90%' />
                </div>
              </div>
            </div>
          </>
        )}
        {!loading &&
        patients.data.map(patient => (
          <div className='col-lg-6 col-sm-12 col-md-12 my-3' key={patient.id}>
            <Link to={`/admin/pacientes/${patient.id}`}>
              <Patient data={patient} />
            </Link>
          </div>
        ))}
      </div>

      <div className='row'>
        <div className='col-lg-12 d-flex justify-content-center mt-3'>
          <Pagination
            activePage={patients.page}
            itemsCountPerPage={patients.perPage}
            totalItemsCount={patients.total}
            pageRangeDisplayed={5}
            onChange={handlePageChange}
            itemClass='page-item'
            linkClass='page-link'
          />
        </div>
      </div>

    </div>
  )
}
