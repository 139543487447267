import React from "react";
import { Container } from './styles'

const WrapperWhatsapp = () => {
    const isMobile = false;
    return (
    <Container isMobile={isMobile}>
      <a
        href='https://whts.co/SiteDrc'
        target="__blank"
      >
        <i class="fab fa-whatsapp fa-2x" />
      </a>
    </Container>    
    )
}

export { WrapperWhatsapp }