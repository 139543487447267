import React, { useEffect, useState } from 'react'
import { createCoupon, fetchBrands, fetchMedicines, updateCoupon } from '~/services/requests'
import { toast } from 'react-toastify'
import * as $ from 'jquery'
import { CustomCheckbox, CustomInput, CustomSelect, CustomTextArea } from '~/components'


const ModalNewCoupon = ({ listCampaigns, defaultValues =
    {
      name: '',
      description: '',
      active: false,
      slug: '',
      discount: '',
      expiration: '',
      quantity: '',
      campaign_id: '',
      medicine_id: '',
      brand: '',
      isMedicine: false
    },
    isPut = false, }
) => {
  const [form, setForm] = useState(defaultValues)
  const [brands, setBrands] = useState([])
  const [medicines, setMedicines] = useState([])


  useEffect(() => {
    if (isPut)
      setForm(defaultValues)
  }, [defaultValues])

  useEffect(() => {
    fetchBrands().then(r => setBrands(r.data))
    fetchMedicines(form.brand).then(r => setMedicines(r.data.data))
  }, [form.brand])

  const submitForm = () => {
    if (isPut) {
      updateCoupon(form)
        .then(() => {
          toast.success('Cupom atualizado!')
          setForm(defaultValues)
          $('#couponModal').modal('hide')
          window.location.reload()
        })
        .catch(() => toast.error('Ocorreu um erro ao atualizar o cupom'))
    } else {
      createCoupon(form)
        .then(() => {
          toast.success('Um novo cupom foi criado')
          setForm(defaultValues)
          $('#couponModal').modal('hide')
        })
        .catch(() => toast.error('Ocorreu um erro ao criar o cupom'))
    }
  }

  const changeOptionsForm = (e) => {
    const { value, checked, type, name } = e.currentTarget
    setForm(prevState => {
      return {
        ...prevState,
        [name]: type === 'checkbox' ? checked : value,
      }
    })
  }

  return (
    <div className='modal fade'
         id='couponModal'
         tabIndex='-1'
         role='dialog'
         aria-labelledby='couponModalLabel'
         aria-hidden='true'
    >
      <div className='modal-dialog' role='document'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h5 className='modal-title' id='couponModalLabel'>
              {isPut ?
                'Atualizar cupom'
                :
                'Novo Cupom'
              }
            </h5>
            <button
              type='button'
              className='close'
              data-dismiss='modal'
              aria-label='Close'
              onClick={() => setForm(defaultValues)}
            >
              <span aria-hidden='true'>&times;</span>
            </button>
          </div>
          <div className='modal-body'>
            <div className='row'>
              <div className='col-8'>
                <CustomInput label='Nome'
                             inputName='name'
                             defaultValue={form.name}
                             callBack={changeOptionsForm}
                />
              </div>
              <div className='col-4'>
                <CustomCheckbox label='Ativo'
                                inputName='active'
                                inputChecked={form.active}
                                callBack={changeOptionsForm}
                                inputClass='my-4'
                />
              </div>
            </div>
            <div className="row my-2">
              <div className='col-12'>
                <CustomCheckbox label='Aplicar cupom à um produto ?'
                                inputName='isMedicine'
                                inputChecked={form.isMedicine}
                                callBack={changeOptionsForm}
                />
              </div>
            </div>
            {form.isMedicine && (
              <>
                <div className='row'>
                  <div className='col-12'>
                    <CustomSelect label='Marca'
                                  inputName='brand'
                                  defaultValue={form.brand}
                                  children={brands.map(val => (
                                    <option key={val.slug}
                                            value={val.slug}
                                    >
                                      {val.name}
                                    </option>
                                  ))}
                                  callBack={changeOptionsForm}
                    >

                    </CustomSelect>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-12'>
                    <CustomSelect label='Medicamento'
                                  inputName='medicine_id'
                                  defaultValue={form.medicine_id}
                                  children={medicines?.map(val => (
                                    <option key={val.id}
                                            value={val.id}
                                    >
                                      {val.name}
                                    </option>
                                  ))}
                                  callBack={changeOptionsForm}
                    >
                    </CustomSelect>
                  </div>
                </div>
              </>
            )}
            {!isPut && (
              <div className='row'>
                <div className='col-12'>
                  <CustomSelect label='Campanha'
                                focus={false}
                                inputName='campaign_id'
                                children={listCampaigns.map(val => (
                                  <option key={val.id}
                                          value={val.id}
                                  >
                                    {val.name}
                                  </option>
                                ))}
                                callBack={changeOptionsForm}
                  />
                </div>
              </div>
            )}

            <div className='row'>
              <div className='col-3'>
                <CustomInput label='Desconto'
                             inputName='discount'
                             inputType='number'
                             defaultValue={form.discount}
                             callBack={changeOptionsForm}
                />
              </div>
              <div className='col-3'>
                <CustomInput label='Quantidade'
                             inputName='quantity'
                             inputType='number'
                             defaultValue={form.quantity}
                             callBack={changeOptionsForm}
                />
              </div>
              <div className='col-6'>
                <CustomInput label='Data de expiração'
                             inputName='expiration'
                             inputType='date'
                             defaultValue={form.expiration}
                             callBack={changeOptionsForm}
                />
              </div>
            </div>
            <div className='row'>
              <div className='col-12'>
                <CustomTextArea label='Descrição'
                                inputName='description'
                                defaultValue={form.description}
                                callBack={changeOptionsForm}
                />
              </div>
            </div>
          </div>
          <div className='modal-footer'>
            <button
              type='button'
              className='btn btn-secondary'
              data-dismiss='modal'
              onClick={() => setForm(defaultValues)}
            >
              Cancelar
            </button>
            <button
              type='button'
              className='btn btn-primary'
              onClick={() => submitForm()}
            >
              {isPut ? 'Atualizar' : 'Cadastrar'}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export { ModalNewCoupon }
