import React, { useEffect, useState } from 'react'
import 'moment/locale/pt-br'
import { getPhysiciansAppointments } from '~/services/requests'
import moment from 'moment'
import Skeleton from 'react-loading-skeleton'
import { AppointmentCard } from '~/components/AppointmentCard/AppointmentCard'
import { ModalConsultationDetails } from '~/pages/telemed/ModalConsultationDetails'
import { showAppointmentDetail, treatmentListConsultation } from '~/shared/telemedOptions'
import { ButtonSecondary } from '~/components'
import { ModalNewConsultation } from '~/pages/telemed/ModalNewConsultation'
import * as $ from 'jquery'
import { DatePicker } from '~/components/DatePicker/DatePicker'


const PhysicianCalendar = () => {
  const [date, setDate] = useState(moment().format('YYYY-MM-DD'))
  const [appointments, setAppointments] = useState()
  const [appointmentLength, setAppointmentLength] = useState('')
  const [calendarOptions, setCalendarOptions] = useState({
    start: '00:00',
    end: '23:59',
  })
  const [showPayment, setShowPayment] = useState(false)
  const [event, setSelectedEvent] = useState({})
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(true)
    getPhysiciansAppointments(moment().format(date), '2100-12-31')
      .then(r => {
        if (!Array.isArray(r.data.appointments))
          setAppointments(Object.entries(r.data.appointments))
        setAppointmentLength(r.data.appointment_length)
        setCalendarOptions(r.data.calendar)
      })
      .finally(() => setLoading(false))
  }, [date])


  const newConsultation = () => {
    $('#newConsultation').modal('show')
  }

  return (
    <>
      <div className='container-fluid my-agenda mb-5'>
        <div className='row'>
          <div className='col-lg-12 d-flex align-items-center justify-content-between'>
            <h1 className='page-title'>Minhas consultas</h1>
            <DatePicker callBack={setDate}
                        returnFormat='YYYY-MM-DD'
                        showMessage={false}
            />
            <ButtonSecondary
              text='Nova consulta'
              btnClass='my-3 my-agenda__configure-shifts d-none'
              icon={<i className='far fa-plus' />}
              callBack={() => newConsultation()}
            />
          </div>
        </div>

        {loading && (
          <div className='row'>
            <div className='col-6'>
              <Skeleton className='mt-2' count={5} />
            </div>
            <div className='col-6'>
              <Skeleton className='mt-2' count={5} />
            </div>
          </div>
        )}

        {Array.isArray(appointments) && !loading && (
          <div className='row my-2'>
            {appointments.map(appointment => (
              <>
                <div className='col-12 mt-2'>
                  <h4>{moment(appointment[0]).format('DD/MM/YYYY')}</h4>
                </div>
                {Array.isArray(appointment[1]) && appointment[1].map((value) => (
                  <AppointmentCard appointment={value}
                                   callback={() => showAppointmentDetail(value, setSelectedEvent, setShowPayment, true)}
                                   treatment={treatmentListConsultation}
                                   isPhysician={true}
                  />
                ))}
              </>
            ))}
          </div>
        )}


        {!Array.isArray(appointments) && !loading && (
          <div className='row my-2'>
            <div className='col-12 mt-2 d-flex justify-content-center'>
              <h4>Nenhuma consulta agendada</h4>
            </div>
          </div>
        )}


      </div>
      <ModalConsultationDetails event={event} isPhysician={true} />
      <ModalNewConsultation appointmentLength={appointmentLength}
                            minTime={calendarOptions.start}
                            maxTime={calendarOptions.end}
                            isAdmin={false}
      />
    </>
  )
}

export { PhysicianCalendar }
