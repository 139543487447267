import React from 'react'
import { Redirect, Switch } from 'react-router-dom'
import Route from './Route'

import Home from '~/pages/Home'
import Login from '~/pages/Login'
import Signup from '~/pages/Signup'
import Logout from '~/components/Logout'
import About from '~/pages/About'
import Faq from '~/pages/Faq'
import BuyCBD from '~/pages/BuyCBD'
import Patient from '~/pages/Patient'
import Physician from '~/pages/Physician'
import Admin from '~/pages/Admin'
import RedirectDashboard from '~/components/RedirectDashboard'
import Terms from '~/pages/Terms'
import TemplateAnvisa from '~/pages/Templates/Anvisa'
import TemplatePrescription from '~/pages/Templates/Prescription'
import Brand from '~/pages/Brand'
import ChangePassword from '~/pages/ChangePassword/index'
import Products from '~/pages/Products'
import Product from '~/pages/Product'
import { BlogRedirect } from '~/pages/BlogRedirect'
import PhysicianProfilePublic from '~/pages/PhysicianPublicProfile/PhysicianProfilePublic'
import { Vote } from '~/pages/Vote/Vote'
import { ConfirmVote } from '~/pages/Vote/ConfirmVote'
import Representatives from '~/pages/Representatives'

export default function Routes() {
  return (
    <Switch>
      <Route path='/' exact component={Home} />
      <Route path='/sobre' component={About} />
      <Route path='/faq' component={Faq} />
      <Route path='/comprar-cbd' component={BuyCBD} />
      <Route path='/cadastro' component={Signup} isGuest redirectTo='/' />
      <Route path='/login/:forced?' component={Login} isGuest redirectTo='/' />
      <Route path='/produtos' exact component={Products} />
      <Route path='/produtos/:id' exact component={Product} />
      <Route path='/admin' component={Admin} isPrivate redirectTo='/login' />
      <Route path='/representantes' component={Representatives} isPrivate redirectTo='/login' />
      <Route path='/dashboard' component={RedirectDashboard} />
      <Route path='/logout' component={Logout} />
      <Route path='/termos' component={Terms} />
      <Route path='/marca/:id' component={Brand} />
      <Route path='/perfil/:slug' component={PhysicianProfilePublic} />
      <Route path='/premio2021' exact component={Vote} />
      <Route path='/premio2021/confirmar/:uuid' exact component={ConfirmVote} />
      <Route path='/premio2021/confirmar' exact component={ConfirmVote} />
      <Route
        path='/trocar-senha'
        component={ChangePassword}
        isGuest
        redirectTo='/'
      />
      <Route
        path='/paciente'
        component={Patient}
        isPrivate
        redirectTo='/login'
      />
      <Route
        path='/medico'
        component={Physician}
        isPrivate
        redirectTo='/login'
      />
      <Route
        path='/template/anvisa/:id'
        component={TemplateAnvisa}
        exact
        isPrivate
        redirectTo='/login'
      />
      <Route
        path='/template/prescricao/:id'
        component={TemplatePrescription}
        exact
        isPrivate
        redirectTo='/login'
      />
      <Route
        path='/summit'
        exact
        component={() =>
          window.location.assign(
            'https://drcacademy.com.br/summit/cannabusiness-2021-pt/',
          )
        }
      />
      <Route path='/blog/:id' exact component={BlogRedirect} />

      <Route component={() => <Redirect to='/' />} />
    </Switch>
  )
}
