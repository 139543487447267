import React from "react"
import Skeleton from 'react-loading-skeleton'


const CustomTextArea = (
  {
    inputName = '',
    label,
    error,
    inputClass = '',
    register,
    inputPlaceholder,
    inputRows = '5',
    defaultValue,
    callBack,
    loading=false,
    skeletonDivClass='',
    maxLength
  })=>{
  return(
    <>
      {
        loading ?
          (
            <div className={skeletonDivClass}>
              <Skeleton width="25%"/>
              <Skeleton className='mt-2' height={150}/>
            </div>
          ):
          (
            <div className='form-group custom__text__area'>
              <label className='w-100' htmlFor="">
                {label}
                <textarea
                  className={`form-control ${inputClass} ${error ?  'error' : ''}`}
                  name={inputName}
                  ref={register}
                  placeholder={inputPlaceholder}
                  rows={inputRows}
                  value={defaultValue}
                  onChange={callBack}
                  maxLength={maxLength ?? ''}
                />
                {error && <span className="error">{error.message}</span>}
              </label>
            </div>
          )
      }

    </>
  )
}

export  {CustomTextArea}
