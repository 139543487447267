import React, { useEffect, useRef, useState } from 'react'
import moment from 'moment'

const Countdown = ({ expirationTime }) => {
  const [num, setNum] = useState(14400)

  useEffect(() => {
    if (expirationTime) {
      const now = moment().local()
      const expiration = moment.utc(expirationTime)
      const duration = moment.duration(expiration.diff(now))
      setNum(duration.asSeconds())
    }
  }, [expirationTime])

  let intervalRef = useRef()

  const decreaseNum = () => setNum((prev) => prev - 1)

  useEffect(() => {
    intervalRef.current = setInterval(decreaseNum, 1000)
    return () => clearInterval(intervalRef.current)
  }, [])

  const formatTime = (s) => {
    const date = new Date(null)
    date.setSeconds(s)
    return date.toISOString().substr(11, 8)
  }


  return (
    <span className='text--rb'>{formatTime(num)}</span>
  )
}

export { Countdown }
