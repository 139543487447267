import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { Alert } from 'react-bootstrap'

import Thumb from '~/components/Thumb'
import { ButtonPrimary } from '~/components/Buttons/PrimaryButton'
import ModalPayment from '~/components/ModalPayment'
import { formatValue } from '~/shared/formatAndMasks'

export default function Order({ data, refresh, ...rest }) {
  const [status, setStatus] = useState(data?.status ?? 'new')
  const [orderStep, setOrderStep] = useState(0)
  const [modalPayment, setModalPayment] = useState(false)
  const [idModalPayment, setIdModalPayment] = useState()


  useEffect(() => {
    switch (status) {
      case 'WAITING_FOR_PAYMENT':
        setOrderStep(2)
        break
      case 'PURCHASED':
      case 'PAYMENT_ACCEPTED':
        setOrderStep(2)
        break
      case 'IN_TRANSIT':
        setOrderStep(3)
        break
      case 'COMPLETE':
        setOrderStep(4)
        break
      case 'CANCELLED':
        setOrderStep(0)
        break
      default:
        setOrderStep(1)
        break
    }
  }, [status])


  const cancellableOrderStatus = [
    'PENDING_VALIDATION',
    'VALIDATION_FAILED',
    'VALIDATION_SUCCEEDED',
    'WAITING_FOR_PAYMENT',
    'PROCESSING_PAYMENT',
  ]

  const modalClose = async () => {
    await refresh()
  }
  const shipping = data.shipping * 1
  const orderTotal = data.medicines.reduce(
    (acc, medicine) => {
      return acc + (medicine.price * medicine.pivot.quantity)
    },
    shipping,
  )

  function handleClose(value) {
    setModalPayment(value)
    modalClose()
  }

  function openModal(id) {
    setModalPayment(true)
    setIdModalPayment(id)
  }

  return (
    <>
      <div className='container-fluid orders__order' {...rest}>

        <div className='row orders__order__box mt-4'>
          <div className='orders__order__header w-100 px-3 pb-2 d-flex justify-content-between'>
            <h2>
              Pedido realizado: {moment(data.created_at).format('DD/MM/YY')}
            </h2>
            <h2>Pedido #{data.id}</h2>
          </div>
          {data.status === 'new' && (
            <Alert variant='success mx-3'>
              Estamos analisando a sua documentação, assim que estiver revisada
              pela equipe Dr. Cannabis você receberá um email com as orientações
              de pagamento.
            </Alert>
          )}
          {data.medicines.map(medicine => (
            <div className='col-lg-12  d-flex mt-2 orders__order__content'>
              <div className='d-flex orders__order__product__image'>
                <Thumb className='lg orders__order__product__image__thumb'>
                  <div className='inner'>
                    <img src={medicine?.photo?.url} alt='' />
                  </div>
                </Thumb>
              </div>
              <div className='orders__order__product__info'>
                <div className='orders__order__product__info__name'>
                  <h4 className=''>{medicine?.name}</h4>
                  <p>Vendido por: {medicine?.brand.name}</p>
                </div>
                <div className='orders__order__product__info__quantity d-flex justify-content-between'>
                  <div className=''>
                    <p className=''>
                      {medicine?.pivot.quantity} Unidade(s)
                    </p>
                  </div>
                  <div className='text-center'>
                    {formatValue(medicine.price, medicine.currency)}
                  </div>
                  <div className=''>
                    {formatValue(medicine.price * medicine.pivot.quantity, medicine.currency)}
                  </div>
                </div>
                <div className='row mt-5'>
                  <div
                    className='col-lg-4 offset-lg-8 orders__order__product__info__shipping font-weight-normal  mb-2 d-flex justify-content-between'>
                    <h6>SubTotal</h6>
                    <span>
                      {formatValue(data.subtotal,
                        medicine.shipping_currency ? medicine.shipping_currency : medicine.brand.shipping_currency,
                      )}
                    </span>
                  </div>
                </div>
                {data.discount?.product > 0 && (
                  <div className='row mt-2'>
                    <div
                      className='col-lg-4 offset-lg-8 orders__order__product__info__shipping font-weight-normal  mb-2 d-flex justify-content-between'>
                      <h6>Desconto no produto</h6>
                      <span>
                      {formatValue(-data.discount.product,
                        medicine.shipping_currency ? medicine.shipping_currency : medicine.brand.shipping_currency,
                      )}
                    </span>
                    </div>
                  </div>
                )}
                <div className='row mt-2'>
                  <div
                    className='col-lg-4 offset-lg-8 orders__order__product__info__shipping font-weight-normal  mb-2 d-flex justify-content-between'>
                    <h6>Frete</h6>
                    <span>
                      {formatValue(shipping,
                        medicine.shipping_currency ? medicine.shipping_currency : medicine.brand.shipping_currency,
                      )}
                    </span>
                  </div>
                </div>
                {data.discount?.shipping > 0 && (
                  <div className='row mt-2'>
                    <div
                      className='col-lg-4 offset-lg-8 orders__order__product__info__shipping font-weight-normal  mb-2 d-flex justify-content-between'>
                      <h6>Desconto no frete</h6>
                      <span>
                      {formatValue(-data.discount.shipping,
                        medicine.shipping_currency ? medicine.shipping_currency : medicine.brand.shipping_currency,
                      )}
                    </span>
                    </div>
                  </div>
                )}
                <div className='row  mt-2'>
                  <div
                    className='col-12 col-lg-4 offset-lg-8 orders__order__product__info__shipping font-weight-normal mb-2 d-flex justify-content-between'>
                    <h6>Total do pedido</h6>
                    <span>
                      {formatValue(data.total,
                        medicine.shipping_currency ? medicine.shipping_currency : medicine.brand.shipping_currency,
                      )}
                    </span>
                  </div>
                </div>
                <div className='row mb-2'>
                  <div className='col-lg-4 offset-lg-8 font-weight-normal text-dark d-flex justify-content-between'>
                    {(status === 'WAITING_FOR_PAYMENT' ||
                      status === 'PAYMENT_REJECTED' || status === 'PROCESSING_PAYMENT') && (
                      <ButtonPrimary
                        btnClass='w-100 btn__show__payment'
                        callBack={() => openModal(data.id)}
                        text='Pagar'
                      >
                        Pagar
                      </ButtonPrimary>
                    )}
                  </div>
                </div>
              </div>
              <ModalPayment
                data={data}
                type={'order'}
                id={idModalPayment}
                show={modalPayment}
                handleClose={handleClose}
              />

            </div>
          ))}
          <div className='orders__order__footer'>
            {data.status !== 'CANCELLED' ? (
              <>
                <div className='d-flex orders__order__footer__steps'>
                <span
                  className={`step ${orderStep >= 1 && 'drc-bg text-white'}`}
                >
                  Produtos selecionados
                  {orderStep >= 1 && <i className='fas fa-check pl-2' />}
                </span>
                  <span
                    className={`step ${orderStep >= 2 && 'drc-bg text-white'}`}
                  >
                  Pagamento autorizado
                    {orderStep >= 2 && <i className='fas fa-check pl-2' />}
                </span>
                  <span
                    className={`step ${orderStep >= 3 && 'drc-bg text-white'}`}
                  >
                  Em transporte
                    {orderStep >= 3 && <i className='fas fa-check pl-2' />}
                </span>
                  <span
                    className={`step ${orderStep >= 4 && 'drc-bg text-white'}`}
                  >
                  Produto entregue
                    {orderStep >= 4 && <i className='fas fa-check pl-2' />}
                </span>
                </div>
              </>
            ) : (
              <div className='d-flex steps'>
              <span className={`step cancelled`}>
                Pedido cancelado
                <i className='fas fa-times pl-2' />
              </span>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}
