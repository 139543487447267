import React, { useEffect, useState } from 'react'
import FullCalendar from '@fullcalendar/react'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
import interactionPlugin from '@fullcalendar/interaction'
import moment from 'moment'
import * as $ from 'jquery'
import { ModalEvent } from '~/pages/telemed/ModalEvent'
import { ModalConfirmConsultation } from '~/pages/telemed/ModalConfirmConsultation'
import { toast } from 'react-toastify'
import { useHistory } from 'react-router-dom'
import { ModalConsultationDetails } from '~/pages/telemed/ModalConsultationDetails'
import { clickCalendar } from '~/shared/telemedOptions'
import { ModalNewConsultation } from '~/pages/telemed/ModalNewConsultation'
import ModalPayment from '~/components/ModalPayment'

const Calendar = (
  {
    calendarClass,
    buttons,
    request,
    treatment,
    eventDurationEditable = true,
    editable = true,
    eventResizableFromStart = true,
    resourceId,
    openModalId = 'modal-event',
    initialDate = moment().format('YYYY-MM-DD'),
    isPhysician = false,
    isAdmin = false,
    eventClick = true,
    configuration = false,
    calendarRef,
    eventOverlap = true,
    selectable = false,
    updateBase = false,
    updateWeek = false,
  }) => {
  const history = useHistory()

  const [customButtons, setCustomButtons] = useState({})
  const [selectedEvent, setSelectedEvent] = useState()
  const [eventOptions, setEventOptions] = useState()
  const [appointmentLength, setAppointmentLength] = useState('')
  const [minTime, setMinTime] = useState('00:00')
  const [maxTime, setMaxTime] = useState('23:59')
  const [options, setOptions] = useState('today,prev,next,title')
  const [openModal, setOpenModal] = useState(false)

  const errorNotFound = () => {
    toast.error('Este médico não possui agenda')
    history.goBack()
    return []
  }

  const compareDates = (start) => {
    if (!isPhysician && !isAdmin)
      if (moment(start).format('YYYY-MM-DD') <= moment().format('YYYY-MM-DD'))
        setOptions('today,next,title')
      else setOptions('today,prev,next,title')
  }

  useEffect(() => {
    setCustomButtons({
      btn1: {
        text: 'Agendamento Interno',
        click() {
        },
      },
      btn2: {
        text: 'Bloquear Horários',
        click() {
        },
      },
    })
  }, [])

  const validateLengthEvent = (e) => {
    const { start, end } = e.event
    if (moment(start).format('DD') !== moment(end).format('DD')) {
      toast.warn('O evento deve terminar antes das 23:59')
      e.revert()
    }
  }

  const addEvent = (e) => {
    let calendarApi = calendarRef.current.getApi()
    const start = moment(e.start).format('YYYY-MM-DD HH:mm')
    const end = moment(e.end).format('YYYY-MM-DD HH:mm')
    if (start > moment().add(1, 'days').format('YYYY-MM-DD HH:mm')) {
      if (configuration) {
        calendarApi.addEvent(
          {
            start,
            end,
            className: 'view-telemed awaiting-appointment',
          },
        )
      } else {
        setSelectedEvent({
          date: moment(e.start).format('YYYY-MM-DD'),
          start,
        })
        $('#newConsultation').modal('show')
      }
    } else calendarApi.unselect()
  }
  const handleClose = (value) => {
    setOpenModal(value)
  }

  return (
    <>
      <div className={`calendar calendar-read-only ${calendarClass}`} id="calendar-card">
        <div className='col-lg-12  calendar-read-only__box mt-3 shadow-lg'>
          <div className='spinner-border calendar-read-only-spinner' id='wait' role='status'>
            <span className='sr-only'>Loading...</span>
          </div>
          <FullCalendar
            ref={calendarRef}
            handleWindowResize={true}
            selectOverlap={eventOverlap}
            selectable={selectable}
            slotEventOverlap={false}
            select={(e) => addEvent(e)}
            height='auto'
            plugins={[timeGridPlugin, listPlugin, interactionPlugin]}
            slotMinTime={minTime}
            slotMaxTime={maxTime}
            locale='pt-br'
            locales='pt-br'
            initialDate={initialDate}
            allDaySlot={false}
            firstDay={1}
            customButtons={buttons ? customButtons : ''}
            eventDurationEditable={eventDurationEditable}
            editable={editable}
            eventResizableFromStart={eventResizableFromStart}
            eventOverlap={eventOverlap}
            eventChange={(e) => eventClick && validateLengthEvent(e)}
            eventDidMount={(info) => {
              if (info.event.extendedProps.background) {
                info.el.style.background = info.event.extendedProps.background
              }
            }}
            headerToolbar={{
              start: options,
              center: '',
              end: `${buttons ? 'btn1,btn2' : ''}`,
            }}
            events={(info) => {
              compareDates(info.start)
              return request(moment(info.start).format('YYYY-MM-DD'), moment(info.end).add(-1, 'days').format('YYYY-MM-DD'), resourceId).then((r) => {
                  setAppointmentLength(r.data?.appointment_length)
                  if (!isPhysician) {
                    r.data?.calendar?.start && setMinTime(r.data.calendar.start)
                    r.data?.calendar?.end && setMaxTime(r.data.calendar.end)
                  }
                  return treatment(r.data, isPhysician, configuration, isAdmin)
                },
              ).catch(e => e?.response?.status === 404 ? errorNotFound() : [])
            }}
            eventClick={(e) => {
              eventClick && setEventOptions(e)
              clickCalendar(eventClick, e, isPhysician, isAdmin, openModalId, setSelectedEvent, setOpenModal)
            }}
            loading={function(isLoading, view) {
              if (isLoading) {
                $('.fc-timegrid-body').addClass('calendar-read-only-loading')
                $('#wait').show()
              } else {
                $('.fc-timegrid-body').removeClass('calendar-read-only-loading')
                $('#wait').hide()
              }
            }}
          />
        </div>

        <ModalEvent event={selectedEvent}
                    isAdmin={isAdmin}
                    isPhysician={isPhysician}
                    calendarRef={calendarRef}
                    eventOptions={eventOptions}
                    updateBase={updateBase}
                    updateWeek={updateWeek}
        />

        <ModalNewConsultation appointmentLength={appointmentLength}
                              minTime={minTime}
                              maxTime={maxTime}
                              calendarRef={calendarRef}
                              event={selectedEvent}
                              resourceId={resourceId}
                              isAdmin={isAdmin}
        />


        {
          !isPhysician && (
            <>
              <ModalConfirmConsultation
                event={selectedEvent}
                resourceId={resourceId}
              />
              <ModalConsultationDetails event={selectedEvent} />
              <ModalPayment
                data={selectedEvent}
                callback={setOpenModal}
                type={'consultation'}
                id={resourceId}
                show={openModal}
                handleClose={handleClose}

              />
            </>
          )
        }

      </div>
    </>
  )
}

export { Calendar }
