import React, { useState } from 'react'
import Cards from 'react-credit-cards'
import InputMask from 'react-input-mask'
import { getAddressByCep } from '~/services/viaCEP'
import StatesBrasil from '~/helpers/StatesBrasil.json'
import { CustomSelect } from '~/components'


const CreditCard = (
  {
    title = 'Enviar Pagamento',
    loading,
    action,
    target,
    callBack,
    toggle = 'collapse',
    addresses,
    installments,
  }) => {
  const [state, setState] = useState({
    cvc: '',
    expiry: '',
    focus: '',
    name: '',
    number: '',
    installments: '',
    address: {
      state: addresses?.state,
      city: addresses?.city,
      neighborhood: addresses?.district ?? '',
      street: addresses?.street,
      street_number: addresses?.number,
      zipcode: addresses?.cep,
      complement: addresses?.complement,
    },
  })

  const inputFocus = e => {
    const { name } = e.target

    setState(prevState => {
      return {
        ...prevState,
        focus: name,
      }
    })
  }

  const changeInput = e => {
    const { name, value } = e.target

    setState(prevState => {
      return {
        ...prevState,
        [name]: value,
      }
    })
  }

  const changeAddress = (e) => {
    const { name, value } = e.target
    if (name === 'zipcode') {
      let cep = value.replace('-', '')
      cep = cep.replaceAll('_', '')
      if (cep.length === 8) {
        getAddressByCep(cep).then((r) => setState(prevState => {
          return {
            ...prevState,
            address: {
              zipcode: cep,
              state: r.data.uf,
              city: r.data.localidade,
              neighborhood: r.data.bairro ?? '',
              street: r.data.logradouro,
            },
          }
        }))
      }
    }

    setState(prevState => {
      return {
        ...prevState,
        address: {
          ...prevState.address,
          [name]: value,
        },
      }
    })
  }

  const clearInput = () => {
    setState({
      cvc: '',
      expiry: '',
      focus: '',
      name: '',
      number: '',
      installments: '',
      address: {
        state: '',
        city: '',
        neighborhood: '',
        street: '',
        street_number: '',
        zipcode: '',
        complement: '',
      },
    })

  }

  return (
    <div id='PaymentForm' className={`collapse credit__card my-3 `}>
      <Cards
        cvc={state.cvc}
        expiry={state.expiry}
        focused={state.focus}
        name={state.name}
        number={state.number}

      />
      <form onSubmit={(e) => action(e, state)} className='mx-2'>
        <div className='form-group'>
          <InputMask
            className='form-control'
            mask='9999 9999 9999 9999'
            name='number'
            type='tel'
            placeholder='Número do cartão'
            required={true}
            onChange={event => changeInput(event)}
            onFocus={event => inputFocus(event)}
            value={state.number}

          />
        </div>
        <div className='form-group'>
          <InputMask
            className='form-control'
            mask=''
            name='name'
            type='text'
            required={true}
            placeholder='Nome (como impresso no cartão)'
            onChange={event => changeInput(event)}
            onFocus={event => inputFocus(event)}
            value={state.name}
          />
        </div>
        <div className='form-group d-flex'>
          <InputMask
            className='form-control mr-2'
            mask='99/99'
            required={true}
            name='expiry'
            type='tel'
            placeholder='Validade'
            onChange={event => changeInput(event)}
            onFocus={event => inputFocus(event)}
            value={state.expiry}
          />
          <InputMask
            className='form-control'
            mask='999'
            name='cvc'
            placeholder='CVC'
            type='tel'
            required={true}
            onChange={event => changeInput(event)}
            onFocus={event => inputFocus(event)}
            value={state.cvc}
          />
        </div>
        {Array.isArray(installments) && installments.length > 1 && (
          <div className='form-group'>
            <CustomSelect
              inputName='installments'
              callBack={changeInput}
              selectValue={state.installments}
            >
              <option value=''>Selecione a quantidade de Parcelas</option>
              {installments.map((value, index) => (
                index + 1 === 1 ?
                  <option value={index + 1}>{index + 1} Parcela de R$ {value?.installmentValue} (sem juros)</option>
                  :
                  <option value={index + 1}>{index + 1} Parcelas de R$ {value?.installmentValue} (com juros, Total
                    R$ {value?.amount})</option>
              ))}
            </CustomSelect>
          </div>
        )}
        <div className='form-group'>
          <p className='text--grey my-1'>Endereço de cobrança</p>
          <InputMask
            className='form-control'
            mask='99999-999'
            name='zipcode'
            type='text'
            required={true}
            placeholder='cep'
            onChange={event => changeAddress(event)}
            onFocus={event => inputFocus(event)}
            value={state.address.zipcode}
          />
        </div>
        <div className='form-group'>
          <InputMask
            className='form-control'
            mask=''
            name='street'
            type='text'
            required={true}
            placeholder='Rua'
            onChange={event => changeAddress(event)}
            onFocus={event => inputFocus(event)}
            value={state.address.street}
          />
        </div>
        <div className='form-group d-flex'>
          <InputMask
            className='form-control mr-2'
            mask=''
            name='street_number'
            type='text'
            required={true}
            placeholder='Número'
            onChange={event => changeAddress(event)}
            onFocus={event => inputFocus(event)}
            value={state.address.street_number}
          />
          <InputMask
            className='form-control'
            mask=''
            name='complement'
            type='text'
            placeholder='Complemento'
            onChange={event => changeAddress(event)}
            onFocus={event => inputFocus(event)}
            value={state.address.complement}
          />
        </div>
        <div className='form-group'>
          <InputMask
            className='form-control mr-2'
            mask=''
            name='neighborhood'
            type='text'
            required={true}
            placeholder='Bairro'
            onChange={event => changeAddress(event)}
            onFocus={event => inputFocus(event)}
            value={state.address.neighborhood}
          />
        </div>
        <div className='form-group d-flex'>
          <InputMask
            className='form-control w-50 mr-2'
            mask=''
            name='city'
            type='text'
            required={true}
            placeholder='Cidade'
            onChange={event => changeAddress(event)}
            onFocus={event => inputFocus(event)}
            value={state.address.city}
          />
          <CustomSelect
            inputName='state'
            inputClass='my-0'
            value={state.address.state}
            callBack={changeInput}
            onFocus={event => inputFocus(event)}
            selectValue={state.address.state}
            skeletonDivClass='mb-4'
          >
            <option value=''>estado</option>
            {StatesBrasil.map(state => (
              <option value={state.value}>{state.label}</option>
            ))}
          </CustomSelect>
        </div>
        <div className='payment__buttons'>
          <button
            type='button'
            className='btn payment__button__clear ml-0'
            disabled={loading}
            onClick={() => {
              clearInput()
              typeof callBack === 'function' && callBack()
            }}
            data-target={target}
            data-toggle={toggle}
          >
            Cancelar
          </button>
          <button
            type='submit'
            className='btn payment__button'
            disabled={loading}
          >
            {title}
            {loading && (
              <div
                className='ml-3 spinner-border spinner-border-sm'
                role='status'
              >
                {' '}
              </div>
            )}
          </button>
        </div>
      </form>
    </div>
  )
}

export { CreditCard }
