import React from 'react'
import * as $ from 'jquery'


const ConfirmConflict = ({ callback }) => {

  return (
    <div className='modal' tabIndex='-1' role='dialog' id='conflict'>
      <div className='modal-dialog' role='document'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h5 className='modal-title'>Conflito</h5>
            <button type='button' className='close' data-dismiss='modal' aria-label='Close'>
              <span aria-hidden='true'>&times;</span>
            </button>
          </div>
          <div className='modal-body'>
            <p>Já existe um compromisso neste horário, deseja continuar ?</p>
          </div>
          <div className='modal-footer'>
            <button type='button' className='btn btn-primary' onClick={callback}>Sim</button>
            <button type='button'
                    className='btn btn-secondary'
                    data-dismiss='modal'
                    onClick={() => $('#newConsultation').modal('show')}
            >
              Não
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
export { ConfirmConflict }
