import React from 'react'
import { shareMessage } from '~/shared/utils'


const SocialLinks = ({ physician, iconWidthClass = 'fa-2x' }) => {

  return (
    <div className='d-flex mt-2'>
      <a
        href={`https://api.whatsapp.com/send?text=${shareMessage(physician).whatsapp}`}
        target='_blank'
        rel='noopener noreferrer'
        data-toggle='tooltip'
        data-placement='top'
        title='Compartilhe este perfil no Whatsapp'
        className='mr-2'
      >
        <i className={`fab fa-whatsapp text-success ${iconWidthClass}`} />
      </a>

      <a
        href={`https://www.linkedin.com/shareArticle?mini=true&url=${shareMessage(physician).linkedin}`}
        target='_blank'
        rel='noopener noreferrer'
        data-toggle='tooltip'
        data-placement='top'
        title='Compartilhe este perfil no Linkedin'
        className='mr-2'
      >
        <i className={`fab fa-linkedin text-info ${iconWidthClass}`} />
      </a>

      <a
        href={`https://www.facebook.com/sharer/sharer.php?u=${shareMessage(physician).facebook}`}
        target='_blank'
        rel='noopener noreferrer'
        data-toggle='tooltip'
        data-placement='top'
        title='Compartilhe este perfil no Facebook'
        className='mr-2'
      >
        <i className={`fab fa-facebook text-info ${iconWidthClass}`} />
      </a>
    </div>
  )
}

export { SocialLinks }
