import Immutable from 'seamless-immutable'
import { createActions, createReducer } from 'reduxsauce'
import { toast } from 'react-toastify'

export const { Types, Creators } = createActions({
  setError: ['message', 'body'],
  hideError: [],
})

const INITIAL_STATE = Immutable({
  visible: false,
  message: '',
  body: {},
})

const setError = (state = INITIAL_STATE, action) => {
  let items
  if (typeof action.message === 'string') {
    items = action.message
  } else {
    if (action.message.data && typeof action.message.data === 'object') {
      if (action.message.data.errors) {
        items = Object.entries(action.message.data.errors)
          .map(v => {
            return v[1][0]
          })
          .join('\n')
      }
    } else {
      items = Object.entries(action.message)
        .map(v => {
          return v[1][0]
        })
        .join('\n')
    }
  }

  if (items)
    toast.error(typeof items === 'string' ? items : 'Ops, algo deu errado...')
  return {
    ...state,
    visible: true,
    body: action.body,
    message: items,
  }
}

const hideError = (state = INITIAL_STATE) => ({
  ...state,
  visible: false,
})

export default createReducer(INITIAL_STATE, {
  [Types.SET_ERROR]: setError,
  [Types.HIDE_ERROR]: hideError,
})
