import axios from 'axios'
import { getToken } from './auth'
import React from 'react'
import { Creators as AuthActions } from '~/store/ducks/auth'


const url = process.env.REACT_APP_API
const debug = process.env.REACT_APP_API_DEBUG

const api = axios.create({
  baseURL: url,
})

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error)
      if (error.response)
        if (error.response.status===401) {
          if (error.config.url==="/sessions") {
            throw error;
          }
          localStorage.clear()
          AuthActions.logout()
          window.location = window.origin+'/login/exp'
        } else {
          throw error;
        }
  }
);

api.interceptors.request.use(async config => {
  const token = getToken()
  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  }
  if (debug==1) {
    if (config.params) {
      config.params.XDEBUG_TRIGGER=1;
    } else {
      config.params={ XDEBUG_TRIGGER: 1 }
    }
  }
  return config
})

export default api
