export const Public = [
  {
    url: '/',
    icon: 'fa-home',
    label: 'Home',
  },

  {
    url: '/sobre',
    icon: '',
    label: 'Sobre nós',
  },
  {
    url: '/produtos',
    icon: '',
    label: 'Produtos',
  },
  {
    url: '/comprar-cbd',
    icon: '',
    label: ' Como comprar CBD',
  },
  {
    url: '/faq',
    icon: '',
    label: ' FAQ',
  },
  {
    url: 'https://blog.drcannabis.com.br',
    icon: '',
    label: 'Blog',
  },
]


export const Patient = [
  {
    url: '/paciente',
    icon: 'fa-home',
    label: 'Home',
  },
  {
    url: '/paciente/medicos',
    icon: 'fa-user-md',
    label: 'Médicos',
  },
  {
    url: '/paciente/prescricao',
    icon: 'fa-file-contract',
    label: 'Prescrições',
  },
  {
    url: '/paciente/pedidos',
    icon: 'fa-bags-shopping',
    label: 'Histórico de pedidos',
  },
  {
    url: '/paciente/lista/consultas',
    icon: 'fa-laptop-medical',
    label: 'Minhas consultas',
    class: localStorage.getItem('telemedicine') === 'true' ? '' : 'd-none',
  },
  {
    url: '/sobre',
    icon: '',
    label: 'Sobre nós',
    class: 'd-none',
  },
  {
    url: '/produtos',
    icon: '',
    label: 'Produtos',
    class: 'd-none',
  },
  {
    url: '/comprar-cbd',
    icon: '',
    label: ' Como comprar CBD',
    class: 'd-none',
  },
  {
    url: '/faq',
    icon: '',
    label: ' FAQ',
    class: 'd-none',
  },
  {
    url: 'https://blog.drcannabis.com.br',
    icon: '',
    label: 'Blog',
    class: 'd-none',
  },
  {
    url: '/paciente/perfil',
    icon: 'fa-user-alt',
    label: 'Perfil',
  },
  {
    url: '/logout',
    icon: 'fa-power-off mt-2',
    label: 'Sair',
  },
]

export const Physician = [
  {
    url: '/medico',
    icon: 'fa-home',
    label: 'Home',
  },
  {
    url: '/medico/pacientes',
    icon: 'fa-hospital-user',
    label: 'Pacientes',
  },
  {
    url: '/medico/prescricao',
    icon: 'fa-file-medical',
    label: 'Prescrições',
  },
  {
    url: '/medico/artigos',
    icon: 'fa-books-medical',
    label: 'Artigos Científicos',
  },
  {
    url: '/medico/telemed',
    icon: 'fa-calendar',
    label: 'Configurar agenda',
    class: localStorage.getItem('telemedicine') === 'true' ? '' : 'd-none',
  },
  {
    url: '/medico/telemed/consultas',
    icon: 'fa-money-check-alt',
    label: 'Minhas consultas',
    class: localStorage.getItem('telemedicine') === 'true' ? '' : 'd-none',
  },
  {
    url: '/sobre',
    icon: '',
    label: 'Sobre nós',
    class: 'd-none',
  },
  {
    url: '/produtos',
    icon: '',
    label: 'Produtos',
    class: 'd-none',
  },
  {
    url: '/comprar-cbd',
    icon: '',
    label: ' Como comprar CBD',
    class: 'd-none',
  },
  {
    url: '/faq',
    icon: '',
    label: ' FAQ',
    class: 'd-none',
  },
  {
    url: 'https://blog.drcannabis.com.br',
    icon: '',
    label: 'Blog',
    class: 'd-none',
  },
  {
    url: '/medico/perfil',
    icon: 'fa-user-alt',
    label: 'Perfil',
  },
  {
    url: '/logout',
    icon: 'fa-power-off mt-2',
    label: 'Sair',
  },
]

export const Admin = [
  {
    url: '/admin/medicos',
    icon: 'fa-user-md',
    label: 'Medicos',
  },
  {
    url: '/admin/pacientes',
    icon: 'fa-hospital-user',
    label: 'Pacientes',
  },
  {
    url: '/admin/produtos',
    icon: 'fa-cube',
    label: 'Produtos',
  },
  {
    url: '/admin/prescricoes',
    icon: 'fa-file-medical',
    label: 'Prescricoes',
  },
  {
    url: '/admin/pedidos',
    icon: 'fa-file-invoice-dollar',
    label: 'Lista de pedidos',
  },
  {
    url: '/admin/campanha',
    icon: 'fa-tags',
    label: 'Campanhas',
  },
  {
    url: '/admin/listar/consultas',
    icon: 'fa-calendar',
    label: 'Consultas',
  },
  {
    url: '/admin/artigos',
    icon: 'fa-books-medical',
    label: 'Artigos',
  },
  {
    url: '/logout',
    icon: 'fa-power-off mt-2',
    label: 'Sair',
  },
]

export const Representative = [
  {
    url: '/representantes/prescricao',
    icon: 'fa-file-medical',
    label: 'Prescrições',
  },
  {
    url: '/representantes/pedidos',
    icon: 'fa-file-invoice-dollar',
    label: 'Lista de pedidos',
  },
  {
    url: '/representantes/perfil',
    icon: 'fa-user-alt',
    label: 'Perfil',
  },
  {
    url: '/logout',
    icon: 'fa-power-off mt-2',
    label: 'Sair',
  },
]
