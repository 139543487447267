import React, { useEffect, useState } from 'react'
import ProfileCard from '~/components/ProfileCard'
import { history } from '~/store'
import Sprites from '~/assets/icons/sprites.svg'
import * as $ from 'jquery'
import { shareMessage } from '~/shared/utils'

const PhysicianProfileHeader = ({
                                  backgroundImage,
                                  physician,
                                  callBack,
                                  favorite,
                                  customClass,
                                  id,
                                }) => {
  const [loginStatus, setLoginStatus] = useState(false)

  const checkLogin = () => {
    const logged = localStorage.getItem('roles')
    if (logged === 'responsible' || logged === 'patient') {
      setLoginStatus(true)
    }
  }

  const toLogin = (localUrl = window.location.pathname) => {
    localStorage.setItem('physicianPublic', localUrl)
    history.push('/login')
  }

  const toTelemed = () => {
    let url = `/paciente/telemed/${physician.id}`
    localStorage.setItem('physicianPublic', url)
    history.push(url)
  }

  useEffect(() => {
    checkLogin()
  }, [])


  const sendWhatsappMsg = (physician) => {
    if (physician?.user?.profile?.gender === 'f') {
      return encodeURIComponent(`Gostaria de agendar uma consulta com a Dra ${physician?.user?.profile?.full_name} na Dr. Cannabis. https://drcannabis.com.br/perfil/${physician?.slug}`)
    } else {
      return encodeURIComponent(`Gostaria de agendar uma consulta com o Dr ${physician?.user?.profile?.full_name} na Dr. Cannabis. https://drcannabis.com.br/perfil/${physician?.slug}`)
    }
  }

  return (
    <div className={`container p-0 physician_profile_header ${customClass}`}>
      <div className='physician_profile_header_bg' style={{ backgroundImage: `url(${backgroundImage})` }}>
        <ProfileCard data={physician.user} physician={physician} />
        <div className='col-lg-12'>
          <div className='row'>
            <div className='col-lg-4 mt-4 offset-lg-8 d-flex align-items-center flex-column justify-content-center'>

              <button
                className={`btn physician_profile_header_favorite_button `}
                onClick={loginStatus ? callBack : toLogin}
              >
                <svg
                  className='physician_profile_header_favorite_icon ml-1'
                  style={{
                    fill: favorite ? '#ff0000' : '#fff',
                    stroke: favorite ? 'none' : '#000',
                  }}
                >
                  <use xlinkHref={`${Sprites}#icon-heart`} />
                </svg>
                Favoritar Médico
              </button>
              {physician.online_consultation && (
                <button className='btn mt-2 physician_profile_header_telemed_button'
                        onClick={() => toTelemed()}
                >
                  Marcar consulta online
                </button>
              )}
              {!physician.online_consultation && (
                <button className='btn mt-2 physician_profile_header_favorite_button'>
                  <a
                    href={`https://api.whatsapp.com/send?phone=+5511941431896&text=${sendWhatsappMsg(physician)}`}
                    target='_blank'
                    rel='noopener noreferrer'
                    data-toggle='tooltip'
                    data-placement='top'
                    title='Compartilhe este perfil no Whatsapp'
                    className='mr-2'
                  >
                    <i className='fab fa-whatsapp text-success' /> Marcar consulta
                  </a>
                </button>
              )}

            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export { PhysicianProfileHeader }
