import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import Skeleton from 'react-loading-skeleton'


import Patient from './PatientCard'
import api from '~/services/api'

export default function Patients() {
  const [patients, setPatients] = useState([])
  const [prescriptions, setPrescriptions] = useState([])
  const [favorites, setFavorites] = useState([])
  const [loading, setLoading] = useState(false)

  const getPatients = async () => {
    setLoading(true)
    const res = await api.get('/patients/list')
    setPatients(res.data.patients)
    setPrescriptions(res.data.prescriptionCount)
    setFavorites(res.data.favoriteCount)
    setLoading(false)
  }

  useEffect(() => {
    getPatients()
  }, [])

  const handlePageChange = page => {
    getPatients()
  }

  return (
    <div className='container-fluid physician__patients'>
      <div className='row'>
        <div className='col-lg-12'>
          <h1 className='physician__patients__title'>Pacientes</h1>
        </div>
      </div>
      {favorites > 0 ? (
        <div className='row'>
          <div className='col-lg-12'>
            <h2 className='physician__patients__info'>Favoritos</h2>
          </div>
          <div className='col-lg-12'>
            <h2 className="physician__patients__text">{favorites} { favorites > 1 ?  ('Pacientes favoritaram ') : ('Paciente favoritou ') } o seu perfil</h2>
          </div>
        </div>
      ) : ''
      }
      {prescriptions > 0 ? (
      <div className='row mt-4'>
        <div className='col-lg-12'>
          <h2 className='physician__patients__info'>Pacientes prescritos</h2>
        </div>
        <div className='col-lg-12'>
          <h3 className="physician__patients__text">{prescriptions} { prescriptions > 1 ? ('pacientes já receberam prescrições') : ('paciente já recebeu prescrição') }</h3>
        </div>
      </div>
      ) : ''
      }
      <div className='row mt-3'>
        {loading && (
          <div className='skeleton__physician__patients'>
          <div className='col-lg-12'>
            <Skeleton width='50%'/>
            <Skeleton className='mt-2' width='60%' />
          </div>
            <div className='col-lg-12'>
              <div className='row mt-3'>
                <div className='col-lg-4 mt-2'>
                  <div className='row'>
                    <div className='col-lg-4 col-4'>
                      <Skeleton className='rounded-circle' width='90px' height='90px'/>
                    </div>
                    <div className='col-lg-6 col-6'>
                      <Skeleton className='skeleton__physician__patients__text__align'/>
                    </div>
                  </div>
                </div>

                <div className='col-lg-4 mt-2'>
                  <div className='row'>
                    <div className='col-lg-4 col-4'>
                      <Skeleton className='rounded-circle' width='90px' height='90px'/>
                    </div>
                    <div className='col-lg-6 col-6'>
                      <Skeleton className='skeleton__physician__patients__text__align' />
                    </div>
                  </div>
                </div>

                <div className='col-lg-4 mt-2'>
                  <div className='row'>
                    <div className='col-lg-4 col-4'>
                      <Skeleton className='rounded-circle' width='90px' height='90px'/>
                    </div>
                    <div className='col-lg-6 col-6'>
                      <Skeleton className='skeleton__physician__patients__text__align' />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {!loading &&
          (patients.length ? (
            patients.map(patient => (
              <div className="col-lg-4 my-3" key={patient.id}>
                <Link to={`/medico/pacientes/${patient.id}`}>
                  <Patient data={patient} />
                </Link>
              </div>
            ))
          ) : (
            <div className="d-flex w-100 justify-content-center m-5">
              <div className="warning p-3">
                {' '}
                Você ainda não tem pacientes ativos. Assim que prescrever
                seus pacientes estarão listado nesse espaço.
              </div>
            </div>
          ))}
      </div>
      {/*{patients.length > 0 && (*/}
      {/*  <Row>*/}
      {/*    <Col className="d-flex justify-content-center mt-3">*/}
      {/*      <Pagination*/}
      {/*        activePage={patients.page}*/}
      {/*        itemsCountPerPage={patients.perPage}*/}
      {/*        totalItemsCount={patients.total ? patients.total : 0}*/}
      {/*        pageRangeDisplayed={5}*/}
      {/*        onChange={handlePageChange}*/}
      {/*        itemClass="page-item"*/}
      {/*        linkClass="page-link"*/}
      {/*      />*/}
      {/*    </Col>*/}
      {/*  </Row>*/}
      {/*)}*/}
    </div>
  )
}
