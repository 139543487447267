import React, { createRef, useState } from 'react'
import { adminConfigPhysicianCalendar } from '~/services/requests'
import { useHistory, useParams } from 'react-router-dom'
import { Calendar } from '~/components/Calendar/Calendar'
import { ButtonSecondary } from '~/components'
import { treatmentOfDates, updateCalendar } from '~/shared/telemedOptions'

const AdminViewPhysicianCalendar = () => {
  const { id } = useParams()
  const history = useHistory()
  const [updateBase, setUpdateBase] = useState(false)
  const [updateWeek, setUpdateWeek] = useState(false)

  const calendarRef = createRef()

  return (
    <div className='container-fluid my-agenda mb-5 view-presential px-4'>
      <div className='row'>
        <div className='col-lg-12 d-flex align-items-center justify-content-between'>
          <h1 className='page-title py-3'>Agenda</h1>

          <div className='d-flex'>
            {updateWeek || updateBase ? (
                <ButtonSecondary
                  text='Salvar'
                  btnClass='my-3 my-agenda__configure-shifts mr-2'
                  icon={<i className='far fa-check-square' />}
                  callBack={() => updateCalendar(calendarRef, updateWeek, updateBase, setUpdateWeek, setUpdateBase, true, id)}
                />
              )
              :
              (
                <>
                  <ButtonSecondary
                    text='Calendario desta semana'
                    btnClass='my-3 my-agenda__configure-shifts mr-2'
                    icon={<i className='far fa-edit' />}
                    callBack={() => setUpdateWeek(true)}
                  />
                  <ButtonSecondary
                    text='Calendario base'
                    btnClass='my-3 my-agenda__configure-shifts mr-2'
                    icon={<i className='far fa-edit' />}
                    callBack={() => setUpdateBase(true)}
                  />
                </>
              )
            }


            <ButtonSecondary
              text='Visualizar consultas'
              btnClass='my-3 my-agenda__configure-shifts'
              icon={<i className='far fa-calendar-check' />}
              callBack={() => history.push(`/admin/medicos/consultas/${id}`)}
            />
          </div>
        </div>
      </div>
      <div className='row'>
        <Calendar calendarClass='calendarMyAgenda'
                  buttons={false}
                  request={adminConfigPhysicianCalendar}
                  treatment={treatmentOfDates}
                  eventDurationEditable={updateBase || updateWeek}
                  eventResizableFromStart={false}
                  editable={updateBase || updateWeek}
                  resourceId={id}
                  isAdmin={true}
                  isPhysician={false}
                  eventClick={updateBase || updateWeek}
                  calendarRef={calendarRef}
                  eventOverlap={false}
                  configuration={true}
                  selectable={updateBase || updateWeek}
                  updateBase={updateBase}
                  updateWeek={updateWeek}
        />
      </div>
    </div>
  )
}

export { AdminViewPhysicianCalendar }
