import api from '~/services/api'


export const getPrescriptions = (page) => {
  let params = ''
  if (page) params = params + `page=${page}&`
  return api.get(`/prescriptions?${params}`)
}

export const fetchBrands = async () => {
  return await api.get(`/brands/summary`)
}

export const fetchMedicines = async (brand, search) => {
  let params = []
  if (search !== undefined) {
    params.push(`name=${search}`)
  }
  if (brand !== undefined) {
    params.push(`brand=${brand}`)
  }
  let url = params.join('&')
  if (url.length > 0) {
    url = '/medicines?' + url
  } else {
    url = '/medicines'
  }
  return await api.get(url)
}

export const fetchPrescriptions = async (prescriptionId) => {
  return await api.get(`/prescriptions/${prescriptionId}`)
}

export const updatePrescription = async (prescriptionId, data) => {
  return await api.put(`/prescriptions/${prescriptionId}`, data)
}

export const getPhysician = async userId => {
  return await api.get(`/users/${userId}`)

}

export const updatePhysician = async (data, id) => {
  return await api.put(`/users/${id}`, data)
}

export const getPhysicianForAdmin = async id => {
  return await api.get(`/admin/physician/${id}`)

}

export const updatePhysicianForAdmin = async (data, id) => {
  return await api.put(`admin/physician/${id}`, data)
}


export const getAllCampaigns = async () => {
  return await api.get('/campaigns')
}

export const createCampaign = async (data) => {
  return await api.post('/campaigns', data)
}

export const updateCampaigns = async (data, slug) => {
  return await api.put(`/campaigns/${slug}`, data)
}

export const deletedCampaign = async (slug) => {
  return await api.delete(`/campaigns/${slug}`)
}

export const deletedCoupon = async (slug) => {
  return await api.delete(`/coupon/${slug}`)
}

export const createCoupon = async (data) => {
  return await api.post('/coupon', data)
}


export const getCampaign = async (slug) => {
  return await api.get(`/campaigns/${slug}`)
}


export const getCoupon = async (slug) => {
  return await api.get(`/coupon/${slug}`)
}

export const updateCoupon = async (data) => {
  return await api.put(`/coupon/${data.slug}`, data)
}


export const checkPhysicianTelemed = async () => {
  return await api.get('/physician/telemedicine/check')
}

export const adminCheckPhysicianTelemed = async (physicianId) => {
  return await api.get(`/admin/physician/telemedicine/${physicianId}/check`)
}

export const enrollPhysicianTelemed = async () => {
  return await api.post('/physician/telemedicine')
}

export const adminEnrollPhysicianTelemed = async (physicianId, data) => {
  return await api.post(`/admin/physician/telemedicine/${physicianId}`, data)
}

export const bankAccount = (data) => {
  return api.put('/physician/bank_account', data)
}

export const adminSetBankAccount = (data, physicianId) => {
  return api.put(`/admin/physician/bank_account/${physicianId}`, data)
}

export const getMyCalendar = (start) => {
  return api.get(`physician/calendar/${start}`)
}

export const getAppointments = () => {
  return api.get('patient/calendar/1240/2021-07-17')
}

export const getPhysiciansAppointments = (
  start = '2021-07-01',
  end = '2021-07-31',
) => {
  return api.get(`physician/appointments/${start}/${end}`)
}

export const createAppointments = (
  date = '2021-07-01',
  hour = '09:00:00',
  physicianId,
) => {
  return api.post(`patient/calendar/${physicianId}/${date}/${hour}`)
}

export const patientGetCalendar = (start, end, physicianId) => {
  return api.get(`patient/calendar/${physicianId}/${start}/${end}`)
}

export const patientFinishAppointment = (date, time, physicianId, data) => {
  return api.post(`patient/calendar/${physicianId}/${date}/${time}`, data)
}

export const patientFinishPayment = (date, time, physicianId, data) => {
  return api.post(`payment/telemedicine/${physicianId}/${date}/${time}`, data)
}

export const patientListConsultation = (start, end = '2100-01-01') => {
  return api.get(`patient/appointments/${start}/${end}`)
}

export const adminPhysicianCalendar = (start, end, physicianId) => {
  return api.get(`admin/appointments/${physicianId}/${start}/${end}`)
}

export const adminPatientCalendar = (start, end, patient) => {
  return api.get(`admin/patient/appointments/${patient}/${start}/${end}`)
}

export const adminConfigPhysicianCalendar = (date, end, physicianId) => {
  return api.get(`admin/calendar/${physicianId}/${date}`)
}

export const patientCheckEnroll = () => {
  return api.get('patient/telemedicine/check')
}

export const adminUpdateBaseCalendar = async (blueprint, date, physicianId) => {
  return await api.put(`admin/physician/calendar/base/${physicianId}/${date}`, { 'blueprint': blueprint })
}

export const adminUpdateWeekCalendar = async (blueprint, date, physicianId) => {
  return await api.put(`admin/physician/calendar/${physicianId}/${date}`, { 'blueprint': blueprint })
}

export const physicianUpdateBaseCalendar = async (blueprint, date) => {
  return await api.put(`physician/calendar/base/${date}`, { 'blueprint': blueprint })
}

export const physicianUpdateWeekCalendar = async (blueprint, date) => {
  return await api.put(`physician/calendar/${date}`, { 'blueprint': blueprint })
}

export const adminCreateAppointment = (date, time, patientId, physicianId) => {
  return api.post(`admin/patient/calendar/${physicianId}/${patientId}/${date}/${time}`)
}

export const physicianCreateAppointment = (date, time, patientId) => {
  return api.post(`physician/calendar/${patientId}/${date}/${time}`)
}

export const adminPatientPreRegisterAppointment = (date, physicianId, time, data) => {
  return api.post(`admin/patient/calendar/${physicianId}/${date}/${time}`, data)
}

export const physicianPatientPreRegisterAppointment = (date, time, data) => {
  return api.post(`physician/calendar/${date}/${time}`, data)
}


export const getPhysicianPublic = async (slug) => {
  return await api.get(`/physician/${slug}/public`)
}

export const getPixKey = (id) => {
  return api.get(`/orders/${id}/pix`)
}

export const getPixKeyConsultation = (id, date, hourly) => {
  return api.get(`/telemedicine/${id}/${date}/${hourly}/pix`)
}

export const payConsultation = (id, cardData, date, hourly) => {
  return api.post(`/telemedicine/${id}/${date}/${hourly}/charge`, cardData)
}

export const payOrder = (id, cardData) => {
  return api.post(`/orders/${id}/charge`, cardData)
}

export const installments = (slug, amount) => {
  return api.post(`/installments/${slug}`, { amount })
}

export const getAppointmentList = (date) => {
  return api.get(`/admin/appointment/list/${date}`)
}

export const adminEditAppointment = (date, time, uuid) => {
  return api.put(`/admin/physician/appointment/${date}/${time}/${uuid}`)
}

export const adminDeleteAppointment = (uuid) => {
  return api.delete(`/admin/physician/appointment/${uuid}`)
}

