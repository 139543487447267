import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Link, useHistory } from 'react-router-dom'

import ProductCard from '~/components/ProductCard'
import { ButtonPrimary } from '~/components'
import { paginateItens } from '~/shared/utils'

export default function Products(
  {
    products = [],
    showTitle = true,
    showButton = true,
    brands = [],
    showDisableMedicines = false,
  }) {
  const roles = localStorage.getItem('roles')
  const history = useHistory()
  const [routeState] = useState('/produtos/')
  const [page, setPage] = useState(1)

  return (
    <div className='products'>
      <div className='col-12'>
        {showTitle && (
          <div>
            <h1 className='products__title mb-3'>Produtos à base de Cannabis</h1>
            {!roles ||
            roles.length === 0 ||
            roles === 'patient' ||
            roles === 'responsible' ? (
              <div>
                <p>
                  Quanto custa um tratamento com produtos à base de cannabis?
                </p>
                <br />
                <p className=' mt-3'>
                  A resposta curta é: depende. O preço de um tratamento com canabinoides está atrelado a questões
                  como a patologia a ser tratada, a dosagem necessária, a concentração prescrita, a epigenética e
                  a metabolização dos componentes da cannabis pelo metabolismo do paciente.
                </p>
                <p className='mt-3'>
                  Além desses, muitos outros fatores são relevantes para garantir que o tratamento ofereça o maior
                  benefício terapêutico com os menores níveis de efeitos colaterais possíveis. Encontrar o produto
                  e a dosagem ideal são os grandes desafios do tratamento com cannabis. É por isso que a Dr. Cannabis
                  trabalha em parceria as marcas que oferecem produtos à base de cannabis com alta qualidade.
                </p>
              </div>
            ) : (
              ''
            )}

            {roles &&
            roles.length > 0 &&
            roles !== 'patient' &&
            roles !== 'responsible' ? (
              <div>
                <h3 className='mt-5'>Conheça as marcas parceiras:</h3>

                <div
                  id='carouselBrands'
                  className='carousel slide bg-gray px-5 my-5'
                  data-ride='carousel'
                >
                  <div className='carousel-inner'>
                    <div className='carousel-item active d-flex row ml-1'>
                      {paginateItens(brands, page, 4).map(brand => (
                        <div className='col-3'>
                          {roles &&
                          (roles === 'administrator' ||
                            roles === 'physician') && brand?.active ? (
                            <Link to={`/marca/${brand.slug}`}>
                              <img
                                src={brand.logo?.uuidUrl}
                                alt={brand?.name ?? ''}
                                className='img-fluid'
                              />
                            </Link>
                          ) : brand.slug === 'proprium-my-cannabis-code' ? (
                            <Link to={`/marca/${brand.slug}`}>
                              <img
                                src={brand.logo?.uuidUrl}
                                alt={brand?.name ?? ''}
                                className='img-fluid'
                              />
                            </Link>
                          ) : (
                            <img
                              src={brand.logo?.uuidUrl}
                              alt={brand?.name ?? ''}
                              className='img-fluid'
                            />
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                  <a
                    className={
                      'carousel-control-prev d-flex justify-content-start ml-3'
                    }
                    href='#carouselProducts'
                    role='button'
                    data-slide='prev'
                    onClick={() => page > 1 && setPage(page - 1)}
                  >
                    <i className='fas fa-chevron-left fa-2x text-black-50' />
                    <span className='sr-only'>Previous</span>
                  </a>
                  <a
                    className='carousel-control-next d-flex justify-content-end mr-2'
                    href='#carouselProducts'
                    role='button'
                    data-slide='next'
                    onClick={() =>
                      page < Math.ceil(brands.length / 3) && setPage(page + 1)
                    }
                  >
                    <i className='fas fa-chevron-right fa-2x text-black-50' />
                    <span className='sr-only'>Next</span>
                  </a>
                </div>
              </div>
            ) : (
              ''
            )}
            <p className=' mt-3'>
              Todos os produtos das marcas parceiras possuem autorização em seus países de origem e Certificado de
              Análise (CoA) - instrumento de segurança sobre os produtos tanto para os médicos quanto para pacientes.
            </p>
            <br />
            <p className=' mt-3'>
              Para atender a regulamentação da Anvisa sobre produtos à base de
              cannabis, os valores de seu tratamento serão informados de acordo
              com a sua prescrição médica - documento essencial para iniciar o
              seu tratamento com canabinoides. O seu médico pode acessar
              informações mais completas e te orientar sobre as opções de
              produtos à base de cannabis.
            </p>
            <br />
          </div>
        )}
        {showButton && (
          <>
            <h6 className='mt-3'>
              <b>
                Atenção: a compra destes produtos tem como obrigatoriedade a
                prescrição médica e a autorização da Anvisa
              </b>
            </h6>
            <div className='d-flex mt-3 mb-5'>
              <div className='d-flex text-center mr-3'>
                <ButtonPrimary
                  text='Já tem prescrição ou autorização ?'
                  btnClass='products__btn__primary'
                  callBack={() => history.push('/paciente/prescricao')}
                />
              </div>
              <div className='d-flex text-center'>
                <ButtonPrimary
                  text=' Quer encontrar um médico prescritor ?'
                  btnClass='products__btn__primary'
                  callBack={() => history.push('/paciente/medicos')}
                />
              </div>
            </div>
          </>
        )}
        <div className='row mt-3'>
          {roles &&
          (roles === 'administrator' || roles === 'physician') &&
          products.map(
            product =>
              product.is_controlled &&
              (product.active === true || showDisableMedicines === true) &&
              (
                <ProductCard product={product} />
              ),
          )}
        </div>
      </div>
    </div>
  )
}

Products.propTypes = {
  products: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      resume: PropTypes.string,
      price: PropTypes.number.isRequired,
    }),
  ).isRequired,
}
