import React from 'react'
import { jsPDF } from 'jspdf'
import api from '~/services/api'
import { cnpj, cpf } from 'cpf-cnpj-validator'
import { toast } from 'react-toastify'

const showValidatePassword = (pwd, repeat, Ok, Block) => {
  const numberRegex = /[0-9]/
  const upperCaracter = /[A-Z]/
  const lowerCaracter = /[a-z]/
  let equality = false
  let number = false
  let passLen = false
  let upperPwd = false
  let lowerPwd = false
  if (pwd === repeat) {
    if (pwd.length > 0) {
      equality = true
    }
  }
  if (numberRegex.test(pwd)) {
    number = true
  }
  if (pwd.length >= 8) {
    passLen = true
  }
  if (upperCaracter.test(pwd)) {
    upperPwd = true
  }
  if (lowerCaracter.test(pwd))  lowerPwd = true

  return (
    <>
      <p className={equality ? 'text-info' : 'text-danger'}>
        {equality ? 'As senhas coincidem' : 'As senhas não coincidem'}
        <img
          src={equality ? Ok : Block}
          alt=''
          style={{ height: '17px' }}
          className='ml-1'
        />
      </p>
      <p className={`mt-1 ${number ? 'text-info' : 'text-danger'}`}>
        Senha deve conter ao menos um múmero
        <img
          src={number ? Ok : Block}
          alt=''
          style={{ height: '17px' }}
          className='ml-1'
        />
      </p>
      <p className={`mt-1 ${passLen ? 'text-info' : 'text-danger'}`}>
        A senha não tem 8 caracteres de comprimento
        <img
          src={passLen ? Ok : Block}
          alt=''
          style={{ height: '17px' }}
          className='ml-1'
        />
      </p>
      <p className={`mt-1 ${upperPwd ? 'text-info' : 'text-danger'}`}>
        A senha deve conter ao menos um caractere maiúsculo
        <img
          src={upperPwd ? Ok : Block}
          alt=''
          style={{ height: '17px' }}
          className='ml-1'
        />
      </p>
      <p className={`mt-1 ${lowerPwd ? 'text-info' : 'text-danger'}`}>
        A senha deve conter ao menos um caractere minúsculo
        <img
          src={upperPwd ? Ok : Block}
          alt=''
          style={{ height: '17px' }}
          className='ml-1'
        />
      </p>
    </>
  )
}

const round = (num, places) => {
  return +parseFloat(num).toFixed(places)
}

const transform = async src => {
  const imageBlob = await api.get(src, { responseType: 'blob' })

  const reader = new FileReader()
  return new Promise((resolve, reject) => {
    reader.onloadend = () => resolve(reader.result)
    reader.readAsDataURL(imageBlob.data)
  })
}

const paginateItens = (items, pageActual, limitItems) => {
  let result = []
  let totalPage = Math.ceil(items.length / limitItems)
  let count = pageActual * limitItems - limitItems
  let delimiter = count + limitItems

  if (pageActual <= totalPage) {
    for (let i = count; i < delimiter; i++) {
      if (items[i] != null) {
        result.push(items[i])
      }
      count++
    }
  }

  return result
}


const generatePdf = (
  prescription,
  anvisa,
  rg,
  address,
  outputFileName = 'user.pdf',
) => {
  const doc = new jsPDF()
  if (prescription && prescription.substr(0, 20) !== 'data:application/pdf')
    doc.addImage(prescription, 'JPEG', 15, 40, 180, 160)

  if (anvisa && anvisa.substr(0, 20) !== 'data:application/pdf')
    doc.addPage('a4')
  doc.addImage(anvisa, 'JPEG', 15, 40, 180, 160)

  if (rg && rg.substr(0, 20) !== 'data:application/pdf')
    doc.addPage('a4')
  doc.addImage(rg, 'JPEG', 15, 40, 180, 160)

  if (address && address.substr(0, 20) !== 'data:application/pdf')
    doc.addPage('a4')
  doc.addImage(address, 'JPEG', 15, 40, 180, 160)
  doc.save(outputFileName)
}
const hasError = (value) => {
  const objs = Object.entries(value)

  for (const item of objs) {
    return !String(item[1]) || String(item[1]).length <= 0

  }
  return false
}

const validateCpfAndCnpj = (value, isCpf = true) => {
  value = value?.replaceAll('.', '')
  value = value?.replaceAll('-', '')
  if (isCpf) {
    return cpf.isValid(value)
  } else
    return cnpj.isValid(value)
}

const showArrayMessages = (data = []) => {
  let items
  items = Object.entries(data)
    .map(v => {
      return v[1]
    })
    .join('\n')
  return toast.error(items)
}


const removeCaracter = (str) => {
  if (str) return str.replace(/[.,/#!$%^&*;:{}=\-_`~()]/g, '')
  return ''
}

const validateEmail = (email) => {
  const regex = /\S+@\S+\.\S+/
  return regex.test(email)
}

const searchPatientAndSetValues = (cpf, callBack, isAdmin) => {
  if (validateCpfAndCnpj(cpf)) {
    let cleanCpf = cpf.replaceAll('.', '')
    cleanCpf = cleanCpf.replaceAll('-', '')
    api.get(`patients?cpf=${cleanCpf}`)
      .then((r) => {
        if (r.data.data && r.data.data[0] && isAdmin) {
          const { email, id } = r.data.data[0]
          const { full_name, mobile_number } = r.data.data[0].profile
          callBack(prevState => {
            return {
              ...prevState,
              id,
              email,
              name: full_name,
              mobilePhone: mobile_number,
            }
          })
        }
        if (!isAdmin && r.data.profile) {
          const { email, id } = r.data
          const { full_name, mobile_number } = r.data?.profile

          callBack(prevState => {
            return {
              ...prevState,
              id,
              email,
              name: full_name,
              mobilePhone: mobile_number,
            }
          })

        }
      })
  }
}

const shareMessage = (physician) => {
  let socialNetworks = {
    whatsapp: '',
    facebook: '',
    linkedin: '',
  }
  if (physician?.user?.profile?.gender === 'f') {
    socialNetworks.whatsapp = encodeURIComponent(`Este é o perfil da Dra ${physician?.user?.profile?.full_name} na Dr. Cannabis. https://drcannabis.com.br/perfil/${physician?.slug}`)
    socialNetworks.linkedin = `https://drcannabis.com.br/perfil/${physician?.slug}`
    socialNetworks.facebook = `https://drcannabis.com.br/perfil/${physician?.slug}`
  } else {
    socialNetworks.whatsapp = encodeURIComponent(`Este é o perfil do Dr ${physician?.user?.profile?.full_name} na Dr. Cannabis. https://drcannabis.com.br/perfil/${physician?.slug}`)
    socialNetworks.linkedin = `https://drcannabis.com.br/perfil/${physician?.slug}`
    socialNetworks.facebook = `https://drcannabis.com.br/perfil/${physician?.slug}`
  }

  return socialNetworks
}

const mobileShare = (physician) => {
  if (navigator.share !== undefined) {
    let title = ''
    let text = ''


    if (physician?.user?.profile?.gender === 'f') {
      title = `${physician?.user?.profile?.full_name}`
      text = `Este é o perfil da Dra ${physician?.user?.profile?.full_name} na Dr. Cannabis.`

    }
    navigator.share({
      title,
      text,
      url: `https://drcannabis.com.br/perfil/${physician?.slug}`,
    })
      .then(r => r)
      .catch((error) => error)
  }
}

export {
  round,
  hasError,
  transform,
  generatePdf,
  paginateItens,
  showArrayMessages,
  validateCpfAndCnpj,
  showValidatePassword,
  removeCaracter,
  validateEmail,
  searchPatientAndSetValues,
  shareMessage,
  mobileShare,
}
