import React from 'react'
import { adminDeleteAppointment } from '~/services/requests'
import { toast } from 'react-toastify'
import { ButtonPrimary, ButtonSecondary } from '~/components'
import { Modal } from 'react-bootstrap'


const ModalDeletedAppointment = ({ appointment, show, closeModal }) => {

  const deleteAppointment = () => {
    if (appointment?.uuid) {
      adminDeleteAppointment(appointment?.uuid)
        .then(() => {
          document.location.reload(true)
          toast.success('Deletado com sucesso!!!')
        })
        .catch(() => toast.error('Erro ao deletar consulta'))
    }
  }

  return (
    <Modal show={show} onHide={() => closeModal(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Editar consulta</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h3>Deseja deletar essa consulta ?</h3>
      </Modal.Body>
      <Modal.Footer>
        <ButtonPrimary text='Confirmar' callBack={() => deleteAppointment()} />
        <ButtonSecondary text='Cancelar' dismiss='modal' callBack={() => closeModal(false)} />
      </Modal.Footer>
    </Modal>
  )
}

export { ModalDeletedAppointment }
