import React, { useEffect, useState } from 'react'
import { ButtonPrimary, CustomInput } from '~/components'
import moment from 'moment'


const DatePicker = (
  {
    initialDate = moment().format('YYYY-MM-DD'),
    returnFormat = 'YYYY/MM/DD',
    minDate,
    maxDate,
    classPrevBtn = 'fa-arrow-circle-left',
    classNextBtn = 'fa-arrow-circle-right',
    callBack,
    showMessage = false,
    justify=''
  }) => {

  const [date, setDate] = useState(initialDate)


  const changeDateButton = (type = 'next') => {
    const newDate = moment(date).add(type === 'next' ? 1 : -1, 'days').format('YYYY-MM-DD')
    if (minDate || maxDate) {
      if (type === 'prev') {
        if (minDate < newDate) {
          setDate(newDate)
        }
      }
      if (type === 'next') {
        if (maxDate > newDate) {
          setDate(newDate)
        }
      }
    } else setDate(newDate)
  }

  useEffect(() => {
    if (typeof callBack === 'function') {
      callBack(moment(date).format(returnFormat))
    }
  }, [date])

  const changeInput = (e) => {
    const { value } = e.currentTarget
    setDate(value)
  }

  return (
    <div className={`d-flex ${justify}`}>
      <div className='datepicker-buttons h-100 mt-1'>
        <button typeof='button' className='btn' onClick={() => changeDateButton('prev')}>
          <i className={`fas ${classPrevBtn} text-success fa-2x`} />
        </button>
      </div>
      <div className='datepicker-input-date mt-2'>
        <CustomInput inputType='date' defaultValue={date} callBack={changeInput} inputClass='my-0' />
      </div>
      <div className='datepicker-buttons h-100 mt-1'>
        <button typeof='button' className='btn' onClick={() => changeDateButton('next')}>
          <i className={`fas ${classNextBtn} text-success fa-2x`} />
        </button>
      </div>
      <div className='datepicker-button-now'>
        <ButtonPrimary text='Hoje' btnMt='mt-2' callBack={() => setDate(moment().format('YYYY-MM-DD'))} />
      </div>
      {showMessage && <p className='mt-3 text-center ml-3 font-rem-1-2 datepicker-date'>
        {(moment(date).format('dddd'))} - {(moment(date).format('DD'))} de {(moment(date).format('MMMM'))}
      </p>}
    </div>
  )
}

export { DatePicker }
