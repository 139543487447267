import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { toast } from 'react-toastify'
import { ModalCreditCard } from '~/pages/telemed/ModalCreditCard'
import { patientCheckEnroll, patientGetCalendar } from '~/services/requests'
import { Calendar } from '~/components/Calendar/Calendar'
import { useHistory, useParams } from 'react-router-dom'
import { treatmentOfDates } from '~/shared/telemedOptions'
import ModalFinishRegistration from '~/components/ModalFinishRegistration'
import { useSelector } from 'react-redux'


const PatientCalendar = () => {
  let { id, date } = useParams()
  const [showModal, setShowModal] = useState(false)
  const { session } = useSelector(state => state.auth)
  const history = useHistory()


  useEffect(() => {
    if (localStorage.getItem('telemedicine') === 'false') {
      patientCheckEnroll()
        .then(() => localStorage.setItem('telemedicine', 'true'))
        .catch((e) => {
          toast.error('Finalize seu cadastro')
          history.push('/paciente/perfil/editar')
        })
    }
  }, [])

  useEffect(() => {
    !session.profile.full_name || !session.profile.cpf || !session.profile.birthdate || !session.profile.mobile_number || !session.profile.mobile_number
      ? setShowModal(true)
      : setShowModal(false)
  }, [session])


  return (
    <>
      <div className='container-fluid my-agenda mb-5 view-presential px-4'>
        <div className='row'>
          <div className='col-lg-12 d-flex align-items-center justify-content-between'>
            <h1 className='page-title py-3'>Agendar consulta</h1>
          </div>
        </div>
        <div className='row'>
          <Calendar calendarClass='calendarMyAgenda'
                    buttons={false}
                    request={patientGetCalendar}
                    treatment={treatmentOfDates}
                    eventDurationEditable={false}
                    eventResizableFromStart={false}
                    editable={false}
                    resourceId={id}
                    openModalId='modal-consultation'
                    initialDate={date ?? moment().format('YYYY-MM-DD')}
          />
        </div>
        <ModalCreditCard />
      </div>
      <ModalFinishRegistration
        show={showModal}
        handleClose={() => {
        }}
        backdrop='static'
      />
    </>
  )
}

export { PatientCalendar }
