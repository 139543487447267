import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { ReactTinyLink } from 'react-tiny-link'

import { Creators as AuthActions } from '~/store/ducks/auth'

import Yup from '~/helpers/yup'
import { Creators as ErrorActions } from '~/store/ducks/error'
import api from '~/services/api'
import { CustomCheckbox, CustomInput, CustomMultSelect, CustomSelect, CustomTextArea } from '~/components'
import { ButtonPrimary } from '~/components/Buttons/PrimaryButton'
import Skeleton from 'react-loading-skeleton'

import StatesBrasil from '~/helpers/StatesBrasil.json'
import Diseases from '~/helpers/Diseases.json'
import Specialties from '~/helpers/Specialties.json'

const EditPhysicianSchema = Yup.object().shape({
  physician: Yup.object().shape({
    crm: Yup.string().required('Digite seu CRM'),
    uf: Yup.string().required('Selecione o UF do seu CRM'),
    specialty: Yup.string().required('Selecione sua especialidade'),
    about: Yup.string(),
  }),
})

export default function Edit({ history }) {
  const { register, handleSubmit, errors, setValue, watch } = useForm({
    validationSchema: EditPhysicianSchema,
  })
  // const error = useSelector(state => state.error)
  const { session } = useSelector(state => state.auth)
  const dispatch = useDispatch()

  const [loading, setLoading] = useState(false)
  const [defaultDiseases, setDefaultDiseases] = useState([])
  const [listLinks, setListLinks] = useState([])
  const [link, setLink] = useState([])
  const [listArticles, setListArticles] = useState([])
  const [articles, setArticles] = useState([])


  useEffect(() => {
    register({ name: 'physician.diseases' })
  })

  const diseases = watch('physician.diseases')

  useEffect(() => {
    if (session.physician)
      if (Array.isArray(session.physician.diseases)) {
        setDefaultDiseases(session.physician.diseases)
        setValue('physician.diseases', session.physician.diseases)
      }
    if (Array.isArray(session.physician?.articles)) setListArticles(session.physician?.articles)
    if (Array.isArray(session.physician?.links)) setListLinks(session.physician?.links)
    setValue('physician', session.physician)
  }, [session.physician])

  const onSubmit = async data => {
    setLoading(true)
    try {
      if (Array.isArray(diseases) || Array.isArray(defaultDiseases)) data.physician.diseases = diseases ?? defaultDiseases
      if (Array.isArray(listArticles)) data.physician.articles = listArticles
      if (Array.isArray(listLinks)) data.physician.links = listLinks
      await api.put(`/users/${session.id}`, data)
      dispatch(AuthActions.getSessionRequest())
      setLoading(false)
      const to = '/dashboard'
      history.push(to)
    } catch (e) {
      dispatch(ErrorActions.setError(e.response))
      setLoading(false)
    }
  }

  const addingLink = (listKey, setListKey, origin, linkValue) => {
    if (linkValue) {
      let list = Array.isArray(listKey) ? listKey : []
      list.push(linkValue)
      setListKey(list)
      origin('')
    }
  }

  const changeLink = (e, set) => {
    set(e.currentTarget.value)
  }

  const removeLink = (index, set, list = []) => {
    let newList = []
    list.map((result, removeIndex) => {
      if (removeIndex !== index) {
        newList.push(result)
      }
    })
    return set(newList)
  }

  return (
    <>
      {
        !session.roles[0] === '' || !session.roles[0] ? (
          <div className='contanier-fluid p-5 patient__profile__show__skeleton'>

            <div className='row '>
              <div className='col-lg-12'>
                <Skeleton width='60%' />
              </div>
            </div>

            <div className='row mt-5'>
              <div className='col-lg-6 patient__profile__show__skeleton__mb'>
                <Skeleton width='70%' />
                <Skeleton className='mt-2' width='50%' />
              </div>
              <div className='col-lg-6'>
                <Skeleton width='70%' />
                <Skeleton className='mt-2' width='50%' />
              </div>
            </div>

            <div className='row mt-5'>
              <div className='col-lg-6 patient__profile__show__skeleton__mb'>
                <Skeleton width='70%' />
                <Skeleton className='mt-2' width='50%' />
              </div>
              <div className='col-lg-6'>
                <Skeleton width='70%' />
                <Skeleton className='mt-2' width='50%' />
              </div>
            </div>

            <div className='row mt-5'>
              <div className='col-lg-12 patient__profile__show__skeleton__mb'>
                <Skeleton width='70%' />
                <Skeleton className='mt-2' width='100%' height='100px' />
              </div>

            </div>

            <div className='row mt-5'>
              <div className='col-lg-4 patient__profile__show__skeleton__mb'>
                <Skeleton width='50%' />
                <Skeleton className='mt-2' width='70%' />
              </div>
              <div className='col-lg-4'>
                <Skeleton width='50%' />
                <Skeleton className='mt-2' width='70%' />
              </div>
              <div className='col-lg-4'>
                <Skeleton width='50%' />
                <Skeleton className='mt-2' width='70%' />
              </div>
            </div>

          </div>
        ) : (
          <div className='container-fluid physician__profile__editPhysician'>
            <div className='row'>
              <div className='col-lg-12'>
                <h2 className='physician__profile__editPhysician__title'>Termine seu cadastro</h2>
              </div>
            </div>
            <div className='row'>
              <div className='col-lg-12'>
                <h2 className='physician__profile__editPhysician__info__title'>Informações médicas</h2>
              </div>
            </div>
            <div className='row'>
              <div className='col-lg-12'>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className='row'>
                    <div className='col-lg-8'>
                      <CustomInput
                        label='CRM'
                        inputName='physician.crm'
                        inputPlaceholder='000000'
                        error={errors.physician?.crm}
                        register={register}
                      />
                    </div>
                    <div className='col-lg-4'>
                      <CustomSelect
                        label='UF'
                        inputName='physician.uf'
                        error={errors.physician?.uf}
                        register={register}
                      >
                        {StatesBrasil.map(state => (
                          <option value={state.value}>{state.value}</option>
                        ))}
                      </CustomSelect>
                    </div>
                    <div className='col-lg-6'>
                      <CustomSelect
                        label='Especialidade'
                        inputName='physician.specialty'
                        error={errors.physician?.specialty}
                        register={register}
                      >
                        {Specialties.map(specialty => (
                          <option value={specialty}>{specialty}</option>
                        ))}
                      </CustomSelect>
                    </div>

                    <div className='col-lg-6'>
                      <CustomMultSelect
                        label='Lista de patologias atendidas'
                        listOptions={Diseases}
                        inputName={'physician.diseases'}
                        inputPlaceholder=''
                        value={defaultDiseases ?? diseases}
                        register={register}
                        setValue={setValue}
                      />


                    </div>

                    <div className='col-lg-12'>
                      <CustomTextArea
                        label='Sobre'
                        inputName='physician.about'
                        inputPlaceholder='Conte um pouco sobre sua vida profissional e a área que atua. Esta informação ficará visível para pacientes quando o seu perfil for aprovado.'
                        error={errors.physician?.about}
                        register={register}
                      />
                    </div>

                    <div className='col-lg-4'>
                      <CustomInput
                        label='Preço da consulta'
                        inputName='physician.price'
                        error={errors.physician?.price}
                        register={register}
                      />
                    </div>

                    <div className='col-lg-12'>
                      <CustomCheckbox
                        inputName='physician.prescribes'
                        label='Tenho experiência na prescrição de produtos à base de cannabis.'
                        register={register}
                        error={errors.physician?.prescribes}
                      />
                    </div>

                    <div className='col-lg-4'>
                      <CustomInput
                        label='Nome do(a) Secretário(a)'
                        inputName='physician.secretary_name'
                        error={errors.physician?.secretary_name}
                        register={register}
                      />
                    </div>

                    <div className='col-lg-4'>
                      <CustomInput
                        label='Email do(a) Secretário(a)'
                        inputName='physician.secretary_email'
                        error={errors.physician?.secretary_email}
                        register={register}
                      />
                    </div>

                    <div className='col-lg-4'>
                      <CustomSelect
                        label='Opções de email'
                        inputName='physician.secretary_option'
                        error={errors.physician?.secretary_option}
                        register={register}
                      >
                        <option value='physician'>Enviar apenas para mim</option>
                        <option value='secretary'>
                          Enviar apenas para secretária
                        </option>
                        <option value='both'>Enviar email para ambos</option>
                      </CustomSelect>
                    </div>

                    <div className='col-12 my-3'>
                      <div className='card'>
                        <div className='card-header d-flex justify-content-center'>
                          <h6>Videos</h6>
                        </div>
                        <div className='card-body'>
                          <div className='row'>
                            {Array.isArray(listLinks) &&
                            listLinks.map(
                              (data, index) =>
                                data && (
                                  <div className='col-4 mt-2'>
                                    <ReactTinyLink
                                      cardSize='large'
                                      showGraphic={true}
                                      maxLine={3}
                                      minLine={1}
                                      url={data}
                                      proxyUrl="https://corsanywhere.herokuapp.com"
                                    />
                                    <ButtonPrimary
                                      text='Deletar'
                                      btnClass='btn_delete'
                                      callBack={() =>
                                        removeLink(index, setListLinks, listLinks)
                                      }
                                    />
                                  </div>
                                ),
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='col-12'>
                      <label htmlFor='links'>Link do video</label>
                      <div className='row'>
                        <div className='col-lg-10'>
                          <CustomInput
                            id='links'
                            inputPlaceholder='youtube.com'
                            defaultValue={link}
                            loading={loading}
                            skeletonDivClass='mb-4'
                            callBack={e => changeLink(e, setLink)}
                          />
                        </div>
                        <div className='col-lg-2'>
                          <ButtonPrimary
                            text='adicionar'
                            width='w-100'
                            btnMt='mt-2'
                            btnType='button'
                            callBack={() =>
                              addingLink(listLinks, setListLinks, setLink, link)
                            }
                            disabled={!link.length > 0}
                          />
                        </div>


                      </div>
                    </div>

                    <div className='col-12 my-3'>
                      <div className='card'>
                        <div className='card-header d-flex justify-content-center'>
                          <h6>Artigos</h6>
                        </div>
                        <div className='card-body'>
                          <div className='row'>
                            {Array.isArray(listArticles) &&
                            listArticles.map(
                              (data, index) =>
                                data && (
                                  <div className='col-4 mt-2'>
                                    <ReactTinyLink
                                      cardSize='large'
                                      showGraphic={true}
                                      maxLine={3}
                                      minLine={1}
                                      url={data}
                                      proxyUrl="https://corsanywhere.herokuapp.com"
                                    />
                                    <ButtonPrimary
                                      text='Deletar'
                                      btnClass='btn_delete'
                                      callBack={() =>
                                        removeLink(index, setListArticles, listArticles)
                                      }
                                    />
                                  </div>
                                ),
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='col-12'>
                      <label htmlFor='links'>Link do artigo</label>
                      <div className='row'>
                        <div className='col-lg-10'>
                          <CustomInput
                            id='articles'
                            inputPlaceholder='articles.com'
                            defaultValue={articles}
                            loading={loading}
                            skeletonDivClass='mb-4'
                            callBack={e => changeLink(e, setArticles)}
                          />
                        </div>

                        <div className='col-lg-2'>
                          <ButtonPrimary
                            text='Adicionar'
                            width='w-100'
                            btnMt='mt-2'
                            btnType='button'
                            callBack={() =>
                              addingLink(
                                listArticles,
                                setListArticles,
                                setArticles,
                                articles,
                              )
                            }
                            disabled={!articles.length > 0}
                          />

                        </div>


                      </div>
                    </div>

                    <div className='col-lg-12'>
                      <ButtonPrimary
                        btnType='submit'
                        text={loading ? 'Carregando...' : 'Continuar'}
                      >
                      </ButtonPrimary>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        )
      }
    </>
  )
}
