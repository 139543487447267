import React, { useState, useEffect } from 'react'
import { Modal, Row, Col } from 'react-bootstrap'
import { useForm } from 'react-hook-form'

import api from '~/services/api'
import { MedicineList } from './styles'
import Button from '~/components/Button'
import { Input } from '~/components/Form'
import { ButtonPrimary } from '~/components'

export default function ModalMedicine({ prescriptionId, onHide, show }) {
  const { register, watch } = useForm()
  const [prescription, setPrescription] = useState({})
  const [medicines, setMedicines] = useState([])
  const search = watch('search')

  const fetchPrescriptions = async () => {
    if (prescriptionId) {
      const res = await api.get(`/prescriptions/${prescriptionId}`)
      setPrescription(res.data)
    }
  }

  useEffect(() => {
    fetchPrescriptions()
  }, [show])

  const fetchMedicines = async () => {
    const res = await api.get(`/medicines?name=${search}`)
    setMedicines(res.data)
  }

  useEffect(() => {
    fetchMedicines()
  }, [search])

  const addMedicine = async id => {
    let listAux = medicines.data
    let listMedicines = medicines
    const medicinesId = prescription.medicines?.map(presc => presc.id) ?? []
    medicinesId.push(id)
    const data = {
      medicines: medicinesId.map(meds => ({ id: meds })),
    }
    await api.put(`/prescriptions/${prescriptionId}`, data)
    await fetchPrescriptions()
    const index = listAux.findIndex(val => val.id === id)
    if (index > -1) {
      listAux.splice(index, 1)
      listMedicines.data = listAux
      setMedicines(listMedicines)
    }
  }

  const removeMedicine = async id => {
    const index = prescription.medicines.findIndex(val => val.id === id)
    let listMedicines = medicines
    if (index > -1) {
      listMedicines.data.push(prescription.medicines[index])
      setMedicines(listMedicines)
    }
    let medicinesId = prescription.medicines?.map(presc => presc.id) ?? []
    medicinesId = medicinesId.filter(med => med !== id)
    const data = {
      medicines: medicinesId.map(meds => ({ id: meds })),
    }
    await api.put(`/prescriptions/${prescriptionId}`, data)
    await fetchPrescriptions()
  }

  const validateList = id => {
    if (Array.isArray(prescription.medicines)) {
      const index = prescription.medicines.findIndex(val => val.id === id)
      return index === -1
    }
    return true
  }

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Selecionar produtos
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col>
            <Input
              type="text"
              name="search"
              placeholder="Buscar"
              size="md"
              icon="icon-search"
              ref={register}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <MedicineList className="my-3">
              {prescription.medicines?.map(medicine => (
                <div className="medicine">
                  <div className="name">{medicine.name}</div>
                  <div>
                    <Button
                      className="danger"
                      onClick={() => removeMedicine(medicine.id)}
                    >
                      Remover
                    </Button>
                  </div>
                </div>
              ))}
            </MedicineList>
          </Col>
        </Row>
        <hr className="separator" />
        <Row>
          <Col>
            <MedicineList className="my-3">
              {medicines.data?.map(
                medicine =>
                  validateList(medicine.id) && (
                    <div className="medicine">
                      <div className="name">{medicine.name}</div>
                      <div>
                        <Button
                          className="primary"
                          onClick={() => addMedicine(medicine.id)}
                        >
                          Adicionar
                        </Button>
                      </div>
                    </div>
                  ),
              )}
            </MedicineList>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <ButtonPrimary
          text='Fechar'
          callBack={onHide}/>
      </Modal.Footer>
    </Modal>
  )
}
