import React, { useState, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Container, Row, Col } from 'react-bootstrap'
import { useCountUp } from 'react-countup'


import { SectionBanner, SectionAbout, SectionGetMedicines } from './styles'
import Button from '~/components/Button'
import Newsletter from '~/components/Newsletter'
import VideoHome from '~/assets/videos/videohome.mp4'
import Paciente1 from '~/assets/images/paciente1.jpg'
import Paciente2 from '~/assets/images/paciente2.jpg'
import Paciente3 from '~/assets/images/paciente3.jpg'
import Medico1 from '~/assets/images/medico1.jpg'
import Medico2 from '~/assets/images/medico2.jpg'
import Medico3 from '~/assets/images/medico3.jpg'
import { useSelector } from 'react-redux'
import { Helmet } from 'react-helmet'

export default function Home() {
  const { session } = useSelector(state => state.auth)
  const [askMedicine, setAskMedicine] = useState('patient')
  const history = useHistory()
  const roles = localStorage.getItem('roles')
  const { countUp: countMedicos } = useCountUp({
    end: 3000,
    duration: 5,
    delay: 3,
  })
  const { countUp: countPacientes } = useCountUp({
    end: 30000,
    duration: 5,
    delay: 3,
  })
  const { countUp: countProdutos } = useCountUp({
    end: 47,
    duration: 5,
    delay: 3,
  })
  const { countUp: countPrescricoes } = useCountUp({
    end: 2100,
    duration: 5,
    delay: 3,
  })
  const { countUp: countDoencas } = useCountUp({
    end: 40,
    duration: 5,
    delay: 3,
  })
  const [routeLink, setRouteLink] = useState('/login')

  useEffect(() => {
    const redirectPage = () => {
      switch (roles) {
        case 'physician':
          return setRouteLink('/medicos')
        case 'responsible':
          return setRouteLink('/paciente')
        case 'patient':
          return setRouteLink('/paciente')
        case 'administrator':
          return setRouteLink('/admin')
        default:
          return setRouteLink('/login')
      }
    }
    redirectPage()
  })

  const title = 'Dr. Cannabis - Tudo sobre cannabis medicinal para você'
  const description = 'Encontre tratamento com cannabis medicinal! A Dr. Cannabis ' +
    'é a sua aliada: encontre médicos, conteúdo e todo o acolhimento que ' +
    'precisa.'

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta property='og:title' content={title} />
        <meta name='twitter:title' content={title} />
        <meta
          name='description'
          content={description}
        />
        <meta
          itemProp='description'
          content={description}
        />
        <meta
          name='og:description'
          content={description}
        />
        <meta
          name='twitter:description'
          content={description}
        />
      </Helmet>
      <SectionBanner>
        <Container fluid='lg'>
          <Row className='m-0'>
            <Col
              md={5}
              className='d-flex flex-column justify-content-between p-0'
            >
              <Row>
                <Col>
                  <h1 className='text-center text-md-left'>
                    O tratamento com cannabis pode mudar vidas e é legal no
                    Brasil
                  </h1>
                </Col>
              </Row>

              <Row className=' d-md-none mt-4 mb-4'>
                <Col md={7}>
                  <div className='banner-mobile'>
                    <video autoPlay muted loop>
                      <source src={VideoHome} type='video/mp4' />
                    </video>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <p className='text--white'>
                    <Link to='/cadastro?role=medico'>MÉDICO</Link>, pacientes em
                    busca de mais saúde esperam por você. Acesse conteúdos
                    científicos sobre cannabis, realize consultas e prescreva
                    online.
                  </p>
                </Col>
              </Row>
              <Row>
                <Col>
                  <p className='text--white mt-4 mb-4 mt-md-0 mb-md-0'>
                    <Link to='/cadastro?role=paciente'>PACIENTE</Link>, encontre
                    médicos qualificados, produtos à base de canabinoides e {'todo '}
                    o apoio e conforto que você precisa para viver melhor.
                  </p>
                </Col>
              </Row>
              <Row>
                <Col className="d-flex justify-content-center justify-content-md-start pr-0">
                  <Button className="white md text--sr px-2" onClick={()=> history.push(routeLink)}>
                    Comece aqui
                  </Button>
                </Col>
                {session?.roles[0] !== 'physician' &&
                session?.roles[0] !== 'administrator' &&
                <Col className='d-flex justify-content-center justify-content-md-start pl-0'>
                  <Link to='/paciente/medicos'>
                    <Button
                      id='comprar-cbd'
                      className='comprar-cbd primary md text--sr px-2'
                      style={{ border: '1px solid #fff' }}
                    >
                      Agende uma Consulta
                    </Button>
                  </Link>
                </Col>
                }
              </Row>
            </Col>
            <Col md={7} className='p-0 pl-3'>
              <div className='banner d-none d-md-block'>
                <video autoPlay muted loop>
                  <source src={VideoHome} type='video/mp4' />
                </video>
              </div>
            </Col>
          </Row>
        </Container>
      </SectionBanner>
      <SectionAbout>
        <Container fluid='lg'>
          <Row>
            <Col>
              <h3 className='text--grey-dark text--sm text-left text-md-center'>
                Sobre Cannabis Medicinal
              </h3>
              <p className='text text--grey text--sr text-justify'>
                A cannabis medicinal é uma importante ferramenta terapêutica
                para tratar doenças crônicas e sintomas associados. A atual
                literatura científica já fornece diferentes níveis de evidência
                para sustentar o uso dos canabinoides em uma variedade de
                condições de saúde como dor crônica, Alzheimer, ansiedade,
                fibromialgia, autismo, Parkinson, epilepsia e muitas outras. O
                acesso é permitido no Brasil desde 2015, quando famílias
                pressionaram a Anvisa para ter o direito de acessar legalmente a
                cannabis medicinal. Em 2019, a Organização Mundial da Saúde
                divulgou posição favorável ao uso terapêutico da planta. Hoje,
                milhares de pessoas já estão autorizadas a se tratar com
                cannabis medicinal no Brasil e com a Dr. Cannabis você também
                pode exercer esse direito
              </p>

              <h3 className='text--grey-dark text--sm text-left text-md-center'>
                Uma comunidade que só cresce
              </h3>

              <h3 className='d-md-none text--grey-dark text--sm text-center'>
                Uma comunidade que só cresce
              </h3>

              <div className='d-none d-md-flex justify-content-center'>
                <div className='box d-flex flex-column align-items-center'>
                  <p className='text--sr text--black font-rem-1-3 mb-2'>+{countMedicos}</p>
                  <p className='text--sm text--black font-rem-1-4'>Médicos</p>
                </div>
                <div className='box d-flex flex-column align-items-center'>
                  <p className='text--sr text--black font-rem-1-3 mb-2'>+{countPacientes}</p>
                  <p className='text--sm text--black font-rem-1-4'>Pacientes</p>
                </div>
                <div className='box d-flex flex-column align-items-center'>
                  <p className='text--sr text--black font-rem-1-3 mb-2'>+{countProdutos}</p>
                  <p className='text--sm text--black font-rem-1-4'>Produtos</p>
                </div>
                <div className='box d-flex flex-column align-items-center'>
                  <p className='text--sr text--black mb-2 font-rem-1-3'>
                    +{countPrescricoes}
                  </p>
                  <p className='text--sm text--black font-rem-1-4'>Prescrições</p>
                </div>
                <div className='box d-flex flex-column align-items-center'>
                  <p className='text--sr text--black mb-2 font-rem-1-3'>+{countDoencas}</p>
                  <p className='text--sm text--black font-rem-1-4'>Doenças Tratadas</p>
                </div>
              </div>

              <div className='d-flex flex-column align-items-center d-md-none mb-3'>
                <div className='d-flex'>
                  <div className='box d-flex flex-column align-items-center'>
                    <p className='number text--sr text--black mb-2 font-rem-1-3'>+3000</p>
                    <p className='decease text--sm text--black font-rem-1-4'>Médicos</p>
                  </div>
                  <div className='box d-flex flex-column align-items-center'>
                    <p className='number text--sr text--black mb-2 font-rem-1-3'>+30000</p>
                    <p className='decease text--sm text--black font-rem-1-4'>Pacientes</p>
                  </div>
                </div>
                <div className='d-flex'>
                  <div className='box d-flex flex-column align-items-center'>
                    <p className='number text--sr text--black mb-2 font-rem-1-3'>+35</p>
                    <p className='decease text--sm text--black font-rem-1-4'>Produtos</p>
                  </div>
                  <div className='box d-flex flex-column align-items-center'>
                    <p className='number text--sr text--black mb-2 font-rem-1-3'>+700</p>
                    <p className='decease text--sm text--black  font-rem-1-4'>Prescrições</p>
                  </div>
                </div>
                <div className='d-flex'>
                  <div className='box d-flex flex-column align-items-center'>
                    <p className='number text--sr text--black mb-2 font-rem-1-3'>+20</p>
                    <p className='decease text--sr text--black font-rem-1-4'>
                      Doenças tratadas
                    </p>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </SectionAbout>
      <SectionGetMedicines>
        <Container fluid='lg'>
          <Row>
            <Col className=' mb-4 mb-md-5'>
              <h3 className='text--grey-dark text--sm text-left text-md-center'>
                Como pedir seu produto
              </h3>
              <p className='text text--rr text--grey text-justify'>
                Se tratar com cannabis medicinal no Brasil nunca foi tão rápido
                como agora. Para ter acesso legal, você deve seguir 3 passos:
              </p>
              <div className='d-flex justify-content-center'>
                <Button
                  className={`btn--sr lg radius left ${
                    askMedicine === 'patient' ? 'blue' : 'outline-blue'
                  }`}
                  onClick={() => setAskMedicine('patient')}
                >
                  Para paciente
                </Button>
                <Button
                  className={`btn--sr lg radius right ${
                    askMedicine === 'physician' ? 'blue' : 'outline-blue'
                  }`}
                  onClick={() => setAskMedicine('physician')}
                >
                  Para médico
                </Button>
              </div>
            </Col>
          </Row>
        </Container>

        <Container fluid='lg' className='d-none d-md-block'>
          <Row>
            <Col className=' d-flex flex-column align-items-center mt-4'>
              <div className='d-flex'>
                <div className='box d-flex flex-column p-3'>
                  <h1 className='box-title text--rm mb-3'>01</h1>
                  <p className='box-text text--grey text--rr text-justify'>
                    {askMedicine === 'patient'
                      ? `Escolha o médico prescritor que preferir e realize uma consulta online ou presencial. Faça login para encontrar médicos.`
                      : `Saiba o que a atual pesquisa diz sobre o uso da cannabis medicinal no tratamento de diversas patologias acessando artigos científicos, relatos de caso e muito mais!`}
                  </p>
                </div>
                <div className='box box--margin'>
                  <img
                    className='box-img box-img'
                    src={`${askMedicine === 'patient' ? Paciente2 : Medico2}`}
                    alt=''
                  />
                </div>
                <div className='box d-flex flex-column p-3'>
                  <h1 className='box-title text--rm mb-3'>03</h1>
                  <p className='box-text text--grey text--rr text-justify'>
                    {askMedicine === 'patient'
                      ? `Receba a autorização, compre seu produto e começar o seu tratamento com cannabis medicinal.`
                      : `Receba pacientes em busca de tratamentos com cannabis medicinal em seu consultório. Você também pode atendê-los remotamente.`}
                  </p>
                </div>
              </div>
              <div className='d-flex mb-5'>
                <div className='box box--margin'>
                  <img
                    className='box-img box-img'
                    src={`${askMedicine === 'patient' ? Paciente1 : Medico1}`}
                    alt=''
                  />
                </div>
                <div className='box d-flex flex-column p-3'>
                  <h1 className='box-title text--rm mb-3'>02</h1>
                  <p className='box-text text--grey text--rr text-justify'>
                    {askMedicine === 'patient'
                      ? `Envie a prescrição médica e o pedido de importação para a Anvisa. Fique tranquilo! Nós te ajudamos com este processo gratuitamente.`
                      : `Prescreva cannabis medicinal de forma rápida e fácil através do site Dr. Cannabis. Fique tranquilo! Seu paciente receberá acolhimento para as próximas etapas do processo.`}
                  </p>
                </div>
                <div className='box box--margin'>
                  <img
                    className='box-img box-img'
                    src={`${askMedicine === 'patient' ? Paciente3 : Medico3}`}
                    alt=''
                  />
                </div>
              </div>
              <div className='text-center mt-md-4 mb-md-5'>
                <Link to='/cadastro'>
                  <Button className='primary xl text--sm'>Cadastre-se!</Button>
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
        <Container fluid='lg' className='d-md-none'>
          <Row>
            <div className='card'>
              <div className='card-horizontal'>
                <div className='img-square-wrapper'>
                  <img
                    src={askMedicine === 'patient' ? Paciente2 : Medico2}
                    alt={
                      askMedicine === 'patient'
                        ? 'Escolha o médico'
                        : 'Conheça pesquisas'
                    }
                  />
                </div>
                <div className='card-body'>
                  <h1 className='box-title text--rm mb-3 d-block '>01</h1>
                  <p className='box-text text--grey text--rr text-justify'>
                    {askMedicine === 'patient'
                      ? `Escolha o médico prescritor que preferir e realize uma consulta online ou presencial. Faça login para encontrar médicos.`
                      : `Saiba o que a atual pesquisa diz sobre o uso da cannabis medicinal no tratamento de diversas patologias acessando artigos científicos, relatos de caso e muito mais!`}
                  </p>
                </div>
              </div>
            </div>

            <div className='card'>
              <div className='card-horizontal'>
                <div className='card-body'>
                  <h1 className='box-title text--rm mb-3'>02</h1>
                  <p className='box-text text--grey text--rr text-justify'>
                    {askMedicine === 'patient'
                      ? `Envie a prescrição médica e o pedido de importação para a Anvisa. Fique tranquilo! Nós te ajudamos com este processo gratuitamente.`
                      : `Receba pacientes em busca de tratamentos com cannabis medicinal em seu consultório. Você também pode atendê-los remotamente.`}
                  </p>
                </div>
                <div className='img-square-wrapper'>
                  <img
                    src={askMedicine === 'patient' ? Paciente1 : Medico1}
                    alt={
                      askMedicine === 'patient'
                        ? 'Nós te ajudamos'
                        : 'Receba pacientes'
                    }
                  />
                </div>
              </div>
            </div>

            <div className='card'>
              <div className='card-horizontal'>
                <div className='img-square-wrapper'>
                  <img
                    src={askMedicine === 'patient' ? Paciente3 : Medico3}
                    alt={
                      askMedicine === 'patient'
                        ? 'Começe seu tratamento'
                        : 'Fique tranquilo seu paciente será acolhido'
                    }
                  />
                </div>
                <div className="card-body">
                  <h1 className="box-title text--rm mb-3">03</h1>
                  <p className="box-text text--grey text--rr text-justify">
                    {askMedicine === 'patient'
                      ? `Receba a autorização, compre seu produto através da Dr. Cannabis e receba em casa para começar o seu tratamento com cannabis medicinal.`
                      : `Prescreva cannabis medicinal de forma rápida e fácil através do site Dr. Cannabis. Fique tranquilo! Seu paciente receberá acolhimento para as próximas etapas do processo.`}
                  </p>
                </div>
              </div>
            </div>
          </Row>
        </Container>
      </SectionGetMedicines>
      <a name="news">
        <Newsletter />
      </a>
    </>
  )
}
