import React from 'react'

import { Link, useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Helmet } from 'react-helmet'
import { ButtonPrimary } from '~/components'

export default function About() {
  const { session } = useSelector(state => state.auth)
  const history = useHistory()
  const geyUrl = () => {
    if (session) {
      switch (session.roles[0]) {
        case 'administrator':
          return 'https://drcannabis.com.br/admin'
        case 'physician':
          return 'https://drcannabis.com.br/medico'
        case 'patient':
          return 'https://drcannabis.com.br/paciente'
        default:
          return 'https://drcannabis.com.br/login'
      }
    }
    return 'https://drcannabis.com.br/login'
  }
  const title = 'Comprar CBD legalmente é mais fácil com a Dr. Cannabis'
  const description = 'A cannabis medicinal pode mudar vidas. Por isso, a Dr. Cannabis te ajuda em tudo o que você ' +
    'precisa para comprar CBD legalmente no Brasil.'
  return (
    <div className='buy__cbd'>
      <Helmet>
        <title>{title}</title>
        <meta property='og:title' content={title} />
        <meta name='twitter:title' content={title} />
        <meta
          name='description'
          content={description}
        />
        <meta
          itemprop='description'
          content={description}
        />
        <meta
          name='og:description'
          content={description}
        />
        <meta
          name='twitter:description'
          content={description}
        />
      </Helmet>

      <div>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12'>
              <h1 className='buy__cbd__title mb-3 mt-5'>
                Os 3 passos para comprar CBD importado
              </h1>
            </div>
            <div className='col-lg-10 m-auto'>
              <div className='separator'></div>
            </div>
            <div className='col-lg-12 mb-0 mb-md-5'>
              <p className='buy__cbd__subtitle'>
                Antes de comprar CBD para um tratamento de saúde é necessário
                seguir algumas etapas para que a regulamentação vigente seja
                cumprida. Mas não se preocupe! A <b>Dr. Cannabis</b> te ajudará
                a passar por todo o processo com mais conforto, segurança e
                total apoio! Você encontrará médicos que realizam consultas
                online e/ou presencial, terá a orientação necessária para
                receber autorização da Anvisa e também o suporte para comprar
                CBD importado legalmente. Você pode ter a qualidade de vida
                impactada, seguindo as normas e leis brasileiras vigentes.
                Conheça abaixo o que é necessário para comprar CBD e outros
                produtos à base de cannabis, como o óleo de CBG, CBN, cápsulas
                ou cremes com canabinoides.
              </p>
              <div className='d-flex justify-content-center my-3'>
                <Link to='/cadastro'>
                  <ButtonPrimary
                    id='quero_tratamento'
                    btnClass='quero-tratamento primary md'
                    text='Quero tratamento'
                  />
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className='buy__cbd__banner notebook'>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-6 content'>
                <h2 className='buy__cbd__banner__title mb-3'>
                  Encontre um médico prescritor e vá à consulta:
                </h2>
                <p className='buy__cbd__banner__content'>
                  Temos uma lista de profissionais selecionados e aptos a
                  prescrever produtos à base de cannabis.
                  <a
                    className='mx-2 text-info'
                    href='https://drcannabis.com.br/cadastro'
                  >
                    Cadastre-se
                  </a>
                  gratuitamente ou
                  <a
                    className='mx-2 text-info'
                    href='https://drcannabis.com.br/login'
                  >
                    acesse seu perfil
                  </a>
                  para visualizar os médicos que realizam consultas online e/ou
                  os consultórios mais próximos de sua região. Durante o
                  atendimento, será avaliado se a cannabis é uma opção viável de
                  tratamento para o paciente e o caso diagnosticado.
                </p>
                <div className='d-flex justify-content-center mt-3'>
                  <ButtonPrimary
                    id='quero_medico'
                    text='Quero um Médico'
                    callBack={() => history.push('/paciente/medico')}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='buy__cbd__banner medicine'>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-12'>
                <h2 className='buy__cbd__banner__title mb-3'>
                  Autorização da Anvisa.
                </h2>
                <p className='buy__cbd__banner__content '>
                  Na plataforma <b>Dr Cannabis</b>, o médico prescreve em menos
                  de 5 minutos e o paciente acessa o gabarito com um
                  passo-a-passo simples para receber autorização da Anvisa. A
                  partir daí, nós tomamos conta da burocracia para você comprar
                  CBD.
                  O prazo para a análise, resposta com ofício de autorização
                  informado pela agência é, em média, de 10 dias úteis. A
                  autorização tem validade de 24 meses e a quantidade para
                  compra é limitada pela receita médica.
                </p>

                <div className='d-flex justify-content-center mt-3'>
                  <ButtonPrimary
                    id='quero_autorizacao'
                    text='Quero autorização'
                    callBack={() => history.push('/paciente')}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='buy__cbd__banner woman'>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-6'>
                <h2 className='buy__cbd__banner__title mb-3'>
                  Recebi aprovação da Anvisa, e agora?
                </h2>
                <p className='buy__cbd__banner__content '>
                  Com a prescrição médica e a autorização da Anvisa em mãos, é
                  possível comprar CBD legalmente. Faça o upload destes
                  documentos em seu{' '}
                  <a className='mx-2 text-info' href={geyUrl()}>
                    perfil
                  </a>{' '}
                  na Dr. Cannabis. Segundo as normas da Anvisa, só é possível
                  comprar CBD conforme as especificações da receita médica, ou
                  seja, o paciente poderá importar apenas o produto prescrito da
                  marca indicada pelo profissional.
                </p>
                <div className='d-flex justify-content-center mt-3'>
                  <ButtonPrimary
                    btnClass='mt-3'
                    id='quero_comprar'
                    text='Quero comprar'
                    callBack={() => history.push('/paciente')}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='buy__cbd__banner anvisa'>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-12'>
                <h2 className='buy__cbd__banner__title mb-3'>
                  Já tem autorização da ANVISA?
                </h2>
                <p className='buy__cbd__banner__content  my-2'>
                  Cadastre-se na <b>Dr Cannabis</b> e acesse a cotação do seu
                  produto! Envie os documentos pela plataforma e deixe o
                  restante conosco.
                </p>
                <p className='buy__cbd__banner__content  my-2'>
                  <b>Importante:</b> A <b>Dr Cannabis</b> faz a cotação do seu
                  medicamento e acompanha a importação, sem taxas adicionais ao
                  preço final do produto prescrito e autorizado.
                </p>
                <p className='buy__cbd__banner__content  my-2'>
                  <b>Quer comprar CBD?</b> A <b>Dr Cannabis</b> encontra o
                  fornecedor do produto prescrito por seu médico e autorizado
                  pela Anvisa e te auxilia com a cotação sem nenhum custo
                  adicional e sem compromisso. O produto chega direto ao
                  endereço do paciente cadastrado na Anvisa e de acordo com o
                  prazo estabelecido pelo fornecedor. E nós também acompanhamos
                  a importação dos produtos das marcas parceiras!
                </p>
                <p className='buy__cbd__banner__content  my-2'>
                  Dica: A cópia do Ofício de autorização emitido pela Anvisa
                  deve ser mantida junto ao produto sempre que o paciente
                  estiver com ele, seja dentro ou fora do Brasil.
                </p>
                <div className='d-flex justify-content-center mt-3'>
                  <ButtonPrimary
                    id='acesse_seu_perfil'
                    text='Acesse seu perfil'
                    callBack={() => history.push('/paciente')}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
