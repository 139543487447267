import * as actionTypes from "./actionTypes";

const setOrderStates = (payload) => {
  return {
    type: actionTypes.SET_ORDER_STATES,
    payload
  }
}

export {setOrderStates}
