import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'

import auth from './auth'
import error from './error'
import medicines from './medicines'
import {orderReducer} from '~/redux/reducers/orders'
import {prescriptionsReducer} from '~/redux/reducers/prescriptions'
import {medicinesReducer} from '~/redux/reducers/medicines'

const createRootReducer = history =>
  combineReducers({
    router: connectRouter(history),
    auth,
    medicines,
    error,
    orderReducer,
    prescriptionsReducer,
    medicinesReducer
  })

export default createRootReducer
