import React from 'react'

import VivianeImg from '~/assets/images/viviane.jpg'
import { Helmet } from 'react-helmet'

export default function About() {
  const title = 'Dr. Cannabis - Sobre nós'
  const description = 'A Dr. Cannabis é a sua aliada para encontrar e proporcionar mais qualidade de vida ' +
    'através da terapêutica canábica. Veja!'
  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta property="og:title" content={title} />
        <meta name="twitter:title" content={title} />
        <meta
          name="description"
          content={description}
        />
        <meta
          itemprop="description"
          content={description}
        />
        <meta
          name="og:description"
          content={description}
        />
        <meta
          name="twitter:description"
          content={description}
        />
      </Helmet>
      <div>

        <div className='container about__us'>
          <div className='row'>
            <div className='col-lg-12'>
              <h1 className="about__us__title text-center mb-3 mt-5">
                Sobre a Dr. Cannabis
              </h1>
            </div>
            <div className="col-lg-10 m-auto">
              <div className="separator" />
            </div>
            <div className="col-lg-12 mb-0 mb-md-5">
              <h5 className=" about__us__sub__title">
                Com tantas vidas sendo impactadas por doenças crônicas, a Dr.
                Cannabis surge com a missão de apresentar a terapia canabinoide
                como uma alternativa de tratamento. Facilitando o acesso a
                informações, médicos e produtos que proporcionam mais conforto e
                saúde para quem precisa.
              </h5>
            </div>
            <div className="col-lg-12 mt-5 mb-3">
              <h1 className="about__us__title">Nossos pilares</h1>
            </div>
          </div>
        </div>
        <div className="about__us__banner notebook">
          <div className='container'>
            <div className='row'>
              <div className='col-lg-6 col-12'>
                <h2 className="about__us__banner__title mb-3">
                  A informação é o melhor remédio.
                </h2>
                <h5 className="about__us__banner__content">
                  Conhecimento é uma ferramenta poderosa. Desmistificar
                  informações sobre cannabis medicinal pode representar o início
                  de uma vida com mais saúde e qualidade para milhões de
                  pessoas. Nesta jornada, a ciência é a maior aliada. Todo o
                  conteúdo da Dr. Cannabis tem respaldo científico. Médicos
                  podem contar com um repositório completo de artigos que
                  sustentam o uso da cannabis como recurso terapêutico.
                </h5>
              </div>
            </div>
          </div>
        </div>
        <div className="about__us__banner medicine">
          <div className='container'>
            <div className='row'>
              <div className='col-lg-6 offset-lg-6'>
                <h2 className="about__us__banner__title pb-3">
                  Conexão entre você e tudo que precisa
                </h2>
                <h5 className="about__us__banner__content">
                  A Dr. Cannabis faz a ponte entre médicos e pacientes. Assim,
                  quem precisa pode acessar tratamentos efetivos de forma
                  apropriada e segura.
                </h5>
              </div>
            </div>
          </div>
        </div>
        <div className="about__us__banner woman">
          <div className='container'>
            <div className='row'>
              <div className='col-lg-4 col-12'>
                <h2 className="about__us__banner__title mb-3">
                  Saúde e bem-estar é com a Dr. Cannabis
                </h2>
                <h5 className="about__us__banner__content">
                  Tudo que somos e fazemos é em prol do cuidado às pessoas.
                  Queremos facilitar o acesso e fazer parte da vida de quem
                  busca e de quem proporciona mais bem-estar e saúde.
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mb-5">
        <div className='container about__us__team'>
          <div className='row'>
            <div className="col-lg-12">
              <h1 className="about__us__title mb-3 mt-5">
                Equipe
              </h1>
            </div>
            <div className="col-12">
              <div className="about__us__team__card">
                <div className="card-header bg-transparent d-flex justify-content-center border-0">
                  <img
                    className="rounded-circle img-fluid img-border-avatar"
                    src={VivianeImg}
                    alt=""
                  />
                </div>
                <div className="card-body">
                  <div className="d-flex justify-content-center">
                    <h4 className="about__us__team__name  mt-2 mb-4">
                      Viviane Sedola
                    </h4>
                  </div>
                  <p className=" about__us__team__content">
                    É fundadora e CEO da <b>Dr Cannabis</b>. Usa sua influência
                    para trabalhar com os principais veículos de comunicação e
                    contatos políticos para reduzir o atrito e a burocracia no
                    uso medicinal da cannabis. Foi homenageada pela revista High
                    Times como uma das 50 mulheres mais influentes do mercado de
                    cannabis no mundo.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
