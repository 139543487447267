import React, { useEffect, useState } from 'react'
import { Alert } from 'react-bootstrap'
import { Link, useHistory, useParams } from 'react-router-dom'
import { FormContext, useForm, useFormContext } from 'react-hook-form'

import pdfIcon from '~/assets/icons/pdfIcon.png'
import api from '~/services/api'
import ModalMedicine from './ModalMedicine'
import Thumb from '~/components/Thumb'
import { Input } from '~/components/Form'
import ModalNewOrder from './ModalNewOrder'
import ModalSelectPhysician from '~/components/ModalSelectPhysician'
import { transform } from '~/shared/utils'
import UploadFile from '~/components/UploadFile'
import moment from 'moment'
import { ButtonPrimary, CustomSelect } from '~/components'
import Skeleton from 'react-loading-skeleton'

export default function Prescription() {
  const [prescription, setPrescription] = useState({ medicines: [] })
  const [form, setForm] = useState({
    review_prescription: 'review',
    review_anvisa: 'review',
    review_document: 'review',
    review_address: 'review',
  })
  const [loading, setLoading] = useState(true)
  const [firstLoading, setFirstLoading] = useState(true)
  const { id } = useParams()
  const [modalShow, setModalShow] = useState(false)
  const formMethods = useForm()
  const [showConfirmDelete, setShowConfirmDelete] = useState(false)
  const [showModalNewOrder, setShowModalNewOrder] = useState(false)
  const [baseAnvisa, setBaseAnvisa] = useState('')
  const [basePrescription, setBasePrescription] = useState('')
  const [baseProofOfAddress, setBaseProofOfAddress] = useState('')
  const [baseRg, setBaseRg] = useState('')
  const [showLinkAnvisa, setShowLinkAnvisa] = useState(false)
  const [showLinkRg, setShowLinkRg] = useState(false)
  const [showLinkPrescription, setShowLinkPrescription] = useState(false)
  const [showModalSelectPhysician, setShowModalSelectPhysician] = useState(
    false,
  )
  const history = useHistory()


  const changeForm = (name, value) => {
    setForm(prevState => {
      return {
        ...prevState,
        [name]: value,
      }
    })
  }

  const updatePrescription = async () => {
    const {
      review_address,
      review_prescription,
      review_anvisa,
      review_document,
    } = form

    if (!loading) {
      const data = {
        review_address,
        review_prescription,
        review_anvisa,
        review_document,
      }
      api.put(`/prescriptions/${id}`, data).then(res => {
        changeForm('review_document', res.data.review_document)
        changeForm('review_anvisa', res.data.review_anvisa)
        changeForm('review_prescription', res.data.review_prescription)
        changeForm('review_address', res.data.review_address)
      })
    }
  }

  const onChangeInput = (e) => {
    const { name, value } = e.currentTarget
    setForm(prevState => {
      return {
        ...prevState,
        [name]: value,
      }
    })
  }

  const getPrescription = async () => {
    setLoading(true)
    const res = await api.get(`/prescriptions/${id}`)
    setPrescription(res.data)
    changeForm('review_document', res.data.review_document)
    changeForm('review_anvisa', res.data.review_anvisa)
    changeForm('review_prescription', res.data.review_prescription)
    changeForm('review_address', res.data.review_address)
    setLoading(false)
  }

  useEffect(() => {
    getPrescription()
  }, [])


  useEffect(() => {
    if (!firstLoading) {
      if (
        form.review_prescription !== prescription.review_prescription
        || form.review_anvisa !== prescription.review_anvisa
        || form.review_document !== prescription.review_document
        || form.review_address !== prescription.review_address
      ) {
        updatePrescription()
      }
    } else setFirstLoading(false)
  }, [form.review_prescription, form.review_document, form.review_anvisa, form.review_address])


  const createOrder = async () => {
    setShowModalNewOrder(true)
  }

  const deletePrescription = async () => {
    setLoading(true)
    await api.delete(`/prescriptions/${id}`)
    history.push('/admin/prescricoes')
    setLoading(false)
  }

  const saveMedicines = async pivotMedicines => {
    setLoading(true)
    const medicinesData = prescription.medicines.map((medicine, idx) => {
      return { id: medicine.id, ...pivotMedicines.medicines[idx] }
    })
    const prescriptionData = {
      medicines: medicinesData,
    }
    await api.put(`/prescriptions/${prescription.id}`, prescriptionData)
    setLoading(false)
  }

  const closeSelectMedicines = async () => {
    await getPrescription()
    setModalShow(false)
  }

  const closeNewOrder = async () => {
    await getPrescription()
    setShowModalNewOrder(false)
  }

  const closeSelectPhysician = async () => {
    await getPrescription()
    setShowModalSelectPhysician(false)
  }

  useEffect(() => {
    if (prescription) {
      if (prescription.file) {
        let url = `/files/${prescription.file.id}`
        if (url) {
          transform(url).then(r => setBasePrescription(r))
        }
      }
    }
  }, [prescription.file])

  useEffect(() => {
    if (prescription) {
      if (prescription.patient?.documents[0]?.file?.id) {
        let url = `/files/${prescription.patient.documents[0].file.id}`
        if (url) {
          transform(url).then(r => {
              setBaseRg(r)
            },
          )
        }
      }
      if (prescription.patient?.addresses[0]?.file?.id) {
        let url = `/files/${prescription.patient?.addresses[0]?.file?.id}`
        if (url) {
          transform(url).then(r => {
              setBaseProofOfAddress(r)
            },
          )
        }
      }
    }
  }, [prescription.user])

  useEffect(() => {
    if (prescription) {
      if (prescription.anvisa) {
        let url = `/files/${prescription.anvisa.id}`
        if (url) {
          transform(url).then(r => setBaseAnvisa(r))
        }
      }
    }
  }, [prescription.anvisa])

  useEffect(() => {
    if (basePrescription.substr(0, 5) === 'data:') {
      setShowLinkPrescription(true)
    }
  }, [basePrescription])

  useEffect(() => {
    if (baseRg.substr(0, 5) === 'data:') {
      setShowLinkRg(true)
    }
  }, [baseRg])

  useEffect(() => {
    if (baseAnvisa.substr(0, 5) === 'data:') {
      setShowLinkAnvisa(true)
    }
  }, [baseAnvisa])

  const isRepresentative = localStorage.getItem('roles') === 'representative'

  return (
    <div className='container-fluid admin_prescription'>
      <div className='row'>
        <div className='col-lg-7 mt-2'>
          {
            loading ? (
              <Skeleton width='200px' />
            ) : (
              <h2 className='admin_prescription_title'>Prescrição #{prescription.id}</h2>
            )
          }
        </div>
        <div className='col-lg-5 mt-2'>
          <div className='row'>
            <div className='col-lg-5 offset-lg-1'>
              <ButtonPrimary
                text=' Criar Pedido'
                callBack={createOrder}
                btnMt=''
                width='w-100'
              />
            </div>
            <div className='col-lg-6'>
              <ButtonPrimary
                text='Deletar prescrição'
                callBack={() => setShowConfirmDelete(true)}
                btnMt=''
                btnClass='btn_delete'
                width='w-100'
              />
            </div>
          </div>

        </div>
      </div>
      <div className='row'>
        <div className='col-lg-12'>
          <Alert show={showConfirmDelete} variant='danger'>
            <Alert.Heading>Deseja mesmo deletar essa prescrição?</Alert.Heading>
            <hr />
            <div className='d-flex justify-content-end'>
              <ButtonPrimary
                callBack={deletePrescription}
                text='Deletar'
                btnClass='btn_delete'
              />
            </div>
          </Alert>
        </div>
      </div>
      <div className='row'>
        <div className='col-lg-4 mt-2'>
          {
            loading ? (
              <div className=' mt-2'>
                <Skeleton height='180px' />
              </div>
            ) : (
              <div className='d-flex flex-column admin_prescription_card'>
                {
                  prescription.isResponsible ? (
                    <>
                      <h4 className='admin_prescription_card_title'>Paciente</h4>
                      <p className='my-2'>
                        {prescription.patient?.ward[0]?.full_name}
                      </p>

                      <h4>Responsável</h4>
                      <p className='my-2'>
                        {prescription.patient?.profile?.full_name}
                      </p>
                    </>
                  ) : (
                    <>
                      <h4 className='admin_prescription_card_title'>Paciente</h4>
                      <p className='mt-2'>
                        {prescription.patient?.profile?.full_name}

                        {prescription.patient?.profile?.mobile_number && (

                          <a
                            href={`https://api.whatsapp.com/send?phone=+55${prescription.patient?.profile?.mobile_number}&text=Olá sou representante da DrCannabis, estou entrando em contato referente a sua prescrição. \n Código: ${id}`}
                            className={'ml-3'}
                            target='_blank'
                          >
                            <i className='fab fa-whatsapp font-rem-1-4' />
                          </a>

                        )}
                      </p>
                    </>
                  )
                }
                <Link
                  className='btn-link mt-2'
                  to={isRepresentative ? `/representantes/paciente/${prescription.patient?.id}` : `/admin/pacientes/${prescription.patient?.id}`}
                >
                  {prescription.isResponsible ? 'Ver perfil do responsável' : 'Ver perfil do paciente'}

                </Link>
                <Link
                  className='btn-link mt-2'
                  to={`/template/anvisa/${prescription.id}`}
                  target='_blank'
                >
                  Ver gabarito Anvisa
                </Link>
                <Link
                  className='btn-link mt-2'
                  to={`/template/prescricao/${prescription.id}`}
                  target='_blank'
                >
                  Ver Prescrição
                </Link>
              </div>
            )
          }

        </div>


        <div className='col-lg-4 mt-2'>
          {
            loading ? (
              <div className=' mt-2'>
                <Skeleton height='180px' />
              </div>
            ) : (
              <div className='d-flex flex-column admin_prescription_card'>

                <h4 className='admin_prescription_card_title'>Médico</h4>
                <p className='mt-2'>
                  {prescription.physician
                    ? prescription.physician.profile.full_name
                    : prescription.prePhysician
                      ? prescription.prePhysician.full_name
                      : 'Não informado'}
                </p>
                {prescription.physician && (
                  <Link
                    className='btn-link mt-2'
                    to={`/admin/medicos/${prescription.physician?.physician?.slug}`}
                  >
                    Ver perfil do médico
                  </Link>
                )}
                <a
                  className='btn-link mt-2'
                  href='#'
                  onClick={() => setShowModalSelectPhysician(true)}
                >
                  Selecionar Médico
                </a>
              </div>
            )
          }
        </div>
        <div className='col-lg-4 mt-2'>
          {
            loading ? (
              <div className=' mt-2'>
                <Skeleton height='180px' />
              </div>
            ) : (
              <div className='d-flex flex-column admin_prescription_card'>

                <h4 className='admin_prescription_card_title'>Criado</h4>
                <p className='mt-2'>{prescription.user?.profile.full_name}</p>
                <p className='mt-2'>
                  {moment(prescription.created_at).format('DD/MM/YYYY')}
                </p>
              </div>
            )
          }

        </div>


      </div>
      {
        loading ? (
          <div className=' row mt-3'>
            <div className='col-lg-12'>
              <Skeleton height='120px' />
            </div>
          </div>
        ) : (
          <div className='row mt-3'>
            <div className='col-lg-6'>
              {prescription.file || prescription.anvisa || prescription.patient?.addresses[0]?.file?.id || prescription.patient?.documents[0]?.file?.id ? (
                <h5 className='admin_prescription_sub_title'>Documentos</h5>
              ) : ''}

              <div className='row'>
                {prescription.file && (
                  <>
                    <div className='col-lg-3'>
                      <a
                        href={basePrescription}
                        target='_blank'
                        rel='noopener'
                        className={showLinkPrescription ? '' : 'd-none'}
                        download={`${prescription.patient?.profile?.full_name}-${prescription.id}`}
                      >
                        <div className='admin_prescription_card_file'>
                          <img src={pdfIcon} alt='' />
                          <p>Prescrição</p>
                        </div>
                      </a>
                    </div>
                  </>

                )}
                {prescription.anvisa && (
                  <>
                    <div className='col-lg-3'>
                      <a
                        href={baseAnvisa}
                        target='_blank'
                        rel='noopener'
                        className={showLinkAnvisa ? '' : 'd-none'}
                        download={`${prescription.patient?.profile?.full_name}-${prescription.id}`}
                      >
                        <div className='admin_prescription_card_file'>
                          <img src={pdfIcon} alt='' />
                          <p>ANVISA</p>
                        </div>
                      </a>
                    </div>
                  </>

                )}
                {prescription.patient?.documents[0]?.file?.id && (
                  <>
                    <div className='col-lg-3'>
                      <a
                        href={baseRg}
                        target='_blank'
                        rel='noopener noreferrer'
                        className={showLinkRg ? '' : 'd-none'}
                        download={`${prescription.patient?.profile?.full_name}-documento-rg`}
                      >
                        <div className='admin_prescription_card_file'>
                          <img src={pdfIcon} alt='' />
                          <p>RG</p>
                        </div>
                      </a>
                    </div>
                  </>

                )}
                {prescription.patient?.addresses[0]?.file?.id && (
                  <>
                    <div className='col-lg-3'>
                      <a
                        href={baseProofOfAddress}
                        target='_blank'
                        rel='noopener noreferrer'
                        className={showLinkRg ? '' : 'd-none'}
                        download={`${prescription.patient?.profile?.full_name}-comprovante-residencia`}
                      >
                        <div className='admin_prescription_card_file'>
                          <img src={pdfIcon} alt='' />
                          <p>C.Residência</p>
                        </div>
                      </a>
                    </div>
                  </>

                )}
              </div>
            </div>

            {prescription.orders?.length > 0 && (
              <div className='col-lg-6'>
                <h4 className='admin_prescription_sub_title pb-2'>Pedidos</h4>
                {prescription.orders?.map(order => {

                  return (

                    <Link
                      key={order.id}
                      className='btn-link my-1 d-inline-block m-1 admin_prescription_order_badge'
                      to={isRepresentative? `/representantes/pedidos/${order.id}` : `/admin/pedidos/${order.id}`}
                    >
                      Pedido {order.id}
                    </Link>

                  )
                })}
              </div>
            )}

          </div>
        )
      }

      <hr />
      <div className='row admin_prescription_uploads mt-3'>
        <div className='col-lg-3'>
          <UploadFile
            btnText='Prescrição'
            assetType='prescription'
            update={true}
            url={`/prescriptions/${id}`}
          />
        </div>
        <div className='col-lg-3'>
          <UploadFile
            btnText='Anvisa'
            assetType='anvisa'
            update={true}
            url={`/prescriptions/${id}`}
          />
        </div>
        <div className='col-lg-3'>
          <UploadFile
            btnText='RG'
            assetType='identification'
            update={true}
            url={`user/${prescription?.patient?.id}/image`}
            userId={prescription?.patient?.id}
            name='rg'
          />
        </div>
        <div className='col-lg-3'>
          <UploadFile
            btnText='C.Residência'
            assetType='address'
            update={true}
            url={`/addresses/${prescription?.patient?.addresses[0]?.id}`}
            error={prescription?.patient?.addresses[0]?.id}
          />
        </div>
      </div>

      <form>
        <div className='row mt-3'>
          <div className='col-lg-3'>
            <CustomSelect
              inputName='review_prescription'
              label='Prescrição'
              loading={loading}
              callBack={onChangeInput}
              selectValue={form.review_prescription}
            >
              <option value='review'>Não revisado</option>
              <option value='ok'>Okey</option>
              <option value='fail'>Não okey</option>
            </CustomSelect>
          </div>
          <div className='col-lg-3'>
            <CustomSelect
              inputName='review_anvisa'
              label='Anvisa'
              loading={loading}
              callBack={onChangeInput}
              selectValue={form.review_anvisa}
            >
              <option value='review'>Não revisado</option>
              <option value='ok'>Okey</option>
              <option value='fail'>Não okey</option>
            </CustomSelect>
          </div>
          <div className='col-lg-3'>
            <CustomSelect
              inputName='review_document'
              label='Documento'
              loading={loading}
              callBack={onChangeInput}
              selectValue={form.review_document}
            >
              <option value='review'>Não revisado</option>
              <option value='ok'>Okey</option>
              <option value='fail'>Não okey</option>
            </CustomSelect>
          </div>
          <div className='col-lg-3'>
            <CustomSelect
              inputName='review_address'
              label='Comprovante de Residência'
              loading={loading}
              callBack={onChangeInput}
              selectValue={form.review_address}
            >
              <option value='review'>Não revisado</option>
              <option value='ok'>Okey</option>
              <option value='fail'>Não okey</option>
            </CustomSelect>
          </div>
        </div>
      </form>
      <form onSubmit={formMethods.handleSubmit(saveMedicines)}>
        <div className='row mt-3'>
          <div className='col-lg-12'>
            <h3 className='admin_prescription_sub_title'>Produtos</h3>
            {
              loading && (
                <div className='mt-1'>
                  <Skeleton height='320px' />
                </div>
              )
            }
            {prescription.medicines && (
              <FormContext {...formMethods}>
                {prescription.medicines.map((medicine, idx) => (
                  <WindowedRow index={idx} item={medicine} />
                ))}
              </FormContext>
            )}
          </div>
          <div className='col-lg-12'>
            <div className='row'>
              <div className='col-lg-3 col-12'>
                <ButtonPrimary
                  text='Selecionar produtos'
                  callBack={() => setModalShow(true)}
                  width='w-100'
                />
              </div>
              <div className='col-lg-3 col-12'>
                {prescription.medicines.length > 0 && (
                  <ButtonPrimary
                    text='Salvar medicamentos'
                    btnType='submit'
                    disabled={loading}
                    width='w-100'
                  />

                )}
              </div>
            </div>
          </div>
        </div>
      </form>

      <ModalMedicine
        show={modalShow}
        onHide={closeSelectMedicines}
        prescriptionId={prescription.id}
      />
      <ModalNewOrder
        show={showModalNewOrder}
        onHide={closeNewOrder}
        prescriptionId={prescription.id}
      />

      <ModalSelectPhysician
        show={showModalSelectPhysician}
        onHide={closeSelectPhysician}
        prescriptionId={prescription.id}
      />
    </div>
  )
}

const WindowedRow = React.memo(({ index, item }) => {
  const { register, errors } = useFormContext()

  return (
    <div className='admin_prescription_products '>
      <div className='row' key={item.id}>
        <div className='col-lg-12'>
          <div className='d-flex align-items-center justify-content-center flex-column'>
            <div className='thumb'>
              <Thumb>
                <div className='inner'>
                  <Link
                    to={`/produtos/${item.slug}`}
                  >
                    <img src={item.photo?.uuidUrl} alt='' />
                  </Link>
                </div>
              </Thumb>
            </div>
            <div className='admin_prescription_products_name'>
              <Link
                to={`/produtos/${item.slug}`}
                className='btn-link'
              >
                {item.name}
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className='row'>
        <div className='col-lg-2'>
          <div>
            <Input
              label='QTD'
              name={`medicines[${index}].quantity`}
              className='valueInput'
              ref={register({ required: true })}
              error={
                errors.medicines ? errors.medicines[index]?.quantity && { message: 'Campo obrigatório' } : false
              }
              defaultValue={item.pivot.quantity}
              type='number'

            />
          </div>
        </div>
        <div className='col-lg-5'>
          <div>
            <Input
              label='Uso'
              ref={register({ required: false })}
              defaultValue={item.pivot.use}
              name={`medicines[${index}].use`}
              className='valueInput'
              error={
                errors.medicines ? errors.medicines[index]?.use && { message: 'Campo obrigatório' } : false
              }
            />
          </div>
        </div>
        <div className='col-lg-5'>
          <div>
            <Input
              label='Duração'
              type='number'
              ref={register({ required: true })}
              defaultValue={item.pivot.duration}
              name={`medicines[${index}].duration`}
              className='valueInput'
              error={
                errors.medicines ? errors.medicines[index]?.duration && { message: 'Campo obrigatório' } : false
              }
            />
          </div>
        </div>
      </div>

    </div>
  )
})
