import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

export default function RedirectDashboard() {
  const history = useHistory()

  const auth = useSelector(state => state.auth)

  useEffect(() => {
    const redirectPage = () => {

      if (localStorage.getItem('physicianPublic')) {
        return history.push(localStorage.getItem('physicianPublic'))
      }
      {
        let redirectTo = '/'
        if (
          auth.session.roles[0] === 'patient' ||
          auth.session.roles[0] === 'responsible'
        )
          redirectTo = '/paciente'
        else if (auth.session.roles[0] === 'physician') redirectTo = '/medico'
        else if (auth.session.roles[0] === 'administrator') redirectTo = '/admin'
        else if (auth.session.roles[0] === 'representative') redirectTo = '/representantes'
        history.push(redirectTo)
      }
    }
    redirectPage()
  }, [])

  return null
}
