import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useForm } from 'react-hook-form'

import api from '~/services/api'
import PrescriptionItem from './PrescriptionItem'
import Pagination from 'react-js-pagination'
import { setListPrescriptions, setListPrescriptionsRequest } from '~/redux/actions/prescriptions'
import { useDispatch, useSelector } from 'react-redux'
import { getPrescriptions } from '~/services/requests'
import { ButtonPrimary, ButtonSecondary, CustomCheckbox, CustomInput, CustomSelect } from '~/components'
import Skeleton from 'react-loading-skeleton'

export default function Prescriptions() {
  const [isFirstRun, setIsFirstRun] = useState(true)
  const [prescriptions, setPrescriptions] = useState({ data: [] })
  const [date, setDate] = useState({
    start: '',
    end: '',
  })
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const { register, watch, reset, setValue } = useForm()
  const { search, status, haveAnvisa, havePrescription, haveOrder } = watch()
  const { listPrescriptions, finishRequest, form } = useSelector((state) => state.prescriptionsReducer)

  const dispatch = useDispatch()

  const history = useHistory()

  const getPrescription = async (page) => {
    setLoading(true)
    if (
      finishRequest &&
      !search &&
      !status &&
      !haveAnvisa &&
      !havePrescription &&
      !haveOrder &&
      !page &&
      !date.start &&
      !date.end
    ) {
      setPrescriptions(listPrescriptions)
    } else {
      let params = ``
      if (page) params = params + `page=${page}&`
      if (search) params = params + `search=${search}&`
      if (status) params = params + `status=${status}&`
      if (haveAnvisa) params = params + `haveAnvisa=${haveAnvisa}&`
      if (havePrescription) params = params + `havePrescription=${havePrescription}&`
      if (haveOrder) params = params + `haveOrder=${haveOrder}&`
      if (date.start) params = params + `start=${date.start}&`
      if (date.end) params = params + `end=${date.end}&`
      const res = await api.get(`/prescriptions?${params}`)
      setPrescriptions(res.data)
      dispatch(
        setListPrescriptions(
          res.data,
          { search, status, haveAnvisa, havePrescription, haveOrder },
        ),
      )
    }
    setLoading(false)
  }

  useEffect(() => {
    if (isFirstRun) {
      getPrescription(
        1,
        search,
        status,
        haveAnvisa,
        havePrescription,
        haveOrder,
      )
      setIsFirstRun(false)
    }
  }, [])


  useEffect(() => {
    setValue('search', form.search)
    setValue('status', form.status)
    setValue('haveAnvisa', form.haveAnvisa)
    setValue('havePrescription', form.havePrescription)
    setValue('haveOrder', form.haveOrder)

  }, [form])

  const handlePageChange = page => {
    getPrescription(page, search, status)
  }

  const cleanFilters = async () => {
    await dispatch(setListPrescriptionsRequest())
    getPrescriptions().then(r => setPrescriptions(r.data))
    reset({
      search: '',
      status: '',
      haveAnvisa: null,
      havePrescription: null,
      haveOrder: null,
    })
    setDate({
      start: '',
      end: '',
    })
  }


  const changeDateForm = (e) => {
    const { name, value } = e.currentTarget


    if (name === 'end') {
      if (value <= date.start) {
        return setError('Data final deve ser maior que que data inicial!')
      }
      if (!date.start) {
        return setError('Selecione a data inicial')
      } else setError('')
    }

    setDate(prevState => {
      return {
        ...prevState,
        [name]: value,
      }
    })
  }

  const isAdmin = localStorage.getItem('roles') === 'administrator'

  return (
    <div className='container-fluid admin_prescription'>
      <div className='row'>
        <div className='col-lg-12'>
          <h2 className='admin_prescription_title'>Prescrições</h2>
        </div>
      </div>
      <div className='row'>
        <div className='col-lg-5 mt-3'>
          <CustomInput
            inputName='search'
            inputPlaceholder='Buscar'
            icon='far fa-search'
            register={register}
          />
        </div>
        <div className='col-lg-4 mt-3'>
          <CustomSelect
            inputName='status'
            register={register}
            focus={false}
          >
            <option value=''>Status</option>
            <option value='askMedicine'>Selecionando Medicamento</option>
            <option value='getPrescription'>Pegando Prescrição</option>
            <option value='sendPrescription'>Enviando Prescrição</option>
            <option value='askAnvisa'>Pergunta se tem Anvisa</option>
            <option value='askPhysician'>Selecionando Médico</option>
            <option value='templateAnvisa'>Template Anvisa</option>
            <option value='sendAnvisa'>Enviando Anvisa</option>
            <option value='quotation'>Cotação</option>
            <option value='analyzing'>Análise</option>
          </CustomSelect>
        </div>
        <div className='col-lg-3 mt-3 d-flex justify-content-end h-100'>
          <ButtonPrimary
            btnClass='w-100'
            text='Nova prescrição'
            callBack={() => history.push(isAdmin ? '/admin/nova/prescricao': '/representantes/nova/prescricao')}
            btnMt='mt-2'
            disabled={loading}
            loading={loading}
          />
        </div>
      </div>
      <div className='row mt-3'>
        <div className='col-6'>
          <CustomInput inputName='start'
                       defaultValue={date.start}
                       label='Data início'
                       inputType='date'
                       callBack={changeDateForm}
          />
        </div>
        <div className='col-6'>
          <CustomInput inputName='end'
                       defaultValue={date.end}
                       label='Data final'
                       inputType='date'
                       callBack={changeDateForm}
                       error={error ? { message: error } : false}
          />
        </div>
      </div>
      <div className='row'>
        <div className='col-lg-3'>
          <CustomCheckbox
            inputName='havePrescription'
            label='Tem Prescrição'
            register={register}
          />
        </div>
        <div className='col-lg-3'>
          <CustomCheckbox
            inputName='haveAnvisa'
            label='Tem Anvisa'
            register={register}
          />
        </div>
        <div className='col-lg-3'>
          <CustomCheckbox
            inputName='haveOrder'
            label='Tem Pedido'
            register={register}
          />
        </div>
      </div>
      <div className='row mt-3'>
        <div className='col-lg-2'>
          <ButtonPrimary
            text='Buscar'
            callBack={() => getPrescription()}
            btnMt=''
            width='w-100'
            loading={loading}
            disabled={loading}
          />
        </div>
        <div className='col-lg-2'>
          <ButtonSecondary
            text='Limpar'
            callBack={() => cleanFilters()}
            btnMt=''
            width='w-100'
          />
        </div>
      </div>
      <div className='row'>
        {
          loading ?
            (
              <div className='col-lg-12 mt-3'>
                <Skeleton height={200} />
              </div>

            )
            :
            prescriptions.data.map(
              prescription =>
                prescription.id && (
                  <div className='col-lg-12 mt-3' key={prescription.id}>
                    <Link to={isAdmin? `/admin/prescricoes/${prescription.id}` : `/representantes/prescricoes/${prescription.id}`}>
                      <PrescriptionItem prescription={prescription} />
                    </Link>
                  </div>
                ),
            )}
      </div>
      <div className='row'>
        <div className='col-lg-12 d-flex justify-content-center my-3'>
          <Pagination
            activePage={prescriptions.page}
            itemsCountPerPage={prescriptions.perPage}
            totalItemsCount={prescriptions.total}
            pageRangeDisplayed={5}
            onChange={handlePageChange}
            itemClass='page-item'
            linkClass='page-link'
          />
        </div>
      </div>
    </div>
  )
}
