import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Skeleton from 'react-loading-skeleton'
import api from '~/services/api'
import { SectionPatient } from '~/pages/Patient/styles'
import ScrollToTop from '~/components/ScrollToTop'
import { Col, Container, Row } from 'react-bootstrap'
import SideMenu from '~/components/SideMenu'
import { Profile } from '~/components/Profile/Profile'
import { isAuthenticated } from '~/services/auth'


export default function PhysicianProfilePublic() {
  const { slug } = useParams()
  const [physician, setPhysician] = useState({})
  const [errorMessage, setErrorMessage] = useState(false)
  const [loading, setLoading] = useState(false)
  const [authenticated, setAuthenticated] = useState(false)
  const [patientLogged, setPatientLogged] = useState({})

  useEffect(() => {
    localStorage.removeItem('physicianPublic')
  }, [])

  useEffect(() => {
    const role = localStorage.getItem('roles')

    if (isAuthenticated() && (role === 'patient' || role === 'responsible')) {
      setAuthenticated(true)
    }
  }, [])


  const getData = async slug => {
    try {
      setLoading(true)
      let res = {}
      isAuthenticated() ?
        res = await api.get(`/physicians/${slug}`)
        :
        res = await api.get(`/physician/${slug}/public`)
      setPhysician(res.data)

      setLoading(false)
    } catch (message) {
      setErrorMessage(true)
      setLoading(false)
    }
  }


  useEffect(() => {
    getData(slug)
  }, [slug])


  const likePhysician = async () => {
    if (physician?.user?.favoritesPatients?.length) {
      await api.delete(`/favorites/${physician.user?.id}`)
    } else {
      await api.post(`/favorites/${physician.user?.id}`)
    }
    await getData(slug)
  }


  const ShowMenu = () => {
    return (
      authenticated ?
        (
          <SectionPatient>
            <ScrollToTop />
            <Container fluid className='p-0'>
              <Row className='m-0'>
                <Col md={3} className='d-none d-md-block p-0 admin_menu'>
                  <SideMenu />
                </Col>
                <Col xs={12} md={9} className='mb-5 mb-md-0 p-0 admin_content_full'>
                  <Profile physician={physician}
                           likePhysician={likePhysician}
                           patientLogged={patientLogged}
                  />
                </Col>
              </Row>
            </Container>
          </SectionPatient>
        )
        :
        (
          <Profile physician={physician}
                   likePhysician={likePhysician}
                   patientLogged={patientLogged}
          />
        )

    )
  }

  return (
    <>
      {
        loading ? (
            <div className='container patient__physician__description__skeleton'>
              <div className='row mt-5 patient__physician__description__skeleton__card'>
                <div className='col-lg-1 pr-0'>
                  <Skeleton className='rounded-circle' width='90px' height='90px' />
                </div>
                <div className='col-lg-11 pl-5'>
                  <Skeleton className='' width='60%' />
                  <Skeleton className='mt-2 mx-4' width='30%' />
                  <div className='row'>
                    <div className='col-lg-6'>
                      <Skeleton className='mt-2' width='40%' />
                      <Skeleton className='mt-2' width='80%' />
                    </div>

                  </div>
                </div>
              </div>
              <div className='row mt-4'>
                <div className='col-lg-12'>
                  <Skeleton width='10%' />
                  <Skeleton className='mt-2' width='100%' height='80px' />
                  <Skeleton className='mt-2' width='20%' />
                </div>
              </div>

              <div className='row mt-4'>
                <div className='col-lg-12'>
                  <Skeleton className='d-block' width='15%' />
                  <Skeleton className='mt-2 patient__physician__description__skeleton__card__full' width='30%' />
                </div>

              </div>

              <div className='row mt-4'>
                <div className='col-lg-12'>
                  <Skeleton className='d-block' width='15%' />
                  <Skeleton className='mt-2 patient__physician__description__skeleton__card__full' width='30%' />
                </div>

              </div>

              <div className='row mt-4'>
                <div className='col-lg-12'>
                  <Skeleton className='d-block' width='15%' />
                  <Skeleton className='mt-2 d-block patient__physician__description__skeleton__card__full' width='30%' />
                  <Skeleton className='mt-2 d-block patient__physician__description__skeleton__card__full' width='20%' />
                  <Skeleton className='mt-2 d-block patient__physician__description__skeleton__card__full' width='25%' />
                </div>

              </div>
            </div>

          ) :
          (
            <>
              {
                errorMessage ? (
                  <div className='container'>
                    <div className='row'>
                      <div className='col-lg-12 p-5'>
                        <div className=' mt-2 alert alert-danger'>
                          <div>
                            <h1 className='text-center'>Médico não encontrado</h1>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                ) : (
                  <ShowMenu />
                )
              }

            </>
          )
      }
    </>
  )
}
