import React from 'react'

import { Link, useHistory, useLocation } from 'react-router-dom'

import logoBgFooter from '~/assets/images/logo-sq-med-cropped.png'
import logoVertical from '~/assets/images/logo-vert-cropped.png'
import Sprites from '~/assets/icons/sprites.svg'

export default function Footer() {
  const location = useLocation()
  const history = useHistory()
  if (location.pathname.split('/').includes('template')) return null

  return (
    <div className='footer'>
      <div className="row justify-content-center mr-0 footer-row">
        <img className="img-fluid left-leaf" src={logoBgFooter} alt="" />
        <div className="col-12 col-md-8 align-self-center">
          <div className="row mt-3">
            <div className="col-4">
              <div className="row">
                <div className="col-12">
                  <img
                    alt="logo"
                    src={logoVertical}
                    className="img-fluid logo-vert float-left mr-3 mb-3"
                  />
                </div>
              </div>
            </div>
            <div className="col-8">
              <div className="row">
                <div className="col-12 col-md-6">
                  <ul className="list-unstyled">
                    <li className="mb-4">
                      <Link
                        to="/sobre"
                        className="text anchor text--rm text--black-dark text-decoration-none"
                      >
                        Sobre nós
                      </Link>
                    </li>
                    <li className="mb-4">
                      <Link
                        to="/produtos"
                        className="text anchor text--rm text--black-dark text-decoration-none"
                      >
                        Produtos
                      </Link>
                    </li>
                    <li className="mb-4">
                      <Link
                        to="/faq"
                        className="text anchor text--rm text--black-dark text-decoration-none"
                      >
                        FAQ
                      </Link>
                    </li>
                    <li className="mb-4">
                      <Link
                        to="/comprar-cbd"
                        className="text anchor text--rm text--black-dark text-decoration-none"
                      >
                        Como comprar CBD
                      </Link>
                    </li>
                    <li className="mb-4">
                      <a
                        href="https://docs.google.com/forms/d/e/1FAIpQLSe8ZHsuDTQNY3hdJXnurBM_vmzFUgsiXWdvaWGxyhEYEXD4zw/viewform"
                        target="_blank"
                        rel="noopener"
                        className="text anchor text--rm text--black-dark text-decoration-none"
                      >
                        Seu produto na Dr. Cannabis
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="col-12 col-md-6">
                  <div className="row">
                    <div className="col-12">
                      <ul className="list-unstyled">
                        <li className="mb-4">
                          <p className="text text--rm text--black-dark">
                            Fale conosco:
                          </p>
                        </li>
                        <li className="mb-4">
                          <p className="text text--rr text--black-dark">
                            <a href="mailto:contato@drcannabis.com.br">
                              contato@drcannabis.com.br
                            </a>
                          </p>
                        </li>
                      </ul>
                    </div>
                    <div className="col-12">
                      <p className="text text--rm text--black-dark mb-3">
                        Redes sociais
                      </p>
                    </div>
                    <div className="col-12"></div>
                    <div className="col-12 d-flex">
                      <a
                        href="https://www.facebook.com/drcannabs"
                        target="_blank"
                        rel="noopener"
                        className="social d-flex align-items-center justify-content-center p-2"
                      >
                        <span className={'accessibility'}>facebook</span>
                        <svg className="social-icon" role="img">
                          <title>Facebook</title>
                          <use xlinkHref={`${Sprites}#icon-facebook`} />
                        </svg>
                      </a>
                      <a
                        href="https://www.instagram.com/drcannabisoficial/"
                        target="_blank"
                        rel="noopener"
                        className="social d-flex align-items-center justify-content-center p-2"
                      >
                        <span className={'accessibility'}>instagram</span>
                        <svg className="social-icon" role="img">
                          <title>instagram</title>
                          <use xlinkHref={`${Sprites}#icon-instagram`} />
                        </svg>
                      </a>
                      <a
                        href="https://twitter.com/drcannabisreal"
                        target="_blank"
                        rel="noopener"
                        className="social d-flex align-items-center justify-content-center p-2"
                      >
                        <span className={'accessibility'}>twitter</span>
                        <svg className="social-icon" role="img">
                          <title>Twitter</title>
                          <use xlinkHref={`${Sprites}#icon-twitter`} />
                        </svg>
                      </a>
                      <a
                        href="https://www.youtube.com/channel/UCRKSmf2hb8xq2gstf04OxoQ/featured"
                        target="_blank"
                        rel="noopener"
                        className="social d-flex align-items-center justify-content-center p-2"
                      >
                        <span className={'accessibility'}>youtube</span>
                        <svg className="social-icon" role="img">
                          <title>Youtube</title>
                          <use xlinkHref={`${Sprites}#icon-youtube`} />
                        </svg>
                      </a>
                      <a
                        href="https://br.linkedin.com/company/dr-cannabis"
                        target="_blank"
                        rel="noopener"
                        className="social d-flex align-items-center justify-content-center p-2"
                      >
                        <span className={'accessibility'}>linkedin</span>
                        <svg className="social-icon" role="img">
                          <title>LinkedIn</title>
                          <use xlinkHref={`${Sprites}#icon-linkedin2`} />
                        </svg>
                      </a>
                    </div>
                  </div>
                  <div className="d-flex"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <img className="img-fluid right-leaf" src={logoBgFooter} alt="" />
      </div>
      <div className="row mx-1 d-flex justify-content-center footer-ribbon">
        <span className="font-weight-normal text--white">
          &copy; 2021 - Dr.
          Cannabis&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
          <span onClick={()=>history.push('/termos')}>
            Privacidade e Termos de uso
          </span>
        </span>
      </div>
    </div>
  )
}
