import React, { useEffect, useRef, useState } from 'react'
import api from '~/services/api'
import { useParams } from 'react-router-dom'
import { formatValue } from '~/shared/formatAndMasks'
import { ButtonPrimary } from '~/components'
import { getPixKey, installments, payOrder } from '~/services/requests'
import Logo from '~/assets/images/logo-vert-cropped.png'
import { cardData, copyToClipboard, paymentError, paymentSuccess } from '~/shared/paymentUtils'
import { CreditCard } from '~/components/CreditCard/CreditCard'
import { useSelector } from 'react-redux'
import LogoPix from '~/assets/images/logo-pix-512.png'
import Skeleton from 'react-loading-skeleton'


export default function OrderPage() {
  const QRCode = require('qrcode.react')
  const { id } = useParams()
  const [pixKey, setPixKey] = useState('')
  const [paymentMethod, setPaymentMethod] = useState('')
  const [order, setOrder] = useState([])
  const paymentRef = useRef(null)
  const [installmentsData, setInstallmentsData] = useState('')
  const [loadingPix, setLoadingPix] = useState(false)
  const [loading, setLoading] = useState(true)
  const [loadingPayment, setLoadingPayment] = useState(false)
  const [paymentAsCompleted, setPaymentAsCompleted] = useState(false)
  const { session } = useSelector(state => state.auth)


  const getData = orderId => {
    setLoading(true)
    api.get(`/orders/${orderId}`)
      .then(res => {
        setOrder(res.data)
        res.data.status === 'PAYMENT_ACCEPTED' && setPaymentAsCompleted(true)
      })
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    getData(id)
  }, [id])

  const pixPayment = () => {
    if (!pixKey) {
      setLoadingPix(true)
      getPixKey(id).then(r => setPixKey(r.data.pix_key))
        .finally(() => setLoadingPix(false))
    }
    setPaymentMethod('pix')

    setTimeout(() => {
      paymentRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }, 50)
  }

  const creditPayment = () => {
    setPaymentMethod('credit')

    setTimeout(() => {
      paymentRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }, 50)
  }

  useEffect(() => {
    if (order?.status === 'WAITING_FOR_PAYMENT' || order?.status === 'PAYMENT_REJECTED' || order?.status === 'PROCESSING_PAYMENT') {
      if (order?.medicines[0]?.brand?.slug) {
        setLoading(true)
        installments(order.medicines[0]?.brand.slug, order.total).then(r => setInstallmentsData(Object.values(r.data)))
        setLoading(false)
      }
    }
  }, [order?.medicines, order?.status])


  const sendPay = (e, state) => {
    setLoading(true)
    setLoadingPayment(true)
    e.preventDefault()
    const { number, name, cvc, expiry, address, installments } = state

    const payload = cardData(address, number, name, cvc, expiry, installments)

    payOrder(id, payload)
      .then(response => {
        response.status === 200 && (
          response.data?.status === 'PAYMENT_ACCEPTED' && setPaymentAsCompleted(true)
        )
        paymentSuccess(response.data?.status)
      }).catch((error) => {
        paymentError(error.response.status)
      }).finally(()=> {
        setLoading(false)
        setLoadingPayment(false)
      })
  }

  return (
    <div className='container mt-4'>
      <div className='row'>
        <div className='col-12 my-2'>
          <h3 className='orders__title'>Pedido #{id}</h3>
        </div>
      </div>
      {loading ?
        (
          <div className='row'>
            <div className='col-lg-4'>
              <Skeleton width='100%' height='8.5rem' />
            </div>
            <div className='col-lg-8'>
              <Skeleton width='100%' />
              <Skeleton className='mt-2' width='100%' />
              <Skeleton className='mt-2' width='100%' />
              <Skeleton className='mt-2' width='100%' />
              <Skeleton className='mt-2' width='100%' />
              <Skeleton className='mt-2' width='100%' />
            </div>
            <div className='col-lg-4 offset-lg-8 col-sm-12'>
              <Skeleton className='mt-2' width='100%' />
              <Skeleton className='mt-2' width='100%' />
              <Skeleton className='mt-2' width='100%' />
              <Skeleton className='mt-2' width='100%' />
              <Skeleton className='mt-2' width='100%' />
            </div>

            <div className='col-12 text-center my-3'>
              <Skeleton className='mt-2' width='100%' />
            </div>
          </div>
        ) :
        (
          <>
            {Array.isArray(order?.medicines) && order?.medicines?.map(medicine => (
              <div className='row'>
                <div className='col-lg-2 col-sm-12'>
                  <img className='img-fluid border' src={medicine?.photo?.url} alt={medicine?.brand.name} />
                </div>
                <div className='col-lg-10 col-sm-12 '>
                  <div className='orders__order__product__info pl-0'>
                    <div className='orders__order__product__info__name'>
                      <h4 className=''>{medicine?.name}</h4>
                      <p>Vendido por: {medicine?.brand.name}</p>
                    </div>
                  </div>
                </div>
                <div className='col-sm-12 col-lg-2 offset-lg-6'>
                  <div className='orders__order__product__info__quantity'>
               <span> Unidade(s):{' '}
                 {medicine?.pivot.quantity}
                </span>
                  </div>
                </div>
                <div className='col-sm-12 col-lg-2'>
                  <div className='orders__order__product__info__quantity'>
               <span className='text-center'>Valor:{' '}
                 {formatValue(medicine.price, medicine.currency)}
                </span>
                  </div>
                </div>
                <div className='col-sm-12 col-lg-2 mb-4 d-flex justify-content-end'>
                  <div className='orders__order__product__info__quantity'>
              <span>Total:{' '}
                {formatValue(medicine.price * medicine.pivot.quantity, medicine.currency)}
                </span>
                  </div>
                </div>
              </div>
            ))}
            {Array.isArray(order?.medicines) && order?.medicines?.map((medicine, index) =>
              (index === 0 &&
                <div className='row'>
                  <div className='col-lg-4 offset-lg-8 col-sm-12'>
                    <div className='orders__order__product__info p-0'>
                      <div className='d-flex justify-content-between mb-2'>
                        <h6 className='font-weight-bold'>SubTotal</h6>
                        <span>
                          {formatValue(order.subtotal, medicine.shipping_currency ? medicine.shipping_currency : medicine.brand.shipping_currency)}
                        </span>
                      </div>
                      {order?.discount?.product > 0 && (
                        <div className='d-flex justify-content-between mb-2'>
                          <h6 className='font-weight-bold'>Desconto no produto</h6>
                          <span>
                            {formatValue(-order.discount.product, medicine.shipping_currency ? medicine.shipping_currency : medicine.brand.shipping_currency)}
                          </span>
                        </div>
                      )}
                      <div className='d-flex justify-content-between mb-2'>
                        <h6 className='font-weight-bold'>Frete</h6>
                        <span>
                          {formatValue(order.shipping * 1, medicine.shipping_currency ? medicine.shipping_currency : medicine.brand.shipping_currency)}
                        </span>
                      </div>
                      {order.discount?.shipping > 0 && (
                        <div className='d-flex justify-content-between mb-2'>
                          <h6 className='font-weight-bold'>Desconto no frete</h6>
                          <span>
                            {formatValue(-order.discount.shipping, medicine.shipping_currency ? medicine.shipping_currency : medicine.brand.shipping_currency)}
                          </span>
                        </div>
                      )}
                      <div className='d-flex justify-content-between'>
                        <h6 className='font-weight-bold'>Total do pedido</h6>
                        <span>
                          {formatValue(order.total, medicine.shipping_currency ? medicine.shipping_currency : medicine.brand.shipping_currency)}
                        </span>
                      </div>
                    </div>
                    {(order?.status === 'WAITING_FOR_PAYMENT' || order?.status === 'PAYMENT_REJECTED' || order?.status === 'PROCESSING_PAYMENT') && !paymentAsCompleted
                    && (
                      <div className='d-flex'>
                        <ButtonPrimary
                          btnClass='w-50 btn__show__payment mt-5 mr-2'
                          text='Crédito'
                          callBack={() => creditPayment()}
                          loading={loading}
                          disabled={loading}
                        />
                        <ButtonPrimary
                          btnClass='w-50 btn__show__payment mt-5'
                          text='Pix'
                          callBack={() => pixPayment()}
                          loading={loading}
                          disabled={loading}
                        />
                      </div>
                    )}
                  </div>
                </div>
              ),
            )}
            {(paymentMethod && !paymentAsCompleted) && (
              <div className='row border-top my-5'>
                <div className='col-12 text-center my-3'>
                  <h4>Detalhes do pagamento</h4>
                </div>
                {paymentMethod === 'pix' ?
                  (
                    <div className='col-12' ref={paymentRef}>
                      <div className='d-flex justify-content-center'>
                        {pixKey && !loadingPix ?
                          (
                            <QRCode
                              className='img-fluid'
                              value={pixKey}
                              size={250}
                              level={'H'}
                              renderAs={'svg'}
                              imageSettings={{
                                src: Logo,
                                x: null,
                                y: null,
                                height: 48,
                                width: 48,
                                excavate: true,
                              }}
                            />
                          )
                          :
                          (
                            <img className='img-fluid' src={LogoPix} alt='pix' />
                          )
                        }
                      </div>

                      {pixKey && !loadingPix && (
                        <div className='text-center mt-2' onClick={() => copyToClipboard(pixKey)}>
                          <p>Escaneie o qrcode ou
                            <p className='div-pay-modal__div-card-qrcod__p-descricao__link'> clique aqui </p>
                            para usar o <strong>PIX Copia e Cola</strong>
                          </p>
                        </div>
                      )}
                    </div>
                  )
                  :
                  (
                    <div className='col-12' ref={paymentRef}>
                      <CreditCard addresses={session.addresses[0]}
                                  action={sendPay}
                                  installments={installmentsData}
                                  loading={loadingPayment || paymentAsCompleted}
                      />
                    </div>
                  )
                }
              </div>
            )}

            {paymentAsCompleted && (
              <div className='row my-5'>
                <div className='col-12 text-center my-3'>
                  <h4>Pagamento Recebido</h4>
                </div>
              </div>
            )}
          </>
        )
      }

    </div>
  )
}
