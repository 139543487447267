import React from 'react'

const ButtonPrimary = ({
 text = '',
 btnClass = '',
 width = '',
 callBack,
 disabled = false,
 controls,
 target = '#modal',
 toggle,
 dismiss,
 icon,
 btnType = 'button',
 btnForm,
 btnMt='mt-3',
 loading = false
}) => {
  return (
    <button
      className={`btn button__primary text-white ${btnMt} ${btnClass} ${width}`}
      type={btnType}
      onClick={callBack}
      disabled={disabled}
      data-toggle={toggle}
      data-target={target}
      aria-controls={controls}
      data-dismiss={dismiss}
      form={btnForm}
    >
      {icon}
      {text}
      {loading && (
        <div
          className='ml-3 spinner-border spinner-border-sm'
          role='status'
        >
          {' '}
        </div>
      )}
    </button>
  )
}

export { ButtonPrimary }
