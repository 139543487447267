import styled from 'styled-components'
import { ProgressBar } from 'react-bootstrap'

import { Variables as V } from '~/styles/global'

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: none;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  .header-prescription {
    padding: 0.75rem 1.25rem;
    margin-bottom: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
    &::first-child {
      border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
    }
    @media only screen and (max-width: 767px) {
      padding: 0.75rem 5px;
      display: flex;
      justify-content: space-between;
      font-size: 1rem;
    }


  }

  .body {
    flex: 1 1 auto;
    min-height: 140px;
    padding: 1.25rem;
    display: flex;
  }
  @media screen and (max-width: 575px) {
    .body {
      flex-direction: column;
      padding: 0.3rem;
    }
  }

  .footer {
    border-top: 1px solid rgba(0, 0, 0, 0.125);

    .steps {
      padding: 0.75rem 1.25rem;
    }
  }
`

export const Progress = styled(ProgressBar)`
  height: 0.5rem;
  background-color: transparent;
  border-radius: 0;

  .progress-bar {
    background-color: ${V.colorPrimary};
  }
`
