import React, { useEffect, useState } from 'react'
import { adminPhysicianCalendar } from '~/services/requests'
import { useHistory, useParams } from 'react-router-dom'
import { ButtonSecondary } from '~/components'
import { showAppointmentDetail, treatmentListConsultation } from '~/shared/telemedOptions'
import moment from 'moment'
import { AppointmentCard } from '~/components/AppointmentCard/AppointmentCard'
import * as $ from 'jquery'
import { ModalNewConsultation } from '~/pages/telemed/ModalNewConsultation'
import { ModalConsultationDetails } from '~/pages/telemed/ModalConsultationDetails'
import { DatePicker } from '~/components/DatePicker/DatePicker'

const AdminViewPhysicianAppointments = () => {
  const { id, date } = useParams()
  const history = useHistory()
  const [appointments, setAppointments] = useState()
  const [datepicker, setDatePicker] = useState(moment().format('YYYY-MM-DD'))
  const [appointmentLength, setAppointmentLength] = useState('')
  const [calendarOptions, setCalendarOptions] = useState({
    start: '00:00',
    end: '23:59',
  })
  const [showPayment, setShowPayment] = useState(false)
  const [event, setSelectedEvent] = useState({})
  const [loading, setLoading] = useState(true)

  useEffect(() => {

    setLoading(true)
    const end = '2100-12-31'

    adminPhysicianCalendar(datepicker, end, id)
      .then(r => {
        if (!Array.isArray(r.data.appointments))
          setAppointments(Object.entries(r.data.appointments))
        setAppointmentLength(r.data.appointment_length)
        setCalendarOptions(r.data.calendar)
      })
      .finally(() => setLoading(false))
  }, [datepicker])

  const newConsultation = () => {
    $('#newConsultation').modal('show')
  }


  return (
    <>
      <div className="container-fluid my-agenda mb-5 view-presential px-4">
        <div className="row">
          <div className="col-lg-6 col-sm-12">
            <h1 className="page-title py-3">Consultas</h1>
          </div>
          <div className="col-lg-6 col-sm-12">
            <ButtonSecondary
              text="Nova consulta"
              btnClass="my-3 my-agenda__configure-shifts d-block w-100"
              icon={<i className="far fa-plus"/>}
              callBack={() => newConsultation()}
            />
            <ButtonSecondary
              text="Visualizar agenda"
              btnClass="my-3 my-agenda__configure-shifts d-block w-100"
              icon={<i className="far fa-calendar"/>}
              callBack={() => history.push(`/admin/medicos/agenda/${id}`)}
            />
            <DatePicker callBack={setDatePicker}
                        returnFormat='YYYY-MM-DD'
                        showMessage={false}
                        justify='justify-content-center'
            />
          </div>
        </div>


        {Array.isArray(appointments) && !loading && (
          <div className="row">
            {appointments.sort().map(appointment => (
              <>
                <div className="col-12 mt-5">
                  <h4>{moment(appointment[0]).format('DD/MM/YYYY')}</h4>
                </div>
                {Array.isArray(appointment[1]) && appointment[1]
                  .sort((a, b) => a.from > b.from ? 1 : a.from < b.from ? -1 : 0)
                  .map((value) => (
                    <AppointmentCard appointment={value}
                                     calendarOptions={calendarOptions}
                                     callback={() => showAppointmentDetail(value, setSelectedEvent, setShowPayment, true)}
                                     treatment={treatmentListConsultation}
                                     isPhysician={true}
                                     isEditable={true}
                                     appointmentLength={appointmentLength}
                    />
                  ))}
              </>
            ))}
          </div>
        )}

      </div>
      <ModalNewConsultation appointmentLength={appointmentLength}
                            minTime={calendarOptions.start}
                            maxTime={calendarOptions.end}
                            isAdmin={true}
                            resourceId={id}
      />
      <ModalConsultationDetails event={event} isPhysician={true} />
    </>
  )
}

export { AdminViewPhysicianAppointments }
