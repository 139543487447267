import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import moment from 'moment'
import Pagination from 'react-js-pagination'

import api from '~/services/api'
import MedicineImage from '~/assets/images/produto.png'
import { useForm } from 'react-hook-form'
import { ButtonPrimary, CustomInput, CustomSelect } from '~/components'
import Skeleton from 'react-loading-skeleton'

export default function Orders() {
  const [orders, setOrders] = useState({ data: [] })
  const [page, setPage] = useState(1)
  const [orderStates, setOrderStates] = useState([])
  const [loading, setLoading] = useState(false)
  const [date, setDate] = useState({
    start: '',
    end: '',
  })
  const [error, setError] = useState('')
  const { register, watch } = useForm()
  const status = watch('status')


  const getOrders = async currentPage => {
    if (currentPage) setPage(currentPage)
    if (!currentPage) setPage(1)
    let params = ``
    if (status) params = params + `status=${status}&`
    if (currentPage) params = params + `page=${currentPage ? currentPage : 1}&`
    if (date.start) params = params + `start=${date.start}&`
    if (date.end) params = params + `end=${date.end}&`

    setLoading(true)
    const res = await api.get(
      `/orders?${params}`,
    )
    setOrders(res.data)
    setLoading(false)
  }

  const getOrderStates = async () => {
    setLoading(true)
    const res = await api.get(`/orders/states`)
    setOrderStates(res.data)
    setLoading(false)
  }

  useEffect(() => {
    getOrderStates()
  }, [])

  useEffect(() => {
    getOrders()
  }, [])

  const handlePageChange = page => {
    getOrders(page)
  }

  const changeDateForm = (e) => {
    const { name, value } = e.currentTarget
    if (name === 'end') {
      if (value <= date.start) {
        return setError('Data final deve ser maior que que data inicial!')
      }
      if (!date.start) {
        return setError('Selecione a data inicial')
      } else setError('')
    }

    setDate(prevState => {
      return {
        ...prevState,
        [name]: value,
      }
    })
  }

  const showStatus = (status) => {
    const index = orderStates.findIndex(value => value.key === status)
    if (index > -1) return orderStates[index].name
    return ''
  }

  const isAdmin = localStorage.getItem('roles') === 'administrator'
  const isRepresentative = localStorage.getItem('roles') === 'representative'

  return (
    <div className='container-fluid admin_orders_list'>
      <div className='row'>
        <div className='col-lg-12'>
          <h2 className='admin_orders_list_title'>Pedidos</h2>
        </div>

      </div>
      <div className='row'>
        <div className='col-3'>
          <CustomInput inputName='start'
                       defaultValue={date.start}
                       label='Data início'
                       inputType='date'
                       callBack={changeDateForm}
          />
        </div>
        <div className='col-3'>
          <CustomInput inputName='end'
                       defaultValue={date.end}
                       label='Data final'
                       inputType='date'
                       callBack={changeDateForm}
                       error={error ? { message: error } : false}
          />
        </div>

        <div className='col-lg-3'>
          <CustomSelect
            label='Status'
            register={register}
            inputName='status'
          >
            <option value=''>Todos os status</option>
            {orderStates.map(state => (
              <option value={state.key}>{state.name}</option>
            ))}
          </CustomSelect>
        </div>
        <div className='col-lg-3 py-2'>
          <ButtonPrimary text='Buscar' callBack={() => getOrders()} loading={loading} disabled={loading} />
        </div>
      </div>
      {
        loading ? (
          <>
            <div className='row'>
              <div className='col-lg-6  mt-4'>
                <Skeleton height='200px' />
              </div>
              <div className='col-lg-6 mt-4'>
                <Skeleton height='200px' />
              </div>
            </div>
          </>
        ) : (
          <div className='row'>
            {
              orders?.data?.map(order => (
                <div className='col-12 col-lg-6 mt-4 '>
                  <div
                    className='admin_orders_list_card'
                    key={order.id}
                  >
                    <a href={isAdmin ? `/admin/pedidos/${order.id}` : `/representantes/pedidos/${order.id}`}>
                      <div className='row align-items-center d-flex justify-content-center'>
                        <div className='d-flex align-items-center'>
                          {order.medicines[0].photo ? (
                            <img
                              className='admin_orders_list_card_img'
                              src={order.medicines[0].photo?.uuidUrl}
                              alt={order.medicines[0].name}
                            />
                          ) : (
                            <img src={MedicineImage} alt='' />
                          )}
                        </div>
                        <div className='col'>
                          <div className='card-block px-2'>
                            <div className='row mb-2 mt-4'>
                              <div className='card-text col'>
                                <strong className='mr-2'>Pedido:</strong>
                                {order.id}
                              </div>
                            </div>
                            <div className='row my-2'>
                              <div className='card-text col'>
                                <strong className='mr-2'>Data:</strong>
                                {moment(order.created_at).format('DD/MM/YYYY')}
                              </div>
                            </div>
                            <div className='row my-2'>
                              <div className='card-text col'>
                                <strong className='mr-2'>{isRepresentative ? 'Representante:' : 'Prescritor:'}</strong>
                                {order.prescription.physician
                                  ? order.prescription.physician.profile.full_name
                                  : order.prescription.prePhysician?.full_name
                                    .length > 0
                                    ? order.prescription.prePhysician?.full_name
                                    : '---------'}
                              </div>
                            </div>
                            <div className='row my-2'>
                              <div className='card-text col'>
                                <strong className='mr-2'>Paciente:</strong>
                                {order.prescription.patient.profile.full_name}
                              </div>
                            </div>
                            <div className='row my-2'>
                              <div className='card-text col'>
                                <strong className='mr-2'>Status: {showStatus(order.status)}</strong>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              ))}
          </div>
        )
      }

      <Row>
        <Col className='d-flex justify-content-center mt-3'>
          <Pagination
            activePage={page}
            itemsCountPerPage={orders.perPage}
            totalItemsCount={orders.total}
            pageRangeDisplayed={5}
            onChange={handlePageChange}
            itemClass='page-item'
            linkClass='page-link'
          />
        </Col>
      </Row>
    </div>
  )
}
