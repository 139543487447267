import React, { createRef, useEffect, useState } from 'react'
import { PhysicianOnboarding } from '~/pages/telemed/PhysicianOnboarding'
import { Calendar } from '~/components/Calendar/Calendar'
import { getMyCalendar } from '~/services/requests'
import { ButtonSecondary } from '~/components'
import { treatmentOfDates, updateCalendar } from '~/shared/telemedOptions'

const PhysicianConfigurationCalendar = () => {
  const [telemedicine, setTelemedicine] = useState(localStorage.getItem('telemedicine') === 'true')
  const [updateBase, setUpdateBase] = useState(false)
  const [updateWeek, setUpdateWeek] = useState(false)

  useEffect(() => {
    setTelemedicine(localStorage.getItem('telemedicine') === 'true')
  }, [localStorage.getItem('telemedicine')])

  const calendarRef = createRef()

  return (
    telemedicine ?
      (
        <div className='container-fluid my-agenda mb-5'>
          <div className='row'>
            <div className='col-lg-12 d-flex align-items-center justify-content-between'>
              <h1 className='page-title py-3'>Configurar agenda</h1>
              <div className='d-flex'>
                {updateWeek || updateBase ? (
                    <ButtonSecondary
                      text='Salvar'
                      btnClass='my-3 my-agenda__configure-shifts mr-2'
                      icon={<i className='far fa-check-square' />}
                      callBack={() => updateCalendar(calendarRef, updateWeek, updateBase, setUpdateWeek, setUpdateBase)}
                    />
                  )
                  :
                  (
                    <>
                      <ButtonSecondary
                        text='Calendario desta semana'
                        btnClass='my-3 my-agenda__configure-shifts mr-2'
                        icon={<i className='far fa-edit' />}
                        callBack={() => setUpdateWeek(true)}
                      />
                      <ButtonSecondary
                        text='Calendario base'
                        btnClass='my-3 my-agenda__configure-shifts mr-2'
                        icon={<i className='far fa-edit' />}
                        callBack={() => setUpdateBase(true)}
                      />
                    </>
                  )
                }
              </div>
            </div>
          </div>
          <div className='row'>
            <Calendar calendarClass='calendarMyAgenda'
                      calendarRef={calendarRef}
                      eventOverlap={false}
                      buttons={false}
                      request={getMyCalendar}
                      treatment={treatmentOfDates}
                      eventDurationEditable={updateBase || updateWeek}
                      eventResizableFromStart={false}
                      editable={updateBase || updateWeek}
                      isPhysician={true}
                      eventClick={updateBase || updateWeek}
                      configuration={true}
                      selectable={updateBase || updateWeek}
                      updateBase={updateBase}
                      updateWeek={updateWeek}

            />
          </div>
        </div>
      )
      :
      (
        <PhysicianOnboarding setTelemedicine={setTelemedicine} />
      )

  )
}


export { PhysicianConfigurationCalendar }
