import React from 'react'
import moment from 'moment'
import { ButtonSecondary } from '~/components'
import { phoneMask } from '~/shared/formatAndMasks'

const ModalEvent = (
  {
    event,
    id = 'modal-event',
    isAdmin = false,
    eventOptions,
    calendarRef,
    updateBase,
    updateWeek,
    isPhysician,
  }) => {

  const remove = () => {
    const calendarApi = calendarRef.current.getApi()

    const target = calendarApi.getEvents().filter((value) =>
      value._def.extendedProps.HourStart === eventOptions.event._def.extendedProps.HourStart)

    if (target.length > 0) {
      target[0].remove()
    }

  }

  return (
    <div
      className='modal fade modal-consultation-details'
      id={id}
      tabIndex='-1'
      aria-labelledby='modal'
      aria-hidden='true'
    >
      <div className='modal-dialog modal-dialog-centered' role='document'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h5 className='modal-title'>{isAdmin ? event?.selectedDate : 'Detalhes da consulta'}</h5>
            <button type='button' className='close' data-dismiss='modal' aria-label='Close'>
              <span aria-hidden='true'>&times;</span>
            </button>
          </div>
          <div className='modal-body d-flex flex-column'>
            <div className='row'>
              {(isAdmin || isPhysician) && (updateBase || updateWeek) ?
                (
                  <div className='col-12'>
                    <div className='modal-consultation-details__box-modal modal-consultation-details__content'>
                      <div className='d-flex justify-content-between'>
                        <h5 className='modal-title'>
                          {moment(event?.HourStart).format('HH:mm')} até {moment(event?.HourEnd).format('HH:mm')}
                        </h5>
                        <ButtonSecondary text='Excluir'
                                         icon={<i className='far fa-trash-alt mr-2' />}
                                         btnMt='mt-0'
                                         callBack={() => remove()}
                                         dismiss='modal'
                        />
                      </div>
                    </div>
                  </div>
                )
                :
                (
                  <div className='col-12'>
                    <div className='modal-consultation-details__box-modal modal-consultation-details__content'>
                      <div className='d-flex justify-content-center'>
                        <span className='badge-video-service mr-auto'>
                          <i className='fas fa-video' /> Teleatendimento
                        </span>
                        {
                          event?.status === 'CONFIRMED' ?
                            (
                              <span className='payment-ok'>
                            <i className='fas fa-dollar-sign mr-2' /> Pago
                          </span>
                            )
                            :
                            (
                              <span className='waiting-payment'>
                            <i className='fas fa-dollar-sign mr-2' /> Aguardando pagamento
                          </span>
                            )
                        }

                      </div>

                      <h4 className='mt-3 mb-2'>{event?.patientName}</h4>
                      {event?.mobile && (
                        <div>
                          <a
                            href={`https://api.whatsapp.com/send?phone=+55${event?.mobile}&text=Olá, somos da DrCannabis e temos noticias sobre sua consulta`}
                            target='_blank' rel='noopener noreferrer'>+55 {phoneMask(event?.mobile)} </a>
                          <i className='fab fa-whatsapp text-success ml-2' />
                        </div>
                      )}

                      {event?.email && (
                        <div className='mt-2 mb-3'>
                          <a href={`mailto:${event?.email}`} target='_blank'
                             rel='noopener noreferrer'>{event?.email}</a>
                          <i className='far fa-envelope ml-2' />
                        </div>
                      )}

                      <h5
                        className='mt-3'>{event?.day}, {event?.dayNumber} de {event?.monthString} {moment(event?.HourStart).format('HH:mm')}</h5>
                    </div>
                  </div>
                )
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export { ModalEvent }
