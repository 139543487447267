import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import api from '~/services/api'
import { toast } from 'react-toastify'
import * as $ from 'jquery'
import { Section } from '../Login/styles'
import { useForm } from 'react-hook-form'
import { Col, Row } from 'react-bootstrap'
import { showValidatePassword } from '~/shared/utils'
import Ok from '~/assets/icons/check.svg'
import Block from '~/assets/icons/quadra.png'

const NewPassword = () => {
  const { session } = useSelector(state => state.auth)
  const [disable, setDisable] = useState(true)
  const [showPassword, setShowPassword] = useState(false)
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false)
  const [form, setForm] = useState({
    password: '',
    confirmPassword: '',
  })

  const changeInput = (e) => {
    const { value } = e.target
    const { name } = e.target
    setForm({
        ...form,
        [name]: value,
    })
  }

  const { handleSubmit } = useForm()

  // useEffect(() => {
  //   if (form.password === form.confirmPassword && form.password !== '') {
  //     setDisable(false)
  //   }
  // }, [form.password, form.confirmPassword])

  const changePwd = async (event) => {
    event.preventDefault();
      const { password, confirmPassword } = form
    if (password === confirmPassword) {
      await api.put('/changepassword', {
        email: session.email,
        password,
        confirmPassword
      }).then(() => {
        toast.success('Senha alterada com sucesso');
        $('#newPasswordModal').modal('hide');
        setForm({ confirmPassword: '', password: '' })
      }).catch(() => {
        toast.error('Senha inválida. Por favor, revise os campos e tente novamente!');
      })
    }else{
      toast.error('As senhas não correspondem!');
    }
  }

  return (
    <div
      className="modal fade"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="myLargeModalLabel"
      aria-hidden="true"
      id="newPasswordModal"
    >
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header border-bottom-0 mt-1">
            <h3 className="my-3 text--rb">Alterar senha</h3>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span className="font-size-35 my-3" aria-hidden="true">
                &times;
              </span>
            </button>
          </div>
          <div className="container">
              <form onSubmit={event => handleSubmit(changePwd(event))}>
              <div className="row">
                <div className="col-12 d-flex justify-content-center">
                  <div className="card w-100 border-0">
                    <div className="card-body border-0">
                      <div className="form-group row">
                        <label
                          htmlFor="staticEmail"
                          className="col-3 col-form-label"
                        >
                          Email
                        </label>
                        <div className="col-9">
                          <input
                            type="text"
                            readOnly
                            className="form-control-plaintext"
                            id="staticEmail"
                            value={session.email}
                          />
                        </div>
                      </div>
                      <Section className="mt-1">
                      <div className="form-group row">
                        <label
                          htmlFor="password"
                          className="col-3 col-form-label"
                        >
                          Nova senha
                        </label>
                        <div className="col-9 divBtnExibirSenha">
                          <input
                            type={showPassword?"text":"password"}
                            className="form-control"
                            id="password"
                            name="password"
                            required="true"
                            placeholder="Digite sua nova senha"
                            value={form.password}
                            onChange={e => changeInput(e, 'password')}
                          />
                          <a className="buttonExibirSenha mt-1" href="#" onClick={()=>setShowPassword(!showPassword)}>
                            <i className={!showPassword ? 'fas fa-eye' : 'fas fa-eye-slash'}
                               data-toggle='tooltip'
                               data-placement='top'
                               title='Exibir a senha'
                            />
                          </a>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label
                          htmlFor="confirmPassword"
                          className="col-3 col-form-label"
                        >
                          Confirme a senha
                        </label>
                        <div className="col-9 divBtnExibirSenha">
                          <input
                            type={showPasswordConfirm?"text":"password"}
                            className="form-control"
                            id="confirmPassword"
                            name="confirmPassword"
                            required="true"
                            value={form.confirmPassword}
                            placeholder="Digite a senha novamente"
                            onChange={e => changeInput(e, 'confirmPassword')}
                          />
                          <a className="buttonExibirSenha mt-1" href="#" onClick={()=>setShowPasswordConfirm(!showPasswordConfirm)}>
                            <i className={!showPasswordConfirm ? 'fas fa-eye' : 'fas fa-eye-slash'}
                               data-toggle='tooltip'
                               data-placement='top'
                               title='Exibir a senha'
                            />
                          </a>
                        </div>
                      </div>
                        <Row>
                          <Col className="my-3">
                            <div>
                              {form.password &&
                              showValidatePassword(form.password, form.confirmPassword, Ok, Block)}
                            </div>
                          </Col>
                        </Row>
                      </Section>
                    </div>
                    <div className="card-footer d-flex justify-content-center border-0">
                      <button
                        type="submit"
                        className="btn btn-success"

                      >
                        Alterar senha
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export { NewPassword }
