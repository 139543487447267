import React from 'react'
import moment from 'moment'
import { Row, Col } from 'react-bootstrap'

import { Container, Progress } from './styles'
import pdfIcon from '~/assets/icons/pdfIcon.png'

function PrescriptionItem({ prescription }) {
  let progressPercert
  switch (prescription.status) {
    case 'askAnvisa':
      progressPercert = 40
      break
    case 'askMedicine':
      progressPercert = 40
      break
    case 'askPhysician':
      progressPercert = 40
      break
    case 'templateAnvisa':
      progressPercert = 40
      break
    case 'sendAnvisa':
      progressPercert = 40
      break
    case 'quotation':
      progressPercert = 62
      break
    case 'analyzing':
      progressPercert = 85
      break
    default:
      progressPercert = 15
      break
  }

  let checkReviews = false

  if (prescription.review_anvisa !== 'review') checkReviews = true
  if (prescription.review_prescription !== 'review') checkReviews = true
  if (prescription.review_document !== 'review') checkReviews = true
  if (prescription.review_address !== 'review') checkReviews = true


  const isRepresentative = localStorage.getItem('roles') === 'representative'

  return (
    <div className='container-fluid admin_prescription_item'>
          <div className='row'>
            <div className="col-lg-12 d-flex justify-content-between admin_prescription_item_header">
              <h5 className="admin_prescription_item_header_title">Prescrição #{prescription.id}</h5>
              <span>
                {prescription.notify && (
                  <span className="text--rb notify mr-2">●</span>
                )}
                {checkReviews && (
                  <span className="text--rb checked mr-2">✓</span>
                )}
                <span>
                  {moment(prescription.created_at).format('DD/MM/YYYY')}
                </span>
              </span>
            </div>
          </div>
          <div className="row mt-3 p-2">
            <div className="col-lg-4 col-6 d-flex align-items-center">
              <div>
                <h6>Paciente</h6>
                <p>{prescription.patient?.profile?.full_name}</p>
              </div>
            </div>
            <div className="col-lg-4 col-6 d-flex align-items-center">
              <div>
                <h6>{isRepresentative ? 'Representante' : 'Médico'}</h6>
                <p>
                  {prescription.physician
                    ? prescription.physician.profile.full_name
                    : prescription.prePhysician
                    ? prescription.prePhysician.full_name
                    : 'Não informado'}
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className='row'>

                  {prescription.file && (
                    <div className='col-lg-6 col-6'>
                      <a href={prescription.file.url} target="_blank" rel="noopener">
                        <div className="admin_prescription_item_file">
                          <h6>Prescrição</h6>
                          <img src={pdfIcon} alt=''/>
                        </div>
                      </a>
                    </div>
                  )}

                  {prescription.anvisa && (
                    <div className='col-lg-6 col-6'>
                      <a href={prescription.anvisa.url} target="_blank" rel="noopener">
                        <div className="admin_prescription_item_file">
                          <h6>ANVISA</h6>
                          <img src={pdfIcon} alt=''/>
                        </div>
                      </a>
                    </div>
                  )}

              </div>


            </div>
          </div>
          <div className='row'>
            <div className='col-lg-12 p-0'>
              <div className="admin_prescription_item_footer">
                <div className='admin_prescription_item_footer_progress'>
                  <Progress now={progressPercert} />

                </div>
                <div className="d-flex admin_prescription_item_footer_steps">
                  <span className="m-auto">Prescrição</span>
                  <span className="m-auto">Anvisa</span>
                  <span className="m-auto">Cotação</span>
                  <span className="m-auto">Análise</span>
                </div>
              </div>
            </div>
          </div>

    </div>
  )
}

export default PrescriptionItem
