import React from 'react'
import PropTypes from 'prop-types'

const Button = React.forwardRef(
  ({ className, children, onClick, ...rest }, ref) => {
    return (
      <button onClick={onClick} className={`${className} btn__primary`} ref={ref} {...rest}>
        {children}
      </button>
    )
  },
)

Button.propTypes = {
  children: PropTypes.string.isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func,
}

export default Button
