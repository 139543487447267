import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as $ from 'jquery'

import { Container } from './styles'
import Prescription from './Prescription'
import ModalFinishRegistration from '~/components/ModalFinishRegistration/index'
import { fetchBrands, getPrescriptions } from '~/services/requests'
import { setBrandToPrescription } from '~/redux/actions/prescriptions'
import Pagination from 'react-js-pagination'

export default function Prescriptions() {
  const dispatch = useDispatch()
  const [prescriptions, setPrescriptions] = useState([])
  const [showModal, setShowModal] = useState(false)
  const auth = useSelector(state => state.auth)
  const { session } = auth

  useEffect(() => {
    auth.session.profile.full_name &&
    ((!auth.session.profile.cpf || !auth.session.profile.birthdate) && session.roles[0] !== 'administrator' && session.roles[0] !== 'representative')
      ? setShowModal(true)
      : setShowModal(false)
    getPrescriptions().then(r=> setPrescriptions(r.data))
    fetchBrands().then(r=>dispatch(setBrandToPrescription(r.data)))
  }, [])

  const handlePageChange = page => {
    $("html, body").animate({ scrollTop: 0 }, "slow");
    getPrescriptions(page).then(r=> setPrescriptions(r.data))
  }

  return (
    <>
      <div className='container-fluid physician__prescriptions'>
        <div className='row'>
          <div className='col-lg-12 mb-3'>
            <h1 className='physician__prescriptions__title'>Prescrições</h1>
          </div>
        </div>
        <Prescription className="mb-3" />

        {session.roles[0] !== 'administrator' && prescriptions?.data?.map(prescription => (
          <Prescription
            data={prescription}
            className="mb-3"
            key={prescription.id}
          />
        ))}
      </div>
      <ModalFinishRegistration
        show={showModal}
        handleClose={() => {}}
        backdrop="static"
      />
      <div className='row my-3'>
        <div className="d-flex col-lg-12 justify-content-center mt-3">
          <Pagination
            activePage={prescriptions?.page}
            itemsCountPerPage={prescriptions?.perPage}
            totalItemsCount={prescriptions?.total}
            pageRangeDisplayed={5}
            onChange={handlePageChange}
            itemClass="page-item"
            linkClass="page-link"
          />
        </div>
      </div>
    </>
  )
}
