import axios from 'axios'


const viaCEP = axios.create({
  baseURL: 'https://viacep.com.br/ws',
})

const getAddressByCep = (cep, formart = 'json') => {
  return viaCEP.get(`/${cep}/${formart}/`)
}

export { getAddressByCep }
