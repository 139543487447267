import React from 'react'
import PropTypes from 'prop-types'

import ProfileIcon from '~/assets/icons/profile.svg'
import { RenderImg } from '~/components/RenderImg/RenderImg'
import { isAuthenticated } from '~/services/auth'
import { SocialLinks } from '~/components/SocialLinks/SocialLinks'

const ProfileCard = function({ data, physician }) {

  return (
    <div className='container profile__card'>
      <div className=' profile__card__img-box'>
        {data &&
        data.profile &&
        (data.profile.avatar ? (
          <RenderImg
            photo_id={data?.profile?.avatar.id}
            photo_uuid={data?.profile?.avatar.uuidUrl}
            isPrivate={data?.profile?.avatar.private}
            imgSecond={ProfileIcon}
            classNameImg='align-self-center rounded-circle'
            alt='Usuário'
          />
        ) : (
          <img
            className='align-self-center rounded-circle profile__card_default_avatar'
            src={ProfileIcon}
            alt='Usuário'
          />
        ))}
      </div>
      <div className={`profile__card__description ${!physician && 'w-100'}`}>
        <div className='mx-3'>
          <div className='row mx-0 px-0'>
            <div className='col-lg-6 mx-0 px-0'>
              <h3 className='profile__card__description__name'>
                {!physician?.crm
                  ? ''
                  : data.profile.gender === 'm'
                    ? 'Dr. '
                    : data.profile.gender === 'f'
                      ? 'Dra. '
                      : ''}
                {data?.profile?.full_name}
              </h3>
            </div>
            {physician && (
              <div>
                <p> Compartilhe esse perfil em suas redes</p>
                <SocialLinks physician={physician} iconWidthClass='fa-2x' />
              </div>
            )}
          </div>
          <h5 className='mt-2 profile__card__description__crm'>
            {physician?.crm && `CRM: ${physician?.crm}`}{' '}
            {physician?.uf && physician?.uf !== 'XX' && (
              <span>{physician?.uf}</span>
            )}
            {!physician &&
            data?.addresses?.length > 0 &&
            data?.addresses[0]?.state}
          </h5>
          {physician?.online_consultation && (
            <h5 className='profile__card__description__telemed'>
              {data.profile.gender === 'f' ? 'Esta médica ' : 'Este médico '} faz consultas online
            </h5>
          )}
          {isAuthenticated() &&
          <h5 className='profile__card__description__link'>
            <a
              href={`/perfil/${physician?.slug}`}>{`${window.location.origin.toString()}/perfil/${physician?.slug}`}</a>
          </h5>
          }
        </div>
      </div>
    </div>
  )
}

ProfileCard.propTypes = {
  data: PropTypes.object.isRequired,
}

export default ProfileCard
