import { updateObject } from '~/shared/updateObject'

const initialState = {
  orderStates: []
}

const setOrderStates = (state, action) => {
  return updateObject(state, {
    orderStates: action.payload
  })
}

const orderReducer = (state=initialState, action) => {
  switch (action.type) {
    case "SET_ORDER_STATES":
      return setOrderStates(state, action);
    default:
      return state;
  }
}

export {orderReducer}
