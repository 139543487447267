import React from "react"
import Skeleton from 'react-loading-skeleton'

const CustomCheckbox = ({label, inputName,register, inputValue, callBack, inputChecked, error, inputClass, loading, skeletonDivClass})=>{
  return(
    <>
    {
      loading ?
        (
          <div  className={skeletonDivClass}>
            <Skeleton/>
          </div>

        ):
        (
          <div className={`form-group custom__checkbox ${inputClass}`}>
            <label className="checkbox-container">
              {label}
              <input className='form-check-input' type="checkbox"
                     name={inputName}
                     id={inputName}
                     ref={register}
                     value={inputValue}
                     onClick={callBack}
                     checked={inputChecked}
              />
              <span className='checkbox-checkmark' />
            </label>
            {error && <span className="error">{error.message}</span>}
          </div>
        )
    }
    </>
  )
}

export {CustomCheckbox}
