import React, { useState } from 'react'
import { ModalConfirmVote } from '~/components/ModalConfirmVote/ModalConfirmVote'
import { CustomInput, CustomSelect, CustomTextArea } from '~/components'
import Button from '~/components/Button'
import SimpleBar from 'simplebar-react'
import StatesBrasil from '~/helpers/StatesBrasil.json'
import Yup from '~/helpers/yup'
import { useForm } from 'react-hook-form'
import Home from '~/assets/images/home.png'
import TrofeuIcon from '~/assets/images/trofeu.png'
import api from '~/services/api'
import * as $ from 'jquery'
import { toast } from 'react-toastify'
import { useHistory } from 'react-router-dom'


const FormSchema = Yup.object().shape({
  voter_email: Yup.string()
    .email('Digite um email válido')
    .required('Digite um email'),
  voter_name: Yup.string().required('Digite seu nome'),
  reason: Yup.string().required('Preencha a descrição'),
  nominee_name: Yup.string().required('Digite o nome do Médico'),
  nominee_crm: Yup.string().required('CRM inválido'),
  nominee_crm_state: Yup.string().required('Selecione o Estado'),
  nominee_email: Yup.string()
    .email('Digite um email válido'),
})

const Vote = () => {
  const [loading, setLoading] = useState(false)
  const { register, handleSubmit, errors, reset } = useForm({
    validationSchema: FormSchema,
  })
  const history = useHistory()

  const onSubmit = (data) => {
    setLoading(true)
    api.post('/contest2021', data)
      .then(r => {
        r.status === 201 && history.push(`/premio2021/confirmar`)
        localStorage.setItem('voter_uuid', r.data.uuid)
        $('#modalConfirmVote').modal('show')
      })
      .catch((error) => {
        if (error.response.status === 409) {
          toast.error('Você já votou, acesse seu e-mail para validar sua indicação')
        }
      })
      .finally(() => setLoading(false))
  }

  const cleanForm = () => {
    reset({
      voter_email: '',
      voter_name: '',
      reason: '',
      nominee_name: '',
      nominee_crm: '',
      nominee_crm_state: '',
      nominee_email: '',
    })
  }

  const sendEmail = () => {
    const uuid = localStorage.getItem('voter_uuid')
    api.get(`contest2021/resend/${uuid}`)
      .then(() => toast.success('E-mail enviado com sucesso!!'))
    $('#modalConfirmVote').modal('hide')
    cleanForm()
  }

  return (
    <div className='py-5' style={{ background: `url(${Home})` }}>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-6 col-sm-12 mb-5'>
            <div className='d-flex justify-content-center'>
              <img src={TrofeuIcon} alt='' />
            </div>
            <div className='text-center my-3'>
              <h1 className='font-size-50'>Prêmio<br />Dr. Cannabis do ano 2021</h1>
            </div>
            <div className='mt-4'>
              <p className='font-weight-normal vote-info'>
                Médicos mudam vidas e o tratamento com cannabis também.
              </p>
              <p className='font-weight-normal vote-info'>
                Indique aqui um médico ou uma médica que prescreve
                canabinoides e que, na sua opinião, se destacou em 2021.
              </p>
              <p className='font-weight-normal vote-info'>Os vencedores serão homenageados pela Dr. Cannabis.</p>
            </div>
          </div>
          <div className='col-lg-6 col-sm-12 mb-5'>
            <div className='card  vote_card'>
              <div className='card-header bg-white vote_card_header text-center py-4'>
                <span className='font-size-25 font-weight-bold'>PREENCHA AS INFORMAÇÕES PARA VOTAR</span>
              </div>
              <form onSubmit={handleSubmit(onSubmit)}>
                <SimpleBar style={{ height: '18rem' }} forceVisible='y' autoHide={false}>
                  <div className='card-body'>
                    <CustomInput inputPlaceholder='Seu nome'
                                 inputName='voter_name'
                                 inputClass='form-control-lg'
                                 error={errors.voter_name}
                                 register={register}

                    />
                    <CustomInput inputPlaceholder='Seu e-mail'
                                 inputClass='form-control-lg'
                                 inputName='voter_email'
                                 register={register}
                                 error={errors.voter_email}
                                 inputType='email'
                    />
                    <CustomInput inputPlaceholder='Nome do médico que você quer homenagear'
                                 inputClass='form-control-lg'
                                 inputName='nominee_name'
                                 error={errors.nominee_name}
                                 register={register}
                    />
                    <div className='d-flex justify-content-between'>
                      <CustomInput inputPlaceholder='CRM do indicado'
                                   inputType='number'
                                   inputClass='form-control-lg'
                                   inputName='nominee_crm'
                                   error={errors.nominee_crm}
                                   register={register}
                      />
                      <CustomSelect inputName='nominee_crm_state'
                                    inputClass='form-control-lg'
                                    error={errors.nominee_crm_state}
                                    register={register}
                      >
                        <option value=''>Estado</option>
                        {StatesBrasil.map(state => (
                          <option value={state.value}>{state.label}</option>
                        ))}
                      </CustomSelect>
                    </div>
                    <CustomInput inputPlaceholder='E-mail do médico (avisaremos sobre sua indicação)'
                                 inputClass='form-control-lg'
                                 inputName='nominee_email'
                                 register={register}
                                 error={errors.nominee_email}
                                 inputType='email'
                    />
                    <CustomTextArea inputPlaceholder='Descreva o motivo da indicação'
                                    inputName='reason'
                                    error={errors.reason}
                                    register={register}
                                    maxLength={250}
                    />
                  </div>
                </SimpleBar>
                <div className='card-footer bg-white d-flex justify-content-center vote_card_footer pb-5'>
                  <Button className='primary loginBtn lg rounded w-50 vote_button'
                          type='submit'
                          disabled={loading}
                  >
                    {loading ? (
                      <div
                        className='spinner-border spinner-border-sm'
                        role='status'
                      >
                      </div>
                    ) : 'Enviar'}
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <ModalConfirmVote sendEmail={sendEmail} cleanForm={cleanForm} />
    </div>
  )
}

export { Vote }
