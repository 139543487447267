import React, { useEffect, useState } from 'react'
import moment from 'moment'

import api from '~/services/api'
import { ProgressBar } from 'react-bootstrap'
import AskMedicine from './AskMedicine'
import AskPatient from './AskPatient'
import GetPrescription from './GetPrescription'
import SendPrescription from './SendPrescription'
import Done from './Done'

const Prescription = ({ data, ...rest }) => {
  const [step, setStep] = useState(data?.status ?? 'new')
  const [prescription, setPrescription] = useState(data)
  const [loading, setLoading] = useState(false)

  const getData = async id => {
    setLoading(true)
    const res = await api.get(`/prescriptions/${id}`)
    setPrescription(res.data)
    setLoading(false)
  }


    let activeSteps = {
      patient: '',
      medicine: '',
      getPrescription: '',
      sendPrescription: '',
      anvisa: '',
    }
    let Body
    let progressPercent
    switch (step) {
      case 'new':
        Body = AskPatient
        progressPercent = 12
        activeSteps.patient = 'active-badges'
        break
      case 'askMedicine':
        Body = AskMedicine
        progressPercent =  33.5
        activeSteps.patient = 'active-badges'
        activeSteps.medicine = 'active-badges'
        break
      case 'getPrescription':
        Body = GetPrescription
        progressPercent = 58
        activeSteps.patient = 'active-badges'
        activeSteps.medicine = 'active-badges'
        activeSteps.getPrescription = 'active-badges'
        break
      case 'sendPrescription':
        Body = SendPrescription
        progressPercent = 85
        activeSteps.patient = 'active-badges'
        activeSteps.medicine = 'active-badges'
        activeSteps.getPrescription = 'active-badges'
        activeSteps.sendPrescription = 'active-badges'
        break
      case 'askAnvisa':
        Body = Done
        progressPercent = 100
        activeSteps.patient = 'active-badges'
        activeSteps.medicine = 'active-badges'
        activeSteps.getPrescription = 'active-badges'
        activeSteps.sendPrescription = 'active-badges'
        activeSteps.anvisa = 'active-badges'
        break
      default:
        Body = Done
        progressPercent = 100
        break
    }




  return (
    <div className="card physician__prescriptions__prescription">
      <div className="physician__prescriptions__prescription__header-container">
        {data && (
          <>
            <span>
              Enviado em {moment(data?.created_at).format('DD/MM/YY')}
            </span>
            <span className="float-right">Prescrição #{data?.id}</span>
          </>
        )}
        {(!data || data?.length === 0) && <span>Nova Prescrição</span>}
      </div>
      <div className="physician__prescriptions__prescription__body">
        <Body next={setStep} data={prescription} refresh={getData} />
      </div>
      <div className="physician__prescriptions__prescription__footer">
        <ProgressBar now={progressPercent} />
        <div className="d-flex physician__prescriptions__prescription__footer__steps">
          <span className={`m-auto ${activeSteps.patient}`}>
            Paciente
            {activeSteps.patient && <i className="fas fa-check pl-2"/>}
          </span>
          <span className={`m-auto ${activeSteps.medicine}`}>
            Medicamento
            {activeSteps.medicine &&  <i className="fas fa-check pl-2"/>}
          </span>
          <span className={`m-auto ${activeSteps.getPrescription}`}>
            Baixar Prescrição
            {activeSteps.getPrescription && <i className="fas fa-check pl-2"/>}
          </span>
          <span className={`m-auto ${activeSteps.sendPrescription}`}>
            Subir Prescrição
            {activeSteps.anvisa && <i className="fas fa-check pl-2"/> }
          </span>
        </div>
      </div>
    </div>
  )
}

export default Prescription
