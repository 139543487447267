import { updateObject } from '~/shared/updateObject'

const initialState = {
  listMedicines: [],
  finishMedicinesRequest: false
}

const setListMedicines = (state, action) => {
  return updateObject(state, {
    listMedicines: action.payload,
    finishMedicinesRequest: true,
  })
}

const setListMedicinesRequest = (state) => {
  return updateObject(state, {
    finishMedicinesRequest: false,
  })
}

const medicinesReducer = (state=initialState, action) => {
  switch (action.type) {
    case "SET_LIST_PRESCRIPTIONS":
      return setListMedicines(state, action);
    case "SET_LIST_PRESCRIPTIONS_REQUEST":
      return setListMedicinesRequest(state)
    default:
      return state;
  }
}

export { medicinesReducer }
