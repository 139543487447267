import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import moment from 'moment'
import NumberFormat from 'react-number-format'
import ProfileCard from '~/components/ProfileCard'
import api from '~/services/api'
import PrescriptionItem from '../Prescriptions/PrescriptionItem'
import { transform } from '~/shared/utils'
import pdfIcon from '~/assets/icons/pdfIcon.png'
import { ButtonPrimary } from '~/components'

export default function Patient() {
  const { id } = useParams()
  const [patient, setPatient] = useState({})
  const [url, setUrl] = useState('')
  const [type, setType] = useState('')
  const [nameFile, setNameFile] = useState('')
  const [prescriptions, setPrescriptions] = useState([])
  const [loading, setLoading] = useState(true)

  const getPrescriptions = async () => {
    setLoading(true)
    const res = await api.get(`/prescriptions?patient=${id}`)
    setPrescriptions(res.data)
    setLoading(false)
  }

  const getPatient = async patientId => {
    setLoading(true)
    const res = await api.get(`/patients/${patientId}`)
    setPatient(res.data)
    setLoading(false)
  }

  useEffect(() => {
    getPatient(id)
    getPrescriptions()
  }, [id])

  const getUrl = async (fileId, fileType, fileName) => {
    setType(fileType)
    setNameFile(fileName)
    await transform(`/files/${fileId}`).then(r => setUrl(r))
  }

  useEffect(() => {
    if (url) {
      let a = document.createElement('a')
      a.href = url
      a.download = `${nameFile}.${type === 'application' ? 'pdf' : type}`
      a.click()
      setUrl('')
    }
  }, [url])

  const profile = patient.profile
  const ward = patient.wards?.length ? patient.wards[0] : {}
  const isRepresentative = localStorage.getItem('roles') === 'representative'

  return (
    <div className='container-fluid admin_patient_detail'>
      <div className='row'>
        <div className='col-lg-9 my-4'>
          <ProfileCard data={patient} />
        </div>
        <div className='col-lg-3'>
          <Link to={isRepresentative ? `/representantes/pacientes/editar/${patient.id}` : `/admin/pacientes/editar/${patient.id}`}>
            <ButtonPrimary
              text='Editar'
              btnMt='mt-3'
              btnClass='my-2 w-100'
              loading={loading}
              disabled={loading}
            />
          </Link>
          <Link to={`/admin/pacientes/consultas/${patient.id}`}>
            <ButtonPrimary
              text='Visualizar consultas'
              btnMt='mt-3'
              btnClass='my-2 w-100'
              loading={loading}
              disabled={loading}
            />
          </Link>
        </div>
      </div>
      <div className='row'>
        <div className='col-lg-12 my-5'>
          <h1 className='admin_patient_detail_title'>Informações Pessoais</h1>
        </div>
      </div>
      <div className='row'>
        <div className='col-lg-6'>
          {profile?.birthdate && (
            <div className='mb-4'>
              <h5>Data de Nascimento</h5>
              <p>{moment(profile?.birthdate).format('DD/MM/YYYY')}</p>
            </div>
          )}
        </div>

        <div className='col-lg-6'>
          {profile?.rg && (
            <div className='mb-4'>
              <h5>RG</h5>
              <p>{profile?.rg}</p>
            </div>
          )}
        </div>
      </div>


      <div className='row'>
        <div className='col-lg-6'>
          {profile?.cpf && (
            <div className='mb-4'>
              <h5>CPF</h5>
              <p>
                <NumberFormat
                  value={profile?.cpf}
                  displayType={'text'}
                  format='###.###.###-##'
                />
              </p>
            </div>
          )}
        </div>
        <div className='col-lg-6'>
          <h5>Contato</h5>
          {profile?.mobile_number && (
            <p>
              <NumberFormat
                value={profile?.mobile_number}
                displayType={'text'}
                format='(##) #####-####'
              />
            </p>
          )}
          {profile?.phone_number && (
            <p>
              <NumberFormat
                value={profile?.phone_number}
                displayType={'text'}
                format='(##) ####-####'
              />
            </p>
          )}
          <p className='mb-2'>{patient.email}</p>
        </div>
      </div>

      {ward.profile && (
        <div>
          <div className='row'>
            <div className='col-lg-6 my-3'>
              <h4>Dados do paciente</h4>
            </div>
            {ward.profile?.full_name && (
              <div className=' col-lg-6 mb-4'>
                <h5>Nome do paciente</h5>
                <p>{ward.profile.full_name}</p>
              </div>
            )}
            {ward.profile?.birthdate && (
              <div className='col-lg-6 mb-4'>
                <h5>Data de Nascimento</h5>
                <p>{moment(ward.profile?.birthdate).format('DD/MM/YYYY')}</p>
              </div>
            )}
          </div>
          <div className='row'>
            {ward.profile?.rg && (
              <div className=' col-lg-6 mb-4'>
                <h5>RG</h5>
                <p>{ward.profile?.rg}</p>
              </div>
            )}


            {ward.profile?.cpf && (
              <div className=' col-lg-6 mb-4'>
                <h5>CPF</h5>
                <p>
                  <NumberFormat
                    value={ward.profile?.cpf}
                    displayType={'text'}
                    format='###.###.###-##'
                  />
                </p>
              </div>
            )}
            {ward.profile?.mobile_number && (
              <div className='col-lg-6 mb-4'>
                <h5>Contato</h5>
                <p>
                  <NumberFormat
                    value={ward.profile?.mobile_number}
                    displayType={'text'}
                    format='(##) #####-####'
                  />
                </p>
              </div>
            )}
          </div>
        </div>
      )}
      {prescriptions?.length > 0 && (
        <div>
          <div className='col-lg-12 my-3'>
            <h4 className=''>Prescrições</h4>
          </div>
        </div>
      )}
      {!loading &&
      patient.addresses?.map(address => (
        <div key={address.id}>
          <div className='row'>
            <div className='col-lg-6 mb-4'>
              <h5>Estado</h5>
              <p>{address.state}</p>
            </div>
            <div className='col-lg-6 mb-4'>
              <h5>Cidade</h5>
              <p>{address.city}</p>
            </div>
          </div>

          <div className='row'>
            <div className='col-lg-6 mb-4'>
              <h5>Logradouro</h5>
              <p>
                {address.street}, {address.number}
              </p>
            </div>
            <div className='col-lg-6 mb-4'>
              <h5>CEP</h5>
              <p>{address.cep}</p>
            </div>
          </div>

          <div className='row'>
            <div className='col-lg-6 mb-4'>
              <h5>Complemento</h5>
              <p>{address.complement}</p>
            </div>
            {address.file && (
              <div className='col-lg-6 mb-4 d-flex'>
                <a
                  onClick={() =>
                    getUrl(
                      address.file.id,
                      address.file.type,
                      address.file.name,
                    )
                  }
                  className='link'
                  style={{ cursor: 'pointer' }}
                >
                  <div className='file'>
                    <img src={pdfIcon} />
                    <p>Comprovante de Endereço</p>
                  </div>
                </a>
              </div>
            )}
          </div>

        </div>
      ))}
      <div className='row'>
        <div className='col-lg-6 mb-4 d-flex'>
          {!loading &&
          patient.documents?.map(document => (
            <a
              key={document.id}
              onClick={() =>
                getUrl(
                  document.file.id,
                  document.file.type,
                  document.file.name,
                )
              }
              className='link'
              style={{ cursor: 'pointer' }}
            >
              <div className='file'>
                <img src={pdfIcon} />
                <p>Documento {document.id}</p>
              </div>
            </a>
          ))}
        </div>
      </div>
      {!loading &&
      prescriptions.data?.map(prescription => (
        <div className='col-lg-12 mt-3' key={prescription.id}>
          <Link to={`/admin/prescricoes/${prescription.id}`}>
            <PrescriptionItem prescription={prescription} />
          </Link>
        </div>
      ))}
    </div>
  )
}
