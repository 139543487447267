import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { FormContext, useForm, useFormContext } from 'react-hook-form'
import { toast } from 'react-toastify'

import api from '~/services/api'
import Thumb from '~/components/Thumb'
import { useSelector } from 'react-redux'
import { fetchMedicines, fetchPrescriptions, updatePrescription } from '~/services/requests'
import useDebounce from '~/shared/useDebounce'
import { ButtonPrimary, ButtonSecondary, CustomInput, CustomSelect, CustomTextArea } from '~/components'

function SelectMedicines({ prescriptionId, onHide, show }) {
  const { brands } = useSelector(state => state.prescriptionsReducer)
  const { register, watch } = useForm()
  const [prescription, setPrescription] = useState({})
  const [medicines, setMedicines] = useState([])
  const [selectedMedicines, setSelectedListMedicines] = useState([])

  const search = watch('search')
  const brand = watch('brand')
  const debouncedSearchSearch = useDebounce(search, 700)
  const debouncedSearchBrand = useDebounce(brand, 700)


  useEffect(() => {
    fetchPrescriptions(prescriptionId).then(r => {
      setPrescription(r.data)
      setSelectedListMedicines(r.data.medicines)
    })
  }, [])

  useEffect(() => {
    if (debouncedSearchSearch || debouncedSearchBrand)
      fetchMedicines(brand, search).then(r => setMedicines(r.data))
  }, [debouncedSearchSearch, debouncedSearchBrand])


  const addMedicine = async (id, name) => {
    let medicinesData = medicines.data
    let listMedicines = medicines
    const medicinesObj = selectedMedicines?.map(sel =>
      ({ name: sel.name, id: sel.id }),
    ) ?? []
    medicinesObj.push({ id, name })
    setSelectedListMedicines(medicinesObj)
    const index = medicinesData.findIndex(val => val.id === id)
    if (index > -1) {
      medicinesData.splice(index, 1)
      listMedicines.data = medicinesData
      setMedicines(listMedicines)
    }
  }

  const removeMedicine = async id => {
    let medicinesObj = selectedMedicines?.map(sel =>
      ({ name: sel.name, id: sel.id }),
    ) ?? []
    medicinesObj = medicinesObj.filter(med => med.id !== id)
    setSelectedListMedicines(medicinesObj)

    const index = selectedMedicines.findIndex(val => val.id === id)
    let listMedicines = medicines
    if (index > -1) {
      if (listMedicines.data) {
        listMedicines.data.push(selectedMedicines[index])
        setMedicines(listMedicines)
      }
    }
  }

  const submit = async () => {
    if (selectedMedicines.length > 0) {
      const data = {
        medicines: selectedMedicines.map(meds => ({ id: meds.id })),
      }
      updatePrescription(prescriptionId, data)
        .then(() => onHide())
        .catch((e) => toast.error(e))
    } else toast.warn('Selecione um medicamento')
  }

  const validateList = id => {
    if (Array.isArray(prescription.medicines)) {
      const index = prescription.medicines.findIndex(val => val.id === id)
      return index === -1
    }
    return true
  }

  return (
    <Modal
      show={show}
      onHide={onHide}
      size='lg'
      aria-labelledby='contained-modal-title-vcenter'
      centered
      className='physician__prescriptions__prescription__AskMedicine'
    >
      <Modal.Header closeButton>
        <Modal.Title id='contained-modal-title-vcenter'>
          Selecionar produtos
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='row'>
          <div className='col-lg-6'>
            <CustomSelect
              inputName='brand'
              register={register}
            >
              <option value=''>Marcas</option>
              {brands.map(brand => (
                <option value={brand.slug}>{brand.name}</option>
              ))}
            </CustomSelect>
          </div>
          <div className='col-lg-6'>

            <CustomInput
              inputName='search'
              inputPlaceholder='Buscar'
              register={register}
            />
          </div>
        </div>
        <div className={`row ${medicines.data && 'd-none'}`}>
          <div className='col-12'>
            <p className="text--rr text-center">SELECIONE UMA MARCA OU DIGITE ALGO EM 'BUSCAR' (EX: 1.000 mg)</p>
          </div>
        </div>
        <div className='row'>
          <div className='col-lg-12'>
            <div className='my-3 physician__prescriptions__prescription__AskMedicine__medicine__list'>
              {selectedMedicines?.map(medicine => (
                <div className='row physician__prescriptions__prescription__AskMedicine__medicine__list__box'>
                  <div className='medicine col-lg-9 col-8 d-flex align-items-center'>
                    <div
                      className='physician__prescriptions__prescription__AskMedicine__medicine__list__name'>{medicine.name}</div>
                    <div>
                    </div>

                  </div>
                  <div className='col-lg-3 col-4 d-flex align-items-center justify-content-end'>
                    <ButtonSecondary
                      btnClass='btn__primary__prescription__remove mt-0'
                      callBack={() => removeMedicine(medicine.id)}
                      text='Remover'
                    />
                  </div>
                </div>

              ))}
            </div>
          </div>
        </div>
        <hr />
        <div className='row'>
          <div className='col-lg-12'>
            <div className='my-3 physician__prescriptions__prescription__AskMedicine__medicine__list'>
              {medicines.data?.map(
                medicine =>
                  validateList(medicine.id) && (
                    <div className='row physician__prescriptions__prescription__AskMedicine__medicine__list__box'>
                      <div className='medicine col-lg-9 col-8 d-flex align-items-center'>
                        <div
                          className='physician__prescriptions__prescription__AskMedicine__medicine__list__name'>{medicine.name}</div>
                        <div>
                        </div>

                      </div>
                      <div className='col-lg-3 col-4 d-flex align-items-center justify-content-end'>
                        <ButtonPrimary
                          callBack={() => addMedicine(medicine.id, medicine.name)}
                          text='Adicionar'
                          btnClass='btn__primary__prescription'
                        />
                      </div>
                    </div>
                  ),
              )}
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <ButtonPrimary
          callBack={() => submit()}
          btnType='button'
          text='Continuar'
          btnClass='btn__primary__prescription'
        />
      </Modal.Footer>
    </Modal>
  )
}

export default function AskMedicine({ next, data, refresh }) {
  const { medicines } = data
  const [modalShow, setModalShow] = useState(false)
  const [loading, setLoading] = useState(false)
  const formMethods = useForm()
  const representative = localStorage.getItem('roles') === 'representative'

  const goGetPrescription = async pivotMedicines => {
    if (data.medicines.length === 0) {
      toast.error('Selecione os produtos antes de continuar')
    } else {
      setLoading(true)
      const dataMedicines = medicines.map((medicine, idx) => {
        return { id: medicine.id, ...pivotMedicines.medicines[idx] }
      })
      const prescription = {
        medicines: dataMedicines,
        status: 'getPrescription',
      }
      await api.put(`/prescriptions/${data.id}`, prescription)
      next(representative ? 'sendPrescription' : 'getPrescription')
      setLoading(false)
    }
  }

  const closeSelectMedicines = async () => {
    await refresh(data.id)
    setModalShow(false)
  }

  return (
    <div className='container-fluid physician__prescriptions__prescription__AskMedicine'>
      <div className='alert-success p-3 mb-2'>
        <p>Agora só falta selecionar os produtos, a quantidade e o uso:</p>
      </div>

      <h4 className={`${medicines.length ? 'mb-2' : 'mb-4'} text-center`}>
        Quais os produtos prescritos?
      </h4>
      <form onSubmit={formMethods.handleSubmit(goGetPrescription)}>
        <FormContext {...formMethods}>
          {data.medicines.map((medicine, idx) => (
            <WindowedRow index={idx} item={medicine} />
          ))}
        </FormContext>

        <div className='d-flex justify-content-between'>
          <ButtonPrimary
            text='Selecionar produtos'
            callBack={() => setModalShow(true)}
            btnClass='btn__primary__prescription'
          />

          <ButtonPrimary
            text='Próximo'
            btnType='submit'
            disabled={loading}
            btnClass='btn__primary__prescription'
          />

        </div>
      </form>
      <SelectMedicines
        show={modalShow}
        onHide={closeSelectMedicines}
        prescriptionId={data.id}
      />
    </div>
  )
}

const WindowedRow = React.memo(({ index, item }) => {
  const { register, errors } = useFormContext()
  const representative = localStorage.getItem('roles') === 'representative'

  return (
    <div className='physician__prescriptions__prescription__AskMedicine'>
      {index !== 0 && <hr className='separator' />}

      <div className='physician__prescriptions__prescription__AskMedicine__product__box' key={item.id}>
        <div className='d-flex align-items-center justify-content-center'>
          <div className='thumb'>
            <Thumb>
              <div className='inner'>
                <img src={item.photo?.uuidUrl} alt='' />
              </div>
            </Thumb>
          </div>
          <div className='name'>{item.name}</div>
        </div>
        <div className='d-flex align-items-center'>
          <div className='row mt-3 w-100'>
            <div className='col-lg-4'>
              <div>

                <CustomInput
                  label='Quantidade'
                  inputName={`medicines[${index}].quantity`}
                  register={register({ required: true })}
                  error={
                    errors.medicines ? errors.medicines[index]?.quantity : false
                  }
                  inputType='number'

                />
              </div>
            </div>
            <div className='col-lg-6'>
                <CustomInput
                  label='Duração do tratamento (em meses)'
                  inputName={`medicines[${index}].duration`}
                  inputType='number'
                  register={register({ required: true })}
                  inputPlaceholder='ex: 24'
                  error={
                    errors.medicines ? errors.medicines[index]?.duration : false
                  }

                />
            </div>
            {!representative && (
              <div className='col-lg-12'>
                <div>
                  <CustomTextArea
                    label='Descreva aqui a posologia e via de uso'
                    register={register({ required: true })}
                    inputName={`medicines[${index}].use`}
                    error={
                      errors.medicines ? errors.medicines[index]?.use : false
                    }
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
})
