import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'

import Yup from '~/helpers/yup'
import { Creators as ErrorActions } from '~/store/ducks/error'
import api from '~/services/api'
import Thumb from '~/components/Thumb'
import PhotoProfile from '~/assets/images/foto_perfil.svg'
import { RenderImg } from '~/components/RenderImg/RenderImg'
import { ButtonPrimary, ButtonSecondary, CustomCheckbox, CustomInput } from '~/components'
import { validateCpfAndCnpj } from '~/shared/utils'

let parseFormats = ['DD/MM/YYYY']

const newPatientSchema = Yup.object().shape({
  isResponsible: Yup.boolean(),
  profile: Yup.object().shape({
    full_name: Yup.string().required('Digite o nome do paciente.'),
    birthdate: Yup.date()
      .format(parseFormats)
      .max(new Date(), 'Paciente não pode ter nascido no futuro!')
      .nullable()
      .typeError('Insira uma data Válida'),
    gender: Yup.string(),
    phone_number: Yup.string().removeSpecials(),
    mobile_number: Yup.string().removeSpecials(),
    rg: Yup.string(),
    cpf: Yup.string()
      .removeSpecials()
      .required('Digite o CPF do paciente'),
  }),
  ward: Yup.object().shape({
    isResponsible: Yup.boolean(),
    full_name: Yup.string().when('isResponsible', (isResponsible, schema) => {
      return isResponsible
        ? schema.required('Digite o nome do Paciente')
        : schema
    }),
    birthdate: Yup.date()
      .format(parseFormats)
      .max(new Date(), 'Paciente não pode ter nascido no futuro!')
      .typeError('Insira uma data Válida')
      .nullable()
      .when('isResponsible', (isResponsible, schema) => {
        return isResponsible ? schema : schema.nullable()
      }),
    gender: Yup.string().when('isResponsible', (isResponsible, schema) => {
      return schema
    }),
    cpf: Yup.string()
      .removeSpecials()
      .when('isResponsible', (isResponsible, schema) => {
        return isResponsible ? schema.required('Digite seu CPF') : schema
      }),
  }),
  email: Yup.string()
    .email('Digite um email válido')
    .required('Digite um email'),
})

function SelectPatient(props) {
  const { onHide } = props
  const { register, errors, watch, handleSubmit, setValue } = useForm({
    validationSchema: newPatientSchema,
  })

  const [loading, setLoading] = useState(false)
  const [formVisible, setFormVisible] = useState(false)
  const [patient, setPatient] = useState({})
  const [patientData, setPatientData] = useState({})


  const fetchPatient = async () => {
    setLoading(true)
    const cpfClean = cpf.replace(/[^0-9 ]/g, '')
    const res = await api.get(`/patients?cpf=${cpfClean}`)
    setPatient(res.data[0])
    if (res.data[0]) {
      setPatientData(res.data[0])
    } else {
      setFormVisible(true)
      setValue('profile.cpf', cpf)
    }
    setLoading(false)
  }

  useEffect(() => {
    register({ name: 'cpf' })
  }, [])

  const cpf = watch('cpf')
  const isResponsible = watch('isResponsible')
  const mask = '999.999.999-99'

  useEffect(() => {
    if (validateCpfAndCnpj(cpf)) fetchPatient()
  }, [cpf])

  const onSubmit = async data => {
    onHide(data)
  }

  const handleHide = () => {
    if (!formVisible) onHide(patientData)
    else {
      onHide({})
    }
  }

  const changeInputCpf = (e) => {
    setValue('cpf', e.currentTarget.value)
  }

  return (
    <Modal
      {...props}
      onHide={handleHide}
      size='lg'
      aria-labelledby='contained-modal-title-vcenter'
      centered
      className='physician__prescriptions__prescription__AskPatient'
    >
      <Modal.Header closeButton>
        <Modal.Title id='contained-modal-title-vcenter'>
          Informações do Paciente
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <>
          <div style={{ display: !formVisible ? 'block' : 'none' }}>
            <form>
              <div className='row'>
                <div className='col-lg-5'>
                  <CustomInput
                    label='Digite o CPF do paciente para começar.'
                    inputName='cpf'
                    defaultValue={cpf}
                    callBack={changeInputCpf}
                    mask={mask}
                  />
                </div>
              </div>
            </form>
            <div className='row'>
              <div className='col-lg-12'>
                {patient?.profile && (
                  <div className='physician__prescriptions__prescription__AskPatient__patient'>
                    <div className='thumb'>
                      <Thumb>
                        <div className='inner'>
                          {patient.profile?.avatar && (
                            <img src={patient.profile?.avatar?.url} />
                          )}
                          {!patient.profile?.avatar && (
                            <img src={PhotoProfile} />
                          )}
                        </div>
                      </Thumb>
                    </div>
                    <div className='name'>
                      {patient.wards.length > 0 && (
                        <p className='my-2'>
                          {patient.wards[0].profile.full_name}
                        </p>
                      )}
                      <p className={patient.wards.length > 0 ? 'small' : ''}>
                        {patient.profile.full_name}
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
        <form
          style={{ display: formVisible ? 'block' : 'none' }}
          id='patientForm'
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className='row'>
            <div className='col-lg-12'>
              <CustomCheckbox
                label='Paciente tem um responsável'
                inputName='isResponsible'
                register={register}
                error={errors.isResponsible}
              />
            </div>

            {isResponsible && (
              <>
                <div className='col-lg-6'>
                  <CustomInput
                    label='Nome do paciente'
                    inputName='ward.full_name'
                    register={register}
                    error={errors.ward?.full_name}
                  />
                </div>
                <div className='col-lg-3'>
                  <CustomInput
                    label='CPF do paciente'
                    inputName='ward.cpf'
                    register={register}
                    error={errors.ward?.cpf}
                    mask={mask}
                  />
                </div>

                <div className='my-3 col-lg-12'>
                  <CustomCheckbox
                    inputName='ward.isResponsible'
                    register={register}
                    error={errors.isResponsible}
                    inputChecked={isResponsible}
                    inputClass='d-none'
                  />
                </div>
              </>
            )}
            <div className='col-lg-6'>
              <CustomInput
                label={`Nome do ${isResponsible ? 'responsavel' : 'paciente'}`}
                inputName='profile.full_name'
                register={register}
                error={errors.profile?.full_name}
              />
            </div>
            <div className='col-lg-6'>
              <CustomInput
                label={`Email do ${isResponsible ? 'responsavel' : 'paciente'}`}
                inputName='email'
                register={register}
                error={errors.email}
              />
            </div>
            <div className='col-lg-6'>
              <CustomInput
                label={`CPF do ${isResponsible ? 'responsavel' : 'paciente'}`}
                inputName='profile.cpf'
                register={register}
                error={errors.profile?.cpf}
                mask={mask}
              />
            </div>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        {patient?.id ? (
          <ButtonPrimary text='Salvar' callBack={handleHide} />
        ) : (
          <ButtonPrimary text='Salvar' btnType='submit' btnForm='patientForm' />

        )}
      </Modal.Footer>
    </Modal>
  )
}

export default function AskPatient({ next, data, refresh }) {
  const [modalShow, setModalShow] = useState(false)
  const [patient, setPatient] = useState({})
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const { session } = useSelector(state => state.auth)

  const onSubmit = async () => {
    setLoading(true)
    if (patient.profile) {
      try {
        const prescription = {
          user_email: session.email,
          status: 'askMedicine',
          patient,
        }

        let res
        if (data?.id) {
          res = await api.put(`/prescriptions/${data.id}`, prescription)
        } else {
          res = await api.post(`/prescriptions`, prescription)
        }
        await refresh(res.data.id)
        next('askMedicine')
        setLoading(false)
      } catch (e) {
        if (e.response.status === 406)
          toast.error('Os dados informados são de um médico. \n' +
            'Por favor informe os dados do paciente.')
        dispatch(ErrorActions.setError(e.response))
        setLoading(false)
      }
    } else {
      toast.error('Selecione um paciente primeiro')
    }
  }

  const closeSelectPatient = data => {
    setPatient(data)
    setModalShow(false)
  }

  let patientPreview
  if (patient.id) {
    localStorage.setItem('user_email', patient.email)
    patientPreview = (
      <div className='physician__prescriptions__prescription__AskPatient'>
        <div className='physician__prescriptions__prescription__AskPatient__patient'>
          <div className='thumb'>
            <Thumb>
              <div className='inner'>
                {patient.profile?.avatar && (
                  <RenderImg
                    photo_id={patient.profile?.avatar?.id}
                    photo_uuid={patient.profile?.avatar?.uuidUrl}
                    isPrivate={patient.profile?.avatar?.private}
                  />
                )}
                {!patient.profile?.avatar && <img src={PhotoProfile} alt='' />}
              </div>
            </Thumb>
          </div>
          <div className='name'>
            {patient.wards?.length > 0 && (
              <p className='my-2'>{patient.wards[0].profile.full_name}</p>
            )}
            <p className={patient.wards.length > 0 ? 'small' : ''}>
              {patient.profile.full_name}
            </p>
          </div>
        </div>
      </div>
    )
  } else if (patient.profile?.full_name && !patient.id) {
    patientPreview = (
      <div className='physician__prescriptions__prescription__AskPatient'>
        <div className='physician__prescriptions__prescription__AskPatient__patient'>
          <div className='thumb'>
            <Thumb>
              <div className='inner'>
                <img src={PhotoProfile} alt='' />
              </div>
            </Thumb>
          </div>
          <div className='name'>
            {patient.ward.full_name && (
              <p className='my-2'>{patient.ward.full_name}</p>
            )}
            <p className={patient.ward.full_name ? 'small' : ''}>
              {patient.profile.full_name}
            </p>
          </div>
        </div>
      </div>
    )
  } else {
    patientPreview = <div className='my-3' />
  }

  return (
    <div className='container-fluid physician__prescriptions__prescription__AskPatient'>
      <div className='alert-success p-3 mb-2'>
        <p>Selecione o paciente para quem você irá fazer esta prescrição.</p>
      </div>

      <h4 className='text-center'>Quem é o paciente?</h4>
      <p></p>

      {patientPreview}

      <div className='d-flex justify-content-center mb-4'>
        <ButtonSecondary
          text='Indique o paciente'
          callBack={() => setModalShow(true)}
          btnClass='btn__primary__prescription'
        />

      </div>

      <div className='d-flex justify-content-end'>
        <ButtonPrimary
          text='Próximo'
          callBack={onSubmit}
          btnClass='btn__primary__prescription'
        />
      </div>

      <SelectPatient show={modalShow} onHide={closeSelectPatient} />
    </div>
  )
}
