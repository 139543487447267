import React, { useEffect, useState } from 'react'
import BigLogo from '~/assets/images/bigLogo.png'
import TrofeuIcon from '~/assets/images/trofeu.png'
import Button from '~/components/Button'
import { useHistory, useParams } from 'react-router-dom'
import api from '~/services/api'
import Skeleton from 'react-loading-skeleton'

const ConfirmVote = () => {
  const [showMessage, setShowMessage] = useState(false)
  const history = useHistory()
  const { uuid } = useParams()

  useEffect(() => {
    if (uuid) {
      api.post(`/contest2021/confirm/${uuid}`)
        .then(() => setShowMessage(true))
        .catch(() => history.push('/premio2021'))
    } else setShowMessage(true)
  }, [uuid])

  return (
    showMessage ? (
      <div className='py-5 ' style={{ background: `url(${BigLogo})` }}>
        <div className='container'>
          <div className='row'>
            <div className='col-12'>
              <p className='text-center vote-confirm-message'>
                VOTAÇÃO
              </p>
              <p className='text-center vote-confirm-message'>
                Prêmio Dr. Cannabis 2021
              </p>
            </div>
          </div>
          <div className='row mt-5'>
            <div className='col-12'>
              <p className='text-center vote-confirm-title text--rb'>
                OBRIGADO POR PARTICIPAR!
              </p>
            </div>
            <div className='col-12 d-flex justify-content-center my-3'>
              <img src={TrofeuIcon} alt='' />
            </div>
            <div className='col-12'>
              <p className='text-center font-weight-normal vote-confirm-info'>
                Tudo certo com seu voto
              </p>
            </div>
          </div>
          <div className='row my-5'>
            <div className='col-12'>
              <p className='text-center text--rb font-size-25'>O resultado será divulgado em 03/12/2021</p>
            </div>
          </div>
        </div>
      </div>
    ) : (
      <div className='py-5 h-100'>
        <div className='container'>
          <div className='row'>
            <div className='col-12'>
              <Skeleton height='50px' />
              <Skeleton height='100px' />
            </div>
          </div>
          <div className='row mt-5'>
            <div className='col-12'>
              <Skeleton height='180px' />
            </div>
            <div className='col-12 d-flex justify-content-center my-3'>
              <Skeleton height='200px' />
            </div>
            <div className='col-12'>
              <Skeleton height='200px' />
            </div>
          </div>
          <div className='row my-5'>
            <div className='col-12'>
              <Skeleton height='200px' />
            </div>
          </div>
        </div>
      </div>
    )
  )
}

export { ConfirmVote }
